import { ref } from 'vue'

import { defineStore } from 'pinia'

import api from '@/services/api'
import notification, { type ToastId } from '@/services/notification'

import { useSiteStore } from '@/stores/site'
import { useDashboardStore } from '@/stores/dashboard'

/**
 * @description Edit Pages Dashboard Store
 */
export const useEditPageStore = defineStore('edit-pages', () => {
    const siteStore = useSiteStore()
    const dashboardStore = useDashboardStore()

    // states
    const isOpenModal = ref(false)

    const loadingUpdatePage = ref(false)
    const loadingClonePage = ref(false)
    const loadingDeletePage = ref(false)

    const pageForEdit = ref<any>({})
    // fim states

    // actions
    const setIsOpenModal = (value: boolean) => {
        isOpenModal.value = value
    }

    const setPageForEdit = (page: any) => {
        pageForEdit.value = page

        pageForEdit.value.visible = page.visible == 1 ? true : false
        pageForEdit.value.menu_visible = page.menu_visible == 1 ? true : false
    }

    const setLoadingUpdatePage = (value: boolean) => {
        loadingUpdatePage.value = value
    }

    const setLoadingClonePage = (value: boolean) => {
        loadingClonePage.value = value
    }

    const setLoadingDeletePage = (value: boolean) => {
        loadingDeletePage.value = value
    }

    const handleUpdatePage = async () => {
        if (!pageForEdit.value.name || pageForEdit.value.name.trim() === '') {
            notification.simple('Preencha o nome da página', 'warning')
            return;
        }

        setLoadingUpdatePage(true)
        const toastId: ToastId = notification.loading("Atualizando página...");
        
        try {
            const response = await api.post(
                `site/${siteStore.id}/category/${pageForEdit.value.id}/update`,
                JSON.stringify(pageForEdit.value)
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on update page')
            }

            setIsOpenModal(false)

            dashboardStore.fetchPages()

            notification.update(toastId, 'Página atualizada com sucesso', 'success')
        } catch (error) {
            console.warn(error)

            notification.update(toastId, 'Erro ao atualizar página', 'error')
        } finally {
            setLoadingUpdatePage(false)
        }
    }

    const handleClonePage = async () => {
        setLoadingClonePage(true)

        const toastId: ToastId = notification.loading('Clonando página...')

        try {
            const response = await api.post(
                `site/${siteStore.id}/post/${pageForEdit.value.id}/duplicate`,
                null
            )
        
            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on clone page')
            }

            setIsOpenModal(false)

            dashboardStore.fetchPages()

            notification.update(toastId, 'Página clonada com sucesso', 'success')

        } catch (error) {
            console.warn(error)

            notification.update(toastId, 'Erro ao clonar página', 'error')
        }

        setLoadingClonePage(false)
    }

    const handleDeletePage = async (option: any = 1) => {
        setLoadingDeletePage(true)

        const toastId: ToastId = notification.loading('Excluindo página...')

        try {
            const response = await api.post(
                `site/${siteStore.id}/category/${pageForEdit.value.id}/${option}/delete`,
                null
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on delete page')
            }

            setIsOpenModal(false)

            if (pageForEdit.value.id == dashboardStore.activePage.id) {
                dashboardStore.handleClickPage(dashboardStore.defaultPages[1])
            }

            dashboardStore.fetchPages()

            notification.update(toastId, 'Página excluída com sucesso', 'success')
        } catch (error) {
            console.warn(error)

            notification.update(toastId, 'Erro ao excluir página', 'error')
        }

        setLoadingDeletePage(false)
    }

    const reset = () => {
        setIsOpenModal(false)
        setLoadingDeletePage(false)
        setPageForEdit({})
    }
    // fim actions

    return {
        isOpenModal,
        pageForEdit,
        loadingDeletePage,
        loadingUpdatePage,
        setIsOpenModal,
        setPageForEdit,
        handleUpdatePage,
        handleClonePage,
        handleDeletePage,
        reset
    }
})
