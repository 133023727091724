<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
        <path d="M0 0.875C0 0.391751 0.391751 0 0.875 0H2.625C3.10825 0 3.5 0.391751 3.5 0.875V2.625C3.5 3.10825 3.10825 3.5 2.625 3.5H0.875C0.391751 3.5 0 3.10825 0 2.625V0.875Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M5.25 0.875C5.25 0.391751 5.64175 0 6.125 0H7.875C8.35825 0 8.75 0.391751 8.75 0.875V2.625C8.75 3.10825 8.35825 3.5 7.875 3.5H6.125C5.64175 3.5 5.25 3.10825 5.25 2.625V0.875Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M10.5 0.875C10.5 0.391751 10.8918 0 11.375 0H13.125C13.6082 0 14 0.391751 14 0.875V2.625C14 3.10825 13.6082 3.5 13.125 3.5H11.375C10.8918 3.5 10.5 3.10825 10.5 2.625V0.875Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M0 6.125C0 5.64175 0.391751 5.25 0.875 5.25H2.625C3.10825 5.25 3.5 5.64175 3.5 6.125V7.875C3.5 8.35825 3.10825 8.75 2.625 8.75H0.875C0.391751 8.75 0 8.35825 0 7.875V6.125Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M5.25 6.125C5.25 5.64175 5.64175 5.25 6.125 5.25H7.875C8.35825 5.25 8.75 5.64175 8.75 6.125V7.875C8.75 8.35825 8.35825 8.75 7.875 8.75H6.125C5.64175 8.75 5.25 8.35825 5.25 7.875V6.125Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M10.5 6.125C10.5 5.64175 10.8918 5.25 11.375 5.25H13.125C13.6082 5.25 14 5.64175 14 6.125V7.875C14 8.35825 13.6082 8.75 13.125 8.75H11.375C10.8918 8.75 10.5 8.35825 10.5 7.875V6.125Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M0 11.375C0 10.8918 0.391751 10.5 0.875 10.5H2.625C3.10825 10.5 3.5 10.8918 3.5 11.375V13.125C3.5 13.6082 3.10825 14 2.625 14H0.875C0.391751 14 0 13.6082 0 13.125V11.375Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M5.25 11.375C5.25 10.8918 5.64175 10.5 6.125 10.5H7.875C8.35825 10.5 8.75 10.8918 8.75 11.375V13.125C8.75 13.6082 8.35825 14 7.875 14H6.125C5.64175 14 5.25 13.6082 5.25 13.125V11.375Z" fill="#060606" fill-opacity="0.8"/>
        <path d="M10.5 11.375C10.5 10.8918 10.8918 10.5 11.375 10.5H13.125C13.6082 10.5 14 10.8918 14 11.375V13.125C14 13.6082 13.6082 14 13.125 14H11.375C10.8918 14 10.5 13.6082 10.5 13.125V11.375Z" fill="#060606" fill-opacity="0.8"/>
    </svg>
</template>

