<script setup lang="ts">
import { ref, watchEffect, onBeforeUnmount } from 'vue'

const props = defineProps<{
    id?: string
    title?: string
    value?: number
    conversionRate?: number | string
    list?: any[]
}>()

const animatedValue = ref(0)
const showDetails = ref(false)

const toggleDetails = () => {
    showDetails.value = !showDetails.value
}

watchEffect(onInvalidate => {
    let start = 0
    const end = props.value || 0
    const duration = 1000

    const step = (timestamp: number) => {
        if (!start) start = timestamp
        const progress = (timestamp - start) / duration
        animatedValue.value = Math.floor(Math.min(progress * end, end))

        if (progress < 1) {
            requestAnimationFrame(step)
        }
    }

    requestAnimationFrame(step)

    onInvalidate(() => {
        animatedValue.value = end
    })
})

onBeforeUnmount(() => {
    animatedValue.value = 0
})
</script>

<template>
    <div
        :id="id"
        class="w-full h-48 bg-white relative drop-shadow-md rounded-xl flex flex-col justify-center items-center"
    >
        <button
            v-if="conversionRate || list"
            @click="toggleDetails"
            class="absolute top-2 right-3 text-[10px] font-semibold"
        >
            <span v-if="!showDetails">Ver detalhes</span>

            <span v-else>Ocultar detalhes</span>
        </button>

        <h2 class="text-lg text-gray-400 font-light">
            {{ title }}
        </h2>

        <div class="flex justify-center items-center space-x-3">
            <h3 class="text-4xl font-bold">
                {{ animatedValue }}
            </h3>

            <span v-if="showDetails" class="mt-2 text-[10px] text-gray-300 font-medium">
                {{ conversionRate ? `${conversionRate}%` : '' }}
            </span>
        </div>

        <div v-if="showDetails" class="flex justify-center items-center space-x-2">
            <p
                v-for="(item, key) in list?.filter(item => item.interactionOriginValue != 0)"
                :key="key"
                class="text-[8px] text-gray-400"
            >
                <span class="text-black font-bold mr-0.5">
                    {{ item.interactionOriginValue }}
                </span>

                {{ item.interactionOriginName }}
            </p>
        </div>
    </div>
</template>
