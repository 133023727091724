<script lang="ts" setup>
import { ref, watch } from 'vue';

import BaseModal from '@/components/molecules/BaseModal.vue';

import { useAiStore } from '@/stores/modal/ai';

const aiStore = useAiStore();
    
const propt = ref<string>('')

const onCloseModal = () => {
    aiStore.reset()

    propt.value = ''
}

const handleAction = () => {
    if (aiStore.action) {
        aiStore.action(propt.value)
    }
}

watch(() => aiStore.isOpenModal, (value: boolean) => {
    if (value) {
        propt.value = ''
    }
})
</script>

<template>
    <base-modal
        :open-watch="aiStore.isOpenModal"
        @modal-closed="() => onCloseModal()"
        title="Gerar texto com IA"
        size="md"
        rounded="2xl"
    >
        <template #body>
            <div class="flex flex-col h-full">
                <div class="flex-1">
                    <textarea
                        v-model="propt"
                        class="w-full h-28 p-6 bg-[#F5F5F5] rounded-lg outline-none"
                        name="propt"
                        id="propt"
                        rows="5"
                        placeholder="ex: Texto sobre a importância da iluminação na fotografia"
                    ></textarea>
                </div>

                <div class="flex justify-end mt-[20px]">
                    <button
                        class="bg-[--main-color] text-white px-8 py-3 rounded-full disabled:bg-gray-500 disabled:cursor-not-allowed"
                        @click="() => handleAction()"
                        :disabled="!propt"
                    >
                        Gerar meu texto
                    </button>
                </div>
            </div>                
        </template>
    </base-modal>
</template>

<style>
</style>