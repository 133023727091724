import { reactive, toRefs } from 'vue'

import { defineStore } from 'pinia'

export const useEditorDefaultStore = defineStore('editor-default', () => {
    // states
    const state = reactive({
        margin: {
            top: '16',
            right: '0',
            bottom: '16',
            left: '0'
        },
        margin_mobile: {
            top: '8',
            right: '0',
            bottom: '8',
            left: '0'
        },
        margin_mobile_lock: true,
        padding: {
            top: '24',
            right: '24',
            bottom: '24',
            left: '24'
        },
        padding_mobile: {
            top: '12',
            right: '12',
            bottom: '12',
            left: '12'
        },
        padding_mobile_lock: true,
        newSection: {
            frameId: null,
            frameType: 'post',
            frameDataId: null,
            frameStatus: 1,
            component: 'maincontent-default',
            extra: {
                'title[show]': ['title'],
                maincontent_sidebar: '0',
                maincontent_comments: '0',
                maincontent_share_buttons: '0',
                'title[background]': 'none',
                'title[height]': 'fixed',
                'title[font_size]': 'font-32 font-md-48',
                'title[font_weight]': '700',
                'title[letter_spacing]': '0',
                'title[text_align]': 'left',
                'title[vertical_align]': 'start',
                'title[text_transform]': 'initial',
                maincontent_gallery: 'gallery-isotope',
                maincontent_gallery_items_crop: '0',
                maincontent_gallery_items: '4',
                maincontent_gallery_items_mobile: '3',
                maincontent_gallery_padding: '0',
                title: {
                    show:['title'],
                    background:'photo'
                },
                maincontent_container: 'container',
                maincontent_vertical_padding: 'section-vertical-padding-medium',
                background_image_focal_point: { 
                    x: 50,
                    y: 50
                },
                background_image_opacity: 80,
                background_color: ''
            },
            grid: 12,
            gridMobile: 12,
            frameOrigin: null,
            action: null,
            actionType: 0,
            subPagesCategories: [],
            frameContent: [],
            options: null,
            backgroundImage: null,
            coverImage: null,
            mainImage: { url: null, width: null, height: null, class: null }
        },
        newElementText: {
            action: null,
            actionType: 0,
            component: null,
            extra: {},
            frameContent: '',
            frameDataId: null,
            frameId: null,
            frameOrigin: null,
            frameStatus: 1,
            frameType: 'text',
            grid: 12,
            gridMobile: 12
        },
        newElementImage: {
            action: null,
            actionType: 0,
            component: null,
            extra: {
                full_width: false,
                parallax: false,
                style: {
                    width: '200px',
                    height: '200px'
                },
                style_height_lock: true,
                style_width_lock: true
            },
            frameContent: '',
            frameDataId: null,
            frameId: null,
            frameOrigin: null,
            frameStatus: 1,
            frameType: 'image',
            grid: 12,
            gridMobile: 12,
            width: null,
            height: null
        },
        newElementVideo: {
            action: null,
            actionType: 0,
            component: null,
            extra: {},
            frameContent: {
                embed: '',
                thumbnail: ''
            },
            frameDataId: null,
            frameId: null,
            frameOrigin: null,
            frameStatus: 1,
            frameType: 'video',
            grid: 12,
            gridMobile: 12
        },
        newElementGallery: {
            frameId: null,
            frameType: 'gallery',
            frameDataId: null,
            frameOrigin: null,
            text: '',
            frameContent: {
                items: [],
                showText: "0",
                text: null
            },
            extra: {},
            grid: 12,
            gridMobile: 12
        },
        newElementGalleryItem: {
            type: 'image',
            id: null,
            image: {
                url: null,
                width: '',
                height: '',
                alt: null,
                createdAt: ''
            },
            title: null,
            subtitle: null,
            href: null,
            embed: null,
            embedFile: null,
            target: '_self',
            button: false,
            buttonText: null,
            page: null,
            likes: 0,
            comments: 0,
            location: null,
            date: null,
            autoplay: false,
            expand: false,
            embedMobile: null,
            buttonList: [],
            focalPoint: {
                x: 50,
                y: 50
            }
        },
        newElementTitle: {
            action: null,
            actionType: 0,
            component: null,
            extra: {
                style: {
                    fontSize: '32px',
                    fontWeight: '700',
                    textAlign: 'left',
                    color: ''
                },
                style_font_size_lock: true,
                style_font_weight_lock: true,
                style_text_align_lock: true
            },
            frameContent: 'Este é um título.',
            frameDataId: null,
            frameId: null,
            frameOrigin: null,
            frameStatus: 1,
            frameType: 'title',
            grid: 12,
            gridMobile: 12
        },
        newElementComment: {
            frameId: null,
            frameType: 'comment',
            frameDataId: null,
            frameStatus: 1,
            component: null,
            extra: null,
            grid: 12,
            gridMobile: 12,
            frameOrigin: null,
            action: null,
            actionType: null,
            subPagesCategories: [],
            frameContent: {
                save_comment: 1,
                source: '',
                datetime: '',
                name: '',
                text: '',
                score: 0,
                lenght: 12,
                picture: '',
                approval: false,
                url: null,
                target: '_blank',
                buttonText: null
            }
        }
    })

    return {
        ...toRefs(state)
    }
})
