<script lang="ts" setup>
import { computed } from 'vue'

import BaseModal from '@/components/molecules/BaseModal.vue'
import MediaManagerOptions from '@/components/molecules/MediaManagerOptions.vue'
import MediaManagerBody from '@/components/molecules/MediaManagerBody.vue'
import { useMediaManagerStore } from '@/stores/modal/media-manager'

const mediaManagerStore = useMediaManagerStore()

const isOpenModal = computed(() => mediaManagerStore.isOpenModal)
</script>

<template>
    <base-modal
        :open-watch="isOpenModal"
        header="center"
        size="full"
        extra-class="px-[10vw] py-[3vh]"
        :closed-disabled="mediaManagerStore.closeDisabled"
        @modal-closed="mediaManagerStore.setIsOpenModal(false)"
        not-scroll-body
    >
        <template #trigger="{ open }">
            <slot name="trigger" :open="open"></slot>
        </template>

        <template #header>
            <p class="media-manager-title">Gerenciador de Mídia</p>
        </template>

        <template #body>
            <media-manager-options />

            <media-manager-body />
        </template>
    </base-modal>
</template>

<style scoped>
.media-manager-title {
    font-family: 'Open Sans';
    @apply text-base font-bold text-[#060606] opacity-50;
}
</style>
