<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'

import BaseInputField from '@/components/atoms/BaseInputField.vue'
import DashboardLayout from '@/components/layouts/DashboardLayout.vue'

import { useSiteStore } from '@/stores/site'
import BaseModal from '@/components/molecules/BaseModal.vue'

const tagName = ref('')
const closeButton = ref()

const siteStore = useSiteStore()

const tags = computed(() => siteStore.tags)

const siteForEdit = ref({
    userEmail: '',
    activeSegment: '',
    activeSubsegments: [],
    userArtisticName: '',
    siteLanguage: '',
    siteDescription: '',
    h1: '',
    document: '',
    location: '',
    cookiebar: false,
    blockCopyImage: false
})

onMounted(() => {
    siteForEdit.value = JSON.parse(
        JSON.stringify({
            userEmail: siteStore.mainEmail,
            activeSegment: siteStore.segment.segment,
            activeSubsegments: siteStore.segment.subSegments,
            userArtisticName: siteStore.name,
            siteLanguage: siteStore.language,
            siteDescription: siteStore.siteDescription,
            h1: siteStore.seoText,
            document: siteStore.documentId,
            location: siteStore.location,
            cookiebar: siteStore.cookiebar,
            blockCopyImage: siteStore.blockCopyImage
        })
    )
})

const onCreateTag = () => {
    siteStore.handleCreateTag(tagName.value)

    tagName.value = ''
    closeButton.value.click()
}

const onDeleteTag = (tagId: number) => {
    siteStore.handleDeleteTag(tagId)
}

const onUpdateSeo = () => {
    siteStore.handleUpdateSeo(siteForEdit.value)
}

const checkChanges = computed(() => {
    return (
        siteForEdit.value.userEmail !== siteStore.mainEmail ||
        siteForEdit.value.activeSegment !== siteStore.segment.segment ||
        siteForEdit.value.userArtisticName !== siteStore.name ||
        siteForEdit.value.siteLanguage !== siteStore.language ||
        siteForEdit.value.siteDescription !== siteStore.siteDescription ||
        siteForEdit.value.h1 !== siteStore.seoText ||
        siteForEdit.value.document !== siteStore.documentId ||
        siteForEdit.value.location !== siteStore.location ||
        siteForEdit.value.cookiebar !== siteStore.cookiebar ||
        siteForEdit.value.blockCopyImage !== siteStore.blockCopyImage
    )
})
</script>

<template>
    <dashboard-layout id="DashboardSeo">
        <div class="max-w-full py-6 px-10 mb-20 space-y-5">
            <div>
                <h1 class="text-center text-3xl font-bold">SEO</h1>

                <p class="text-center text-sm font-light -mt-1 text-slate-700">
                    SEO é a otimização do seu site para melhorar o posicionamento no Google e outros
                    buscadores.
                </p>
            </div>

            <base-input-field
                label="TÍTULO DO SITE"
                id="userArtisticName"
                label-size="text-base"
                label-color="text-black"
            >
                <p class="text-sm font-light text-slate-700">
                    Nome comercial que aparece no título do navegador, após o nome da página
                    acessada.
                </p>

                <input
                    v-model="siteForEdit.userArtisticName"
                    type="text"
                    id="userArtisticName"
                    name="userArtisticName"
                />
            </base-input-field>

            <base-input-field
                label="IDIOMA DO SITE"
                id="siteLanguage"
                label-size="text-base"
                label-color="text-black"
                type="select"
            >
                <p class="text-sm font-light text-slate-700">Selecione o idoma do site.</p>

                <select
                    v-model="siteForEdit.siteLanguage"
                    class="w-full py-2 bg-transparent outline-none border-b-[1px] focus:border-[var(--main-color)]"
                    id="siteLanguage"
                    name="siteLanguage"
                >
                    <option value="pt-br">Português (Brasil)</option>
                    <option value="en">Inglês</option>
                    <option value="es">Espanhol</option>
                    <option value="it">Italiano</option>
                </select>
            </base-input-field>

            <base-input-field
                label="SEGMENTO"
                id="activeSegments"
                label-size="text-base"
                label-color="text-black"
            >
                <p class="text-sm font-light text-slate-700">
                    Descreva a sua área de atuação. Isso ajuda muito quando visitantes procurarem na
                    internet por esse termo. Por exemplo, caso alguém procure por "Fotógrafos em Rio
                    Preto" e o seu segmento estiver também como "Fotógrafo", as chances do seu site
                    aparecer nessa busca são maiores.
                </p>

                <input
                    v-model="siteForEdit.activeSegment"
                    type="text"
                    id="activeSegment"
                    name="activeSegment"
                />
            </base-input-field>

            <base-input-field
                label="DESCRIÇÃO DO SITE"
                id="siteDescription"
                label-size="text-base"
                label-color="text-black"
            >
                <p class="text-sm font-light text-slate-700">
                    Breve descrição com umas duas linhas que vai aparecer embaixo do nome do seu
                    site nos resultados do Google e outros buscadores.
                </p>

                <textarea
                    v-model="siteForEdit.siteDescription"
                    id="siteDescription"
                    name="siteDescription"
                    rows="5"
                ></textarea>
            </base-input-field>

            <base-input-field
                label="TEXTO DO H1"
                id="h1"
                label-size="text-base"
                label-color="text-black"
            >
                <p class="text-sm font-light text-slate-700">
                    Texto em destaque na página inicial do seu site que resume sua área de atuação.
                    Recomendamos que preencha com sua atividade principal e cidades onde atua (de 1
                    a 3) e em seguida o nome do site, ex: Fotógrafo de casamentos, Uberaba,
                    Uberlândia - MG, Nome do fotógrafo.
                </p>

                <input v-model="siteForEdit.h1" type="text" id="h1" name="h1" />
            </base-input-field>

            <div class="space-y-1">
                <label for="" class="font-semibold">TAGS</label>

                <p class="text-sm font-light text-slate-700">
                    Áreas que atua, cidades, serviços ou produtos.Ex: fotos de casamento, ensaios,
                    pré-wedding, 15 anos, Uberaba, Uberlândia, MG, Minas Gerais.
                </p>

                <div class="flex flex-row space-x-3">
                    <base-modal
                        title="Adicionar tags"
                        size="custom"
                        height="h-[14rem]"
                        width="w-[30rem]"
                        rounded="2xl"
                    >
                        <template #trigger="{ open }">
                            <button @click="open" class="text-3xl font-smibold">+</button>
                        </template>

                        <template #body="{ close }">
                            <div class="h-full flex flex-col justify-between">
                                <div class="flex-1">
                                    <base-input-field label="Nome da tag">
                                        <input type="text" v-model="tagName" />
                                    </base-input-field>
                                </div>

                                <div class="flex justify-end mt-4 space-x-2">
                                    <button
                                        ref="closeButton"
                                        @click="close"
                                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                                    >
                                        Cancelar
                                    </button>

                                    <button
                                        @click="onCreateTag"
                                        class="px-4 py-2 text-sm font-medium rounded-full outline-none text-white bg-green-500 hover:bg-green-600"
                                    >
                                        Adicionar
                                    </button>
                                </div>
                            </div>
                        </template>
                    </base-modal>

                    <div class="flex flex-row flex-wrap space-x-2">
                        <span
                            v-for="tag in tags"
                            :key="tag"
                            class="px-4 py-2 text-sm font-medium rounded-full outline-none text-white bg-gray-300 hover:bg-gray-400"
                        >
                            {{ tag.name }}

                            <button @click="onDeleteTag(tag.id)">
                                <span class="sr-only">Delete Tag</span>

                                x
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <base-input-field label="DICAS" label-size="text-base" label-color="text-black">
                <p class="text-sm font-light text-slate-700">
                    Pense como o seu cliente irá procurar o seu trabalho para definir as
                    palavras-chaves adequadas. Utilize o Google Trends para saber termos mais
                    pesquisados:
                    <a href="https://trends.google.com" class="text-blue-500">
                        www.google.com/trends
                    </a>
                </p>
            </base-input-field>

            <div class="">
                <span class="font-semibold text-base">
                    CONFIGURAÇÕES DO SITE
                </span>

                <div class="space-x-3">
                    <label for="show-menu" class="relative inline-flex items-center cursor-pointer">
                        <input
                            v-model="siteForEdit.cookiebar"
                            type="checkbox"
                            name="show-menu"
                            id="show-menu"
                            class="sr-only peer"
                        />

                        <div
                            class="w-11 h-6 bg-gray-200 outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--main-color)]"
                        ></div>

                        <span class="ml-3 text-sm font-medium text-gray-900">
                            Barra informativa - Cookies
                        </span>
                    </label>

                    <label for="blockCopyImage" class="relative inline-flex items-center cursor-pointer">
                        <input
                            v-model="siteForEdit.blockCopyImage"
                            type="checkbox"
                            name="blockCopyImage"
                            id="blockCopyImage"
                            class="sr-only peer"
                        />

                        <div
                            class="w-11 h-6 bg-gray-200 outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--main-color)]"
                        ></div>

                        <span class="ml-3 text-sm font-medium text-gray-900">
                            Inibir cópia de imagem
                        </span>
                    </label>
                </div>
            </div>

            <base-input-field
                label="CNPJ"
                id="document"
                label-size="text-base"
                label-color="text-black"
            >
                <input
                    id="document"
                    name="document"
                    v-model="siteForEdit.document"
                    type="text"
                    v-maska
                    data-maska="##.###.###/####-##"
                />
            </base-input-field>
        </div>

        <button
            :disabled="!checkChanges"
            @click="onUpdateSeo"
            class="fixed bottom-10 left-1/2 -translate-x-1/2 px-10 py-4 bg-[var(--main-color)] text-white font-semibold rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
        >
            Salvar alterações
        </button>
    </dashboard-layout>
</template>

<style></style>
