<script lang="ts" setup>
import { ref, computed } from 'vue'

import { onClickOutside } from '@vueuse/core'

import clsx from 'clsx'

import SettingsSection from '@/components/molecules/SettingsSection.vue'
import SettingsText from '@/components/molecules/SettingsText.vue'
import SettingsContainer from '@/components/molecules/SettingsContainer.vue'
import SettingsImage from '@/components/molecules/SettingsImage.vue'
import SettingsTitle from '@/components/molecules/SettingsTitle.vue'
import SettingsButton from '@/components/molecules/SettingsButton.vue'
import SettingsBeforeAfter from '@/components/molecules/SettingsBeforeAfter.vue'
import SettingsForm from '@/components/molecules/SettingsForm.vue'
import SettingsGallery from '@/components/molecules/SettingsGallery.vue'
import SettingsRow from '@/components/molecules/SettingsRow.vue'
import SettingsDefault from '@/components/molecules/SettingsDefault.vue'

import { useEditorSidebarStore } from '@/stores/editor/sidebar'
import { useEditorPageStore } from '@/stores/editor/page'

const editorSidebarStore = useEditorSidebarStore()
const editorPageStore = useEditorPageStore()

const isOpen = computed(() => editorSidebarStore.isOpen)
const disabledClosed = computed(() => editorSidebarStore.disabledClosed)
const configsReference = computed(() => editorSidebarStore.configsReference)

const sidebarEditor = ref()

const onCloseSidebar = () => {
    editorSidebarStore.reset()

    editorPageStore.setSectionForEdit(null)

    editorPageStore.setElementForEdit(null)
}

onClickOutside(sidebarEditor, (e: any) => {
    if (
        isOpen.value &&
        typeof e.srcElement.className === 'string' &&
        (e.srcElement.className.includes('vc-') ||
            e.srcElement.className.includes('color-') ||
            e.target.localName == 'input')
    )
        return

    if (disabledClosed.value) return

    onCloseSidebar()
})

const currentConfigurationComponent = () => {
    switch (configsReference.value) {
        case 'section':
            return SettingsSection

        case 'text':
            return SettingsText

        case 'container':
            return SettingsContainer

        case 'image':
            return SettingsImage

        case 'title':
            return SettingsTitle

        case 'button':
            return SettingsButton

        case 'beforeAfterImage':
            return SettingsBeforeAfter

        case 'form':
            return SettingsForm

        case 'gallery':
            return SettingsGallery

        case 'row': 
            return SettingsRow

        default:
            return SettingsDefault
    }
}
</script>

<template>
    <aside ref="sidebarEditor" :class="clsx('sidebar-editor', isOpen ? 'open' : 'close')">
        <div class="sidebar-content">
            <div class="sidebar-header">
                <button @click="onCloseSidebar">
                    <span class="sr-only">Close Sidebar</span>

                    <svg
                        class="w-6 h-6 stroke-[#060606]"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                    >
                        <path
                            d="M18 6L6 18M18 18L6 6"
                            stroke-opacity="0.3"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </button>
            </div>

            <div class="sidebar-body">
                <component :is="currentConfigurationComponent()" />
            </div>
        </div>
    </aside>
</template>

<style scoped lang="scss">
.sidebar-editor {
    @apply fixed top-0 bottom-0 right-0 h-full bg-[#FAFAFA] transition-all duration-300 z-50;
    box-shadow: -10px 0px 36px 0px rgba(18, 18, 18, 0.12);

    &.open {
        @apply w-[16rem] sm:w-[18rem] md:w-[20rem] lg:w-[22rem] xl:w-[24rem];

        .sidebar-content {
            @apply flex;
        }
    }

    &.close {
        @apply w-0;

        .sidebar-content {
            @apply hidden;
        }
    }

    .sidebar-content {
        @apply h-full w-full flex flex-col p-6 space-y-9;
    }

    .sidebar-header {
        @apply flex justify-start items-center space-x-3;
    }

    .sidebar-body {
        @apply flex-1 flex flex-col justify-start items-start space-y-9 overflow-y-scroll;
    }
}
</style>
