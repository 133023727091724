<script setup lang="ts">
import { computed } from 'vue'
import clsx from 'clsx'

import { useDashboardStore } from '@/stores/dashboard'
import { useMakePagesStore } from '@/stores/modal/make-pages'

const dashboardStore = useDashboardStore()
const makePagesStore = useMakePagesStore()

const pages = computed(() => dashboardStore.pages)
const loadingPages = computed(() => dashboardStore.loadingPages)
</script>

<template>
    <div class="w-full h-16 flex space-x-5">
        <div class="flex items-center space-x-3 w-auto">
            <!--
            <button
                v-tooltip="{
                    text: 'Pesquisa'
                }"
                @click="dashboardStore.handleClickPage(dashboardStore.defaultPages[0])"
                :class="
                    clsx(
                        'py-2 px-4 relative bg-transparent focus:outline-none cursor-pointer',
                        dashboardStore.activePage.pageType == 'search' &&
                            'after:h-1.5 after:w-1.5 after:content-[\'\'] after:bg-[var(--main-color)] after:absolute after:-bottom-2 after:left-1/2 after:transform after:-translate-x-1/2 after:rounded-full after:transition-all after:duration-300 after:ease-in-out'
                    )
                "
            >
                <span class="sr-only">Pesquisa</span>

                <svg
                    class="w-5 h-5 stroke-black opacity-60"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                </svg>
            </button>
            -->

            <button
                v-tooltip="{
                    text: 'Dashboard'
                }"
                @click="dashboardStore.handleClickPage(dashboardStore.defaultPages[1])"
                :class="
                    clsx(
                        'py-2 px-4 rounded-full relative bg-[var(--main-color)] focus:outline-none cursor-pointer',
                        dashboardStore.activePage.pageType == 'dashboard' &&
                            'after:h-1.5 after:w-1.5 after:content-[\'\'] after:bg-[var(--main-color)] after:absolute after:-bottom-2 after:left-1/2 after:transform after:-translate-x-1/2 after:rounded-full after:transition-all after:duration-300 after:ease-in-out'
                    )
                "
            >
                <span class="sr-only">Dashboard</span>

                <svg
                    class="w-5 h-5 fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"
                    />
                    <path
                        d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"
                    />
                </svg>
            </button>
        </div>

        <div class="flex items-center space-x-1 w-full overflow-x-auto">
            <template v-if="!loadingPages">
                <button
                    v-for="(page, index) in pages.filter(
                        page => page.pageType != 'search' && page.pageType != 'dashboard'
                    )"
                    :key="index"
                    :class="
                        clsx(
                            'py-2 px-4 whitespace-nowrap relative bg-transparent focus:outline-none cursor-pointer',
                            dashboardStore.activePage.id == page.id &&
                                'after:h-1.5 after:w-1.5 after:content-[\'\'] after:bg-[var(--main-color)] after:absolute after:-bottom-2 after:left-1/2 after:transform after:-translate-x-1/2 after:rounded-full after:transition-all after:duration-300 after:ease-in-out'
                        )
                    "
                    @click="dashboardStore.handleClickPage(page)"
                >
                    <span
                        :class="
                            clsx(
                                dashboardStore.activePage.id == page.id
                                    ? 'text-black'
                                    : 'text-gray-400',
                                'hover:text-black font-medium w-full break-words'
                            )
                        "
                    >
                        {{ page.name }}
                    </span>
                </button>
            </template>

            <template v-else>
                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-20"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-28"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-20"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-16"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-28"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-20"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-28"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-20"></div>

                <div class="cursor-wait animate-pulse h-10 bg-gray-100 rounded-full w-16"></div>
            </template>
        </div>

        <div class="flex items-center w-auto">
            <button
                @click="() => makePagesStore.setIsOpenModal(true)"
                v-tooltip="{
                    text: 'Adicionar Página',
                    background: 'main'
                }"
                class="py-1 px-3 rounded-full relative flex items-center justify-center border-2 text-[var(--main-color)] border-[var(--main-color)] focus:outline-none cursor-pointer"
            >
                <span class="sr-only">Adicionar Página</span>

                <svg
                    class="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                </svg>
            </button>
        </div>
    </div>
</template>
