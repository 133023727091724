<script lang="ts" setup>
import { watch, ref, computed, markRaw } from 'vue'

import clsx from 'clsx'

import BaseModal from '@/components/molecules/BaseModal.vue'

import { useModalPresetStore } from '@/stores/modal/presets'

import BaseImage from '@/components/atoms/BaseImage.vue'

import IconGridCol1 from '@/components/atoms/icons/IconGridCol1.vue'
import IconGridCol2 from '@/components/atoms/icons/IconGridCol2.vue'
import IconGridCol3 from '@/components/atoms/icons/IconGridCol3.vue'

const presetStore = useModalPresetStore()

const isOpenModal = computed(() => presetStore.isOpenModal)

const presets = computed(() => presetStore.presetsFiltered)
const filters = computed(() => presetStore.filters)
const activeFilter = computed(() => presetStore.activeFilter)

const cols = ref([
    {
        id: 2,
        name: '2',
        value: 2,
        icon: markRaw(IconGridCol2),
    },
    {
        id: 3,
        name: '3',
        value: 3,
        icon: markRaw(IconGridCol3),
    },
    {
        id: 1,
        name: '1',
        value: 1,
        icon: markRaw(IconGridCol1),
    },
])

const colActive = ref(cols.value[0].value)

const setColActive = (value: number) => {
    colActive.value = value
}

watch(isOpenModal, value => {
    if (value) {
        colActive.value = cols.value[0].value
    }
})

const onClickCancel = () => {
    presetStore.handleCancel()
}

const onClickConfirm = () => {
    presetStore.handleConfirm()
}
</script>

<template>
    <base-modal
        :open-watch="isOpenModal"
        @modal-closed="() => onClickCancel()"
        title="Modelos"
        size="custom"
        width="w-[60vw]"
        height="h-[65vh]"
        rounded="2xl"
        container-size="sm"
    >
        <template #body>
            <div class="w-full h-full flex-col overflow-hidden">
                <div class="w-full h-[90%] flex overflow-hidden">
                    <div class="my-4 pr-20 border-r-2 overflow-y-scroll">
                        <ul class="mt-2 py-1 space-y-4">
                            <li
                                v-for="filter in filters"
                                :key="filter.id"
                                @click="() => presetStore.setActiveFilter(filter.value)"
                                :class="clsx('text-base font-bold cursor-pointer', { 'text-[var(--main-color)]': activeFilter == filter.value })"
                            >
                                {{ filter.name }}
                            </li>
                        </ul>
                    </div>

                    <div class="w-full h-full px-8 relative overflow-y-scroll">
                        <div class="py-1 sticky top-0 bg-white flex justify-end items-center">
                            <div class="bg-[#F5F5F5] p-1 rounded-md flex justify-center items-center gap-3">
                                <button
                                    v-for="col in cols"
                                    :key="col.id"
                                    :class="clsx(
                                        'rounded p-2',
                                        colActive == col.value && 'bg-white' 
                                    )"
                                    @click="() => setColActive(col.value)"
                                >
                                    <component
                                        :is="col.icon"
                                        class="w-4 h-4"
                                    />
                                </button>
                            </div>
                        </div>

                        <div :class="clsx(
                            'mt-[12px] w-full h-full grid gap-4',
                            `grid-cols-${colActive}`,
                        )">
                            <button 
                                v-for="preset in presets" :key="preset.id"
                                :class="clsx(
                                    'w-full h-full border-2 rounded',
                                    preset.id == presetStore.presetSelected?.id ? 'border-[var(--main-color)]' : 'border-transparent'
                                )"
                                @click="() => presetStore.setPresetSelected(preset)"
                            >
                                <base-image
                                    :src="preset.image"
                                    :alt="preset.name"
                                    class="w-full h-full object-contain rounded"
                                    externalImage
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="w-full h-[10%] flex items-center">
                    <div class="w-full h-full flex justify-end items-center">
                        <button
                            @click="() => onClickCancel()"
                            class="px-6 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                        >
                            Cancelar
                        </button>

                        <button
                            @click="() => onClickConfirm()"
                            class="px-6 py-2 text-sm font-medium rounded-full outline-none bg-[var(--main-color)] text-white hover:bg-[#060606] disabled:bg-slate-500"
                            :disabled="!presetStore.presetSelected"
                        >
                            Adicionar
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </base-modal>
</template>
