<script setup lang="ts">
import DashboardLayout from '@/components/layouts/DashboardLayout.vue'
import api from '@/services/api';

import { ref, onBeforeMount } from 'vue'

import { useSiteStore } from '@/stores/site'
import BaseInputField from '@/components/atoms/BaseInputField.vue';

const siteStore = useSiteStore()

const redirects = ref<any[]>([])
const loadingRedirects = ref(false)

const handleAddRedirect = () => {
    console.log('Add redirect')
    let item = {
        from:{
            id: null,
            path: ''
        },
        to:{
            id: null,
            path: ''
        },
        error: ''
    };

    redirects.value.push(item);
}

const removeRedirect = (index: number) => {
    redirects.value.splice(index, 1);
}

const fetchRedirects = async () => {
    loadingRedirects.value = true;

    try {
        const response = await api.get(`site/${siteStore.id}/route/redirect`);

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error('Error fetching redirects.');
        }

        redirects.value = data;
    } catch (error) {
        console.error(error);
    }

    loadingRedirects.value = false;
}

onBeforeMount(async () => {
    await fetchRedirects();
})

const slug = (str: string) => {
    if(!str){
        return '/';
    }
    
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·,:;";
    var to   = "aaaaeeeeiiiioooouuuunc----";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/\(/g, '');
    str = str.replace(/\)/g, '');

    str = str.replace(/[^a-z0-9 -|.]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

const handleSaveRedirects = async () => {
    loadingRedirects.value = true;

    try {
        for (let index = 0; index < redirects.value.length; index++) {
            redirects.value[index].from.path = slug(redirects.value[index].from.path);
            redirects.value[index].to.path = slug(redirects.value[index].to.path);
        }

        const response = await api.post(
            `site/${siteStore.id}/route/redirect/create`,
            JSON.stringify({
                routes: redirects.value
            })
        );

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error('Error fetching redirects.');
        }

        redirects.value = data;
    } catch (error) {
        console.error(error);
    }

    loadingRedirects.value = false;
}
</script>

<template>
    <dashboard-layout id="DashboardRedirects">
        <div class="max-w-full py-6 px-10 space-y-5">
            <div class="w-full flex justify-between items-center">
                <div class="flex items-center space-x-3">
                    <h1 class="text-3xl font-bold">Redirecionamentos</h1>
                </div>

                <button
                    @click="() => handleAddRedirect()"
                    class="px-4 py-2 bg-[var(--main-color)] font-semibold text-white rounded-full"
                >
                    + Novo
                </button>
            </div>

            <div v-if="loadingRedirects" class="text-center">
                <p>Carregando...</p>
            </div> 
           
            <div v-else-if="redirects.length == 0">
                <p>
                    Você ainda não possui redirecionamentos de url cadastrados. Clique no botão
                    "Novo" para criar um redirecionamento.
                </p>
            </div>

            <div
                v-else
                class="space-y-3"
            >
                <div 
                    v-for="(redirect, index) in redirects"
                    :key="index"
                    class="w-full flex-col space-y-2"
                >
                    <div class="flex w-full items-center justify-center space-x-3">
                        <div class="w-full">
                            <base-input-field
                                label="URL Acessada"
                            >
                                <input
                                    v-model="redirect.from.path"
                                    type="text"
                                    placeholder="De"
                                />
                            </base-input-field>
                        </div>

                        <div class="w-full">
                            <base-input-field
                                label="Destino"
                            >
                                <input
                                    v-model="redirect.to.path"
                                    type="text"
                                    placeholder="Para"
                                />
                            </base-input-field>
                        </div>

                        <div class="flex-1">
                            <button
                                @click="() => removeRedirect(index)"
                                class="px-4 py-2 bg-red-500 font-semibold text-white rounded-full"
                            >
                                Remover
                            </button>
                        </div>
                    </div>

                    <p class="text-xs text-red-500">
                        {{ redirect.error }}
                    </p>
                </div>
            </div>

            <button 
                :disabled="loadingRedirects"
                @click="() => handleSaveRedirects()"
                class="fixed bottom-6 left-1/2 -translate-x-1/2 px-6 py-3 bg-[var(--main-color)] text-white rounded-full disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
                Salvar
            </button>
        </div>
    </dashboard-layout>
</template>

<style></style>
