<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingImage from '@/components/atoms/icons/IconSettingImage.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingImageSelector from '@/components/atoms/SettingImageSelector.vue';
import SettingLink from '@/components/atoms/SettingLink.vue';
import SettingImageWidth from '@/components/atoms/SettingImageWidth.vue';
import SettingImageHeight from '@/components/atoms/SettingImageHeight.vue';
import SettingBorderRadius from '@/components/atoms/SettingBorderRadius.vue';

import SettingWidth from '@/components/atoms/SettingWidth.vue'
import SettingMargin from '@/components/atoms/SettingMargin.vue';
import SettingPadding from '@/components/atoms/SettingPadding.vue';

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()

const editorPageStore = useEditorPageStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeMargin = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin: {
                ...editorPageStore.elementForEdit.extra.margin,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile: {
                ...editorPageStore.elementForEdit.extra.margin_mobile,
                [event.target]: event.value
            }
        }
    }
    
    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangePadding = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding: {
                ...editorPageStore.elementForEdit.extra.padding,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile: {
                ...editorPageStore.elementForEdit.extra.padding_mobile,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile_lock: event.value
        }
    }
    
    editorPageStore.updateElementForEdit()
}

const settings = ref([
    {
        id: 1,
        name: 'image',
        icon: markRaw(IconSettingImage)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])

const onChangeImageSelector = (event: any) => {
    editorPageStore.elementForEdit.frameContent = event.value.content

    editorPageStore.updateElementForEdit()
}

const onChangeLink = (event: any) => {
    if (event.target == 'link') {
        editorPageStore.elementForEdit.action = event.value
    }

    if (event.target == 'open-in') {
        editorPageStore.elementForEdit.actionType = event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeStyle = (event: any) => {
    if (event.target == 'width') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                width: event.value
            }
        }
    }

    if (event.target == 'height') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                height: event.value
            }
        }
    }

    if (event.target == 'border-radius') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                borderRadius: event.value
            }
        }
    }

    if (event.target == 'style_width_lock') {
        editorPageStore.elementForEdit.extra.style_width_lock = event.value
    }

    if (event.target == 'style_height_lock') {
        editorPageStore.elementForEdit.extra.style_height_lock = event.value
    }

    if (event.target == 'style_border_radius_lock') {
        editorPageStore.elementForEdit.extra.style_border_radius_lock = event.value
    }

    editorPageStore.updateElementForEdit()
}
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #image>
            <settings-layout
                name="Configurações da Imagem"
                :default-open="true"
            >
                <setting-image-selector
                    label="Imagem"
                    :value="elementForEdit.frameContent"
                    @change="onChangeImageSelector"
                />

                <setting-link
                    label="Link"
                    type="number"
                    :value="elementForEdit.action"
                    :value-open-in="elementForEdit.actionType"
                    @change="onChangeLink"
                />

                <setting-image-width
                    label="Largura da Imagem"
                    :value="elementForEdit.extra?.style.width"
                    :lock="elementForEdit.extra?.style_width_lock"
                    @change="onChangeStyle"
                />

                <setting-image-height
                    label="Altura da Imagem"
                    :value="elementForEdit.extra?.style.height"
                    :lock="elementForEdit.extra?.style_height_lock"
                    @change="onChangeStyle"
                />

                <setting-border-radius
                    label="Borda Arredondada"
                    :value="elementForEdit.extra.style?.borderRadius"
                    :lock="elementForEdit.extra?.style_border_radius_lock"
                    @change="onChangeStyle"
                />
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                name="Configurações do Conteúdo"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit.grid"
                    :value-mobile="elementForEdit.gridMobile"
                    :values-permission-desktop="authStore.accessType == 'admin' ? [3, 4, 6, 12, 99] : [4, 6, 12, 99]"
                    :values-permission-mobile="[4, 6, 12, 99]"
                    @change="onChangeWidth"
                />
            </settings-layout>

            <settings-layout
                name="Avançado"
            >
                <setting-margin
                    label="Margem Externa"
                    :value="elementForEdit.extra?.margin"
                    :value-mobile="elementForEdit.extra?.margin_mobile"
                    :lock="elementForEdit.extra?.margin_mobile_lock"
                    @change="onChangeMargin"
                />

                <setting-padding
                    label="Margem Interna"
                    :value="elementForEdit.extra?.padding"
                    :value-mobile="elementForEdit.extra?.padding_mobile"
                    :lock="elementForEdit.extra?.padding_mobile_lock"
                    @change="onChangePadding"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
