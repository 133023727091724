<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingContainer from '@/components/atoms/icons/IconSettingContainer.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingHorizontalAlign from '@/components/atoms/SettingHorizontalAlign.vue';
import SettingVerticalAlign from '@/components/atoms/SettingVerticalAlign.vue';
import SettingColorPicker from '@/components/atoms/SettingColorPicker.vue'
import SettingImageSelector from '@/components/atoms/SettingImageSelector.vue'
import SettingOpacity from '@/components/atoms/SettingOpacity.vue'
import SettingWidth from '@/components/atoms/SettingWidth.vue'
import SettingMargin from '@/components/atoms/SettingMargin.vue';
import SettingPadding from '@/components/atoms/SettingPadding.vue';

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'
import { useLayoutStore } from '@/stores/layout'

const editorPageStore = useEditorPageStore()
const layoutStore = useLayoutStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const isCustomBackgroundColor = computed(() => {
    return elementForEdit.value.extra?.background_color ? true : false
})

const backgroundColor = computed(() => {
    if (isCustomBackgroundColor.value) {
        return elementForEdit.value.extra?.background_color
    }

    return layoutStore.getSectionBackgroundColorDefault(elementForEdit.value.sectionIndex)
})

const backgroundImage = computed(() => {
    return elementForEdit.value.extra?.background_image ?? null
})

const backgroundImageOpacity = computed(() => {
    return elementForEdit.value.extra?.background_image_opacity ?? "80"
})

const backgroundFocalPoint = computed(() => {
    return (
        elementForEdit.value.extra?.background_image_focal_point ?? {
            x: 50,
            y: 50
        }
    )
})

const onChangeBackgroundColor = (color: string) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        background_color: color
    }

    editorPageStore.updateElementForEdit()
}

const onChangeImageSelector = (event: any) => {
    if (event.target == 'image') {
        onChangeBackgroundImage(event.value)

        return
    }

    if (event.target == 'focal-point') {
        onChangeBackgroundImageFocalPoint(event.value)

        return
    }
}

const onChangeBackgroundImage = (image: any) => {
    if (!image) {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            background_image: {
                id: null,
                uri: null,
                width: null,
                height: null
            }
        }

        editorPageStore.updateElementForEdit()

        return
    }

    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        background_image: {
            id: image.id,
            uri: image.content,
            width: image.width,
            height: image.height
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangeBackgroundImageOpacity = (opacity: number) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        background_image_opacity: opacity
    }

    editorPageStore.updateElementForEdit()
}

const onChangeBackgroundImageFocalPoint = (focalPoint: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        background_image_focal_point: focalPoint
    }

    editorPageStore.updateElementForEdit()
}

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeMargin = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin: {
                ...editorPageStore.elementForEdit.extra?.margin,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile: {
                ...editorPageStore.elementForEdit.extra?.margin_mobile,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangePadding = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding: {
                ...editorPageStore.elementForEdit.extra?.padding,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile: {
                ...editorPageStore.elementForEdit.extra?.padding_mobile,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangeVerticalAlign = (event: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        vertical_align: event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeHorizontalAlign = (event: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        horizontal_align: event.value
    }

    editorPageStore.updateElementForEdit()
}

const settings = ref([
    {
        id: 1,
        name: 'row',
        icon: markRaw(IconSettingContainer)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #row>
            <settings-layout
                :name="`Configurações do Fundo`"
                :default-open="true"
            >
                <setting-color-picker
                    label="Cor de Fundo"
                    :value="backgroundColor"
                    :default-value="
                        layoutStore.getSectionBackgroundColorDefault(elementForEdit.sectionIndex) ?? ''
                    "
                    @change="onChangeBackgroundColor"
                />

                <setting-image-selector
                    label="Imagem de Fundo"
                    :value="backgroundImage?.uri"
                    with-focal-point
                    with-remove
                    :focal-point="backgroundFocalPoint"
                    @change="onChangeImageSelector"
                />

                <setting-opacity
                    v-if="backgroundImage?.uri"
                    label="Opacidade da imagem"
                    :value="backgroundImageOpacity"
                    @change="onChangeBackgroundImageOpacity"
                />
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                :name="`Configurações do Conteúdo`"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit.grid"
                    :value-mobile="elementForEdit.gridMobile"
                    :values-permission-desktop="[12, 99]"
                    :values-permission-mobile="[12, 99]"
                    @change="onChangeWidth"
                />
                
                <!-- 
                <setting-vertical-align
                    label="Alinhamento Vertical"
                    :value="elementForEdit.extra.vertical_align"
                    :has-lock="false"
                    @change="onChangeVerticalAlign"
                /> -->

                <!--
                <setting-horizontal-align
                    label="Alinhamento Horizontal"
                    :value="elementForEdit.extra.horizontal_align"
                    :has-lock="false"
                    @change="onChangeHorizontalAlign"
                />
                -->
            </settings-layout>

            <settings-layout
                :name="`Avançado`"
            >
                <setting-margin
                    label="Margem Externa"
                    :value="elementForEdit.extra?.margin"
                    :value-mobile="elementForEdit.extra?.margin_mobile"
                    :lock="elementForEdit.extra?.margin_mobile_lock"
                    @change="onChangeMargin"
                />

                <setting-padding
                    label="Margem Interna"
                    :value="elementForEdit.extra?.padding"
                    :value-mobile="elementForEdit.extra?.padding_mobile"
                    :lock="elementForEdit.extra?.padding_mobile_lock"
                    @change="onChangePadding"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
