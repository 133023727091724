<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { useSidebarDashboardStore } from '@/stores/dashboard/sidebar'
import DashboardSidebar from '@/components/organisms/DashboardSidebar.vue'
import router from '@/router'
import { useDashboardStore } from '@/stores/dashboard'
import { useSiteStore } from '@/stores/site'

import BaseImage from '@/components/atoms/BaseImage.vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

import { useApplicationStore } from '@/stores/application'

import api from '@/services/api'
import type { ToastId } from '@/services/notification'
import notification from '@/services/notification'

const applicationStore = useApplicationStore()

const menuAnimation = ref(false)
const sidebarDashboardStore = useSidebarDashboardStore()
const dashboardStore = useDashboardStore()
const siteStore = useSiteStore()

const avatar = ref()

function handleMenu() {
    // menuAnimation.value = !menuAnimation.value;
    sidebarDashboardStore.toggleMenu()
}

// function brandImage() {
// return this.$store.state.application.app_logo;
// let url = window.location.hostname;
// if (url === "painels.sitejet.com.br") {
//     return "https://sitejet.com.br/img/Logo-Sitejet.png";
// } else {
//     return "https://ulisite.com/img/logo-uli.svg";
// }
// }

function goDashboard() {
    dashboardStore.handleClickPage(dashboardStore.defaultPages[1])

    router.push('/dashboard')
}

function goPages() {
    sidebarDashboardStore.setMenuOption('pages')
}

const domain = computed(() => {
    return '//' + siteStore.url
})

const onChangeAvatar = () => {
    avatar.value.click()

    avatar.value.addEventListener('change', handleChangeAvatar)
}

const handleChangeAvatar = async ($event: any) => {
    const toastId: ToastId = notification.loading('Atualizando avatar...')

    try {
        const file = $event.target.files[0]

        const str = file.name.toLowerCase().split(".");
        const ext = str[str.length - 1];
        const suported = ["jpeg", "jpg", "png", "gif"];

        if (!suported.includes(ext)) {
            notification.update(toastId, 'Formato de imagem não suportado', 'error')
            return;
        }
        
        const formData = new FormData();
        formData.append("image", file);

        const { data } = await api.file('site/' + siteStore.id + '/avatar/update', formData);

        if (data.status != '1') {
            throw new Error('Error ao atualizar avatar');
        }

        siteStore.avatar = data.image;

        notification.update(toastId, 'Sucesso ao atualizar avatar', 'success')
    } catch (error) {
        console.error(error)
        notification.update(toastId, 'Erro ao atualizar avatar', 'error')
    }
}

const goUserDashboard = () => {
    if(applicationStore.panelUrl){
        window.location.href = applicationStore.panelUrl;
    }
}

const logout = async () => {
    try {
        const response = await api.get('site/' + siteStore.id + '/post/imported/update');

        const { status, message } = await response.json();

        if ( status != '1') {
            throw new Error(message);
        }

        const urlLogout = applicationStore.appBaseDomain;

        localStorage.clear();

        if (urlLogout) {
            if (urlLogout.includes('epics.vc')) {
                window.location.replace("https://epics.com.br/dashboard")
            }

            window.location.replace("https://" + urlLogout);
        } else {
            router.push("/login");
        }
    } catch (error) {
        console.error(error);
    }
}

const fetchAvatar = async () => {
    try {
        const response = await api.get('site/' + siteStore.id);

        const data = await response.json();
        
        
        if (data.image == null || data.image == '' || data.image == undefined) {

            return;
        }

        siteStore.avatar = data.image;

        siteStore.refreshSiteData();
    } catch (error) {
        console.error(error);
    }
}

onBeforeMount(async () => {
    await fetchAvatar()
})

const avatarUrl = computed(() => {
    if (siteStore.avatar) {
        return siteStore.avatar
    }

    return '/images/default-avatar.png'
})
</script>

<template>
    <div
        id="dashboard-menu"
        class="z-10 fixed top-0 left-0 right-0 h-[74px] w-full bg-white px-4 flex shadow-lg py-3"
    >
        <div class="w-1/3 flex justify-start items-center">
            <ul class="ul d-flex align-items-center mr-3">
                <li>
                    <div>
                        <div class="menu-aniation" v-if="menuAnimation">
                            <span
                                class="icon-menu position-absolute bg-[var(--main-color)] ball-animation-menu"
                            ></span>
                            <span
                                class="icon-menu position-absolute bg-[var(--main-color)] ball-animation-menu-small"
                            ></span>
                        </div>

                        <button
                            id="button-open-dashboard-sidebar"
                            class="icon-menu p-3 cursor-pointer"
                            @click="handleMenu"
                        >
                            <span class="sr-only">Menu</span>

                            <svg
                                class="w-8 h-8 text-gray-500 hover:text-gray-700"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </button>
                    </div>
                </li>
            </ul>

            <dashboard-sidebar />

            <button @click="goDashboard" class="hidden md:flex py-2 px-2 mr-2">
                <span class="text-[var(--main-color)] font-bold text-base">Início</span>
            </button>

            <button @click="goPages" class="hidden md:flex py-2 px-2" target="_blank" href="">
                <span class="text-[var(--main-color)] font-bold text-base">Páginas</span>
            </button>
        </div>

        <div class="w-1/3 flex justify-center items-center">
            <div class="logo-uli">
                <img
                    :src="applicationStore.appLogo"
                    class="img-fluid cursor-pointer"
                    alt="logo"
                    @click="goDashboard()"
                />
            </div>
        </div>

        <div class="w-1/3 flex justify-end items-center">
            <a id="domainSite" class="py-2 px-3 mr-4" target="_blank" :href="domain">
                <span class="hidden md:flex text-[var(--main-color)] font-bold text-base">
                    Ver site
                </span>

                <span class="flex md:hidden text-[var(--main-color)] font-bold text-base">
                    Site
                </span>
            </a>

            <Menu as="div" class="relative inline-block text-left">
                <div>
                    <MenuButton
                        class="inline-flex w-full justify-center"
                    >
                        <base-image
                            :src="avatarUrl"
                            alt="avatar"
                            class="w-12 h-12 rounded-full"
                            :external-image="avatarUrl.includes('default-avatar.png')"
                        />
                    </MenuButton>
                </div>

                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <MenuItems
                        class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                    >
                        <div class="px-1 py-1">
                            <div class="relative flex items-center justify-center px-4 py-6">
                                <base-image
                                    :src="avatarUrl"
                                    alt="avatar"
                                    class="w-20 h-20 rounded-md"
                                    :external-image="avatarUrl.includes('default-avatar.png')"
                                />

                                <input ref="avatar" type="file" class="hidden">

                                <button
                                    @click="() => onChangeAvatar()"
                                    class="absolute bottom-2 left-1/2 translate-x-4 py-3 px-3 rounded-full bg-gray-100"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-4 h-4"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <hr>
                            
                            <MenuItem>
                                <button
                                    @click="() => goUserDashboard()"
                                    class="group flex w-full items-center rounded-md px-2 py-2 text-base"
                                >
                                    Perfil
                                </button>
                            </MenuItem>

                            <hr>

                            <MenuItem>
                                <button
                                    @click="() => logout()"
                                    class="group flex w-full items-center rounded-md px-2 py-2 text-base"
                                >
                                    Sair
                                </button>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
    </div>
</template>

<style scoped>
a {
    color: #111;
}

a:hover {
    text-decoration: none;
}

.menu-user-panel {
    text-align: left;
    position: fixed;
    right: 15px;
    top: 75px;
    width: 290px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 1px 9px 20px 4px #ccc;
    border-radius: 8px;
    z-index: 30;
}

.menu-user-panel a {
    color: #111;
}

.menu-user-panel a:hover {
    text-decoration: none;
}

.menu-avatar-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.logo-uli img {
    max-height: 45px;
}

#domainSite {
    color: var(--main-color);
}

#domainSite:hover {
    text-decoration: none;
}

.nav-tabs {
    padding-bottom: 20px;
}

.menu-content {
    display: inline-block;
    width: 375px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 11;
    background-color: rgb(255, 255, 255);
}

.andBtn {
    background-color: fade(var(--main-color), 60%);
}

.ball-animation-menu {
    width: 50px;
    height: 50px;
    opacity: 0.2;
    border-radius: 50%;
    left: 41px;
    top: 10px;
    animation: ballMenuBig 2s infinite;
    z-index: 0 !important;
}

@keyframes ballMenuBig {
    to {
        transform: scale(1.3);
        opacity: 0;
    }

    from {
        transform: scale(0.3);
    }
}

.ball-animation-menu-small {
    width: 30px;
    height: 30px;
    opacity: 0.3;
    border-radius: 50%;
    left: 51px;
    top: 20px;
    animation: ballMenuSmall 2s infinite;
    z-index: 0 !important;
}

@keyframes ballMenuSmall {
    to {
        transform: scale(1.3);
        opacity: 0;
    }

    from {
        transform: scale(0.3);
    }
}

.overlay-menu {
    top: 0;
    left: 0;
    z-index: 98;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #2b2931ab;
}

.menu-overflow {
    overflow-y: auto;
    overflow-x: hidden;
}

.menu-option {
    z-index: 99;
    background: #fff;
    min-height: 100vh;
    position: relative;
}

@media all and (max-width: 500px) {
    .menu-avatar-image {
        width: 24px;
        height: 24px;
    }

    .menu-content {
        width: 100%;
    }

    .logo-uli img {
        max-height: 24px;
    }
}

@media all and (min-width: 992px) {
}

.menu {
    width: 100%;
    padding-top: 5px;
}

.txt-name {
    color: black;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
}

.txt-site {
    color: gray;
    font-size: 0.6em;
}

.menu-list {
    padding-left: 0;
    margin-bottom: 0;
}

.menu-list li {
    list-style-type: none;
    color: black;
    padding: 20px 0;
    border-top: 1px solid;
    border-color: #d4d4d44d;
    transition: all 100ms;
    min-height: 47px;
    padding-left: 30px;
    font-size: 16px;
}

.menu-list li:hover {
    cursor: pointer;
}

.menu-list i {
    font-size: 16px;
}

.menu-list .fa-chevron-right {
    color: #333333 !important;
    margin-right: 30px;
}

.navigation-menu {
    color: var(--main-color);
}

.navigation-menu span:hover {
    cursor: pointer;
}

.close-menu {
    font-size: 1.2rem;
}

.add-item-seo {
    border-radius: 50px;
    padding: 3px 6px;
    background-color: var(--main-color);
    margin: 5px 2px 5px 0;
    display: inline-block;
    color: white;
    font-size: 0.7em;
}

.dashboard-component label {
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 700;
}

.total-avatar {
    padding: 7px 0;
}

.total-avatar p {
    margin-bottom: 3px;
}

.total-avatar img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.avatar-button {
    background-color: var(--main-color);
    color: white;
    border: none;
    border-radius: 50px;
    padding: 8px 12px;
    margin-left: -28px;
    position: relative;
    bottom: -46px;
    cursor: pointer;
}
</style>
