import { ref } from 'vue'

import { defineStore } from 'pinia'
import api from '@/services/api'

/**
 * @description Make Pages Dashboard Store
 */
export const useMakePagesStore = defineStore('make-pages', () => {
    // states
    const isOpenModal = ref(false)

    const combinations = ref()

    const combinationsDefault = ref([
        {
            id: 'unic-page',
            variables: {},
            picture: 'https://uli-pictures.s3.sa-east-1.amazonaws.com/pagina-unica.png',
            component: 'maincontent',
            options: {}
        },
        {
            id: 'folder-page',
            variables: {},
            hasComments: false,
            hasSidebar: false,
            picture: 'https://uli-pictures.s3.sa-east-1.amazonaws.com/pagina-pastas.png',
            component: 'blog',
            options: {}
        }
    ])

    const filtersPage = ref<any[]>([
        {
            id: 1,
            name: 'Página em branco',
            default: false,
            component: 'default',
            options: { frames: ['text'] },
            description: `
                <p class="text-xs">
                    Você pode criar uma página em branco ou começar com um layout predefinido escolhendo o tipo acima.
                    
                    <br>

                    Caso queira uma página em branco, escolha abaixo se vai ser uma página com listagem de outras páginas (tipo blog, videos..) ou única (landing pages, sobre...)
                </p>
            `
        },
        {
            id: 2,
            name: 'Blog',
            component: 'blog',
            default: false,
            options: {},
            steps: [
                {
                    title: 'Estilo da listagem',
                    message: '1º Escolha o layout do seu blog',
                    component: 'blog'
                },
                {
                    title: 'Estilo da galeria',
                    message: '2º Layout dos posts',
                    component: 'maincontent'
                }
            ]
        },
        {
            id: 3,
            name: 'Galerias diversas',
            default: false,
            component: 'blog',
            options: { galleryType: 'isotope' },
            steps: [
                {
                    title: 'Estilo da listagem',
                    message: '1º Escolha o layout da listagem',
                    component: 'blog'
                },
                {
                    title: 'Estilo da galeria',
                    message: '2º Layout das fotos',
                    component: 'maincontent'
                }
            ]
        },
        {
            id: 4,
            name: 'Galeria única',
            default: false,
            component: 'maincontent',
            options: { frames: ['gallery'] },
            description: `
                <p class="text-xs">
                    <b>Galeria única</b> é uma página com uma galeria de fotos, ela pode ser usada para criar uma página de fotos, de produtos, de portfólio, etc.
                </p>
            `
        },
        {
            id: 5,
            name: 'Sobre',
            component: 'about',
            default: true,
            options: {},
            description: `
                <p class="text-xs">
                    <b>Sobre</b> é uma página com uma foto e um texto sobre você ou sua empresa.
                </p>
            `
        },
        {
            id: 6,
            name: 'Formulário',
            default: true,
            component: 'contact',
            options: {},
            description: `
                <p class="text-xs">
                    <b>Formulário</b> é uma página com um formulário de contato para que seus clientes possam entrar em contato com você.
                </p>
            `
        },
        {
            id: 7,
            name: 'Depoimentos',
            default: true,
            component: 'comments',
            options: {},
            description: `
                <p class="text-xs">
                    <b>Depoimentos</b> é uma página com depoimentos de clientes, amigos, parceiros, etc.
                </p>
            `
        },
        {
            id: 8,
            name: 'Newsletter',
            default: true,
            component: 'newsletter',
            options: {},
            description: `
                <p class="text-xs">
                    <b>Newsletter</b> é uma página com um formulário para que seus clientes possam se cadastrar em sua newsletter.
                </p>
            `
        },
        {
            id: 9,
            name: 'Loja',
            default: true,
            component: 'storelist',
            options: {},
            description: `
                <p class="text-xs">
                    <b>Loja</b> é uma página com uma listagem de produtos, ela pode ser usada para criar uma página de produtos, de portfólio, etc.
                </p>
            `
        },
        {
            id: 10,
            name: 'Link externo',
            default: false,
            component: 'link',
            options: {},
            description: `
                <p class="text-xs">
                    Páginas do tipo <b>'Link Externo'</b> não possuem layouts, ela serve para criar um link personalizado no menu do seu site
                </p>
            `
        }
    ])

    const step = ref(0)

    const loadingCombinations = ref(false)

    const filterActive = ref<any>()

    const filterStep = ref(0)

    const combinationActive = ref<any>([])

    const loadingCreatePage = ref(false)
    // fim states

    // getters
    // fim getters

    // actions
    const fetchCombinations = async () => {
        loadingCombinations.value = true

        try {
            const response = await api.get('template/organism/combination')

            const { success, message } = await response.json()

            if (!success) {
                throw new Error(message)
            }

            setCombinations(message)
        } catch (error) {
            console.warn(error)
        }

        loadingCombinations.value = false
    }

    const setCombinations = (data: any) => {
        combinations.value = data
    }

    const setFilterActive = (filter: any) => {
        combinationActive.value = []

        filterActive.value = filter
    }

    const setCombinationActive = (combination: any, filterStep: number = 0) => {
        combinationActive.value[filterStep] = combination
    }

    const setFilterStep = (step: number) => {
        filterStep.value = step
    }

    const setStep = (value: number) => {
        step.value = value
    }

    const previousStep = () => {
        step.value = step.value - 1
    }

    const nextStep = () => {
        step.value = step.value + 1
    }

    const reset = () => {
        setStep(0)

        setFilterStep(0)

        setFilterActive(filtersPage.value[0])

        setCombinationActive([])

        setIsOpenModal(false)
    }

    const setIsOpenModal = (value: boolean) => {
        isOpenModal.value = value
    }

    const setLoadingCreatePage = (value: boolean) => {
        loadingCreatePage.value = value
    }
    // fim actions

    return {
        filtersPage,
        combinations,
        filterActive,
        loadingCombinations,
        step,
        combinationActive,
        combinationsDefault,
        filterStep,
        isOpenModal,
        loadingCreatePage,
        setCombinationActive,
        fetchCombinations,
        setFilterActive,
        setStep,
        previousStep,
        nextStep,
        setFilterStep,
        reset,
        setIsOpenModal,
        setLoadingCreatePage
    }
})
