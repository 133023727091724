<script lang="ts" setup>
import { ref, computed, watch } from 'vue'

import clsx from 'clsx'

import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'

const props = defineProps({
    openWatch: {
        required: false,
        type: Boolean,
        default: false
    },
    title: {
        required: false,
        type: String
    },
    header: {
        required: false,
        type: String
    },
    footer: {
        required: false,
        type: String
    },
    showButtonCloseHeader: {
        required: false,
        type: Boolean,
        default: true
    },
    closedDisabled: {
        required: false,
        type: Boolean,
        default: false
    },
    size: {
        required: false,
        type: String,
        default: 'lg'
    },
    width: {
        required: false,
        type: String,
        default: 'w-[80vw]'
    },
    height: {
        required: false,
        type: String,
        default: 'h-[80vh]'
    },
    containerSize: {
        required: false,
        type: String,
        default: 'md'
    },
    extraClass: {
        required: false,
        type: String
    },
    rounded: {
        required: false,
        type: String,
        default: '2xl'
    },
    notScrollBody: {
        required: false,
        type: Boolean,
        default: false
    }
})

const modal = ref()

const isOpen = ref(false)
const emit = defineEmits(['modal-closed'])

function setIsOpen(value: boolean) {
    isOpen.value = value
}

const modalHeaderType = computed(() => {
    switch (props.header) {
        case 'between':
            return 'modal-header-between'

        case 'center':
            return 'modal-header-center'

        default:
            return 'modal-header-default'
    }
})

const modalSize = computed(() => {
    switch (props.size) {
        case 'sm':
            return 'modal-sm'

        case 'md':
            return 'modal-md'

        case 'lg':
            return 'modal-lg'

        case 'custom':
            return props.width + ' ' + props.height

        default:
        case 'full':
            return 'modal-full'
    }
})

const modalRounded = computed(() => {
    if (props.size == 'full') {
        return 'rounded-none'
    }

    switch (props.rounded) {
        default:
        case 'sm':
            return 'modal-rounded-sm'

        case 'md':
            return 'modal-rounded-md'

        case 'lg':
            return 'modal-rounded-lg'

        case 'xl':
            return 'modal-rounded-xl'

        case '2xl':
            return 'modal-rounded-2xl'

        case 'full':
            return 'modal-rounded-full'
    }
})

const modalContainerSize = computed(() => {
    switch (props.containerSize) {
        default:
        case 'sm':
            return 'container-sm'

        case 'md':
            return 'container-md'

        case 'lg':
            return 'container-lg'
    }
})

const showButtonClose = computed(() => {
    return props.showButtonCloseHeader
})

const close = () => {
    if (props.closedDisabled) {
        return
    }

    setIsOpen(false)

    emit('modal-closed')
}

watch(
    () => props.openWatch,
    value => {
        if (value != null) {
            setIsOpen(value)
        }
    }
)
</script>

<template>
    <slot name="trigger" :open="() => setIsOpen(true)"></slot>

    <template v-if="isOpen">
        <TransitionRoot
            :show="isOpen"
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
            <Dialog :initial-focus="modal" as="div" @close="close">
                <!-- BACKDROP -->
                <div @click="close" className="modal-backdrop"></div>

                <!-- MODAL -->
                <div
                    ref="modal"
                    className="modal-wrapper"
                >
                    <TransitionChild
                        :class="clsx('modal-panel', modalSize, modalRounded)"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel :class="clsx('modal-container', modalContainerSize, extraClass)">
                            <header :class="modalHeaderType">
                                <div class="modal-container-title">
                                    <button
                                        v-if="showButtonClose"
                                        @click="close"
                                        class="modal-button-close"
                                        :disabled="closedDisabled"
                                    >
                                        <span class="sr-only">Close Modal</span>

                                        <svg
                                            class="w-4 h-4"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M4.63552 3.20005L1.59961 6.27704M1.59961 6.27704L4.63552 9.35404M1.59961 6.27704L11.1381 6.27704C12.9394 6.27704 14.3996 7.73727 14.3996 9.53855V9.53855C14.3996 11.3398 12.9394 12.8 11.1381 12.8L7.99961 12.8"
                                                stroke="#060606"
                                                stroke-opacity="0.3"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </button>

                                    <h2 v-if="title" class="text-2xl font-bold">
                                        {{ title }}
                                    </h2>
                                </div>

                                <slot name="header"></slot>
                            </header>

                            <div
                                :class="
                                    clsx(
                                        'modal-body scroll-hidden',
                                        !notScrollBody && 'overflow-y-scroll'
                                    )
                                "
                            >
                                <slot name="body" :close="close"></slot>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </template>
</template>

<style lang="scss" scoped>
.modal-backdrop {
    @apply fixed inset-0 bg-black opacity-30 z-50;
}

.modal-wrapper {
    @apply fixed top-1/2 -translate-y-1/2 left-0 right-0 z-50 flex justify-center items-center;
}

.modal-container {
    @apply w-full h-full flex flex-col relative;
}

.modal-container.container-sm {
    @apply px-[3%] py-[1%];
}

.modal-container.container-md {
    @apply px-[5%] py-[3%];
}

.modal-container.container-lg {
    @apply px-[10%] py-[5%];
}

.modal-panel {
    @apply transform bg-white shadow-xl transition-all relative flex justify-center items-center;
}

.modal-panel.modal-sm {
    @apply w-[40vw] h-[40vh];
}

.modal-panel.modal-md {
    @apply w-[90vw] h-auto xl:w-[60vw] xl:h-[60vh];
}

.modal-panel.modal-lg {
    @apply w-[80vw] h-[80vh];
}

.modal-panel.modal-full {
    @apply w-[100vw] h-[100vh];
}

.modal-header-default {
    @apply flex flex-row items-center p-4 space-x-4;
}

.modal-header-default .modal-container-title,
.modal-header-between .modal-container-title {
    @apply flex flex-row items-center space-x-4;
}

.modal-header-between {
    @apply w-full flex justify-between items-center p-4;
}

.modal-header-center {
    @apply w-full flex relative justify-center items-center p-4;
}

.modal-header-default .modal-button-close,
.modal-header-between .modal-button-close {
    @apply p-2 bg-[#F9F9F9] rounded-full outline-none disabled:cursor-not-allowed;
}

.modal-header-center .modal-button-close {
    @apply p-2 bg-[#F9F9F9] rounded-full outline-none absolute top-1/2 -translate-y-1/2 left-4;
}

.modal-body {
    @apply h-full w-full p-4 relative;
}

.modal-rounded-sm {
    @apply rounded-sm;
}

.modal-rounded-md {
    @apply rounded-md;
}

.modal-rounded-lg {
    @apply rounded-lg;
}

.modal-rounded-xl {
    @apply rounded-xl;
}

.modal-rounded-2xl {
    @apply rounded-2xl;
}

.modal-rounded-full {
    @apply rounded-full;
}

.modal-footer {
    @apply p-4 absolute bottom-0 left-0 right-0 bg-transparent;
}

.modal-footer.modal-footer-default {
    @apply float-right;
}
</style>
