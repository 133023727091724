<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'

import BaseImage from '@/components/atoms/BaseImage.vue';

import { useLayoutStore } from '@/stores/layout'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const layoutStore = useLayoutStore()

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: [String, Number]
    },
})

const emit = defineEmits(['change'])

const onChangeGalleryType = (value: any) => {
    emit('change', {
        target: 'gallery-type',
        value: value
    })
}

const options = ref([
    {
        id: 0,
        name: 'Galeria padrão',
        hasComments: false,
        hasSidebar: false,
        picture: 'https://uli-pictures.s3.sa-east-1.amazonaws.com/design/maincontent/maincontent_gallery-gallery-default.svg',
        variables: {
            maincontent_gallery: 'gallery-default',
            maincontent_gallery_items: '',
            maincontent_gallery_items_mobile: '',
            maincontent_gallery_items_crop: '',
            maincontent_gallery_padding: '',
        }
    }
])

onBeforeMount(async () => {
    console.log('SettingGalleryType created')

    await layoutStore.fetchTemplateOrganismsCombinations()

    options.value = [
        ...options.value,
        ...layoutStore.templateOrganismsCombinations.maincontent,
    ]
})

const activeOption = computed(() => options.value.find(option => option.id == props.value))
</script>

<template>
    <div class="space-y-2">
        <label class="text-sm font-semibold text-[#060606] opacity-50">
            {{ label }}
        </label>
        
        <div class="w-full flex items-center justify-between space-x-4">
            <div class="w-full bg-[#EEEEE] px-4 py-2 rounded">
                <Disclosure v-slot="{ open, close }">
                    <DisclosureButton class="w-full flex justify-between items-center">
                        <div class="flex items-center space-x-3">
                            <base-image
                                :src="activeOption?.picture"
                                :alt="activeOption?.variables?.maincontent_gallery"
                                class="w-20 h-16 object-cover"
                                external-image
                            />

                            <span class="text-sm font-bold text-[#060606]">
                                {{ activeOption?.name }}
                            </span>
                        </div>

                        <button v-if="open">
                            <span class="sr-only">Hidden Accordion</span>

                            <svg class="rotate-180" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 7L9.00081 11.58L14 7" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>

                        <button v-else>
                            <span class="sr-only">Open Accordion</span>

                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 7L9.00081 11.58L14 7" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                    </DisclosureButton>

                    <DisclosurePanel class="w-full mt-6 space-y-3">
                        <button
                            v-for="option in options.filter(option => option.id != value)"
                            :key="option.id"
                            @click="() => {onChangeGalleryType(option); close();}"
                            class="w-full flex items-center space-x-4"
                        >
                            <base-image
                                :src="option.picture"
                                :alt="option.variables.maincontent_gallery"
                                external-image
                                class="w-20 h-16 object-cover"
                            />

                            <span class="text-sm font-bold text-[#060606]">
                                {{ option.name }}
                            </span>
                        </button>
                    </DisclosurePanel>
                </Disclosure>
            
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

