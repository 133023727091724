<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingGallery from '@/components/atoms/icons/IconSettingGallery.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingWidth from '@/components/atoms/SettingWidth.vue'
import SettingMargin from '@/components/atoms/SettingMargin.vue';
import SettingPadding from '@/components/atoms/SettingPadding.vue';

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'
import SettingGalleryType from '@/components/atoms/SettingGalleryType.vue';
import SettingGalleryItemsPerLine from '../atoms/SettingGalleryItemsPerLine.vue';

const editorPageStore = useEditorPageStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeMargin = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin: {
                ...editorPageStore.elementForEdit.extra.margin,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile: {
                ...editorPageStore.elementForEdit.extra.margin_mobile,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangePadding = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding: {
                ...editorPageStore.elementForEdit.extra.padding,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile: {
                ...editorPageStore.elementForEdit.extra.padding_mobile,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const settings = ref([
    {
        id: 1,
        name: 'gallery',
        icon: markRaw(IconSettingGallery)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])

const onChangeGalleryType = (event: any) => {
    console.log('onChangeGalleryType', event)
    if (event.value.id == 0) {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            maincontent_gallery_id: event.value.id,
            maincontent_gallery: event.value.variables.maincontent_gallery,
            maincontent_gallery_items: "",
            maincontent_gallery_items_mobile: "",
            maincontent_gallery_items_crop: "",
            maincontent_gallery_padding: ""
        }

        editorPageStore.updateElementForEdit()

        return
    }

    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        maincontent_gallery_id: event.value.id,
        maincontent_gallery: event.value.variables.maincontent_gallery,
        maincontent_gallery_items: event.value.variables.maincontent_gallery_items,
        maincontent_gallery_items_mobile: event.value.variables.maincontent_gallery_items_mobile,
        maincontent_gallery_items_crop: event.value.variables.maincontent_gallery_items_crop,
        maincontent_gallery_padding: event.value.variables.maincontent_gallery_padding
    }

    editorPageStore.updateElementForEdit()
}

const onChangeGalleryItemsPerLine = (event: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        maincontent_gallery_items: event.value
    }

    editorPageStore.updateElementForEdit()
}
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #gallery>
            <settings-layout
                :name="`Configurações da Galeria`"
                :default-open="true"
            >
                <setting-gallery-type
                    label="Design personalizado da galeria"
                    :value="elementForEdit.extra?.maincontent_gallery_id"
                    @change="onChangeGalleryType"
                />

                <setting-gallery-items-per-line
                    label="Itens por linha (Desktop)"
                    :value="elementForEdit.extra?.maincontent_gallery_items"
                    @change="onChangeGalleryItemsPerLine"
                />
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                :name="`Configurações do Conteúdo`"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit.grid"
                    :value-mobile="elementForEdit.gridMobile"
                    :values-permission-desktop="[4, 6, 12]"
                    :values-permission-mobile="[4, 6, 12]"
                    @change="onChangeWidth"
                />
            </settings-layout>

            <settings-layout
                :name="`Avançado`"
            >
                <setting-margin
                    label="Margem Externa"
                    :value="elementForEdit.extra?.margin"
                    :value-mobile="elementForEdit.extra?.margin_mobile"
                    :lock="elementForEdit.extra?.margin_mobile_lock"
                    @change="onChangeMargin"
                />

                <setting-padding
                    label="Margem Interna"
                    :value="elementForEdit.extra?.padding"
                    :value-mobile="elementForEdit.extra?.padding_mobile"
                    :lock="elementForEdit.extra?.padding_mobile_lock"
                    @change="onChangePadding"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
