<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'

import api from '@/services/api';

import countries from '@/data/countries.json'

import DashboardLayout from '@/components/layouts/DashboardLayout.vue'

import draggable from 'vuedraggable';
import BaseModal from '@/components/molecules/BaseModal.vue';
import BaseInputField from '@/components/atoms/BaseInputField.vue';
import SettingImageSelector from '@/components/atoms/SettingImageSelector.vue';

import { useSiteStore } from '@/stores/site'
import { useApplicationStore } from '@/stores/application'

import modal from '@/services/modal';
import MediaManagerModal from '@/components/organisms/MediaManagerModal.vue';
import type { ToastId } from '@/services/notification';
import notification from '@/services/notification';
import { computed } from 'vue';

const siteStore = useSiteStore()
const storeApplication = useApplicationStore()

const initialPage = ref<any>({
    logo: "",
    avatar: "",
    name: "",
    logo_type: "",
    route: "",
    background_file: "",
    description: "",
})

const page = ref({
    logo: "",
    avatar: "",
    name: "",
    logo_type: "",
    route: "",
    background_file: "",
    description: "",
})

const titleModalLink = ref<string>('')

const links = ref<any[]>([])
const newLink = ref<any>({
    route_name: "",
    route_path: "",
    type: 7,
    target_blank: 1,
    typeRoute: "route",
    phone: "",
    ddi: storeApplication.appDefaultIdd ?? 30
})
const isOpen = ref(false)

const fetchLinkBuilding = async () => {
    try {
        const response = await api.get(`site/${siteStore.id}/linkBuilding`);

        const { status, message } = await response.json();

        if (status != '1') {
            throw new Error('Error fetching link building.');
        }

        page.value = message.page;
        initialPage.value = JSON.parse(JSON.stringify(message.page));
        links.value = message.linksBuilding;
    } catch (error) {
        console.error(error);
    }   
}

const handleNewLink = async () => {
    newLink.value.target_blank = newLink.value.target_blank ? 1 : 0;

    const ToastId: ToastId = notification.loading('Adicionando link...');

    try {
        const response = await api.post(
            `site/${siteStore.id}/linkBuilding/create`,
            JSON.stringify(newLink.value)
        );

        const { status, message } = await response.json();

        if (status != '1') {
            throw new Error('Error creating link.');
        }

        links.value.push(message.data);

        notification.update(ToastId, 'Link adicionado com sucesso.', 'success');
    } catch (error) {
        console.error(error);

        notification.update(ToastId, 'Erro ao adicionar link.', 'error');
    }

    onCloseModal();
}

const handleEditLink = async () => {
    newLink.value.target_blank = newLink.value.target_blank ? 1 : 0;

    const ToastId: ToastId = notification.loading('Atualizando link...');

    try {
        const response = await api.post(
            `site/${siteStore.id}/linkBuilding/${newLink.value.id}/update`,
            JSON.stringify(newLink.value)
        );

        const { status, message } = await response.json();

        if (status != '1') {
            throw new Error('Error updating link.');
        }

        const index = links.value.findIndex((link) => link.id == newLink.value.id);

        links.value[index] = message.data;

        notification.update(ToastId, 'Link atualizado com sucesso.', 'success');
    } catch (error) {
        console.error(error);

        notification.update(ToastId, 'Erro ao atualizar link.', 'error');
    }

    onCloseModal();
}

onBeforeMount(async () => {
    await fetchLinkBuilding();
})

const onEndSortLink = async () => {
    try {
        const response = await api.post(
            `site/${siteStore.id}/linkBuilding/sort`,
            JSON.stringify({ linksBuilding: links.value })
        );

        const { status } = await response.json();

        if (status != '1') {
            throw new Error('Error sorting links.');
        }
    } catch (error) {
        console.error(error);
    }
}

const updateForm = async () => {
    // valida se tem ou n alteração para evitar requisições desnecessárias
    if (!checkChanges.value) {
        return;
    }

    const ToastId: ToastId = notification.loading('Atualizando link building...');

    try {
        const request = await api.post(
            `site/${siteStore.id}/linkBuilding/updateForm`,
            JSON.stringify(page.value)
        );

        const { status } = await request.json();

        if (status != '1') {
            throw new Error('Error updating link building.');
        }

        initialPage.value = JSON.parse(JSON.stringify(page.value));

        notification.update(ToastId, 'Link building atualizado com sucesso.', 'success');
    } catch (error) {
        console.error(error);

        notification.update(ToastId, 'Erro ao atualizar link building.', 'error');
    }
}

const onOpenModal = () => {
    isOpen.value = true;

    titleModalLink.value = 'Adicionar Link';
    
    newLink.value = {
        route_name: "",
        route_path: "",
        type: 7,
        target_blank: 1,
        typeRoute: "route",
        phone: "",
        ddi: storeApplication.appDefaultIdd ?? 30
    }
}

const onCloseModal = () => {
    isOpen.value = false;

    newLink.value = {
        route_name: "",
        route_path: "",
        type: 7,
        target_blank: 1,
        typeRoute: "route",
        phone: "",
        ddi: storeApplication.appDefaultIdd ?? 30
    }
}

const handleDeleteLink = async (id: number) => {
    const ToastId: ToastId = notification.loading('Excluindo link...');

    try {
        const request = await api.post(`site/${siteStore.id}/linkBuilding/${id}/delete`, null);
        
        const { status } = await request.json();

        if (status != '1') {
            throw new Error('Error deleting link.');
        }

        links.value = links.value.filter((link) => link.id != id);

        notification.update(ToastId, 'Link excluído com sucesso.', 'success');
    } catch (error) {
        console.error(error);

        notification.update(ToastId, 'Erro ao excluir link.', 'error');
    }
}

const onDeleteLink = async (id: number) => {
    const title = 'Confirmar exclusão'
    const message = 'Deseja realmente excluir este link?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    await handleDeleteLink(id);
}

const onEditLink = async (id: number) => {
    titleModalLink.value = 'Editar Link';

    newLink.value = {
        ...newLink.value,
        ...links.value.find((link) => link.id == id)
    };

    isOpen.value = true;
}

const onChangeTarget = (value: boolean) => {
    newLink.value.target_blank = value ? 1 : 0;
}

const handleChangeBackgroundImage = async (event: any) => {
    if (!event.value) {
        page.value.background_file = "";

        await updateForm();

        return;
    }

    page.value.background_file = event.value.content;

    await updateForm();
}

const handleChangeLogo = async (event: any) => {
    if (!event.value) {
        page.value.logo = "";

        await updateForm();

        return
    }

    page.value.logo = event.value.content;

    await updateForm();
}

const handleChangeAvatar = async (event: any) => {
    page.value.avatar = event.value.content;

    await updateForm();
}

const checkChanges = computed(() => {
    return initialPage.value.logo != page.value.logo ||
        initialPage.value.avatar != page.value.avatar ||
        initialPage.value.name != page.value.name ||
        initialPage.value.logo_type != page.value.logo_type ||
        initialPage.value.route != page.value.route ||
        initialPage.value.background_file != page.value.background_file ||
        initialPage.value.description != page.value.description
})
</script>

<template>
    <dashboard-layout id="DashboardLinkBuilding">
        <media-manager-modal />

        <div class="max-w-full md:max-w-xl lg:max-w-5xl xl:max-w-7xl mx-auto py-6 px-20 space-y-5">
            <div class="flex flex-col md:flex-row">
                <div class="w-2/3 flex flex-col">
                    <h1 class="text-3xl font-bold mb-3">Link Building</h1>
                    
                    <a
                        :href="`//${siteStore.url}/links`"
                        target="_blank"
                        class="text-blue-500"
                    >
                        {{ siteStore.url + '/links' }}
                    </a>

                    <!--
                    <div class="flex items-center" v-if="!showEditRoute">
                        <a :href="'https://' + page.app_short_domain + '/' + page.route" target="_blank" class="text-blue-500">
                            {{ page.app_short_domain|removeHttps }}/{{ page.route }}
                        </a>

                        <i class="fal fa-pen cursor-pointer p-2" @click="showEditRoute = !showEditRoute"></i>
                    </div>

                    <div class="editUrl" v-else style="line-height: 2.5rem;">
                        <span class="float-left">https://{{ page.app_short_domain }}/</span>
                        <input type="text" v-model="page.route" class="form-control float-left" style="max-width: 150px;">
                        <span class="cursor-pointer" @click="editShortUrl()">Salvar</span>
                    </div>
                    -->
                </div>

                <div class="w-1/3 flex flex-col items-end">
                    <setting-image-selector
                        label="Imagem de fundo"
                        :value="page.background_file"
                        :border="false"
                        class-image="w-1/3"
                        :with-change="true"
                        :with-remove="true"
                        @change="(event: any) => handleChangeBackgroundImage(event)"
                    />
                </div>
            </div>

            <div class="flex flex-col items-center space-y-2">
                <div class="flex justify-center">
                    <label for="">Exibir</label>

                    <select
                        class="w-full bg-transparent border-b-[1px] focus:border-[var(--main-color)] outline-none appearance-none"
                        name=""
                        id=""
                        v-model="page.logo_type"
                        @blur="() => updateForm()"
                    >
                        <option value="1">Logo</option>
                        <option value="2">Avatar arredondado</option>
                    </select>
                </div>

                <setting-image-selector
                    v-if="page.logo_type == '1'"
                    label=""
                    :value="page.logo"
                    :border="false"
                    class-image="w-1/3"
                    :with-change="true"
                    :with-remove="true"
                    @change="(event: any) => handleChangeLogo(event)"
                />

                <setting-image-selector
                    v-else
                    label=""
                    :value="page.avatar"
                    :border="false"
                    class-image="w-1/3"
                    :with-change="true"
                    :with-remove="true"
                    @change="(event: any) => handleChangeAvatar(event)"
                />
            </div>

            <div class="flex flex-col space-y-2">
                <label for="">Titulo</label>

                <input
                    class="py-2 bg-transparent border-b-[1px] focus:border-[var(--main-color)] outline-none"
                    type="text"
                    name="name"
                    id="name"
                    v-model="page.name"
                    @blur="() => updateForm()"
                />
            </div>

            <div class="flex flex-col space-y-2">
                <label for="">Descrição</label>

                <textarea
                    class="py-2 bg-transparent border-b-[1px] focus:border-[var(--main-color)] outline-none"
                    name="description"
                    id="description"
                    v-model="page.description"
                    @blur="() => updateForm()"
                ></textarea>
            </div>

            <div class="space-y-2 w-full">
                <div class="w-full flex justify-between items-center">
                    <h2 class="text-2xl font-bold">Links</h2>

                    <base-modal
                        :open-watch="isOpen"
                        @modal-closed="() => onCloseModal()"
                        :title="titleModalLink"
                        size="custom"
                        height="h-[30rem]"
                        width="w-[36rem]"
                        rounded="2xl"
                    >
                        <template #trigger>
                            <button
                                @click="() => onOpenModal()"
                                class="px-4 py-2 bg-[var(--main-color)] font-semibold text-white rounded-full"
                            >
                                + Novo
                            </button>
                        </template>

                        <template #body>
                            <div class="h-full flex flex-col justify-between">
                                <div class="flex-1 space-y-3">
                                    <base-input-field
                                        label="Nome"
                                        id="name"
                                    >
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            v-model="newLink.route_name"
                                        />
                                    </base-input-field>

                                    <base-input-field
                                        label="Link para:"
                                        id="typeRoute"
                                        type="select"
                                    >
                                        <select
                                            name="typeRoute"
                                            id="typeRoute"
                                            v-model="newLink.typeRoute"
                                        >
                                            <option 
                                                value="route"
                                                :selected="newLink.typeRoute == 'route' ? true : false"

                                            >
                                                Url
                                            </option>

                                            <option
                                                value="whats"
                                                :selected="newLink.typeRoute == 'whats' ? true : false"
                                            >
                                                Whatsapp
                                            </option>
                                        </select>
                                    </base-input-field> 
                                    
                                    <base-input-field
                                        label="Endereço (url)"
                                        id="link"
                                        v-if="newLink.typeRoute == 'route'"
                                    >
                                        <input
                                            type="text"
                                            id="link"
                                            name="link"
                                            v-model="newLink.route_path"
                                        />
                                    </base-input-field>

                                    <base-input-field
                                        label="Número de telefone"
                                        id="phone"
                                        v-if="newLink.typeRoute == 'whats'"
                                        type="select"
                                    >
                                        <div
                                            class="flex w-full space-x-3"
                                        >
                                            <select 
                                                class="w-1/3"
                                                name="idd"
                                                id="idd"
                                                v-model="newLink.ddi"
                                            >
                                                <option
                                                    v-for="(country, index) in countries"
                                                    :key="index"
                                                    :value="country.id"
                                                    :selected="country.id === newLink.idd"
                                                >
                                                    {{ country.emoji + ' (+' + country.phonecode + ')' }}
                                                </option>
                                            </select>

                                            <input
                                                v-if="newLink.ddi == 30"
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                v-model="newLink.phone"
                                                placeholder="(##) #####-####"
                                                class="w-2/3"
                                                v-maska
                                                data-maska="(##) #####-####"
                                            />

                                            <input
                                                v-else
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                v-model="newLink.phone"
                                                class="w-2/3"
                                                placeholder="(##) #####-####"
                                                v-maska
                                                data-maska="############"
                                            />
                                        </div>
                                    </base-input-field>

                                    <base-input-field
                                        label=""
                                        id="target"
                                    >
                                        <label class="cursor-pointer flex items-center space-x-3" for="target">
                                            <input
                                                type="checkbox"
                                                name="target"
                                                id="target"
                                                class="p-2 border-[1px] border-gray-300 rounded-md"
                                                @change="(event: any) => onChangeTarget(event.target.checked)"
                                                :checked="newLink.target_blank == 1 ? true : false"
                                            >

                                            <span class="text-[10px] font-bold text-gray-400">
                                                Abrir em nova aba
                                            </span>
                                        </label>
                                    </base-input-field>
                                </div>

                                <div class="flex justify-end mt-4 space-x-2">
                                    <button
                                        @click="() => onCloseModal()"
                                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                                    >
                                        Cancelar
                                    </button>

                                    <button
                                        v-if="!newLink.id"
                                        @click="() => handleNewLink()"
                                        class="bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none"
                                    >
                                        Adicionar
                                    </button>

                                    <button
                                        v-else
                                        @click="() => handleEditLink()"
                                        class="bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none"
                                    >
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </template>
                    </base-modal>
                </div>

                <draggable
                    :list="links"
                    item-key="index"
                    class="flex flex-col space-y-3"
                    @end="onEndSortLink"
                >
                    <template #item="{ element }">
                        <div
                            class="p-3 bg-white rounded drop-shadow-lg flex justify-between"
                        >
                            <div class="flex items-center space-x-3">
                                <button class="handle-drag cursor-ns-resize">
                                    <span class="sr-only">
                                        Move
                                    </span>

                                    <svg
                                        class="w-6 h-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                        />
                                    </svg>
                                </button>

                                <span class="text-xl font-bold">
                                    {{ element.route_name }}
                                </span>
                            </div>

                            <div class="flex items-center space-x-3">
                                <button
                                    @click="() => onEditLink(element.id)"
                                    class="p-2 bg-gray-100 rounded-full"
                                >
                                    <span class="sr-only">
                                        Edit
                                    </span>

                                    <svg
                                        class="w-5 h-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                        />
                                    </svg>
                                </button>

                                <button 
                                    @click="() => onDeleteLink(element.id)"
                                    class="p-2 bg-gray-100 rounded-full"
                                >
                                    <span class="sr-only">
                                        Delete
                                    </span>

                                    <svg
                                        class="w-5 h-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </dashboard-layout>
</template>

<style></style>
