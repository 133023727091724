<template>
    <svg width="88" height="56" viewBox="0 0 88 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1141_3684)">
        <rect x="5.82959" y="5.82947" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="14.8845" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="23.9396" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="21.5249" y="5.82947" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="21.5249" y="14.8845" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="21.5249" y="23.9396" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="37.2207" y="5.82947" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="37.2207" y="14.8845" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="37.2207" y="23.9396" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="52.9165" y="5.82947" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="52.9165" y="14.8845" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="52.9165" y="23.9396" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="68.6113" y="5.82947" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="68.6113" y="14.8845" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="68.6113" y="23.9396" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="32.9948" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="21.5249" y="32.9948" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="37.2207" y="32.9948" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="52.9165" y="32.9948" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="68.6113" y="32.9948" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="42.0499" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="21.5249" y="42.0499" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="37.2207" y="42.0499" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="52.9165" y="42.0499" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="68.6113" y="42.0499" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="51.1049" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="21.5249" y="51.1049" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="37.2207" y="51.1049" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="52.9165" y="51.1049" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        <rect x="68.6113" y="51.1049" width="13.2808" height="7.24409" rx="1.20735" fill="#E0E0E0"/>
        </g>
        <rect x="0.6" y="0.6" width="86.8" height="54.8" rx="2.4" stroke="#DCDCDC" stroke-width="0.8"/>
        <defs>
        <clipPath id="clip0_1141_3684">
        <rect x="1" y="1" width="86" height="54" rx="2" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>