import { ref, watch } from 'vue'
import { defineStore } from 'pinia'
import router from '@/router'
import api from '@/services/api'
import { useSiteStore } from '@/stores/site'
import notification from '@/services/notification'
import moment from 'moment'

/**
 * @description Dashboard Store
 */
export const useDashboardStore = defineStore('dashboard', () => {
    const siteStore = useSiteStore()

    // states
    const posts = ref<any[]>([])
    const postsSkeleton = ref<any[]>([
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        },
        {
            id: 6
        },
        {
            id: 7
        },
        {
            id: 8
        },
        {
            id: 9
        },
        {
            id: 10
        },
        {
            id: 11
        },
        {
            id: 12
        },
        {
            id: 13
        },
        {
            id: 14
        },
        {
            id: 15
        },
        {
            id: 116
        }
    ])
    const pages = ref<any[]>([])
    const defaultPages = [
        {
            name: 'Pesquisar',
            pageType: 'search',
            route: '/'
        },
        {
            name: 'Dashboard',
            pageType: 'dashboard',
            route: '/'
        }
    ]
    const activePage = ref<any>(defaultPages[1])

    const loadingPosts = ref(false)
    const loadingPages = ref(false)

    const sortType = ref('DATEDESC')

    watch(sortType, async () => {
        await updateOrder()

        filteredPosts()
    })
    // fim states

    // getters

    // fim getters

    // actions
    const normalizeTitlePosts = (title: string) => {
        return title.toLowerCase()
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    const updateOrder = async () => {
        if (activePage.value.id == null) return
        
        const data = {
            sortBy: sortType.value,
            items: posts.value,
        };

        const response = await api.post(
            `site/${siteStore.id}/post/${activePage.value.id}/sort`,
            JSON.stringify(data)
        )

        const { status, message } = await response.json()

        if (status != '1') {
            throw new Error('Error on update order: ' + message)
        }

        return true
    }

    const filteredPosts = () => {
        loadingPosts.value = true;

        const sortingFunctions: { [key: string]: (a: any, b: any) => number } = {
            DATEASC: (post1: any, post2: any) => {
                if (post1.created_time < post2.created_time) return -1;
                if (post1.created_time > post2.created_time) return 1;

                return parseInt(post1.postId) > parseInt(post2.postId) ? 1 : -1;
            },
            DATEDESC: (date1: any, date2: any) => {
                if (date1.created_time > date2.created_time) return -1;
                if (date1.created_time < date2.created_time) return 1;

                return parseInt(date1.postId) < parseInt(date2.postId) ? 1 : -1;
            },
            CHRONOASC: (post1: any, post2: any) => {
                if (
                    moment(post1.display_date, "YYYY-MM-DD").toDate() >
                    moment(post2.display_date, "YYYY-MM-DD").toDate()
                )
                    return 1;
                if (
                    moment(post1.display_date, "YYYY-MM-DD").toDate() <
                    moment(post2.display_date, "YYYY-MM-DD").toDate()
                )
                    return -1;

                return parseInt(post1.postId) > parseInt(post2.postId) ? 1 : -1;
            },
            CHRONODESC: (date1: any, date2: any) => {
                if (
                    moment(date1.display_date, "YYYY-MM-DD HH:mm").toDate() <
                    moment(date2.display_date, "YYYY-MM-DD HH:mm").toDate()
                )
                    return 1;
                if (
                    moment(date1.display_date, "YYYY-MM-DD HH:mm").toDate() >
                    moment(date2.display_date, "YYYY-MM-DD HH:mm").toDate()
                )
                    return -1;

                return parseInt(date1.postId) < parseInt(date2.postId) ? 1 : -1;
            },
            ALPHABDESC: (post1: any, post2: any) => {
                const innerPost1 = { ...post1 };
                const innerPost2 = { ...post2 };
                if (innerPost1.title)
                    innerPost1.title = normalizeTitlePosts(innerPost1.title);
                if (innerPost2.title)
                    innerPost2.title = normalizeTitlePosts(innerPost2.title);
                if (innerPost1.title > innerPost2.title || !innerPost2.title)
                    return -1;
                if (innerPost1.title < innerPost2.title || !innerPost1.title)
                    return 1;
                return 0;
            },
            ALPHABASC: (post1: any, post2: any) => {
                const innerPost1 = { ...post1 };
                const innerPost2 = { ...post2 };
                if (innerPost1.title)
                    innerPost1.title = normalizeTitlePosts(innerPost1.title);
                if (innerPost2.title)
                    innerPost2.title = normalizeTitlePosts(innerPost2.title);
                if (innerPost1.title < innerPost2.title || !innerPost2.title)
                    return -1;
                if (innerPost1.title > innerPost2.title || !innerPost1.title)
                    return 1;
                return 0;
            },
            CUSTOM: (post1: any, post2: any) => {
                const post1Position = post1.position;
                const post2Position = post2.position;

                if (post1Position === post2Position) {
                    return 0;
                }

                return post1Position > post2Position ? 1 : -1;
            },
        };

        activePage.value.id && updateOrder();

        loadingPosts.value = false;

        return posts.value.sort(sortingFunctions[sortType.value]);
    }

    const fetchPosts = async () => {
        loadingPosts.value = true

        try {
            const url = activePage.value.id
                ? `site/${siteStore.id}/dashboard/posts?reference=${activePage.value.id}`
                : `site/${siteStore.id}/dashboard/posts`

            const response = await api.get(url)

            const { count, data } = await response.json()

            const dataFormated = []

            if (activePage.value.pageType == 'dashboard' && siteStore.contract.is_trial == 1) {
                dataFormated.push({
                    postId: 'rocket'
                })
            }

            dataFormated.push(...data)

            posts.value = count ? dataFormated : []

            // verifica se a pagina ativa tem um tipo de ordenação (HOME NO DASHBOARD NÃO ORDENA)
            if (activePage.value.sortBy) {
                sortType.value = activePage.value.sortBy
            }

            filteredPosts()
        } catch (error) {
            console.warn(error)
        }

        loadingPosts.value = false
    }

    const fetchPages = async () => {
        loadingPages.value = true

        try {
            const respose = await api.get(`site/${siteStore.id}/section`)

            const { status, content } = await respose.json()

            if (status == 0) {
                throw new Error('error on fetch pages')
            }

            pages.value = content
        } catch (error) {
            console.warn(error)
        }

        loadingPages.value = false
    }

    const setActivePage = (page: any) => {
        activePage.value = page
    }

    const clearActivePage = () => {
        activePage.value = defaultPages[1]
    }

    const clearPosts = () => {
        posts.value = []
    }

    const handleClickPage = (page: any) => {
        if (loadingPosts.value) return

        if (page === activePage.value) return

        clearPosts()

        setActivePage(page)

        sortType.value = page.sortBy || 'DATEDESC'

        // SE FOR PAGINA DE LISTAGEM CARREGA OS POSTS
        if (page.listPages || page.pageType == 'dashboard') {
            fetchPosts()
            return router.push({ name: 'dashboard' })
        }

        // SE FOR PAGINA DE CONTEUDO REDIRECIONA PARA EDITOR
        if (page.pageType == 'section') {
            if (page.type == 'external_url') {
                return router.push({ name: 'page.external.edit', params: { id: page.id } })
            }

            return router.push({ name: 'page.edit', params: { id: page.id } })
        }
    }

    const createPost = async (blogId: any) => {
        try {
            const response = await api.post(
                `site/${siteStore.id}/post/new`,
                JSON.stringify({ id: blogId })
            );

            const { status, message, postId } = await response.json();

            if (status != '1') {
                throw new Error('Error on create page: ' + message)
            }
            
            router.push({ name: 'post.edit', params: { id: postId } })

            return true
        } catch (error) {
            console.warn(error)

            return false
        }
    }

    const createPage = async (page: any) => {
        try {
            const combination = []

            for (let i = 0; i < page.combination.length; i++) {
                if (!page.combination[i]?.id) continue

                combination.push(page.combination[i].id)
            }

            const pageList = [
                ...pages.value,
                {
                    name: page.name,
                    target: page.target,
                    id: null,
                    visible: 1,
                    type: page.type,
                    options: page.options,
                    combination: combination
                }
            ]

            const response = await api.post(
                `site/${siteStore.id}/category/update`,
                JSON.stringify({ categories: pageList })
            )

            const { status, content, message } = await response.json()

            if (status != '1') {
                throw new Error('Error on create page: ' + message)
            }

            const listPageWithNamesEqual = content.filter((item: any) => item.name == page.name)
            
            const pageCreated = listPageWithNamesEqual[listPageWithNamesEqual.length - 1]

            pages.value.push(pageCreated)

            handleClickPage(pageCreated)

            return true
        } catch (error) {
            console.warn(error)

            return false
        }
    }

    const updatePositionPages = async () => {
        try {
            const data = { categories: pages.value }

            const response = await api.post(`site/${siteStore.id}/category/update`, JSON.stringify(data))

            const { status, message } = await response.json()

            if (status != '1') {
                throw new Error('Error on update position pages: ' + message)
            }

            notification.simple('Ordem das páginas atualizada com sucesso!', 'success');

            return true
        } catch (error) {
            console.warn(error)

            notification.simple('Erro ao atualizar ordem das páginas!', 'error');

            return false
        }
    }
    // fim actions

    return {
        // disponibiliza as states
        posts,
        loadingPosts,
        pages,
        loadingPages,
        activePage,
        defaultPages,
        postsSkeleton,
        sortType,
        // disponibiliza os getters
        // disponibiliza as actions
        fetchPosts,
        filteredPosts,
        updateOrder,
        fetchPages,
        setActivePage,
        clearActivePage,
        handleClickPage,
        clearPosts,
        createPage,
        createPost,
        updatePositionPages,
    }
})
