import { defineStore } from 'pinia'

import { reactive, toRefs } from 'vue'

import { useEditorPageStore } from '@/stores/editor/page'

interface State {
    isOpenModal: boolean,
    titleModalLink: string,
    confirmModalLink: string,
    linkUrl: string,
    editor: any, //não conseguimos acertar o type correto
}

/**
 * @description Text-Link Store
 */
export const useTextLinkStore = defineStore('text-link', () => {
    const editorPageStore = useEditorPageStore()

    // states
    const state: State = reactive({
        isOpenModal: false,
        titleModalLink: '',
        confirmModalLink: '',
        linkUrl: '',
        editor: undefined,
    })
    // fim states

    // actions

    const setIsOpenModal = (isOpenModal: boolean) => {
        state.isOpenModal = isOpenModal
    }

    const reset = () => {
        state.isOpenModal = false
    }
    // fim actions

    return {
        ...toRefs(state),
        setIsOpenModal,
        reset,
    }
})
