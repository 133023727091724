<script setup lang="ts">
import { Line } from 'vue-chartjs'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

defineProps<{
    data?: any
    options?: object
}>()
</script>

<template>
    <div class="col-span-2 w-full h-48 bg-white relative drop-shadow-md rounded-xl">
        <Line :data="data" :options="options" />
    </div>
</template>
