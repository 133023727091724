import { ref } from 'vue'
import { defineStore } from 'pinia'
import api from '@/services/api'
import notification, { type ToastId } from '@/services/notification'

/**
 * @description Site Store
 */
export const useSiteStore = defineStore('site', () => {
    // states
    const defined = ref<any>(false)
    const loadingUpdateScripts = ref(false)
    const avatar = ref<any>()
    const categories = ref<any>()
    const contract = ref<any>()
    const email = ref<any>()
    const expiration = ref<any>()
    const favicon = ref<any>()
    const id = ref<any>()
    const layout = ref<any>()
    const logo = ref<any>()
    const logoDark = ref<any>()
    const logoDarkMobile = ref<any>()
    const logoMobile = ref<any>()
    const name = ref<any>()
    const preferences = ref<any>()
    const sections = ref<any>()
    const segment = ref<any>()
    const status = ref<any>()
    const tags = ref<any>()
    const template = ref<any>()
    const url = ref<any>()
    const welcome = ref<any>()
    const whatsapp = ref<any>()
    const whatsappTitle = ref<any>()
    const whatsappIndex = ref<any>()
    const whatsappMessage = ref<any>()
    const wizard = ref<any>()
    const wizardDate = ref<any>()
    const scriptHeader = ref<any>(null)
    const scriptFooter = ref<any>(null)
    const expirationDate = ref<any>(null)
    const contactName = ref<any>(null)
    const contactEmail = ref<any>(null)
    const contactPhone = ref<any>(null)
    const mainEmail = ref<any>(null)
    const siteDescription = ref<any>(null)
    const phoneCountry = ref<any>(null)
    const address = ref<any>(null)
    const location = ref<any>(null)
    const uliaccount = ref<any>(null)
    const language = ref<any>(null)
    const homePage = ref<any>(null)
    const cookiebar = ref<any>(null)
    const documentId = ref<any>(null)
    const seoText = ref<any>(null)
    const blockCopyImage = ref<any>(null)
    // fim states

    // actions
    const setSite = (site: any) => {
        avatar.value = site.site_avatar
        categories.value = site.categories
        contract.value = site.contract
        email.value = site.email
        expiration.value = site.expiration
        favicon.value = site.favicon
        id.value = site.id
        layout.value = site.layout
        logo.value = site.logo
        logoDark.value = site.logo_dark
        logoDarkMobile.value = site.logo_dark_mobile
        logoMobile.value = site.logo_mobile
        name.value = site.name
        preferences.value = site.preferences
        sections.value = site.sections
        segment.value = site.segment
        status.value = site.status
        tags.value = site.tags
        template.value = site.template
        url.value = site.url
        welcome.value = site.welcome
        whatsapp.value = site.whatsapp
        whatsappTitle.value = site.whatsapp_title
        whatsappIndex.value = site.whatsapp_index
        whatsappMessage.value = site.whatsapp_message
        wizard.value = site.wizard
        wizardDate.value = site.wizard_date
        expirationDate.value = site.expiration_date
        contactName.value = site.contact_name
        contactEmail.value = site.contact_email
        contactPhone.value = site.contact_phone
        mainEmail.value = site.main_email
        siteDescription.value = site.site_description
        phoneCountry.value = site.phone_country
        address.value = site.address
        location.value =
            typeof site.location == 'string' ? JSON.parse(site.location) : site.location
        uliaccount.value = site.uliaccount
        language.value = site.language
        homePage.value = site.home_page
        cookiebar.value = !!site.cookiebar
        blockCopyImage.value = !!site.blockCopyImage
        documentId.value = site.document_id
        seoText.value = site.seo_text
        defined.value = true
    }

    const setScriptHeader = (value: string) => {
        scriptHeader.value = value
    }

    const setScriptFooter = (value: string) => {
        scriptFooter.value = value
    }

    const setLoadingUpdateScripts = (value: boolean) => {
        loadingUpdateScripts.value = value
    }

    const fetchScripts = async () => {
        try {
            const response = await api.get('site/' + id.value + '/script')

            const { status, headerScript, footerScript } = await response.json()

            if (status != '1') {
                throw new Error('Error on fetch scripts')
            }

            setScriptHeader(headerScript)
            setScriptFooter(footerScript)
        } catch (error) {
            console.warn(error)
        }
    }

    const handleApplyScript = async () => {
        setLoadingUpdateScripts(true);

        const toastId: ToastId = notification.loading("Atualizando scripts...");

        try {
            const response = await api.post(
                'site/' + id.value + '/script/update',
                JSON.stringify({
                    siteHeaderScript: scriptHeader.value,
                    siteFooterScript: scriptFooter.value
                })
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on update scripts')
            }
     
            notification.update(toastId, 'Scripts aplicados com sucesso!', 'success');

            setLoadingUpdateScripts(false);

            return true
        } catch (error) {
            notification.update(toastId, 'Erro ao aplicar scripts!', 'error');

            console.warn(error)
            
            setLoadingUpdateScripts(false);
        }
    }

    const handleCreateTag = async (tag: string) => {
        const notificationId = notification.loading('Criando tag...')

        try {
            const response = await api.post(
                `site/${id.value}/tag/create`,
                JSON.stringify({
                    siteId: id.value,
                    name: tag
                })
            )

            const data = await response.json()

            console.log(data)
            if (data.status != '1') {
                throw new Error('Error on create tag')
            }

            tags.value = [...tags.value, { name: data.name, id: data.id }]

            await refreshSiteData()

            notification.update(notificationId, 'Tag criada com sucesso!', 'success')

            return true
        } catch (error) {
            notification.update(notificationId, 'Erro ao criar tag', 'error')

            console.warn(error)
        }
    }

    const handleDeleteTag = async (tagId: number) => {
        const notificationId = notification.loading('Excluindo tag...')

        try {
            const response = await api.post(
                `site/${id.value}/tag/${tagId}/delete`,
                null
            )

            const data = await response.json()

            console.log(data)
            if (data.status != '1') {
                throw new Error('Error on delete tag')
            }

            tags.value.splice(
                tags.value.findIndex((tag: any) => tag.id == tagId),
                1
            )

            await refreshSiteData()

            notification.update(notificationId, 'Tag excluída com sucesso!', 'success')

            return true
        } catch (error) {
            notification.update(notificationId, 'Erro ao excluir tag', 'error')

            console.warn(error)
        }
    }

    const refreshSiteData = async () => {
        const siteData = await JSON.parse(localStorage.getItem('siteData') || '{}')

        localStorage.setItem(
            'siteData',
            JSON.stringify({
                ...siteData,
                site_avatar: avatar.value,
                categories: categories.value,
                contract: contract.value,
                email: email.value,
                expiration: expiration.value,
                favicon: favicon.value,
                id: id.value,
                layout: layout.value,
                logo: logo.value,
                logo_dark: logoDark.value,
                logo_dark_mobile: logoDarkMobile.value,
                logo_mobile: logoMobile.value,
                name: name.value,
                preferences: preferences.value,
                sections: sections.value,
                segment: segment.value,
                status: status.value,
                tags: tags.value,
                template: template.value,
                url: url.value,
                welcome: welcome.value,
                whatsapp: whatsapp.value,
                whatsapp_title: whatsappTitle.value,
                whatsapp_index: whatsappIndex.value,
                whatsapp_message: whatsappMessage.value,
                wizard: wizard.value,
                wizard_date: wizardDate.value,
                expiration_date: expirationDate.value,
                contact_name: contactName.value,
                contact_email: contactEmail.value,
                contact_phone: contactPhone.value,
                main_email: mainEmail.value,
                site_description: siteDescription.value,
                phone_country: phoneCountry.value,
                address: address.value,
                location: location.value,
                uliaccount: uliaccount.value,
                language: language.value,
                home_page: homePage.value,
                cookiebar: cookiebar.value,
                document_id: documentId.value,
                seo_text: seoText.value,
                blockCopyImage: blockCopyImage.value
                // //
                // avatar: avatar.value,
                // categories: categories.value,
                // contract: contract.value,
                // email: email.value,
                // expiration: expiration.value,
                // favicon: favicon.value,
                // id: id.value,
                // layout: layout.value,
                // logo: logo.value,
                // logoDark: logoDark.value,
                // logoDarkMobile: logoDarkMobile.value,
                // logoMobile: logoMobile.value,
                // name: name.value,
                // preferences: preferences.value,
                // sections: sections.value,
                // segment: segment.value,
                // status: status.value,
                // tags: tags.value,
                // template: template.value,
                // url: url.value,
                // welcome: welcome.value,
                // whatsapp: whatsapp.value,
                // whatsappTitle: whatsappTitle.value,
                // whatsappIndex: whatsappIndex.value,
                // whatsappMessage: whatsappMessage.value,
                // wizard: wizard.value,
                // wizardDate: wizardDate.value,
                // scriptHeader: scriptHeader.value,
                // scriptFooter: scriptFooter.value,
                // expirationDate: expirationDate.value,
                // contactName: contactName.value,
                // contactEmail: contactEmail.value,
                // contactPhone: contactPhone.value,
                // mainEmail: mainEmail.value,
                // siteDescription: siteDescription.value,
                // phoneCountry: phoneCountry.value,
                // address: address.value,
                // location: location.value,
                // uliaccount: uliaccount.value,
                // language: language.value,
                // homePage: homePage.value,
                // cookiebar: cookiebar.value,
                // documentId: documentId.value,
                // seoText: seoText.value,
            })
        )
    }

    const handleUpdateSeo = async (data: any) => {
        const notificationId = notification.loading('Atualizando SEO...')

        try {
            const response = await api.post(
                `site/${id.value}/update`,
                JSON.stringify({
                    ...data
                })
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on update seo')
            }

            mainEmail.value = data.userEmail
            segment.value.segment = data.activeSegment
            segment.value.subSegments = data.activeSubsegments
            name.value = data.userArtisticName
            language.value = data.siteLanguage
            siteDescription.value = data.siteDescription
            seoText.value = data.h1
            documentId.value = data.document
            location.value = data.location
            cookiebar.value = data.cookiebar
            blockCopyImage.value = data.blockCopyImage

            await refreshSiteData()

            defined.value = false

            notification.update(notificationId, 'SEO atualizado com sucesso!', 'success')

            return true
        } catch (error) {
            notification.update(notificationId, 'Erro ao atualizar SEO', 'error')

            console.warn(error)
        }
    }

    const handleUpdateContact = async (data: any) => {
        //
        const notificationId = notification.loading('Atualizando contato...')

        try {
            const response = await api.post(
                `site/${id.value}/update/contact`,
                JSON.stringify({
                    ...data
                })
            )

            const { status } = await response.json()

            console.log(data)
            if (status != '1') {
                throw new Error('Error on updated contact')
            }

            mainEmail.value = data.mainEmail
            contactEmail.value = data.userEmail
            contactPhone.value = data.contactPhone
            address.value = data.address
            location.value = data.location
            phoneCountry.value = data.phoneCountry
            whatsappTitle.value = data.whatsappButton
            whatsappMessage.value = data.whatsappMessage
            whatsappIndex.value = data.whatsappIndex

            await refreshSiteData()

            notification.update(notificationId, 'Contato atualizado com sucesso!', 'success')

            return true
        } catch (error) {
            notification.update(notificationId, 'Erro ao atualizar contato', 'error')

            console.warn(error)
        }
    }

    const handleUpdateLogo = async (file: any, type: string) => {
        const toastId: ToastId = notification.loading('Atualizando imagem...')

        try {
            const response = await api.post(
                `site/${id.value}/logo/update`,
                JSON.stringify({
                    file,
                    type
                })
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on update logo')
            }

            switch (type) {
                case 'logo':
                    logo.value = file
                break

                case 'logo_dark':
                    logoDark.value = file
                break

                case 'logo_dark_mobile':
                    logoDarkMobile.value = file
                break

                case 'logo_mobile':
                    logoMobile.value = file
                break

                case 'favicon':
                    favicon.value = file
                break

                default:
                    throw new Error('Error on update store logo')
                break
            }

            refreshSiteData()

            notification.update(toastId, 'Imagem atualizada com sucesso!', 'success')

            return true
        } catch (error) {
            notification.update(toastId, 'Erro ao atualizar imagem', 'error')

            console.warn(error)

            return false
        }
    }

    // const handleAddCategory = async (category: string) => {
    //     const notificationId = notification.loading('Adicionando categoria...')

    //     try {
    //         const response = await api.post(
    //             `site/${id.value}/category/create`,
    //             JSON.stringify({
    //                 siteId: id.value,
    //                 name: category
    //             })
    //         )

    //         const data = await response.json()

    //         if (data.status != '1') {
    //             throw new Error('Error on create category')
    //         }

    //         categories.value = [...categories.value, { name: data.name, id: data.id }]

    //         await refreshSiteData()

    //         notification.update(notificationId, 'Categoria criada com sucesso!', 'success')

    //         return true
    //     } catch (error) {
    //         notification.update(notificationId, 'Erro ao criar categoria', 'error')

    //         console.warn(error)
    //     }
    // }
    // fim actions

    return {
        // disponibiliza as states
        defined,
        avatar,
        categories,
        contract,
        email,
        expiration,
        favicon,
        id,
        layout,
        logo,
        logoDark,
        logoDarkMobile,
        logoMobile,
        name,
        preferences,
        sections,
        segment,
        status,
        tags,
        template,
        url,
        welcome,
        whatsapp,
        whatsappTitle,
        whatsappIndex,
        whatsappMessage,
        wizard,
        wizardDate,
        scriptHeader,
        scriptFooter,
        expirationDate,
        contactName,
        contactEmail,
        contactPhone,
        mainEmail,
        siteDescription,
        phoneCountry,
        address,
        location,
        uliaccount,
        language,
        homePage,
        cookiebar,
        documentId,
        seoText,
        blockCopyImage,
        // disponibiliza as actions
        setSite,
        setScriptHeader,
        setScriptFooter,
        fetchScripts,
        handleApplyScript,
        handleCreateTag,
        handleDeleteTag,
        handleUpdateSeo,
        handleUpdateContact,
        handleUpdateLogo,
        loadingUpdateScripts,
        refreshSiteData
    }
})
