<script setup lang="ts">
import { computed, watch, ref, reactive } from 'vue'

import { type UseDraggableReturn ,VueDraggable } from 'vue-draggable-plus';

import clsx from 'clsx'

import { isDark } from '@/utils/color'

import EditorElement from '@/components/molecules/EditorElement.vue'
import EditorSectionSettings from '@/components/molecules/EditorSectionSettings.vue'

import BaseImage from '@/components/atoms/BaseImage.vue'

import { useLayoutStore } from '@/stores/layout'
import { useEditorPageStore } from '@/stores/editor/page'
import { useEditorSidebarStore } from '@/stores/editor/sidebar'
import { useSiteStore } from '@/stores/site'

import api from '@/services/api';

const editorPageStore = useEditorPageStore()
const layoutStore = useLayoutStore()
const editorSidebarStore = useEditorSidebarStore()
const siteStore = useSiteStore()

interface Section {
    action?: any
    actionType?: number
    backgroundImage?: any
    component?: string
    coverImage?: string
    extra?: any
    frameContent?: Array<any>
    frameDataId?: number
    frameId?: number
    frameOrigin?: any
    frameStatus?: number
    frameType?: string
    grid?: number
    gridMobile?: number
    mainImage: Object
    options?: null
    subPagesCategories?: Array<any>
}

const props = defineProps<{
    section: Section
    sectionIndex: number
    isDeleted: boolean
    hasDelete: boolean
    isEditorDisabled: boolean
    hasAddNewSection?: boolean
}>()

const section = ref<Section>(reactive(props.section ?? {}))
const extra = ref<any>(reactive(section.value.extra))
const content = ref<any[]>(section.value.frameContent ?? [])

const sectionIndex = computed(() => props.sectionIndex)
const isDeleted = computed(() => props.isDeleted)

const draggable =  ref<UseDraggableReturn>()

watch(props.section, value => {
    section.value = reactive(value)
    content.value = section.value.frameContent ?? []

    extra.value = reactive(value.extra)
}, { deep: true })

watch(content, value => {
    section.value.frameContent = value
}, { deep: true })

const sectionTarget = ref()
const focused = ref(false)

const getTypeSection = (component: string) => {
    if (component.includes('home')) {
        return 'home'
    }

    if (component.includes('blog')) {
        return 'listblog'
    }

    if (component.includes('contact')) {
        return 'contact'
    }

    if (component.includes('store')) {
        return 'storelist'
    }

    if (component.includes('about')) {
        return 'about'
    }

    if (component.includes('comments')) {
        return 'comments'
    }

    if (component.includes('maincontent')) {
        return 'maincontent'
    }

    if (component.includes('post')) {
        return 'post'
    }

    if (component.includes('newsletter')) {
        return 'newsletter'
    }

    return
}

const container = computed(() => {
    if (isMaincontent.value && section.value.extra?.maincontent_container) {
        return section.value.extra?.maincontent_container
    }

    // TODO: trocar para o valor default do usuario
    let container = 'container'

    const type = getTypeSection(section.value.component ?? '');

    const id = section.value.frameId ?? 0

    if (type) {
        const teste = layoutStore.getContainer(id, type, type + '_container')

        if (teste) {
            return teste
        }
    }

    return container
})

const fontWeight = computed(() => {
    return layoutStore.getSectionFontWeightDefault(sectionIndex.value)
})

const backgroundColor = computed(() => {
    if (isCustomBackgroundColor.value) {
        return extra.value.background_color
    }

    return layoutStore.getSectionBackgroundColorDefault(sectionIndex.value)
})

const isDarkMode = computed(() => isDark(backgroundColor.value))

const isCustomBackgroundColor = computed(() => {
    return extra.value.background_color && extra.value.background_color != '' ? true : false
})

const backgroundImage = computed(() => {
    return section.value.backgroundImage?.uri ?? null
})

const backgroundImageOpacity = computed(() => {
    return extra.value.background_image_opacity ?? "80"
})

const backgroundFocalPoint = computed(() => {
    return (
        extra.value.background_image_focal_point ?? {
            x: 50,
            y: 50
        }
    )
})

const dynamicStyleSection = computed(() => {
    return {
        backgroundColor: backgroundColor.value,
        fontWeight: fontWeight.value
    }
})

const handleCreateLastFixedTextElement = () => {
    if (editorSidebarStore.isOpen) return

    editorPageStore.createLastFixedElementText(sectionIndex.value)
} 

const handleRemoveLastFixedTextElement = () => {
    editorPageStore.removeLastFixedTextElement(sectionIndex.value)
}

watch(isDeleted, value => {
    if (value) {
        editorPageStore.removeSection(sectionIndex.value)
    }
})

const onFocusInSection = (e: any) => {
    focused.value = true
}

const onFocusOutSection = (e: any) => {
    if (!sectionTarget.value.contains(e.relatedTarget)) {
        focused.value = false;
    }
}

watch(focused, (focused) => {
    if (notEditable.value) return

    if (focused && !hasLastTextVoid()) return handleCreateLastFixedTextElement()
    
    return handleRemoveLastFixedTextElement()
})

const hasLastTextVoid = () => {
    const lastElement = section.value.frameContent?.slice(-1)[0]

    if (!lastElement) return false

    if (lastElement.frameType == 'text' && (lastElement?.frameContent == '' || lastElement?.frameContent == '<p></p>')) {
        return true
    }

    return false
}

const elementsRebuild = computed(() => {
    return editorPageStore.elementsRebuild
})

const key = ref(Date.now());

watch(elementsRebuild, () => {
    if (elementsRebuild.value) {
        key.value = Date.now()

        editorPageStore.setElementsRebuild(false)
    }
})

const onStartDraggable = () => {
    handleRemoveLastFixedTextElement()

    editorPageStore.setIsEditableContent(false)
}

const onEndDraggable = () => {
    editorPageStore.setIsEditableContent(true)
}

const isHome = computed(() => {
    return props.section.component?.includes('home')
})

const isBlog = computed(() => {
    return props.section.component?.includes('blog')
})

const isContact = computed(() => {
    return props.section.component?.includes('contact')
})

const isStore = computed(() => {
    return props.section.component?.includes('store')
})

const isMaincontent = computed(() => {
    return props.section.component?.includes('maincontent')
})

const isPost = computed(() => {
    return props.section.component?.includes('post')
})

const isAbout = computed(() => {
    return props.section.component?.includes('about')
})

const isComments = computed(() => {
    return props.section.component?.includes('comments')
})

const isNewsletter = computed(() => {
    return props.section.component?.includes('newsletter')
})

const notEditable = computed(() => {
    return isHome.value || isBlog.value || isContact.value || isStore.value ||  isAbout.value || isComments.value || isNewsletter.value
})

const isEditable = computed(() => {
    return isMaincontent.value || isPost.value
})

const loadingStore = ref<boolean>(false);

const goStore = async () => {
    loadingStore.value = true;
    
    try {
        const response = await api.get(`store/login?site=${siteStore.id}`)

        const { status, url } = await response.json()

        if (status != "1") {
            throw new Error('Erro ao buscar url da loja')
        }

        window.location.href = url;
    } catch (error) {
        console.error('error', error)
    }

    loadingStore.value = false;
}

const validateSection = computed(() => {
    if (isHome.value && props.sectionIndex == 0) return false

    if (editorPageStore.activePage?.isHome && props.sectionIndex != 0) return true

    return true
})

const schema = ref([
    {
        level: 0,
        element: 'section',
        index: props.sectionIndex,
    }
])

const getSchema = (frameType: string, index: number) => {
    return [
        ...schema.value,
        {
            level: 1,
            element: frameType,
            index,
        }
    ]
}

const loadingAddNewSection = ref<boolean>(false);

const onAddNewSection = async () => {
    loadingAddNewSection.value = true;

    try {
        await editorPageStore.handlerAddNewSection(schema.value)
    } catch (error) {
        console.error('error', error)        
    }

    loadingAddNewSection.value = false;
}
</script>

<template>
    <section
        ref="sectionTarget"
        @focusin="onFocusInSection"
        @focusout="onFocusOutSection"
        :id="`${section.frameId}`"
        :class="
            clsx(
                'editor-section',
                isDeleted && 'deleted-section'
            )
        "
        :style="{
            ...({
                ...dynamicStyleSection,
            } as any)
        }"
    >
        <base-image
            v-if="backgroundImage"
            :src="backgroundImage"
            :alt="`Background Image ${sectionIndex}`"
            :class="clsx('absolute inset-0 w-full h-full object-cover object-center')"
            :style="{
                opacity: `${backgroundImageOpacity / 100}`,
                objectPosition: `${backgroundFocalPoint.x}% ${backgroundFocalPoint.y}%`
            }"
        />

        <div 
            :class="clsx(container && container)"
        >
            <editor-section-settings
                v-if="validateSection"
                :section="section"
                :section-index="sectionIndex"
                :section-background-color="backgroundColor"
                :is-dark-mode="isDarkMode"
                :has-config="isEditable ?? false"
                :has-delete="hasDelete"
            />

            <VueDraggable
                v-if="isEditable"
                ref="draggable"
                v-model="content"
                class="editor-elements"
                :animation="150"
                handle=".action-move-element"
                :filter="'.no-draggable'"
                group="elements"
                ghostClass="ghost"
                @start="onStartDraggable"
                @end="onEndDraggable"
            >
                <template 
                    v-for="(element, index) in content"
                    :key="element?.frameId || element?.temporaryId"
                >
                    <editor-element
                        v-if="element"
                        :element="element"
                        :element-index="index"
                        :section-index="sectionIndex"
                        :section-background-color="backgroundColor"
                        :section-has-background-image="!!backgroundImage"
                        :is-editable="true"
                        :schema="getSchema(element.frameType, index)"
                    />
                </template>
            </VueDraggable>

            <div
                v-if="notEditable"
                class="editor-elements"
            >
                <template
                    v-if="!isStore"
                >
                    <template
                        v-for="(element, index) in section.frameContent"
                        :key="element.frameId"
                    >
                        <editor-element
                            :element="element"
                            :element-index="index"
                            :section-index="sectionIndex"
                            :section-background-color="backgroundColor"
                            :section-has-background-image="!!backgroundImage"
                            :is-home="isHome"
                            :is-about="isAbout"
                            :is-editable="false"
                            :schema="getSchema(element.frameType, index)"
                        />
                    </template>
                </template>

                <div v-else 
                    :class="clsx(
                        'w-full flex flex-col justify-center items-center',
                        editorPageStore.activePage.isHome ? 'h-[30vh]' : 'h-[78vh]'
                    )"
                >
                    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1217_1983)">
                        <mask id="mask0_1217_1983" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
                        <path d="M120 0H0V120H120V0Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_1217_1983)">
                        <mask id="mask1_1217_1983" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
                        <path d="M0 7.62939e-06H120V120H0V7.62939e-06Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask1_1217_1983)">
                        <path d="M76.8475 93.7494L99.7284 71.2042C102.72 68.2149 104.208 64.0381 103.779 59.8306L99.7694 20.4646L60.3007 16.453C56.0925 16.0253 51.9152 17.5145 48.9267 20.5082L8.79794 60.5042C3.31567 65.9956 3.31755 74.8899 8.80239 80.379L39.5868 111.189C45.0761 116.683 53.9798 116.817 59.4738 111.328" stroke="url(#paint0_linear_1217_1983)" stroke-width="7.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M83.3896 36.7964C83.3896 36.7964 115.326 35.1164 115.312 11.4075V11.2209C115.312 7.61225 112.387 4.68702 108.778 4.68702C108.778 4.68702 91.8747 6.09327 87.431 18.0464" stroke="url(#paint1_linear_1217_1983)" stroke-width="7.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M41.5146 79.5722L37.2656 83.6719" stroke="url(#paint2_linear_1217_1983)" stroke-width="7.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M72.4218 48.5151L67.8506 53.2358" stroke="url(#paint3_linear_1217_1983)" stroke-width="7.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M69.3201 65.3616C72.0599 62.6218 71.8494 58.2402 69.1136 55.5043L66.2413 52.6323C62.913 49.3037 57.6491 49.2078 54.3156 52.5413L53.5755 53.2815C50.2422 56.615 50.1236 62.0933 53.4519 65.4216L56.4892 68.4591C59.8178 71.7875 59.9099 77.0921 56.5764 80.4256L56.2314 80.7704C52.8981 84.1039 47.6912 83.9141 44.3627 80.5857L39.4265 75.6495C36.6906 72.9137 36.5392 68.6298 39.2791 65.8897" stroke="url(#paint4_linear_1217_1983)" stroke-width="7.65625" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </g>
                        </g>
                        <defs>
                        <linearGradient id="paint0_linear_1217_1983" x1="-35.7457" y1="65.8791" x2="105.616" y2="65.8791" gradientUnits="userSpaceOnUse">
                        <stop stop-color="var(--main-color)"/>
                        <stop offset="1" stop-color="#8B51FF"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1217_1983" x1="70.3736" y1="20.7417" x2="115.88" y2="20.7417" gradientUnits="userSpaceOnUse">
                        <stop stop-color="var(--main-color)"/>
                        <stop offset="1" stop-color="#8B51FF"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_1217_1983" x1="35.5331" y1="81.622" x2="41.5902" y2="81.622" gradientUnits="userSpaceOnUse">
                        <stop stop-color="var(--main-color)"/>
                        <stop offset="1" stop-color="#8B51FF"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_1217_1983" x1="65.9867" y1="50.8754" x2="72.5032" y2="50.8754" gradientUnits="userSpaceOnUse">
                        <stop stop-color="var(--main-color)"/>
                        <stop offset="1" stop-color="#8B51FF"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1217_1983" x1="23.4448" y1="66.6331" x2="71.8777" y2="66.6331" gradientUnits="userSpaceOnUse">
                        <stop stop-color="var(--main-color)"/>
                        <stop offset="1" stop-color="#8B51FF"/>
                        </linearGradient>
                        <clipPath id="clip0_1217_1983">
                        <rect width="120" height="120" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>

                    <h2 class="text-[40px] font-bold">
                        <span class="text-[var(--main-color)]">Parabéns! </span> Sua loja está online {{ ';)' }}
                    </h2>

                    <p class="text-lg">
                        Para gerenciar sua EPICS Store, acesse o seu <span class="text-[var(--main-color)]">painel de gerenciamento clicando no botão abaixo.</span>
                    </p>

                    <button
                        @click="() => goStore()"
                        :disabled="loadingStore"
                        class="w-60 flex items-center justify-center mt-8 px-6 py-3 text-[var(--main-color)] bg-[transparent] border-2 border-[var(--main-color)] rounded-full"
                    >
                        <span
                            v-if="!loadingStore"
                        >
                            Acessar meu painel
                        </span>

                        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-[var(--main-color)] animate-spin">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div
        v-if="hasAddNewSection"
        :style="{
            backgroundColor: dynamicStyleSection.backgroundColor,
        }"
        class="relative h-8"
    >
        <hr class="border-2 border-dashed border-gray-100 opacity-10">

        <button
            :disabled="loadingAddNewSection"
            @click="() => onAddNewSection()"
            class="absolute -top-[14px] -translate-y-1/5 right-10 p-1 bg-gray-300 flex items-center justify-center rounded-full opacity-70"
        >
            <span class="sr-only">Create Section</span>
            
            <svg v-if="!loadingAddNewSection" class="w-6 h-6 stroke-gray-600 opacity-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path d="M12 7.19995L12 16.8M16.8 12L7.2 12" stroke-width="2" stroke-linecap="round"/>
            </svg>

            <svg v-else class="w-6 h-6 stroke-gray-600 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </button>
    </div>
</template>

<style scoped>
.editor-section {
    @apply overflow-x-hidden relative py-20 transition-all duration-1000 ease-in-out;
}

.editor-section.deleted-section {
    @apply h-0 p-0;
}

.editor-section .container,
.editor-section .container-medium,
.editor-section .container-fluid {
    @apply relative mx-auto px-6;
}

.editor-section.deleted-section .container,
.editor-section.deleted-section .container-medium,
.editor-section.deleted-section .container-fluid {
    @apply hidden;
}

.container {
    @apply max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px];
}

.container-medium {
    @apply max-w-[1280px];
}

.editor-elements {
    @apply flex flex-wrap justify-center;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
