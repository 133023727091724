<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'


import IconItemsPerLine1 from '@/components/atoms/icons/IconItemsPerLine1.vue';
import IconItemsPerLine2 from '@/components/atoms/icons/IconItemsPerLine2.vue';
import IconItemsPerLine3 from '@/components/atoms/icons/IconItemsPerLine3.vue';
import IconItemsPerLine4 from '@/components/atoms/icons/IconItemsPerLine4.vue';
import IconItemsPerLine5 from '@/components/atoms/icons/IconItemsPerLine5.vue';

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: [String, Number]
    },
})

const emit = defineEmits(['change'])

const onChangeGalleryItemsPerLine = (value: any) => {
    emit('change', {
        target: 'gallery-items',
        value: value
    })
}

const options = ref([
    { 
        id: 1, 
        name: "1 por linha", 
        value: "1", 
        icon: markRaw(IconItemsPerLine1),
    },
    { 
        id: 2, 
        name: "2 por linha", 
        value: "2", 
        icon: markRaw(IconItemsPerLine2),
    },
    { 
        id: 3, 
        name: "3 por linha", 
        value: "3", 
        icon: markRaw(IconItemsPerLine3),
    },
    { 
        id: 4, 
        name: "4 por linha", 
        value: "4", 
        icon: markRaw(IconItemsPerLine4),
    },
    { 
        id: 5, 
        name: "6 por linha", 
        value: "6",
        icon: markRaw(IconItemsPerLine5),
    },
])

const activeOption = computed(() => options.value.find(option => option.value == props.value))
</script>

<template>
    <div class="space-y-2">
        <label class="text-sm font-semibold text-[#060606] opacity-50">
            {{ label }}
        </label>
        
        <div class="w-full grid grid-cols-3 gap-3">
            <button
                v-for="(option, key) in options"
                :key="key"
                :title="option.name"
                class="w-full h-full border-2 rounded-md"
                :class="option.value == activeOption?.value ? 'border-[var(--main-color)]' : 'border-transparent'"
                @click="onChangeGalleryItemsPerLine(option.value)"
            >
                <component class="h-full w-full " :is="option.icon" />
            </button>
        </div>
    </div>
</template>

<style scoped>
</style>

