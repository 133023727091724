<template>
    <svg viewBox="0 0 482 541" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="162" y="434" width="17" height="42" fill="url(#paint0_linear_428_1506)" />
        <rect x="215" y="434" width="17" height="42" fill="url(#paint1_linear_428_1506)" />
        <rect x="248" y="434" width="17" height="42" fill="url(#paint2_linear_428_1506)" />
        <rect x="301" y="434" width="17" height="42" fill="url(#paint3_linear_428_1506)" />
        <ellipse cx="103.5" cy="476" rx="52.5" ry="53" fill="#F9F9F9" />
        <ellipse cx="75.5" cy="521" rx="52.5" ry="53" fill="#F9F9F9" />
        <ellipse cx="405.5" cy="526" rx="52.5" ry="53" fill="#F9F9F9" />
        <ellipse cx="119.5" cy="468" rx="52.5" ry="53" fill="#F4F4F4" />
        <ellipse cx="119.5" cy="484.5" rx="56.5" ry="57.5" fill="url(#paint4_linear_428_1506)" />
        <circle cx="86" cy="527" r="54" fill="url(#paint5_linear_428_1506)" />
        <circle cx="236" cy="501" r="53" fill="url(#paint6_linear_428_1506)" />
        <circle cx="173" cy="528" r="54" fill="url(#paint7_linear_428_1506)" />
        <ellipse cx="358" cy="476.5" rx="56" ry="57.5" fill="#F4F4F4" />
        <ellipse cx="350" cy="484.5" rx="56" ry="57.5" fill="url(#paint8_linear_428_1506)" />
        <circle cx="301" cy="539" r="54" fill="url(#paint9_linear_428_1506)" />
        <circle cx="397" cy="527" r="54" fill="url(#paint10_linear_428_1506)" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M275.999 141H203.999C204 102.299 209.218 62.7287 234.251 38.1029C237.482 34.9241 242.598 34.8187 245.879 37.9466C272.933 63.7411 276 112.448 275.999 141ZM204 144H276V419H204V144ZM230 76.9999C225.029 76.9999 221 81.0294 221 85.9999C221 90.9705 225.029 94.9999 230 94.9999H250C254.971 94.9999 259 90.9705 259 85.9999C259 81.0294 254.971 76.9999 250 76.9999H230Z"
            fill="#EEEEEE"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M307.448 176.183C288.14 193.545 284.001 217.57 284 243V419H336V243C336 222.736 332.305 195.235 312.484 176.258C311.082 174.917 308.891 174.886 307.448 176.183Z"
            fill="#E0E0E0"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M168.448 176.183C149.14 193.545 145.001 217.57 145 243V419H197V243C197 222.736 193.305 195.235 173.484 176.258C172.082 174.917 169.891 174.886 168.448 176.183Z"
            fill="#E0E0E0"
        />
        <path d="M164.5 419H176.5L179 434H162L164.5 419Z" fill="#535461" />
        <path d="M218 419H229L232 434H215L218 419Z" fill="#535461" />
        <path d="M251 419H262L265 434H248L251 419Z" fill="#535461" />
        <path d="M304 419H315L318 434H301L304 419Z" fill="#535461" />
        <rect x="204" y="141" width="72" height="3" fill="#D4D4D4" />
        <path
            d="M284.062 243V419H289.067V243C289.067 243 288.065 225 292.567 209C297.07 193 311.062 175.5 311.062 175.5C311.062 175.5 300.655 175.01 289.065 204C283.067 219 284.062 243 284.062 243Z"
            fill="#C8C8C8"
        />
        <rect x="284" y="243" width="13" height="16" fill="#535461" />
        <rect x="277" y="251" width="7" height="5" fill="#535461" />
        <rect x="323" y="243" width="13" height="16" fill="#535461" />
        <path
            d="M197 243V419H191.994V243C191.994 243 192.997 225 188.494 209C183.991 193 170 175.5 170 175.5C170 175.5 180.406 175.01 191.997 204C197.994 219 197 243 197 243Z"
            fill="#C8C8C8"
        />
        <circle cx="4" cy="268" r="4" fill="#D9D9D9" />
        <circle cx="118" cy="186" r="4" fill="#D9D9D9" />
        <circle cx="106" cy="104" r="4" fill="#D9D9D9" />
        <circle cx="427" cy="241" r="4" fill="#D9D9D9" />
        <path
            d="M29 138V144M32 141L26 141"
            stroke="#D4D4D7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M73 311V317M76 314L70 314"
            stroke="#D4D4D7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M478 252V258M481 255L475 255"
            stroke="#D4D4D7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M337 97V103M340 100L334 100"
            stroke="#D4D4D7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M359 358V364M362 361L356 361"
            stroke="#D4D4D7"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <g clip-path="url(#clip0_428_1506)">
            <circle cx="422.5" cy="29.5" r="29.5" fill="#EEEEEE" />
            <circle cx="437" cy="56" r="13" fill="#FCFCFC" />
            <circle cx="438" cy="11" r="8" fill="#FCFCFC" />
            <circle cx="435.5" cy="30.5" r="5.5" fill="#FCFCFC" />
            <ellipse
                cx="413.513"
                cy="14.7811"
                rx="3.5"
                ry="5.5"
                transform="rotate(60 413.513 14.7811)"
                fill="#FCFCFC"
            />
        </g>
        <g clip-path="url(#clip1_428_1506)">
            <circle cx="92" cy="253" r="14" fill="#EEEEEE" />
            <circle cx="98.8814" cy="265.576" r="6.16949" fill="#FCFCFC" />
            <circle cx="99.3562" cy="244.22" r="3.79661" fill="#FCFCFC" />
            <circle cx="98.1697" cy="253.474" r="2.61017" fill="#FCFCFC" />
            <ellipse
                cx="87.7351"
                cy="246.015"
                rx="1.66102"
                ry="2.61017"
                transform="rotate(60 87.7351 246.015)"
                fill="#FCFCFC"
            />
        </g>
        <rect x="197" y="251" width="6" height="5" fill="#535461" />
        <rect x="184" y="243" width="13" height="16" fill="#535461" />
        <rect x="145" y="243" width="13" height="16" fill="#535461" />
        <defs>
            <linearGradient
                id="paint0_linear_428_1506"
                x1="170.5"
                y1="434"
                x2="170.5"
                y2="476"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E4DDCD" />
                <stop offset="0.245949" stop-color="#F6D07C" />
                <stop offset="0.735532" stop-color="#F55F44" />
                <stop offset="1" stop-color="#EB4D31" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_428_1506"
                x1="223.5"
                y1="434"
                x2="223.5"
                y2="476"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E4DDCD" />
                <stop offset="0.245949" stop-color="#F6D07C" />
                <stop offset="0.735532" stop-color="#F55F44" />
                <stop offset="1" stop-color="#EB4D31" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_428_1506"
                x1="256.5"
                y1="434"
                x2="256.5"
                y2="476"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E4DDCD" />
                <stop offset="0.245949" stop-color="#F6D07C" />
                <stop offset="0.735532" stop-color="#F55F44" />
                <stop offset="1" stop-color="#EB4D31" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_428_1506"
                x1="309.5"
                y1="434"
                x2="309.5"
                y2="476"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E4DDCD" />
                <stop offset="0.245949" stop-color="#F6D07C" />
                <stop offset="0.735532" stop-color="#F55F44" />
                <stop offset="1" stop-color="#EB4D31" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_428_1506"
                x1="119.5"
                y1="427"
                x2="119.5"
                y2="542"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_428_1506"
                x1="86"
                y1="473"
                x2="86"
                y2="581"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_428_1506"
                x1="236"
                y1="448"
                x2="236"
                y2="554"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_428_1506"
                x1="173"
                y1="474"
                x2="173"
                y2="582"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_428_1506"
                x1="350"
                y1="427"
                x2="350"
                y2="542"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_428_1506"
                x1="301"
                y1="485"
                x2="301"
                y2="593"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_428_1506"
                x1="397"
                y1="473"
                x2="397"
                y2="581"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0F0F0" />
                <stop offset="0.699074" stop-color="#CFCFCF" />
                <stop offset="1" stop-color="#B8B8B8" />
            </linearGradient>
            <clipPath id="clip0_428_1506">
                <rect x="393" width="59" height="59" rx="29.5" fill="white" />
            </clipPath>
            <clipPath id="clip1_428_1506">
                <rect x="78" y="239" width="28" height="28" rx="14" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
