<script setup lang="ts">
import { ref, markRaw, computed, reactive } from 'vue'

import clsx from 'clsx';

import IconDesktop from '@/components/atoms/icons/IconDesktop.vue';
import IconMobile from '@/components/atoms/icons/IconMobile.vue';

import IconMarginTop from '@/components/atoms/icons/IconMarginTop.vue';
import IconMarginBottom from '@/components/atoms/icons/IconMarginBottom.vue';
import IconMarginLeft from '@/components/atoms/icons/IconMarginLeft.vue';
import IconMarginRight from '@/components/atoms/icons/IconMarginRight.vue';

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: Object
    },
    valueMobile: {
        type: Object
    },
    lock: {
        type: Boolean
    }
})

const emit = defineEmits(['change'])

const configs = ref<any>([
    {
        id: 1,
        name: 'desktop',
        icon: markRaw(IconDesktop)
    },
    {
        id: 2,
        name: 'mobile',
        icon: markRaw(IconMobile)
    }
])

const marginIcons = ref<any>([
    {
        id: 1,
        name: 'top',
        icon: markRaw(IconMarginTop)
    },
    {
        id: 2,
        name: 'bottom',
        icon: markRaw(IconMarginBottom)
    },
    {
        id: 3,
        name: 'left',
        icon: markRaw(IconMarginLeft)
    },
    {
        id: 4,
        name: 'right',
        icon: markRaw(IconMarginRight)
    }
])

const marginInFocus = ref<any>(marginIcons.value[0].name)

const activeConfig = ref(configs.value[0])

const setActiveConfig = (config: any) => {
    activeConfig.value = config
}

const setMarginInFocus = (margin: any) => {
    marginInFocus.value = margin
}

const dynamicMarginIcon = computed(() => {
    return marginIcons.value.find((icon: any) => icon.name == marginInFocus.value).icon
})

const onChangeMargin = (target: string, value: number) => {
    emit('change', {
        config: activeConfig.value.name,
        target,
        value
    })

    if (props.lock && activeConfig.value.name == 'desktop') {
        emit('change', {
            config: 'mobile',
            value: `${value / 2}`,
            target
        })
    }
}

const onChangeLock = () => {
    emit('change', {
        config: 'lock',
        target: 'style_margin_lock',
        value: !props.lock
    })
}

const options = reactive({
    mask: ['-####'],
    masked: true,
    tokens: {
        '#': /\d/,
        '-': /-/
    }
})
</script>

<template>
    <div class="space-y-2">
        <div class="flex justify-between items-center">
            <div class="flex space-x-2">
                <span class="text-sm font-semibold text-[#060606] opacity-50">
                    {{ label }}
                </span>
                
                <div class="flex flex-row space-x-2">
                    <button 
                        v-for="config in configs"
                        :key="config.id"
                        @click="setActiveConfig(config)"
                    >
                        <component
                            :is="config.icon"
                            :class="clsx(
                                'w-[14px] h-[14px] transition-all duration-500',
                                activeConfig.id == config.id ? 'stroke-[var(--main-color)]' : 'stroke-[#060606] opacity-10'
                            )"
                        />
                    </button>
                </div>
            </div>
        </div>

        <div class="w-full flex justify-between">
            <div v-if="activeConfig.name == 'desktop'" class="flex items-center w-full">
                <div
                    class="w-[2rem] flex items-center space-x-1"
                >
                    <component 
                        :is="dynamicMarginIcon"
                        class="w-7 h-[18px]"
                    />
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent"
                        type="number"
                        v-maska:[options]
                        :value="value?.top ?? 0"
                        @input="(e: any) => onChangeMargin(marginIcons[0].name, e.target.value)"
                    >
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[1].name)"
                        @focusout="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent"
                        type="number"
                        :value="value?.bottom ?? 0"
                        v-maska="[options]"
                        min="-9999"
                        max="9999"
                        @input="(e: any) => onChangeMargin(marginIcons[1].name, e.target.value)"
                    >
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[2].name)"
                        @focusout="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent"
                        type="number"
                        :value="value?.left ?? 0"
                        v-maska="[options]"
                        min="-9999"
                        max="9999"
                        @input="(e: any) => onChangeMargin(marginIcons[2].name, e.target.value)"
                    >
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[3].name)"
                        @focusout="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent"
                        type="number"
                        :value="value?.right ?? 0"
                        v-maska="[options]"
                        min="-9999"
                        max="9999"
                        @input="(e: any) => onChangeMargin(marginIcons[3].name, e.target.value)"
                    >
                </div>
            </div>

            <div v-else class="flex items-center w-full">
                <div
                    class="w-[2rem] flex items-center space-x-1"
                >
                    <component 
                        :is="dynamicMarginIcon"
                        class="w-7 h-[18px]"
                    />
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent disabled:bg-gray-200 disabled:cursor-not-allowed"
                        type="number"
                        v-maska:[options]
                        :value="valueMobile?.top ?? 0"
                        :disabled="lock"
                        @input="(e: any) => onChangeMargin(marginIcons[0].name, e.target.value)"
                    >
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[1].name)"
                        @focusout="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent disabled:bg-gray-200 disabled:cursor-not-allowed"
                        type="number"
                        :value="valueMobile?.bottom ?? 0"
                        :disabled="lock"
                        v-maska="[options]"
                        min="-9999"
                        max="9999"
                        @input="(e: any) => onChangeMargin(marginIcons[1].name, e.target.value)"
                    >
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[2].name)"
                        @focusout="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent disabled:bg-gray-200 disabled:cursor-not-allowed"
                        type="number"
                        :value="valueMobile?.left ?? 0"
                        :disabled="lock"
                        v-maska="[options]"
                        min="-9999"
                        max="9999"
                        @input="(e: any) => onChangeMargin(marginIcons[2].name, e.target.value)"
                    >
                </div>

                <svg class="mx-1" xmlns="http://www.w3.org/2000/svg" width="2" height="16" viewBox="0 0 2 16" fill="none">
                    <path opacity="0.1" d="M1 1V15" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="flex justify-center w-[3rem]">
                    <input
                        @focus="setMarginInFocus(marginIcons[3].name)"
                        @focusout="setMarginInFocus(marginIcons[0].name)"
                        class="text-sm w-full text-center appearance-none outline-none bg-transparent disabled:bg-gray-200 disabled:cursor-not-allowed"
                        type="number"
                        :value="valueMobile?.right ?? 0"
                        :disabled="lock"
                        v-maska="[options]"
                        min="-9999"
                        max="9999"
                        @input="(e: any) => onChangeMargin(marginIcons[3].name, e.target.value)"
                    >
                </div>
            </div>

            <div class="flex items-center">
                <button
                    @click="() => onChangeLock()"
                >
                    <svg v-if="lock" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4.91901 6.79375L3.43004 8.28272C2.87395 8.83881 2.55416 9.59545 2.56 10.3907C2.56585 11.1859 2.87864 11.9471 3.46111 12.5116C4.02557 13.0941 4.78699 13.4069 5.58207 13.4127C6.39529 13.4187 7.13402 13.1169 7.69014 12.5608L9.17911 11.0719M11.081 9.20636L12.57 7.71739C13.126 7.1613 13.4458 6.40466 13.44 5.60945C13.4341 4.81424 13.1214 4.05299 12.5389 3.4885C11.9746 2.92417 11.2133 2.61137 10.4181 2.60552C9.62285 2.59968 8.86611 2.90131 8.30999 3.45742L6.82102 4.94639M5.74207 10.2182L10.209 5.75126" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g opacity="0.3">
                            <path d="M4.91901 6.79375L3.43004 8.28272C2.87395 8.83881 2.55416 9.59545 2.56 10.3907C2.56585 11.1859 2.87864 11.9471 3.46111 12.5116C4.02557 13.0941 4.78699 13.4069 5.58207 13.4127C6.39529 13.4187 7.13402 13.1169 7.69014 12.5608L9.17911 11.0719M11.081 9.20636L12.57 7.71739C13.126 7.1613 13.4458 6.40466 13.44 5.60945C13.4341 4.81424 13.1214 4.05299 12.5389 3.4885C11.9746 2.92417 11.2133 2.61137 10.4181 2.60552C9.62285 2.59968 8.86611 2.90131 8.30999 3.45742L6.82102 4.94639M5.74207 10.2182L10.209 5.75126" stroke="#060606" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
