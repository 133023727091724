<script setup lang="ts">
import { ref, reactive, computed, watch } from 'vue'

import { useEditorPageStore } from '@/stores/editor/page'
import { useDashboardStore } from '@/stores/dashboard'
import { useApplicationStore } from '@/stores/application'

import modal from '@/services/modal';
import clsx from 'clsx';
import notification from '@/services/notification';

const editorPageStore = useEditorPageStore()
const dashboardStore = useDashboardStore()
const applicationStore = useApplicationStore()

const loadingGetPage = computed(() => editorPageStore.loadingGetPage)

const page = ref<any>({ value: null})

watch(loadingGetPage, () => {
    page.value = reactive(editorPageStore.activePage)
})

const onClickNext = async () => {
    const isBlank = await editorPageStore.checkIsBlankPost();

    if (isBlank) {
        notification.simple('Preencha os campos de texto para continuar.', 'error')
        return
    }

    editorPageStore.nextPostStep()
}

const onClickPublish = async () => {
    await editorPageStore.publishPost(
        { 
            sketch: 0,
            notification: {
                loading: 'Publicando...',
                success: 'Post publicado com sucesso',
                error: 'Erro ao publicar post'
            }
        }
    )
}

const onClickSchedule = async () => {
    const title = 'Confirmar Agendamento'
    const message = 'Ao agendar, o post será publicado na data selecionada. Continuar?'
    const type = 'info'
    const confirm = 'Sim, agendar'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    await editorPageStore.publishPost(
        { 
            sketch: 0,
            notification: {
                loading: 'Agendando...',
                success: 'Post agendado com sucesso',
                error: 'Erro ao agendar post'
            }
        }
    )
}

const goDashboard = async () => {
    if (editorPageStore.postStep == 2) {
        editorPageStore.previousStep()

        return
    }

    const title = 'Voltar para o Dashboard?'
    const message = 'Essa ação salva e mantém o Post atual como rascunho. Continuar?'
    const type = 'danger'
    const confirm = 'Sim, continuar'
    const cancel = 'Não, voltar ao Editor'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    await editorPageStore.publishPost(
        { 
            sketch: 1,
            notification: {
                loading: 'Salvando...',
                success: 'Post salvo com sucesso',
                error: 'Erro ao salvar post'
            }
        }
    )
}
</script>

<template>
    <div id="post-post-editor-menu" class="post-editor-menu">
        <div class="fragment-action-back">
            <button class="button-back" @click="goDashboard">
                <span class="sr-only">Back Dashboard</span>

                <svg
                    class="w-4 h-4"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.63552 3.20005L1.59961 6.27704M1.59961 6.27704L4.63552 9.35404M1.59961 6.27704L11.1381 6.27704C12.9394 6.27704 14.3996 7.73727 14.3996 9.53855V9.53855C14.3996 11.3398 12.9394 12.8 11.1381 12.8L7.99961 12.8"
                        stroke="#060606"
                        stroke-opacity="0.3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>

        <div class="fragment-title-post">
            <span v-if="!loadingGetPage" class="title-post">
                {{ page.title }}
            </span>

            <span v-else class="title-post-loading">Carregando...</span>
        </div>

        <div class="fragment-actions">
            <button
                v-if="editorPageStore.postStep == 1"
                @click="onClickNext"
                :class="clsx(
                    'button-publish',
                    applicationStore.className == 'uli' && 'bg-gradient-to-br from-violet-500 via-purple-600 to-indigo-700',
                    applicationStore.className != 'uli' && 'bg-gradient-to-bl from-lime-500 via-emerald-500 to-green-600'
                )"
            >
                Próximo
            </button>

            <button
                v-else-if="editorPageStore.postStep == 2 && !editorPageStore.schedule"
                @click="onClickPublish"
                :class="clsx(
                    'button-publish',
                    applicationStore.className == 'uli' && 'bg-gradient-to-br from-violet-500 via-purple-600 to-indigo-700',
                    applicationStore.className != 'uli' && 'bg-gradient-to-bl from-lime-500 via-emerald-500 to-green-600'
                )"
                :disabled="loadingGetPage || editorPageStore.disabledPublish"
            >
                Publicar
            </button>

            <button
                v-else-if="editorPageStore.postStep == 2 && editorPageStore.schedule"
                @click="onClickSchedule"
                :class="clsx(
                    'button-publish',
                    applicationStore.className == 'uli' && 'bg-gradient-to-br from-violet-500 via-purple-600 to-indigo-700',
                    applicationStore.className != 'uli' && 'bg-gradient-to-bl from-lime-500 via-emerald-500 to-green-600'
                )"
            >
                Agendar
            </button>
        </div>
    </div>
</template>

<style scoped>
.post-editor-menu {
    @apply z-50 fixed top-0 left-0 right-0 h-[78px] w-full mx-auto px-20 flex items-center justify-between backdrop-blur-sm;
}

.post-editor-menu .fragment-action-back {
    @apply w-[180px] text-center flex items-center justify-start;
}

.post-editor-menu .action-back .button-back {
    @apply p-2 bg-[#F9F9F9] rounded-full outline-none disabled:cursor-not-allowed;
}

.post-editor-menu .fragment-title-post {
    @apply text-center flex flex-1 items-center justify-center;
}

.post-editor-menu .fragment-title-post .title-post,
.post-editor-menu .fragment-title-post .title-post-loading {
    @apply text-base text-[#060606] opacity-50 font-bold;
}

.post-editor-menu .fragment-title-post .title-post-loading {
    @apply animate-pulse;
}

.post-editor-menu .fragment-actions {
    @apply w-[180px] flex items-center  justify-end space-x-3;
}

.post-editor-menu .fragment-actions .button-preview {
    @apply ml-auto;
}

.post-editor-menu .fragment-actions .button-publish {
    @apply px-8 py-2 rounded-full font-semibold text-white text-base disabled:bg-none disabled:bg-gray-500 disabled:cursor-not-allowed; 
}
</style>
