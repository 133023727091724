<script setup lang="ts">
import { ref, computed, onBeforeMount, onUpdated } from 'vue'

import clsx from 'clsx'

import { ColorPicker } from 'vue3-colorpicker'

import { useEyeDropper } from '@vueuse/core'

import { useLayoutStore } from '@/stores/layout'

const { isSupported, open } = useEyeDropper()

const layoutStore = useLayoutStore()

const colors = computed(() => layoutStore.getColors())

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: String,
    },
    defaultValue: {
        type: String,
        required: false,
        default: ''
    }
})

const activeValue = ref('')

const onClickColorPicker = async () => {
    const result = await open()

    if (!result) {
        return
    }

    activeValue.value = result.sRGBHex?.toUpperCase()

    emit('change', activeValue.value)
}

const onChangeColor = (color: string) => {
    if (color.length == 9 && (color[color.length - 1] == 'f' && color[color.length - 2] == 'f')) {
        color = color.slice(0, -2)
    }

    if (color == '#00000000') {
        onChangeColor(props.defaultValue)

        emit('change', activeValue.value)
            
        return
    }

    activeValue.value = color.toUpperCase()

    emit('change', activeValue.value)
}

const emit = defineEmits(['change'])

const isCustomColor = computed(() => {
    const filtered = colors.value.filter(
        (color: any) => color.value.toUpperCase() == activeValue.value
    )

    return filtered.length == 0
})

onBeforeMount(() => {
    if (props.value) {
        activeValue.value = props.value.toUpperCase()

        return
    }

    if (!props.value && props.defaultValue) {
        activeValue.value = props.defaultValue

        return
    }
})

onUpdated(() => {
    if (activeValue.value != '') {
        return
    }

    if (activeValue.value == null) {
        activeValue.value = props.defaultValue

        return
    }

    activeValue.value = props.value?.toUpperCase() ?? ''
})
</script>

<template>
    <div class="space-y-2">
        <span class="text-sm font-semibold text-[#060606] opacity-50">
            {{ label }}
        </span>

        <div class="w-full space-y-2 pb-2 h-24 pr-2 overflow-y-scroll">
            <button
                class="w-full flex justify-between items-center"
                v-if="defaultValue != ''"
                @click="onChangeColor('')"
            >
                <span class="text-sm text-[#060606] font-bold opacity-30">PADRÃO</span>

                <div
                    :class="
                        clsx(
                            defaultValue.toUpperCase() == activeValue.toUpperCase()
                                ? 'border-[var(--main-color)]'
                                : 'border-[#EEEEEE]',
                            'w-[18px] h-[18px] border-[1.5px] rounded-full'
                        )
                    "
                    :style="{ backgroundColor: defaultValue }"
                ></div>
            </button>

            <template v-for="(color, index) in colors" :key="index">
                <button
                    class="w-full flex justify-between items-center"
                    @click="onChangeColor(color.value)"
                    v-if="defaultValue.toUpperCase() != color.value.toUpperCase()"
                >
                    <span class="text-sm text-[#060606] font-bold opacity-30">
                        {{ color.value.toUpperCase() }}
                    </span>

                    <div
                        v-tooltip="{
                            text: color.name.toUpperCase(),
                            position: 'left'
                        }"
                        :class="
                            clsx(
                                color.value.toUpperCase() == activeValue.toUpperCase()
                                    ? 'border-[var(--main-color)]'
                                    : 'border-[#EEEEEE]',
                                'w-[18px] h-[18px] border-[1.5px] rounded-full'
                            )
                        "
                        :style="{ backgroundColor: color.value }"
                    ></div>
                </button>
            </template>
        </div>

        <div
            :class="
                clsx(
                    'flex justify-between items-center px-4 py-3 border-2 rounded bg-[#FCFCFC]',
                    isCustomColor
                        ? 'border-[var(--main-color)]'
                        : 'border-[#F5F5F5]'
                )
            "
        >
            <input
                v-model="activeValue"
                @input="(e:any) => onChangeColor(e.target.value)"
                type="text"
                class="flex-1 bg-transparent text-sm text-[#060606] font-bold opacity-30 outline-none"
            />

            <div class="flex space-x-1">
                <color-picker
                    format="hex8"
                    shape="circle"
                    :pure-color="activeValue"
                    @pure-color-change="onChangeColor"
                />

                <button v-if="isSupported" @click="onClickColorPicker">
                    <span class="sr-only">Color Picker</span>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <g opacity="0.1">
                            <path
                                d="M7.85463 4.95537L13.0445 10.1452M1.7998 12.7401L12.0233 2.51662C12.9788 1.56119 14.5278 1.56119 15.4832 2.51662C16.4387 3.47204 16.4387 5.02109 15.4832 5.97652L5.25971 16.2H1.7998V12.7401Z"
                                stroke="#060606"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
:deep(.vc-color-wrap) {
    @apply shadow-none;
}
</style>
