<script setup lang="ts">
import router from '@/router'
import api from '@/services/api'
import { ref, onMounted, onBeforeMount } from 'vue'

import { useSiteStore } from '@/stores/site'
import { useApplicationStore } from '@/stores/application'
import { useUserStore } from '@/stores/user'

const applicationStore = useApplicationStore()
const userStore = useUserStore()
const siteStore = useSiteStore()

const token = ref()
const postId = ref()
const site = ref()

const panelUrl = ref()

onBeforeMount(() => {
    if (router.currentRoute.value.query.token) {
        token.value = router.currentRoute.value.query.token
    }

    if (router.currentRoute.value.query.postid) {
        postId.value = router.currentRoute.value.query.postid
    }
})

onMounted(async () => {
    await verifyToken()
})

const populateStores = async () => {
    const siteData = localStorage.getItem('siteData')
    const applicationData = localStorage.getItem('applicationData')
    const userData = localStorage.getItem('userData')

    if (siteData) {
        siteStore.setSite(JSON.parse(siteData))
    }

    if (applicationData) {
        applicationStore.setApplication(JSON.parse(applicationData))
    }

    if (userData) {
        userStore.setUser(JSON.parse(userData))
    }

    document.title = applicationStore.appName + " - Painel";

    const body = document.body;
    
    if (applicationStore.className == "uli") {
        body.classList.add(applicationStore.className);
    } else {
        body.classList.add("others");
    }
}

const verifyToken = async () => {
    try {
        let requestData = JSON.stringify({
            token: token.value
        })

        const response = await api.post('application/user/login/validate', requestData)

        const data = await response.json()

        if (data.status == 0) {
            throw new Error(data.error)
        }

        const applicationData: Object = {
            ...data.application,
            panelUrl: data.panelUrl,
            wizardUrl: data.wizardUrl
        }

        panelUrl.value = data.panelUrl

        const userData: Object = {
            token: data.token,
            email: data.email
        }

        saveDataInLocalStorage(applicationData, userData)

        if (data.sites.length <= 0) {
            site.value = await createSite()

            goWizard(site.value.siteId)
        }

        site.value = data.sites[0]
        localStorage.setItem('siteData', JSON.stringify(site.value))

        if (!site.value.wizard) {
            goWizard(site.value.id)
        }

        // this.$store.dispatch("userModule/GET_USER_MEDIAS", [this.$session.get('jwt'), site.id]);

        if (postId.value) {
            goPostEditor(site.value.id, postId.value)
        }

        await goDashboard()
    } catch (error) {
        console.warn(error)

        document.location.href = panelUrl.value
    }
}

const saveDataInLocalStorage = (applicationData: any, userData: any) => {
    localStorage.setItem('applicationData', JSON.stringify(applicationData))
    localStorage.setItem('userData', JSON.stringify(userData))

    applicationStore.setApplication(applicationData)
    userStore.setUser(userData)
}

const createSite = async () => {
    let url = `/site/create`

    let response = await api.get(url)

    const data = await response.json()

    return data
}

const goWizard = (siteId: number) => {
    router.push({
        name: 'Wizard',
        params: {
            siteId: siteId
        }
    })
}

const goPostEditor = async (siteId: number, postId: number) => {
    await populateStores()

    await router.push({
        name: 'dashboard.page',
        params: {
            siteId: siteId,
            postId: postId
        }
    })
}

const goDashboard = async () => {
    await populateStores()

    await router.push({
        name: 'dashboard'
    })
}
</script>

<template>
    <div class="w-screen h-screen flex flex-col justify-center items-center" id="DashbaordLogin">
        <img class="h-64 w-64 animate-pulse" src="@/assets/images/Uli-loading.gif" />

        <h2 class="text-xl animate-pulse">Acessando painel...</h2>
    </div>
</template>
