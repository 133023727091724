import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import api from '@/services/api'

import { isDark } from '@/utils/color'

import { useSiteStore } from '@/stores/site'
import { useEditorPageStore } from '@/stores/editor/page'

/**
 * @description Layout Store
 */
export const useLayoutStore = defineStore('layout', () => {
    // stores
    const siteStore = useSiteStore()
    const editorPage = useEditorPageStore()

    const isHome = computed(() => {
        return editorPage.activePage?.isHome
    })
    // fim stores

    // states
    const loading = ref(false)

    const css = ref()
    const hash = ref(null)
    const publishedAt = ref(null)
    const updatedAt = ref(null)
    const status = ref(null)
    const template = ref(null)
    const theme = ref(null)
    const templateOrganismsCombinations = ref()
    // fim states

    // getters

    // fim getters

    // actions
    const setLoading = (value: boolean) => {
        loading.value = value
    }

    const setLayout = (content: any) => {
        css.value = JSON.parse(content.data)
        hash.value = content.hash
        publishedAt.value = content.published_at
        updatedAt.value = content.updated_at
        status.value = content.status
        template.value = content.template
        theme.value = content.theme
    }

    const getSectionBackgroundColorDefault = (position: number) => {
        let backgroundColorDefault = ''

        if (isHome.value && position === 0) {
            backgroundColorDefault = css.value.backgroundColor
        }

        if (isHome.value && position > 0) {
            position = position + 1
        }

        if (css.value.backgroundSecondaryColor) {
            backgroundColorDefault = css.value.backgroundSecondaryColor
        }

        if (position % 2 === 0) {
            if (css.value.backgroundColor) {
                backgroundColorDefault = css.value.backgroundColor
            }
        }

        return backgroundColorDefault.toUpperCase()
    }

    const getContainer = (frameId: number, component: string, variable: string) => {
        const container = css.value[`${frameId}-${component}--${variable}`]

        return container
    }

    const getSectionTextColorDefault = (position: number) => {
        let textColorDefault = ''

        if (isHome.value && position === 0) {
            textColorDefault = css.value.textSecondaryColor
        }

        if (isHome.value && position > 0) {
            position = position + 1
        }

        if (css.value.textSecondaryColor) {
            textColorDefault = css.value.textSecondaryColor
        }

        if (position % 2 === 0) {
            if (css.value.textColor) {
                textColorDefault = css.value.textColor
            }
        }

        return textColorDefault
    }

    const getSectionTextColorInverted = () => {
        let textColorInverted = ''

        if (css.value.invertedTextColor) {
            textColorInverted = css.value.invertedTextColor
        }

        return textColorInverted
    }

    const getAdaptiveTextColor = (backgroundColor: string) => {
        if (css.value.backgroundColor == backgroundColor && css.value.textColor) {
            return css.value.textColor
        }

        if (css.value.backgroundSecondaryColor == backgroundColor && css.value.textColor) {
            return css.value.textSecondaryColor
        }

        const isDarkColor = isDark(backgroundColor)

        if (
            (!isDarkColor && isDark(css.value.textColor)) ||
            (isDarkColor && !isDark(css.value.textColor))
        ) {
            return css.value.textColor
        }

        if (
            (!isDarkColor && isDark(css.value.textSecondaryColor)) ||
            (isDarkColor && !isDark(css.value.textSecondaryColor))
        ) {
            return css.value.textSecondaryColor
        }
    }

    const getSectionTitleColorDefault = (position: number) => {
        let titleColorDefault = ''

        if (isHome.value && position === 0) {
            titleColorDefault = css.value.titleColor
        }

        if (isHome.value && position > 0) {
            position = position + 1
        }

        if (css.value.titleSecondaryColor) {
            titleColorDefault = css.value.titleSecondaryColor
        }

        if (position % 2 === 0) {
            if (css.value.titleColor) {
                titleColorDefault = css.value.titleColor
            }
        }

        return titleColorDefault
    }

    const getSectionTitleColorInverted = () => {
        let titleColorInverted = ''

        if (css.value.invertedTitleColor) {
            titleColorInverted = css.value.invertedTitleColor
        }

        return titleColorInverted
    }

    const getAdaptiveTitleColor = (backgroundColor: string) => {
        if (css.value.backgroundColor == backgroundColor && css.value.titleColor) {
            return css.value.titleColor
        }

        if (css.value.backgroundSecondaryColor == backgroundColor && css.value.titleSecondaryColor) {
            return css.value.titleSecondaryColor
        }

        const isDarkColor = isDark(backgroundColor)

        if (
            (!isDarkColor && isDark(css.value.titleColor)) ||
            (isDarkColor && !isDark(css.value.titleColor))
        ) {
            return css.value.titleColor
        }

        if (
            (!isDarkColor && isDark(css.value.titleSecondaryColor)) ||
            (isDarkColor && !isDark(css.value.titleSecondaryColor))
        ) {
            return css.value.titleSecondaryColor
        }
    }

    const getSectionFontWeightDefault = (position: number) => {
        let fontWeightDefault = ''

        if (isHome.value && position === 0) {
            fontWeightDefault = css.value.primary_font_weight
        }

        if (isHome.value && position > 0) {
            position = position + 1
        }

        if (css.value.seccondary_font_weight) {
            fontWeightDefault = css.value.seccondary_font_weight
        }

        if (position % 2 === 0) {
            if (css.value.primary_font_weight) {
                fontWeightDefault = css.value.primary_font_weight
            }
        }

        return fontWeightDefault
    }

    const getLayout = async () => {
        setLoading(true)

        try {
            const response = await api.get(`site/${siteStore.id}/client/layout`)

            const { status, content } = await response.json()

            if (status != '1') {
                throw new Error('Error on get layout')
            }

            setLayout(content)
        } catch (error) {
            console.warn(error)
        }

        setLoading(false)
    }

    const fetchTemplateOrganismsCombinations = async () => {
        try {
            const response = await api.get(
                `template/organism/combination`
            )

            const { success, message } = await response.json()

            if (!success) {
                throw new Error('Error on fetch template organisms combinations')
            }

            templateOrganismsCombinations.value = message

            return
        } catch (error) {
            console.warn(error)
        }
    }

    const getColors = () => {
        if (!css.value) {
            return []
        }

        // define as cores padrão do site (Pega as principais variaveis do css)
        const colors = [
            {
                value: css.value.invertedTitleColor,
                name: 'Cor do Titulo Invertido do Site'
            },
            {
                value: css.value.titleColor,
                name: 'Cor do Titulo Principal do Site'
            },
            {
                value: css.value.titleSecondaryColor,
                name: 'Cor do Titulo Secundário do Site'
            },
            {
                value: css.value.hightlightColor,
                name: 'Cor de Destaque do Site'
            },
            {
                value: css.value.textColor,
                name: 'Cor do Texto Principal do Site'
            },
            {
                value: css.value.textColorHighlight,
                name: 'Cor do Texto de Destaque do Site'
            },
            {
                value: css.value.textSecondaryColor,
                name: 'Cor do Texto Secundário do Site'
            },
            {
                value: css.value.titleColor,
                name: 'Cor do Titulo Principal do Site'
            },
            {
                value: css.value.titleCoverColor,
                name: 'Cor do Titulo de Capa do Site'
            },
            {
                value: css.value.primaryColor,
                name: 'Cor Primária do Site'
            },
            {
                value: css.value.secondaryColor,
                name: 'Cor Secundária do Site'
            },
            {
                value: css.value.colorHighlight,
                name: 'Cor de Destaque do Site'
            },
            {
                value: css.value.backgroundColor,
                name: 'Cor de Fundo do Site'
            },
            {
                value: css.value.backgroundColorButton,
                name: 'Cor de Fundo do Botão do Site'
            },
            {
                value: css.value.backgroundSecondaryColor,
                name: 'Cor de Fundo Secundária do Site'
            },
            {
                value: css.value.backgroundSecondaryColorButton,
                name: 'Cor de Fundo Secundária do Botão do Site'
            }
        ]

        // inicia um set para filtrar as cores repetidas
        const uniqueValues = new Set()

        // inicia um array para armazenar as cores filtradas
        const colorsFiltered = <any>[]

        // filtra as cores repetidas
        colors.map(item => {
            // verifica se a cor já existe no set
            if (!uniqueValues.has(item.value)) {
                // se não existir, adiciona a cor no set e no array de cores filtradas

                // verifica se a cor é válida
                if (
                    item.value !== undefined &&
                    item.value !== null &&
                    item.value !== 'transparent' &&
                    item.value.includes('#')
                ) {
                    colorsFiltered.push(item)
                    uniqueValues.add(item.value)
                }
            }
        })

        // retorna as cores filtradas
        return colorsFiltered
    }

    const getButtonBackgroundColor = (position: number) => {
        if (position % 2 === 0) {
            return css.value.backgroundSecondaryColor
        }

        return css.value.backgroundColorButton
    }

    const getButtonColor = (position: number) => {
        if (position % 2 === 0) {
            return css.value.colorSecondaryTextButton
        }

        return css.value.colorTextButton
    }

    // fim actions

    return {
        // disponibiliza as states
        loading,
        css,
        hash,
        publishedAt,
        updatedAt,
        status,
        template,
        theme,
        templateOrganismsCombinations,
        // disponibiliza os getters
        // disponibiliza as actions
        getLayout,
        getSectionBackgroundColorDefault,
        getSectionTextColorInverted,
        getAdaptiveTextColor,
        getSectionTextColorDefault,
        getSectionTitleColorInverted,
        getSectionTitleColorDefault,
        getAdaptiveTitleColor,
        getSectionFontWeightDefault,
        getColors,
        getButtonBackgroundColor,
        getButtonColor,
        getContainer,
        fetchTemplateOrganismsCombinations
    }
})
