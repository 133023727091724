<script setup lang="ts">
import { computed } from 'vue'

import SettingWidth from '@/components/atoms/SettingWidth.vue'

import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'

const editorPageStore = useEditorPageStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()
}
</script>

<template>
    <settings-layout
        :name="`Configurações do Conteúdo`"
        :default-open="true"
    >
        <setting-width
            label="Largura"
            :value="elementForEdit?.grid"
            :value-mobile="elementForEdit?.gridMobile"
            :values-permission-desktop="[4, 6, 12]"
            :values-permission-mobile="[4, 6, 12]"
            @change="onChangeWidth"
        />
    </settings-layout>
</template>

<style scoped></style>
