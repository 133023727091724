<script setup lang="ts">
import { ref, markRaw, computed } from 'vue'

import IconGridLarge from '@/components/atoms/icons/IconGridLarge.vue';
import IconGridMedium from '@/components/atoms/icons/IconGridMedium.vue';
import IconGridSmall from '@/components/atoms/icons/IconGridSmall.vue';
import IconGridSmaller from '@/components/atoms/icons/IconGridSmaller.vue';
import IconGridFull from '@/components/atoms/icons/IconGridFull.vue';

import IconDesktop from '@/components/atoms/icons/IconDesktop.vue';
import IconMobile from '@/components/atoms/icons/IconMobile.vue';

import clsx from 'clsx';

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: Number
    },
    valueMobile: {
        type: Number
    },
    valuesPermissionDesktop: {
        type: Array,
        default: () => []
    },
    valuesPermissionMobile: {
        type: Array,
        default: () => []
    }
})

const emit = defineEmits(['change'])

const handleChangeWidth = (value: any) => {
    const target = configs.value.find((config: any) => config.id == activeConfig.value.id).name

    emit('change', 
        {
            target,
            value
        }
    )
}

const gridsDesktop = [
    {
        value: 12,
        icon: IconGridLarge
    },
    {
        value: 6,
        icon: IconGridMedium
    },
    {
        value: 4,
        icon: IconGridSmall
    },
    {
        value: 3,
        icon: IconGridSmaller
    },
    {
        value: 99,
        icon: IconGridFull
    }
]

const configs = ref<any>([
    {
        id: 1,
        name: 'desktop',
        icon: markRaw(IconDesktop)
    },
    {
        id: 2,
        name: 'mobile',
        icon: markRaw(IconMobile)
    }
])

const activeConfig = ref(configs.value[0])

const setActiveConfig = (config: any) => {
    activeConfig.value = config
}

const filteredGrids = computed(() => {
    if (activeConfig.value.id == 1) {
        return gridsDesktop.filter((grid: any) => {
            if (props.valuesPermissionDesktop.includes(grid.value)) {
                return true
            }

            return false
        });
    }

    return gridsDesktop.filter((grid: any) => {
        if (props.valuesPermissionMobile.includes(grid.value)) {
            return true
        }

        return false
    });
})
</script>

<template>
    <div class="space-y-2">
        <div class="flex items-center space-x-2">
            <div class="flex space-x-2">
                <span class="text-sm font-semibold text-[#060606] opacity-50">
                    {{ label }}
                </span>
                
                <div class="flex flex-row space-x-2">
                    <button 
                        v-for="config in configs"
                        :key="config.id"
                        @click="setActiveConfig(config)"
                    >
                        <component
                            :is="config.icon"
                            :class="clsx(
                                'w-[14px] h-[14px] transition-all duration-500',
                                activeConfig.id == config.id ? 'stroke-[var(--main-color)]' : 'stroke-[#060606] opacity-10'
                            )"
                        />
                    </button>
                </div>
            </div>
        </div>

        <div class="w-full flex items-center space-x-2">
            <button
                v-for="(grid, index) in filteredGrids"
                :class="clsx(
                    'flex items-center justify-center border-[1.5px] rounded p-[6px]',
                    (activeConfig.id == 1 && value == grid.value) 
                    ||
                    (activeConfig.id == 2 && valueMobile == grid.value)
                    ? 'border-[var(--main-color)]'
                    : 'border-[#060606] opacity-10'
                )"
                :key="index"
                @click="handleChangeWidth(grid.value)"
            >
                <component 
                    :is="grid.icon"
                    :class="clsx(
                        'w-4 h-4',
                        (activeConfig.id == 1 && value == grid.value)
                        ||
                        (activeConfig.id == 2 && valueMobile == grid.value)
                        ? 'fill-[var(--main-color)]'
                        : 'fill-[#060606]',
                    )"
                />
            </button>
        </div>
    </div>
</template>

<style scoped>

</style>
