<template>
    <svg width="88" height="56" viewBox="0 0 88 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1140_3640)">
        <rect x="5.83008" y="5.82959" width="76.0629" height="38.6351" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.83008" y="46.8793" width="76.0629" height="38.6351" rx="1.20735" fill="#E0E0E0"/>
        </g>
        <rect x="0.6" y="0.6" width="86.8" height="54.8" rx="2.4" stroke="#DCDCDC" stroke-width="0.8"/>
        <defs>
        <clipPath id="clip0_1140_3640">
        <rect x="1" y="1" width="86" height="54" rx="2" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>