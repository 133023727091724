import { ref } from 'vue'

import { defineStore } from 'pinia'

/**
 * @description Editor Sidebar Store
 */
export const useEditorSidebarStore = defineStore('editor-sidebar', () => {
    // states
    const isOpen = ref(false)
    const disabledClosed = ref(false)

    const closedDisabled = ref(false)

    const configsReference = ref<string | null>(null)
    // fim states

    // actions
    const setIsOpenModal = (value: boolean) => {
        isOpen.value = value
    }

    const setClosedDisabled = (value: boolean) => {
        closedDisabled.value = value
    }

    const reset = () => {
        setIsOpenModal(false)
        setConfigsReference(null)
    }

    const setConfigsReference = (value: string | null) => {
        configsReference.value = value
    }

    const setDisabledClosed = (value: boolean) => {
        disabledClosed.value = value
    }
    // fim actions

    return {
        isOpen,
        closedDisabled,
        configsReference,
        disabledClosed,
        setIsOpenModal,
        setClosedDisabled,
        reset,
        setConfigsReference,
        setDisabledClosed
    }
})
