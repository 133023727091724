export const isDark = (hex: string) => {
    if (!hex) return false

    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    const brilho = (r * 299 + g * 587 + b * 114) / 1000

    return brilho < 128
}

export const convertColorRGBtoHEX = (color: string): string => {
    if (!color) return '#000000'

    if (color.startsWith('rgb')) {
        const rgbValues = color.match(/\d+/g)!.map(Number)

        const hex = `#${rgbValues.map(value => value.toString(16).padStart(2, '0')).join('')}`

        return hex.toUpperCase()
    }

    return color
}
