<template>
    <svg width="88" height="56" viewBox="0 0 88 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1140_3643)">
        <rect x="5.82959" y="5.82959" width="36.8241" height="19.3176" rx="1.20735" fill="#E0E0E0"/>
        <rect x="45.0684" y="5.82959" width="36.8241" height="19.3176" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="27.5618" width="36.8241" height="19.3176" rx="1.20735" fill="#E0E0E0"/>
        <rect x="45.0684" y="27.5618" width="36.8241" height="19.3176" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.82959" y="49.2942" width="36.8241" height="19.3176" rx="1.20735" fill="#E0E0E0"/>
        <rect x="45.0684" y="49.2942" width="36.8241" height="19.3176" rx="1.20735" fill="#E0E0E0"/>
        </g>
        <rect x="0.6" y="0.6" width="86.8" height="54.8" rx="2.4" stroke="#E0E0E0" stroke-width="0.8"/>
        <defs>
        <clipPath id="clip0_1140_3643">
        <rect x="1" y="1" width="86" height="54" rx="2" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>