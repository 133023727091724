<script setup lang="ts">
defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: String
    }
})

const emit = defineEmits(['change'])

const handleChangeOpacity = (event: any) => {
    emit('change', event.target.value)
}
</script>

<template>
    <div class="space-y-2">
        <span class="text-sm font-semibold text-[#060606] opacity-50">
            {{ label }}
        </span>

        <div class="w-full flex items-center space-x-4">
            <input
                class="w-3/4"
                type="range"
                min="0"
                max="100"
                @input="handleChangeOpacity"
                :value="value"
                :style="{
                    borderRadius: '9999px',
                    background: `linear-gradient(90deg, var(--main-color) ${value}%, #EEEEEE ${value}%)`
                }"
            />

            <div class="bg-[#FCFCFC] w-1/4 px-3 py-2 flex justify-center items-center rounded">
                <input
                    type="number"
                    @input="handleChangeOpacity"
                    :value="value"
                    min="0"
                    max="100"
                    class="w-full text-[#060606] text-center opacity-30 font-semibold outline-none bg-transparent"
                />

                <span class="text-sm font-semibold text-[#060606] opacity-30">%</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
input[type='range'] {
    @apply appearance-none outline-none h-4 overflow-hidden rounded-full cursor-pointer;
}

input[type='range']::-webkit-slider-thumb {
    @apply appearance-none w-8 h-4 bg-[var(--main-color)] brightness-[1.3] rounded-full cursor-pointer;
}

input[type='range']::-moz-range-thumb {
    @apply appearance-none w-8 h-4 bg-[var(--main-color)] brightness-[1.3] rounded-full cursor-pointer;
}
</style>
