<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'

import DashboardLayout from '@/components/layouts/DashboardLayout.vue'

import BaseModal from '@/components/molecules/BaseModal.vue';
import BaseImage from '@/components/atoms/BaseImage.vue';
import BaseInputField from '@/components/atoms/BaseInputField.vue';
import ConfirmationModal from '@/components/organisms/ConfirmationModal.vue';
import MediaManagerModal from '@/components/organisms/MediaManagerModal.vue'
import IconCloud from '@/components/atoms/icons/IconCloud.vue';

import { useMediaManagerStore } from '@/stores/modal/media-manager'


import api from '@/services/api';
import { useSiteStore } from '@/stores/site';
import notification, { type ToastId } from '@/services/notification';
import modal from '@/services/modal';

const siteStore = useSiteStore();
const mediaManagerStore = useMediaManagerStore()

const isOpen = ref<boolean>(false);
const isOpenTitleModal = ref<boolean>(false);

const enableCloseModal = ref<boolean>(false);

const loading = ref<boolean>(false)
const errorImage = ref<boolean>(false)

const titleModal = ref<string>('Adicionar Publicidade'); // usa pra modal de adicionar e editar
const actionModal = ref<string>('Adicionar'); // usa pra modal de adicionar e editar

const dataForAd = ref({
    id: '',
    title: '',
    position: 'menu',
    advertisementType: 'text',
    content: '',
    image: '',
    ref: '',
    urlImage: ''
})

const resetDataForAd = () => {
    dataForAd.value = {
        id: '',
        title: '',
        position: 'menu',
        advertisementType: 'text',
        content: '',
        image: '',
        ref: '',
        urlImage: ''
    }
}

// lista de lugares que pode mudar o titulo da publicidade
const listTitlesForAds = ref({
    after_content: {
        text: 'Depois do Conteúdo', title: ''
    },
    footer: {
        text: 'Rodapé', title: ''
    },
    sidebar: {
        text: 'Menu lateral', title:' '
    },
    menu: { 
        text: 'Menu Topo', title:''
    }
});

const adsList = ref<any[]>([])

onBeforeMount(async () => {
    await loadAds()
});

const newAdvertisementHandler = () => {
    actionModal.value = 'Adicionar'
    titleModal.value = 'Adicionar Publicidade'
    resetDataForAd()
    isOpen.value = true
}

/** 
 * @description Função para adicionar ou atualizar publicidade
 * @param {void}
 */
const handleActionModal = () => {
    if (actionModal.value == 'Adicionar') {
        addNewAds();
    } else {
        updateAds();
    }
}

/** 
 * @description Função para atualizar publicidade
 * @param {void}
 */
const updateAds = async () => {
    const toastId: ToastId = notification.loading('Atualizando...')

    try {
        loading.value = true

        const response = await api.post(`site/${siteStore.id}/flyer/${dataForAd.value.id}/update`, JSON.stringify(dataForAd.value))

        const { status, data } = await response.json()

        loading.value = false
        if (data === undefined) {
            throw new Error('Erro ao atualizar publicidade')
        }

        const index = adsList.value.findIndex(item => item.id === dataForAd.value.id)
        adsList.value[index] = dataForAd.value
        isOpen.value = false
        notification.update(toastId, 'Publicidade atualizada com sucesso', 'success')
    } catch (error) {
        notification.update(toastId, 'Erro ao atualizar publicidade', 'error');
        console.error(error);
    }
}

/** 
 * @description Função para adicionar nova publicidade
 * @param {void}
 */
const addNewAds = async () => {
    errorImage.value = false
    const toastId: ToastId = notification.loading('Adicionando publicidade')

    // se for do tipo banner e nao tiver imagem, retorna erro
    if (!dataForAd.value.image 
        &&dataForAd.value.advertisementType === "banner") {
        errorImage.value = true;
        return
    }

    try {
        loading.value = true

        const dataNew = {
            title: dataForAd.value.title,
            position: dataForAd.value.position,
            advertisementType: dataForAd.value.advertisementType,
            content: dataForAd.value.content,
            image: dataForAd.value.image,
            ref: dataForAd.value.ref
        }

        const response = await api.post(`site/${siteStore.id}/flyer/create`, JSON.stringify(dataNew))

        const { status, data } = await response.json()
        
        if (status != 1) {
            throw new Error('Erro ao adicionar publicidade')
        }

        adsList.value.push(data)
        isOpen.value = false
        notification.update(toastId, 'Publicidade adicionada com sucesso', 'success')
        resetDataForAd()
    } catch (error) {
        notification.update(toastId, 'Erro ao adicionar publicidade', 'error')
        console.error(error);
    }
}

const loadAds = async () => {
    try {
        loading.value = true

        const response = await api.get(`site/${siteStore.id}/flyer`)

        const { status, data, titles } = await response.json()

        if (data !== undefined) {
            // Antes de atribuir ao seu modelo de dados, defina todos os status como true, para mostrar o checkbox
            const dataStatus = data.map(item => ({
            ...item,
            status: item.status ? true : false // Isto define cada item.status para true
            }));
            adsList.value = dataStatus
        }

        if (titles !== undefined) {
            for (const key in titles) {
                listTitlesForAds.value[key].title = titles[key]
            }
        }

    } catch (error) {
        console.error(error);
    }

}

const onCloseModal = () => {
    isOpen.value = false;
}

const onAddImage = () => {
    enableCloseModal.value = true
    mediaManagerStore.setIsOpenModal(true)

    mediaManagerStore.setMaxSelectedMedias(1)

    mediaManagerStore.setAction(($event: any) => handleAddImage($event))
}

const handleAddImage = (image: any) => {
    dataForAd.value.image = image.content

    mediaManagerStore.setIsOpenModal(false)
    enableCloseModal.value = false
}

const editAdvertisementHandler = async (item: any, index: number) => {
    // faz isso para nao ficar reativo a hora que tiver editando
    const itemCopy = JSON.parse(JSON.stringify(item));

    dataForAd.value = itemCopy;
    actionModal.value = 'Atualizar'
    titleModal.value = 'Editar Publicidade'
    isOpen.value = true
}

/** 
 * @description Função para ativar ou desativar publicidade, como usa o toggle, tem que mudar o status antes do envio
 * @param {item} item - item da publicidade
 */
const toggleAdvertise = async (item: any) => {
    let advertiseItem = item

    try {
        loading.value = true
        
        // troca o status que vai ser enviado
        advertiseItem.status = advertiseItem.status ? false : true

        const response = await api.post(`site/${siteStore.id}/flyer/${item.id}/update`, JSON.stringify(advertiseItem))

        const { status, data } = await response.json()

        if (status != 1) {
            throw new Error('Erro ao atualizar status do anúncio')
        }

    } catch (error) {
        console.error(error);
    }
}

/** 
 * @description Função que envia para deletar publicidade
 * @param {index} index - index da publicidade
 */
const handlerDeleteAds = async (index: number) => {
    const itemToDelete = adsList.value[index]

    const toastId: ToastId = notification.loading('Deletando...');

    try {
        loading.value = true

        const response = await api.post(`site/${siteStore.id}/flyer/${itemToDelete.id}/delete`, null)

        const { status, data } = await response.json()

        if (status != 1) {
            throw new Error('Erro ao deletar publicidade')
        }
        adsList.value.splice(index, 1)
        notification.update(toastId, 'Deletado com sucesso', 'success');
    } catch (error) {
        notification.update(toastId, 'Erro ao deletar publicidade', 'error');
        console.error(error);
    }
}

/** 
 * @description Abre confirmacao para deletar publicidade
 * @param {index} index - index da publicidade
 */
const onDelete = async (index: number) => {
    const title = 'Confirmar Remoção'
    const message = 'Deseja realmente remover esta publicidade?'
    const type = 'danger'
    const confirm = 'Sim, remover'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    handlerDeleteAds(index)
}

const onCloseModalTitles = () => {
    isOpenTitleModal.value = false
}

const handleSaveTitles = async () => {
    const toastId: ToastId = notification.loading('Salvando...')

    try {
        loading.value = true

        let titles = {}

        for (const key in listTitlesForAds.value) {
            titles[key] = listTitlesForAds.value[key].title
        }

        const response = await api.post(`site/${siteStore.id}/flyer/titles/update`, JSON.stringify(titles))

        const { status, data } = await response.json()

        loading.value = false
        
        if (status != 1) {
            throw new Error('Erro ao salvar títulos')
        }

        isOpenTitleModal.value = false
        notification.update(toastId, 'Títulos salvos com sucesso', 'success')
    } catch (error) {
        console.error(error);
    }

    notification.update(toastId, 'Erro ao salvar títulos', 'error');
}
</script>

<template>
    <base-modal
        :title="titleModal"
        :open-watch="isOpen"
        @modal-closed="() => isOpen = false"
        size="custom"
        height="h-[35rem]"
        width="w-[30rem]"
        rounded="2xl"
        :closed-disabled="enableCloseModal"
    >
        <template #body>
            <div class="h-full flex flex-col justify-between">
                <base-input-field label="Título" id="newAdivertiseTitle">
                    <input type="text" name="title" id="newAdivertiseTitle" v-model="dataForAd.title" required />
                </base-input-field>

                <base-input-field label="Posição da publicidade" id="newAdivertsePosition">
                    <select v-model="dataForAd.position" required>
                        <option value="menu" :selected="dataForAd.position == 'menu' ? true : false">Topo menu</option>
                        <option value="after_content" :selected="dataForAd.position == 'after_content'">Depois do Conteúdo</option>
                        <option value="footer" :selected="dataForAd.position == 'footer'">Rodapé</option>
                        <option value="sidebar" :selected="dataForAd.position == 'sidebar'">Menu lateral</option>
                    </select>
                </base-input-field>
							
                <base-input-field label="Tipo de publicidade" id="newAdivertseType">
                    <select v-model="dataForAd.advertisementType" required>
                        <option 
                            v-if="dataForAd.position == 'menu'" 
                            :selected="dataForAd.advertisementType == 'text'"
                            value="text">
                            Texto
                        </option>
                        <option value="banner" 
                            :selected="true || dataForAd.advertisementType == 'banner'
                                || dataForAd.advertisementType === null
                                || !dataForAd.advertisementType">
                            Imagem
                        </option>
                        <option 
                            value="script" 
                            :selected="dataForAd.advertisementType == 'script'">
                            Código incorporado
                        </option>
                    </select>
                </base-input-field>
				
                <template v-if="dataForAd.advertisementType == 'script'">
                    <base-input-field label="Código Incorporado" id="newAdivertiseContent">
                        <textarea name="content" cols="30" rows="4" class="form-control" v-model="dataForAd.content" required></textarea>
                    </base-input-field>
                </template>
                <template v-else-if="dataForAd.advertisementType == 'text'">
                    <base-input-field label="Texto" id="newAdivertiseContent">
                        <textarea name="content" cols="30" rows="4" class="form-control" v-model="dataForAd.content" required></textarea>
                    </base-input-field>
                </template>
                <template v-else>
                    <div class="add-image-container flex flex-row">
                        <button
                            v-if="!dataForAd.image"
                            @click="onAddImage()"
                            class="flex flex-col items-center justify-center basis-1/4 h-full border-dashed border-2 border-[var(--main-color)] rounded-md space-y-2 p-4"
                        >
                            <IconCloud />
                            <span 
                                class="text-[var(--main-color)] text-xs font-bold"
                            >
                                Enviar Imagem
                            </span>

                        </button>
                        <div v-else>
                            <base-image
                                    :src="dataForAd.image"
                                    class="w-20 h-16 object-cover rounded-md"
                                />
                            <p 
                                @click="() => dataForAd.image = ''"
                                class="text-xs cursor-pointer text-center py-1">
                                Remover
                            </p>
                        </div>
                        <div class="basis-full ps-5">
                            <base-input-field label="Url de destino" id="newAdivertiseRef">
                                <input type="text" name="ref" id="newAdivertiseRef" v-model="dataForAd.ref" />
                            </base-input-field>
                        </div>
                    </div>
                    <span
                        v-if="errorImage" 
                        class="error-message">
                        <small>Imagem obrigatória</small>
                    </span>
                </template>
                <div class="flex justify-end mt-4 space-x-2">
                    <button
                        @click="() => onCloseModal()"
                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                    >
                        Cancelar
                    </button>

                    <button
                        @click="() => handleActionModal()"
                        class="bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none"
                    >
                        {{ actionModal }}
                    </button>
                </div>
            </div>
        </template>
    </base-modal>

    <base-modal
        title="Titulos das posições das publicidades"
        :open-watch="isOpenTitleModal"
        @modal-closed="() => isOpenTitleModal = false"
        size="custom"
        height="h-[35rem]"
        width="w-[30rem]"
        rounded="2xl"
        :closed-disabled="enableCloseModal"
    >
        <template #body>
            <div class="h-full flex flex-col justify-between">
                <div 
                    class="col-12 form-group"
                    v-for="(position, index) in listTitlesForAds"
                    :key="index" 
                >
                    
                    <base-input-field
                        :label="position.text"
                        :id="index"
                    >
                        <input
                            type="text"
                            :id="index"
                            v-model="position.title"
                        />
                    </base-input-field>
                </div>
                <div class="flex justify-end mt-4 space-x-2">
                    <button
                        @click="() => onCloseModalTitles()"
                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                    >
                        Cancelar
                    </button>

                    <button
                        @click="() => handleSaveTitles()"
                        class="bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none"
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </template>
    </base-modal>

    <media-manager-modal />

    <dashboard-layout id="DashboardAdversiments">
        <div class="max-w-full py-6 px-10 space-y-5">
            <div class="w-full flex justify-between">
                <div class="flex items-center space-x-3">
                    <h1 class="text-3xl font-bold">Minhas publicidades</h1>

                    <button
                        class="flex items-center justify-center h-10 w-10 bg-gray-100 rounded-full"
                        @click="() => isOpenTitleModal = true"
                    >
                        <span class="sr-only">configurations</span>

                        <svg
                            class="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                            />
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    </button>
                </div>

                <button
                    class="px-4 py-2 bg-[var(--main-color)] font-semibold text-white rounded-full"
                    @click="newAdvertisementHandler"
                >
                    + Nova
                </button>
            </div>

            <p v-if="!adsList">
                Você ainda não possui publicidades cadastradas. Clique no botão "Nova" para
                criar um novo anúncio.
            </p>
            <div  
                v-else
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div
					class=""
					v-for="(item, index) in adsList"
					:key="index">
					<div class="card advertise-card drop-shadow-md hover:drop-shadow-lg cursor-pointer bg-white rounded-md p-3 h-full">
						<div class="card-body py-3 flex h-full">
							<div class="advertise-thumbnail">
								<template
									v-if="item.advertisementType == 'banner'"
								>
                                    <base-image
                                        :src="item.image"
                                        class="img-fluid rounded-md"
                                    ></base-image>
								</template>
								<template v-else-if="item.advertisementType == 'text'">
                                    <span class="w-full h-full flex items-center justify-center bg-gray-100 rounded-md">Texto</span>
								</template>
								<template v-else>
                                    <span class="h-full w-full flex items-center justify-center bg-gray-100 rounded-md">Código</span>
								</template>
							</div>
							<div class="advertise-data flex p-3">
                                <div class="flex-1">
                                    <div class="flex justify-between items-start w-full">
                                        <h5 class="text-base font-bold mb-2">
                                            {{ item.title }}
                                        </h5>
                                        <div class="item-settings-button" @click="editAdvertisementHandler(item,index)">
                                            <span>editar</span>
                                        </div>
                                    </div>
                                    <p v-if="item.advertisementType == 'banner'">
                                        <strong>Url: </strong>
                                        <a
                                            :href="item.ref"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            v-if="item.ref"
                                            >{{ item.ref }}</a
                                        >
                                    </p>
                                    <p v-if="item.advertisementType == 'script'">
                                        <strong>Script:</strong>
                                        {{ item.content }}
                                    </p>
                                </div>
								
                                <div
									class="m-0 flex justify-between align-items-center"
								>

                                <label 
                                    :for="`checkbox-${index}`" 
                                    class="relative inline-flex items-center cursor-pointer" 
                                >
                                    <input
                                        v-model="item.status"
                                        type="checkbox"
                                        :name="`checkbox-${index}`"
                                        :id="`checkbox-${index}`"
                                        class="sr-only peer"
                                        @click.stop="toggleAdvertise(item)"
                                    />

                                    <div
                                        class="w-11 h-6 bg-gray-200 outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--main-color)]"
                                    ></div>

                                    <span class="ml-3 text-xs font-medium text-gray-900">
                                        {{ item.status ? 'Ativo' : 'Desativado' }}
                                    </span>
                                </label>

									<div class="item-settings-button" @click="onDelete(index)">
                                        <svg class="w-6 h-6 stroke-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path></svg>
									</div>
								</div>
							</div>
                        </div>
					</div>
				</div>
            </div>
        </div>
    </dashboard-layout>
</template>

<style scoped>
	.add-image-span {
		background-color: #f2f2f2;
		color: #858585;
		padding: 14px 39px;
		font-weight: 700;
	}
	.advertise-thumbnail {
		display: flex;
		width: 33%;
		min-height: 90px;
		padding: 0 10px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #858585;
	}
	.advertise-data {
		font-size: 0.8rem;
		display: flex;
		flex-direction: column;
		width: 65%;
		min-height: 90px;
	}
</style>
