<script setup lang="ts">
import { computed } from 'vue'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import SettingColorPicker from '@/components/atoms/SettingColorPicker.vue'
import SettingImageSelector from '@/components/atoms/SettingImageSelector.vue'
import SettingOpacity from '@/components/atoms/SettingOpacity.vue'

import { useEditorPageStore } from '@/stores/editor/page'
import { useLayoutStore } from '@/stores/layout'

const editorPageStore = useEditorPageStore()
const layoutStore = useLayoutStore()

const sectionForEdit = computed(() => editorPageStore.sectionForEdit)

const isCustomBackgroundColor = computed(() => {
    return sectionForEdit.value.extra?.background_color != '' ? true : false
})

const backgroundColor = computed(() => {
    if (isCustomBackgroundColor.value) {
        return sectionForEdit.value.extra?.background_color
    }

    return layoutStore.getSectionBackgroundColorDefault(sectionForEdit.value.sectionIndex)
})

const backgroundImage = computed(() => {
    return sectionForEdit.value.backgroundImage ?? null
})

const backgroundImageOpacity = computed(() => {
    return sectionForEdit.value.extra?.background_image_opacity ?? "80"
})

const backgroundFocalPoint = computed(() => {
    return (
        sectionForEdit.value.extra?.background_image_focal_point ?? {
            x: 50,
            y: 50
        }
    )
})

const onChangeBackgroundColor = (color: string) => {
    if (layoutStore.getSectionBackgroundColorDefault(sectionForEdit.value.sectionIndex) == color) {
        color = ''
    }

    editorPageStore.updateSectionExtra({
        ...sectionForEdit.value.extra,
        background_color: color
    })
}

const onChangeImageSelector = (event: any) => {
    if (event.target == 'image') {
        onChangeBackgroundImage(event.value)

        return
    }

    if (event.target == 'focal-point') {
        onChangeBackgroundImageFocalPoint(event.value)

        return
    }
}

const onChangeBackgroundImage = (image: any) => {
    if (!image) {
        editorPageStore.setBackgrounImageSectionForEdit(null)

        return
    }

    editorPageStore.setBackgrounImageSectionForEdit({
        id: image.id,
        uri: image.content,
        width: image.width,
        height: image.height
    })
}

const onChangeBackgroundImageOpacity = (opacity: number) => {
    editorPageStore.updateSectionExtra({
        ...sectionForEdit.value.extra,
        background_image_opacity: opacity
    })
}

const onChangeBackgroundImageFocalPoint = (focalPoint: any) => {
    editorPageStore.updateSectionExtra({
        ...sectionForEdit.value.extra,
        background_image_focal_point: focalPoint
    })
}
</script>

<template>
    <Disclosure :key="sectionForEdit.sectionIndex" :default-open="true" v-slot="{ open }">
        <DisclosureButton class="w-full flex justify-between items-center">
            <span class="text-sm font-bold text-[#060606]">Configurações da Seção</span>

            <button v-if="open">
                <span class="sr-only">Hidden Accordion</span>

                <svg
                    class="w-6 h-6 stroke-[var(--main-color)]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                >
                    <path d="M18 12L6 12" stroke-width="2" stroke-linecap="round" />
                </svg>
            </button>

            <button v-else>
                <span class="sr-only">Open Accordion</span>

                <svg
                    class="w-6 h-6 stroke-[var(--main-color)]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                >
                    <path d="M18 12L6 12" stroke-width="2" stroke-linecap="round" />
                    <path d="M12 18L12 6" stroke-width="2" stroke-linecap="round" />
                </svg>
            </button>
        </DisclosureButton>

        <DisclosurePanel class="w-full space-y-4">
            <setting-color-picker
                label="Cor de Fundo"
                :value="backgroundColor"
                :default-value="
                    layoutStore.getSectionBackgroundColorDefault(sectionForEdit.sectionIndex) ?? ''
                "
                @change="onChangeBackgroundColor"
            />

            <setting-image-selector
                label="Imagem de Fundo"
                :value="backgroundImage?.uri"
                with-focal-point
                with-remove
                :focal-point="backgroundFocalPoint"
                @change="onChangeImageSelector"
            />

            <setting-opacity
                v-if="backgroundImage"
                label="Opacidade da imagem"
                :value="backgroundImageOpacity"
                @change="onChangeBackgroundImageOpacity"
            />
        </DisclosurePanel>
    </Disclosure>
</template>

<style scoped></style>
