<script lang="ts" setup>
import { computed } from 'vue'

import clsx from 'clsx'

const props = defineProps<{
    id?: string
    label?: string
    labelSize?: string
    labelColor?: string
    extraClasses?: string
    labelHighlight?: string
    labelHighlightColor?: string
    type?: string
}>()

const labelSize = computed(() => props.labelSize || 'text-[10px]')
const labelColor = computed(() => props.labelColor || 'text-gray-400')
const extraClasses = computed(() => props.extraClasses || '')
const labelHighlightColor = computed(() => props.labelHighlightColor || 'text-red-300')
</script>

<template>
    <div class="base-input-field">
        <label :class="clsx('input-field-label', labelSize, labelColor)" v-if="label" :for="id">
            {{ label }}

            <span v-if="labelHighlight" :class="clsx('font-medium', labelHighlightColor)">
                {{ labelHighlight }}
            </span>
        </label>

        <div v-if="type == 'select'" class="select-wrapper">
            <slot></slot>
        </div>

        <slot v-else></slot>
    </div>
</template>

<style lang="scss" scoped>
.base-input-field {
    @apply w-full flex flex-col space-y-1;

    .input-field-label {
        @apply font-semibold;
    }

    :deep(input[type='text']),
    :deep(input[type='email']),
    :deep(input[type='password']),
    :deep(input[type='number']),
    :deep(input[type='tel']),
    :deep(input[type='url']),
    :deep(select) {
        @apply h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent;
    }

    :deep(input[type='checkbox']) {
        @apply cursor-pointer appearance-none relative h-4 w-4 bg-[#F9F9F9] checked:bg-[var(--main-color)] rounded-sm  checked:after:h-2.5 checked:after:w-1.5 checked:after:border-b-2 checked:after:border-r-2 checked:after:border-white checked:after:absolute checked:after:top-1.5 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:rotate-45;
    }

    :deep(input[type='radio']) {
        @apply cursor-pointer appearance-none relative h-5 w-5 border-[1px] border-gray-400 rounded-full after:w-3 after:h-3 after:top-1/2 after:left-1/2 after:absolute after:-translate-y-1/2 after:-translate-x-1/2 after:bg-transparent after:rounded-full checked:after:bg-[var(--main-color)] focus:outline-none;
    }

    :deep(textarea) {
        @apply border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent;
    }

    :deep(select) {
        @apply appearance-none;
    }

    .select-wrapper {
        @apply relative;

        &:after {
            @apply absolute pointer-events-none top-1/2 right-2 w-4 h-4;
            content: url('/public/chevron-down.svg');
        }
    }

    .select-wrapper select {
        @apply w-full
    }
}
</style>
