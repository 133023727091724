<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingForm from '@/components/atoms/icons/IconSettingForm.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingWidth from '@/components/atoms/SettingWidth.vue'
import SettingText from '@/components/atoms/SettingText.vue'

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'

const editorPageStore = useEditorPageStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()
}

const settings = ref([
    {
        id: 1,
        name: 'form',
        icon: markRaw(IconSettingForm)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])

const onChangeSubmit = (event: any) => {
    editorPageStore.elementForEdit.frameContent = {
        ...editorPageStore.elementForEdit.frameContent,
        submit: event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeMessage = (event: any) => {
    editorPageStore.elementForEdit.frameContent = {
        ...editorPageStore.elementForEdit.frameContent,
        message: event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeRedirect = (event: any) => {
    editorPageStore.elementForEdit.frameContent = {
        ...editorPageStore.elementForEdit.frameContent,
        redirect: event.value
    }

    editorPageStore.updateElementForEdit()
}
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #form>
            <settings-layout
                :name="`Configurações do Formulário`"
                :default-open="true"
            >
                <setting-text
                    label="Texto do botão"
                    placeholder="'Ex: Mensagem enviada com sucesso'"
                    :value="elementForEdit.frameContent?.submit ?? null"
                    @change="onChangeSubmit"
                />

                <setting-text
                    label="Mensagem após o envio"
                    placeholder="'Ex: Mensagem enviada com sucesso'"
                    :value="elementForEdit.frameContent?.message ?? null"
                    @change="onChangeMessage"
                />

                <div>
                    <setting-text
                        label="Url de redirecionamento"
                        placeholder="'Ex: https://www.google.com.br'"
                        :value="elementForEdit.frameContent?.redirect ?? null"
                        @change="onChangeRedirect"
                    />

                    <span class="text-[10px] font-semibold text-[#060606] opacity-50">
                        (caso em branco, vai continuar na mesma página)
                    </span>
                </div>
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                :name="`Configurações do Conteúdo`"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit?.grid"
                    :value-mobile="elementForEdit?.gridMobile"
                    :values-permission-desktop="[4, 6, 12]"
                    :values-permission-mobile="[4, 6, 12]"
                    @change="onChangeWidth"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
