import { reactive, toRefs } from 'vue'

import { defineStore } from 'pinia'
import { useSiteStore } from '../site'
import api from '@/services/api'

export const useEditorCommentStore = defineStore('editor-comment', () => {
    const siteStore = useSiteStore()

    // states
    const state = reactive({
        comments: []
    })

    const fetchComments = async () => {
        try {
            const url = `site/${siteStore.id}/wizard/comments`;

            const response = await api.get(url)

            const { status, message, data } = await response.json()

            if (status != '1') {
                throw new Error(message)
            }

            state.comments = data
        } catch (error) {
            console.error(error)
        }
    }

    const getUrlRequestComment = (id: number) => {
        return `https://${siteStore.url}/depoimento/enviar?frameId=${id}`;
    }

    return {
        ...toRefs(state),
        fetchComments,
        getUrlRequestComment
    }
})
