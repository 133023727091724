import { type App, type DirectiveBinding } from 'vue'

import { type TooltipDirectiveOptions } from './interfaces'

import './styles.scss'

/**
 * Create a tooltip element and append it to the element
 * @param el
 * @param text
 */
const createTooltip = (el: HTMLElement, options: TooltipDirectiveOptions) => {
    // Create the tooltip element
    const tooltip = document.createElement('div')

    // Add the default class
    tooltip.classList.add('tooltip')
    tooltip.classList.add('hidden')
    tooltip.classList.add(options.position)
    tooltip.classList.add(`background-${options.background}`)
    tooltip.classList.add(`color-${options.color}`)

    // Set the text
    tooltip.textContent = options.text

    // Check if the element already has 'position: absolute'
    const hasAbsolutePosition = window.getComputedStyle(el).position === 'absolute'

    // Temporarily set 'position: relative' if it's not absolute
    if (!hasAbsolutePosition) {
        el.style.position = 'relative'
    }

    // Append the tooltip to the element
    el.appendChild(tooltip)

    // ADD THE EVENT LISTENER OF MOUSEENTER
    el.addEventListener('mouseenter', () => {
        // Set ACTIVE CLASS
        tooltip.classList.add('active')

        // REMOVE HIDDEN CLASS
        tooltip.classList.remove('hidden')
    })

    // ADD THE EVENT LISTENER OF MOUSELEAVE
    el.addEventListener('mouseleave', () => {
        // SET HIDDEN CLASS
        tooltip.classList.add('hidden')

        // REMOVE ACTIVE CLASS
        tooltip.classList.remove('active')
    })
}

const TooltipDirective = {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        const options = binding.value || {}
        const tooltipText = options.text
        const tooltipBackground = options.background || 'black'
        const tooltipColor = options.color || 'white'
        const tooltipPosition = options.position || 'top'

        const tooltipOptions = {
            text: tooltipText,
            background: tooltipBackground,
            color: tooltipColor,
            position: tooltipPosition
        }

        createTooltip(el, tooltipOptions)
    }
}

export default TooltipDirective

export const TooltipPlugin = (app: App) => {
    app.directive('tooltip', TooltipDirective)
}
