<script setup lang="ts">
import { onBeforeMount, onMounted, reactive, ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import api from '@/services/api';

import clsx from 'clsx'

import EditorLayout from '@/components/layouts/EditorLayout.vue'
import BaseInputField from '@/components/atoms/BaseInputField.vue'
import ConfirmationModal from '@/components/organisms/ConfirmationModal.vue'

import { useEditorPageStore } from '@/stores/editor/page'
import { useAuthStore } from '@/stores/auth'
import { useSiteStore } from '@/stores/site'
import { useDashboardStore } from '@/stores/dashboard'

import notification from '@/services/notification';

const editorPageStore = useEditorPageStore()
const authStore = useAuthStore()
const siteStore = useSiteStore()
const dashboardStore = useDashboardStore()

const router = useRouter()

const page = ref<any>({ value: null})

const loadingGetPage = computed(() => editorPageStore.loadingGetPage)
const loadingPorcentGetPage = computed(() => editorPageStore.loadingPorcentGetPage)
const loading = ref<boolean>(false)

const getPageDate = async (pageId: any) => {
    loading.value = true;

    try {
        const response = await api.get(`site/${siteStore.id}/post/${pageId}`)

        const { status, content } = await response.json()

        if (status != "1") {
            throw new Error('erro ao buscar página')
        }

        page.value = reactive(content)
    } catch (error) {
        console.error('error', error)
    }

    loading.value = false;
}

const checkHttps = () => {
    if (!page.value.route.includes('https://') && !page.value.route.includes('http://')) {
        page.value.route = `https://${page.value.route}`
    }
}

const savePage = async () => {
    loading.value = true;
    
    try {
        if (!page.value.title || !page.value.route) {
            notification.simple('Preencha todos os campos', 'error')

            return
        }

        checkHttps()

        const response = await api.post(
            `site/${siteStore.id}/post/${page.value.postId}/external/update`,
            JSON.stringify(page.value)
        )

        const { status } = await response.json()

        if (status != "1") {
            throw new Error('Erro ao salvar página')
        }

        notification.simple('Página salva com sucesso', 'success')

        dashboardStore.handleClickPage(dashboardStore.defaultPages[1])

        // sei la o pq mas router.push('/dashboard') não funciona, até troca a rota, mas o dom n atualiza
        window.location.href = '/dashboard'
    } catch (error) {
        console.error('error:', error)

        notification.simple('Erro ao salvar página', 'error')
    }

    loading.value = false;
}

onBeforeMount(async () => {
    const pageId = router.currentRoute.value.params.id

    await getPageDate(pageId)
})

onMounted(async () => {
    authStore.checkAccessType()
})
</script>

<template>
    <editor-layout id="page-external-editor">
        <confirmation-modal />

        <div class="relative h-auto">
            <div
                :class="clsx(
                    loadingGetPage ? 'h-2' : 'h-0',
                    ' top-0 bg-[var(--main-color)] transition-all duration-300'
                )"
                :style="{ width: `${loadingPorcentGetPage}%` }"
            ></div>

            <!-- TODO: Adicionar bunner no erp para ativar alertas -->
            <!-- <div
                :class="
                    clsx(
                        'transition-all duration-1000 delay-300 w-full flex justify-center items-center pb-2 left-0 right-0 bg-[var(--main-color)]',
                        loadingPorcentGetPage == 100 ? 'h-12 opacity-100' : 'h-0 opacity-0'
                    )
                "
            >
                <div
                    :class="
                        clsx(
                            'flex items-center text-white space-x-4 duration-1000 delay-1000',
                            loadingPorcentGetPage == 100 ? 'opacity-100' : 'opacity-0'
                        )
                    "
                >
                    <p class="flex text-lg font-bold space-x-3">
                        <svg
                            class="w-6 h-6 fill-[#E0D0FF] animate-pulse"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path
                                d="M14.8234 2.4L16.6537 7.34621L21.5999 9.17647L16.6537 11.0067L14.8234 15.9529L12.9932 11.0067L8.04696 9.17647L12.9932 7.34621L14.8234 2.4Z"
                            />
                            <path
                                d="M6.35284 13.6941L7.95167 16.0482L10.3058 17.6471L7.95167 19.2459L6.35284 21.6L4.75402 19.2459L2.3999 17.6471L4.75402 16.0482L6.35284 13.6941Z"
                            />
                        </svg>

                        <span>Novidade na área!</span>
                    </p>

                    <span class="font-light text-base">Experimente nossa ferramenta com IA.</span>
                </div>
            </div> -->
        </div>

        <template v-if="!loadingGetPage">
            <div class="container mx-auto h-[30vh] flex flex-col justify-center items-center space-y-10">
                <div class="w-full">
                    <base-input-field
                        :label="'Título da página'"
                    >
                        <input
                            type="text"
                            v-model="page.title"
                        />
                    </base-input-field>
                </div>

                <div class="w-full flex space-x-3">
                    <div class="w-2/3">
                        <base-input-field
                            :label="'URL da página'"
                        >
                            <input
                                type="text"
                                placeholder="https://"
                                v-model="page.route"
                            />
                        </base-input-field>
                    </div>

                    <div class="w-1/3">
                        <base-input-field
                            :label="'Abrir em'"
                            type="select"
                        >
                            <select
                                v-model="page.target_blank"
                            >
                                <option value="0">Mesma aba</option>
                                <option value="1">Nova aba</option>
                            </select>
                        </base-input-field>
                    </div>
                </div>

                <button
                    @click="() => savePage()"
                    class="mt-8 px-6 py-3 text-white bg-[var(--main-color)] border-2 border-[var(--main-color)] rounded-full"
                >
                    Salvar
                </button>
            </div>
        </template>
    </editor-layout>
</template>

<style></style>
