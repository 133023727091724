<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'

import BaseModal from './BaseModal.vue';
import BaseInputField from '../atoms/BaseInputField.vue';

import api from '@/services/api';
import { useSiteStore } from '@/stores/site';
import notification, { type ToastId } from '@/services/notification';
import modal from '@/services/modal';

import clsx from 'clsx'

const siteStore = useSiteStore();

const isOpen = ref<boolean>(false);
const isOpenModalIntegration = ref<boolean>(false);
const isOpenConfirmation = ref<boolean>(false);

const loading = ref<boolean>(false)
const loadingIntegrations = ref<boolean>(false)

const error = ref<boolean>(true);

const titleModal = ref<string>('Adicionar Rede Social');
const actionModal = ref<string>('Adicionar');
const descriptionModal = ref<string>('');
const placeholderModal = ref<string>('');
const labelModal = ref<string>('');

// modal para inserir integracao
const insertApi = ref<string>('')
const userSocialMedia = ref<string>('')

// modal para confirmacao da integracao
const avatarConfirmation = ref<string>('')
const nameConfirmation = ref<string>('')

const instagramConnected = ref<any>([]);
const youtubeConnected = ref<any>([]);

onBeforeMount(async () => {
    await fetchIntegrations();
});

/** 
 * Busca se tem integracao conectada do Youtube e do Instagram
 */
const fetchIntegrations = async () => {
        try {
            loadingIntegrations.value = true;
            const response = await api.get(`site/${siteStore.id}/verify`);

            const { apis_integration } = await response.json();

            if (apis_integration.Instagram.profile_data !== undefined) {
                instagramConnected.value = apis_integration.Instagram.profile_data
            }

            if (apis_integration.Youtube.profile_data !== undefined) {
                youtubeConnected.value = apis_integration.Youtube.profile_data
            }

            loadingIntegrations.value = false;
        }
        catch (error) {
            console.error(error);
        }
	}

    /**
     * Abre modal para inserir usuário do Instagram ou Youtube
     */
    const onAddIntegration = (api: string) => {
        if (api == 'insta') {
            titleModal.value = 'Entre com o usuário do Instagram';
            descriptionModal.value = 'Coloque o perfil que tem conteúdos relevantes para criar seu site.';  
            placeholderModal.value = 'Preencha com o nome do usuário' 
            labelModal.value = 'Nome do usuário' 
        } else if (api == 'youtube') {
            titleModal.value = 'Entre com a url do canal do Youtube';
            descriptionModal.value = 'Coloque o canal que tem conteúdos relevantes para criar seu site.';
            placeholderModal.value = 'Preencha com a url do canal' 
            labelModal.value = 'Url do Canal'
        }
        
        insertApi.value = api;
        isOpenModalIntegration.value = true;
    }

    const handleUser = async () => {
        const toastId: ToastId = notification.loading('Realizando a busca...');

        try {
            if (userSocialMedia.value.length < 1) {
                notification.update(toastId, 'Preencha o campo', 'error');

                return
            }

            if (insertApi.value == "insta") {
                handleUserInstagram();
            } else if (insertApi.value == "youtube") {
                handleUserYoutube();
            }

            await handleConfirmUser(insertApi.value);

            notification.update(toastId, 'Perfil encontrado', 'success');
        } catch (error) {
            console.error(error);

            notification.update(toastId, 'Erro ao realizar a busca', 'error');
        }
	}

    /** 
     * Faz o tratamento do usuario do instagram
     */
    const handleUserInstagram = () => {
		userSocialMedia.value = userSocialMedia.value.replace("@", "");
		userSocialMedia.value = userSocialMedia.value.replace(" ", "");
		userSocialMedia.value = userSocialMedia.value.replace("/?hl=pt-br", "");
		userSocialMedia.value = userSocialMedia.value.replace(
			"https://www.instagram.com/",
			""
		);
		userSocialMedia.value = userSocialMedia.value.replace("/", "");
		userSocialMedia.value = userSocialMedia.value.trim();
	}

    /** 
     * Faz o tratamento do usuario do youtube
     */
    function handleUserYoutube() {
		userSocialMedia.value = userSocialMedia.value.trim();
		userSocialMedia.value = userSocialMedia.value.replace("/featured/", "");
		userSocialMedia.value = userSocialMedia.value.replace("/featured", "");
		let youtubeName = userSocialMedia.value.split("/");
		userSocialMedia.value = youtubeName[youtubeName.length - 1];
		if (userSocialMedia.value == "") {
			userSocialMedia.value = youtubeName[youtubeName.length - 2];
		}
		userSocialMedia.value = userSocialMedia.value.replace(/ /g, "");
		userSocialMedia.value = userSocialMedia.value.replace(
			"?view_as=subscriber",
			""
		);
	}

    /**
     * Depois que insere o username, busca o perfil e mostra uma modal para confirmacao
     * Caso tenha erro, informa o erro
     */
    const handleConfirmUser = async (integration: string) => {
        try {
            loading.value = true;
            const response = await api.get(`site/${siteStore.id}/profile/${integration}?username=${userSocialMedia.value}`)
            isOpenModalIntegration.value = false;

            const { status, content } = await response.json();

            // erro nao encontrou
            if (status === undefined || content === undefined) {
                titleModal.value = 'Ops! Não encontramos esse perfil'
                descriptionModal.value = 'Verifique se o nome do usuário está correto e tente novamente.'
                error.value = true
                actionModal.value = ''
                loading.value = false
                isOpenConfirmation.value = true
                avatarConfirmation.value = ''
                return
            }

            // erro perfil privado
            if (content.private === true) {
                titleModal.value = 'Ops! Perfil privado'
                descriptionModal.value = 'Usuário com perfil privado. Em seu Instagram acesse Configurações > Privacidade > Privacidade da conta e desabilite a privacidade.'
                error.value = true
                actionModal.value = ''
                loading.value = false
                isOpenConfirmation.value = true
                avatarConfirmation.value = ''
                return
            }

            error.value = false
            avatarConfirmation.value = content.picture;
            nameConfirmation.value = content.name;

            titleModal.value = 'É você?';
            actionModal.value = 'Sim, sou eu';
            descriptionModal.value = ''

            loading.value = false;
            isOpenConfirmation.value = true;
        } catch (error) {
            console.error(error);
        }
	}

    /** 
     * Caso feche a modal de confirmacao, reseta os valores
     */
    function handleResetUser() {
        isOpenConfirmation.value = false;
        // TODO: fazer abrir a modal de integracao novamente
        onAddIntegration(insertApi.value)
    }

    /**
     * Fecha modal de integracao
     */
    const onCanceledIntegration = () => {
        isOpenModalIntegration.value = false
    }

    /**
     * Confirma a integracao e faz a requisicao para o backend
     * Depois atualiza a lista de integracoes
     */
    const confirmIntegration = async () => {
        const toastId: ToastId = notification.loading('Realizando a integração...');
        
        let apiToIntegrate = insertApi.value

        loading.value = true;

        try {
            const response = await api.post(
                `site/${siteStore.id}/profile/create/${apiToIntegrate}?username=${userSocialMedia.value}`,
                null
            )  

            console.log('response', response);

            if (response === undefined) {
                throw new Error('Erro ao realizar a integracao');
            }

            fetchIntegrations();

            loading.value = false;
            isOpenConfirmation.value = false;

            notification.update(toastId, 'Integração realizada com sucesso', 'success');
        } catch (error) {
            console.error(error)

            notification.update(toastId, 'Erro ao realizar a integração', 'error');
        }
        
    }

    /**
     * Deleta uma integracao
     */
    const handleDeleteRealIntegration = async (integration: string) => {
        const toastId: ToastId = notification.loading('Excluindo a integração...');

        loading.value = true

        try {
            const response = await api.post(
                `site/${siteStore.id}/disconnect/${integration}`,
                null
            )

            if (response === undefined) {
                throw new Error('Erro ao deletar a integracao');
            }

            fetchIntegrations();
            
            loading.value = false;

            notification.update(toastId, 'Integração removida com sucesso', 'success');
        } catch (error) {
            console.error(error)

            notification.update(toastId, 'Erro ao deletar a integração', 'error');
        }
    }

    /**
     * Abre modal de confirmacao para deletar a integracao
     */
    const onDeleteRealIntegration = async (integration: string) => {
        const title = 'Confirmar Remoção'
        const message = 'Deseja realmente remover esta integração?'
        const type = 'danger'
        const confirm = 'Sim, remover'
        const cancel = 'Não, voltar'

        const result = await modal.confirmation(title, message, {
            type: type,
            textConfirm: confirm,
            textCancel: cancel
        })

        if (!result) {
            return
        }

        handleDeleteRealIntegration(integration)
	}

</script>

<template>
    <div id="list-integrations">
        <base-modal
            :open-watch="isOpenModalIntegration"
            @modal-closed="() => onCanceledIntegration()"
            :title="titleModal"
            size="custom"
            height="h-[21rem]"
            width="w-[32rem]"
            rounded="2xl"
        >
            <template #body>
                <div class="h-full flex flex-col justify-between">
                    <div class="flex-1">
                        <p class="mb-3">{{ descriptionModal }}</p>
                        <div class="flex items-center">
                            <span class="p-2" v-if="insertApi == 'insta'">@</span>
                            <base-input-field
                                :label="labelModal"
                                id="username"
                            >
                                <input
                                    type="text"
                                    id="username"
                                    name="domain"
                                    v-model="userSocialMedia"
                                    @keyup.enter="handleUser"
                                    :placeholder="placeholderModal"
                                />
                            </base-input-field>
                        </div>
                    </div>

                    <div class="flex justify-end mt-4 space-x-2">
                        <button
                            @click="() => onCanceledIntegration()"
                            class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                        >
                            Cancelar
                        </button>

                        <button
                            :disabled="loading || userSocialMedia.length < 1"
                            @click="() => handleUser()"
                            :class="
                                clsx(
                                    (loading || userSocialMedia.length < 1 ) && 'disabled:opacity-50 disabled:cursor-wait disabled:animate-pulse',
                                    'bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none'
                                )
                            "
                        >
                            Adicionar
                        </button>
                    </div>
                </div>
            </template>
        </base-modal>

        <base-modal
            :open-watch="isOpenConfirmation"
            @modal-closed="() => handleResetUser()"
            :title="titleModal"
            size="custom"
            height="h-[21rem]"
            width="w-[32rem]"
            rounded="2xl"
        >
            <template #body>
                <div class="h-full flex flex-col justify-center">
                    <div class="flex flex-1 flex-col justify-left">
                        <p v-if="descriptionModal">{{ descriptionModal }}</p>
                        <div class="flex flex-1 flex-row items-center" v-if="avatarConfirmation">
                            <img 
                                :src="avatarConfirmation"
                                alt="Avatar"
                                class="w-20 h-20 rounded-full me-3" />

                            <div class="text-left mb-3 basis-full" v-if="nameConfirmation">
                                <p
                                    class="exhibition--name pb-0 mb-0 font-bold"
                                    :class="{ minimize: nameConfirmation.length > 11 }"
                                >
                                    {{ nameConfirmation }}
                                </p>
                            </div>
                        </div>
                        <small v-if="!error">Ao clicar em sim, você aceita os Termos de Uso, e confirma que é de sua propriedade o perfil</small>
                    </div>

                    <div class="flex justify-end mt-4 space-x-2">
                        <button
                            @click="() => handleResetUser()"
                            class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                        >
                            Cancelar
                        </button>

                        <button
                            v-if="!error"
                            :disabled="loading"
                            @click="() => confirmIntegration()"
                            :class="
                                clsx(
                                    loading && 'disabled:opacity-50 disabled:cursor-wait disabled:animate-pulse',
                                    'bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none'
                                )
                            "
                        >
                            {{ actionModal }}
                        </button>
                    </div>
                </div>
            </template>
        </base-modal>

        <div v-if="loadingIntegrations">
        </div>

        <div
            v-else
            id="list-integrations" 
            class="w-full py-3 flex flex-row whitespace-nowrap space-x-3 overflow-x-scroll">
            <div>
                <div
                    class="flex flex-col justify-center items-center h-32 w-32 bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500 rounded-lg"
                >
                    <!-- se nao tiver conectado o instagram mostra o icone, senao, mostra a picture-->
                    <svg
                        v-if="instagramConnected === null"
                        class="w-12 h-12 fill-white"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                        />
                    </svg>
                    <img 
                        v-else
                        :src="instagramConnected.data.picture"
                        alt="Instagram"
                        class="w-12 h-12 rounded-full mb-1" 
                        />
                    <span class="text-white text-sm font-semibold">Instagram</span>
                </div>

                <button
                    v-if="instagramConnected === null"
                    class="mt-3 w-full text-center font-semibold text-sm text-[var(--main-color)]"
                    @click="() => onAddIntegration('insta')"
                >
                    Conectar
                </button>
                <button 
                    v-else
                    class="mt-3 w-full text-center font-semibold text-sm text-[var(--text-color)]"
                    @click="() => onDeleteRealIntegration('insta')"
                >
                    Remover
                </button>
            </div>

            <div>
                <div
                    class="flex flex-col justify-center items-center h-32 w-32 bg-red-600 rounded-lg"
                >
                    <svg
                        v-if="youtubeConnected === null"
                        class="w-12 h-12 fill-white"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"
                        />
                    </svg>
                    <img 
                        v-else
                        :src="youtubeConnected.data.picture"
                        alt="Youtube picture"
                        class="w-12 h-12 rounded-full mb-1" 
                    />

                    <span class="text-white text-sm font-semibold">Youtube</span>
                </div>

                <button
                    v-if="youtubeConnected === null"
                    class="mt-3 w-full text-center font-semibold text-sm text-[var(--main-color)]"
                    @click="() => onAddIntegration('youtube')"
                >
                    Conectar
                </button>
                <button 
                    v-else
                    class="mt-3 w-full text-center font-semibold text-sm text-[var(--text-color)]"
                    @click="() => onDeleteRealIntegration('youtube')"
                >
                    Remover
                </button>
            </div>
        </div>
    </div>
</template>
