<script lang="ts" setup>
import { ref, watch } from 'vue';
import clsx from 'clsx'

import BaseModal from '@/components/molecules/BaseModal.vue';
import BaseInputField from '@/components/atoms/BaseInputField.vue'

import { useTextLinkStore } from '@/stores/modal/text-link';

const textLinkStore = useTextLinkStore();
    
const propt = ref<string>('')

const onRemovedLink = () => {
    textLinkStore.editor.chain().focus().unsetLink().run()

    onCanceledLink()
}

const onCanceledLink = () => {
    textLinkStore.isOpenModal = false
}
const handleSetLink = () => {
    if (textLinkStore.linkUrl == '' || textLinkStore.linkUrl == null) {
        return
    }

    textLinkStore.editor.chain().focus().extendMarkRange('link').setLink({ href: textLinkStore.linkUrl }).run()

    onCanceledLink()
}

watch(() => textLinkStore.isOpenModal, (value: boolean) => {
    if (value) {
        propt.value = ''
    }
})
</script>

<template>
    <base-modal
        :open-watch="textLinkStore.isOpenModal"
        @modal-closed="onCanceledLink"
        :title="textLinkStore.titleModalLink"
        size="custom"
        height="h-[14rem]"
        width="w-[30rem]"
        rounded="2xl"
    >
        <template #body>
            <div class="h-full flex flex-col justify-between">
                <div class="flex-1">
                    <base-input-field label="Link" id="link">
                        <input type="text" id="link" name="link" v-model="textLinkStore.linkUrl" />
                    </base-input-field>
                </div>

                <div class="flex justify-between mt-4 space-x-2">
                    <div>
                        <button
                            v-if="textLinkStore.editor.isActive('link')"
                            @click="onRemovedLink"
                            class="px-4 py-2 text-sm text-red-500 bg-transparent rounded-full hover:text-gray-500 outline-none"
                        >
                            Remover
                        </button>
                    </div>

                    <div>
                        <button
                            @click="onCanceledLink"
                            class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                        >
                            Cancelar
                        </button>

                        <button
                            @click="handleSetLink()"
                            :class="
                                clsx(
                                    'bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none'
                                )
                            "
                        >
                            {{ textLinkStore.confirmModalLink }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<style>
</style>