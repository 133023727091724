<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        fill="none"
        stroke="currentColor"
    >
        <path
            d="M13.5 4.5L4.5 13.5M13.5 13.5L4.5 4.5"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>