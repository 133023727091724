<script setup lang="ts">
import { ref, onBeforeMount, computed } from 'vue';

import api from '@/services/api';

import { useSiteStore } from '@/stores/site';

const siteStore = useSiteStore();

const opportunities = ref<any[]>([]);
const loadingOppotunities = ref(false);

const fetchOpportunities = async () => {
    loadingOppotunities.value = true

    try {
        const response = await api.get(`site/${siteStore.id}/submit`)

        const { status, contents } = await response.json()

        if (status != '1') {
            throw new Error('Error fetching opportunities.')
        }

        opportunities.value = contents
    } catch (error) {
        console.error(error)
    }

    loadingOppotunities.value = false
}

onBeforeMount(async () => {
    await fetchOpportunities()
})

const url = computed(() => {
    return import.meta.env.VITE_CDN_URL
})
</script>

<template>
    <div class="px-6 space-y-3 mb-10">
        <h2 class="text-2xl font-bold">Mensagens</h2>

        <div v-if="loadingOppotunities" class="flex justify-center items-center">
            <svg class="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4.472v3.101a5.001 5.001 0 00-5 4.728H6zm2 5.291a8.001 8.001 0 01-5.291-2H4a8 8 0 008 8v-4zm11.729-2A8.001 8.001 0 0120 12h-4v4c0 2.485-1.791 4.549-4.146 4.949A8.001 8.001 0 0112 20.472v-3.101a5.001 5.001 0 005-4.728h3.729z">
                </path>
            </svg>

            <span class="ml-2 text-gray-500">Carregando...</span>

            <div class="absolute inset-0 bg-white opacity-50"></div>

            <div class="absolute inset-0 bg-white opacity-50"></div>

            <div class="absolute inset-0 bg-white opacity-50"></div>

            <div class="absolute inset-0 bg-white opacity-50"></div>

            <div class="absolute inset-0 bg-white opacity-50"></div>
        </div>

        <div v-else>
            <div v-if="opportunities.length == 0" class="text-gray-500">Nenhuma mensagem.</div>

            <div
                v-else
                class="flex flex-col space-y-3"
            >
                <div
                    v-for="opportunity in opportunities"
                    :key="opportunity.id"
                    class="flex flex-wrap items-center space-y-2 border-2 p-3"
                >
                    <div class="flex w-full">
                        <p class="w-1/2 text-sm font-medium text-gray-900">
                            <strong>Origem:</strong> {{ opportunity.source }}
                        </p>

                        <p class="w-1/2 text-sm font-medium text-gray-900">
                            <strong>Data:</strong> {{ opportunity.created_at.substr(0, 10) }}
                        </p>
                    </div>

                    <div
                        class="flex w-full" 
                        v-for="(item, index) in opportunity.contents"
                        :key="index"
                    >
                        <p class="w-full text-sm font-medium text-gray-900">
                            <strong>{{ item.title }}: </strong>

                            <span
                                v-if="item.item_type == 'email'" 
                            >
                                <a
                                    :href="'mailto:' + item.contents"
                                >
                                    {{ item.contents }}
                                </a>
                            </span>

                            <span
                                v-else-if="item.item_type == 'textarea'"
                            >
                                {{ item.contents }}
                            </span>

                            <span v-else-if="item.item_type != 'file'">
                                {{ item.contents }}
                            </span>

                            <span v-else-if="item.item_type == 'file'">
                                <a 
                                    v-for="(subitem, subindex) in item.contents" 
                                    :href="url + subitem.url"
                                    :key="subindex"
                                    target="_blank"
                                >
                                    <p>Baixar arquivo {{ subindex + 1 }}</p>
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
