import { ref } from 'vue'
import { defineStore } from 'pinia'

/**
 * @description User Store
 */
export const useUserStore = defineStore('user', () => {
    // states
    const defined = ref<any>(false)

    const email = ref<any>()
    const token = ref<any>()
    // fim states

    // actions
    const setUser = (user: any) => {
        email.value = user.email
        token.value = user.token

        defined.value = true
    }
    // fim actions

    return {
        // disponibiliza as states
        defined,
        email,
        token,
        // disponibiliza as actions
        setUser
    }
})
