<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'

import type { ToastId } from '@/services/notification'
import notification from '@/services/notification'

import DashboardLayout from '@/components/layouts/DashboardLayout.vue'

import DashbaordNavPages from '@/components/molecules/DashbaordNavPages.vue'
import PublishRocket from '@/components/molecules/PublishRocket.vue'
import CardPost from '@/components/molecules/CardPost.vue'

import DashboardReports from '@/components/organisms/DashboardReports.vue'
import MakePagesModal from '@/components/organisms/MakePagesModal.vue'

import { useDashboardStore } from '@/stores/dashboard'
import { useMakePagesStore } from '@/stores/modal/make-pages'
import MediaManagerModal from '@/components/organisms/MediaManagerModal.vue'

const dashboardStore = useDashboardStore()
const makePagesStore = useMakePagesStore()

const posts = computed(() => dashboardStore.posts)
const loadingPosts = computed(() => dashboardStore.loadingPosts)
const activePage = computed(() => dashboardStore.activePage)

import { type UseDraggableReturn, VueDraggable } from 'vue-draggable-plus'
const el = ref<UseDraggableReturn>()

onBeforeMount(async () => {
    dashboardStore.fetchPosts()
    
    dashboardStore.fetchPages()
    
    makePagesStore.fetchCombinations()
})

const handleAddPost = async () => {
    makePagesStore.setCombinationActive(makePagesStore.combinationsDefault[0], 0)

    const toastId: ToastId = notification.loading('Criando post...')

    makePagesStore.setLoadingCreatePage(true)

    const status = await dashboardStore.createPost(activePage.value.id)

    if (!status) {
        return notification.update(toastId, 'Não foi possível criar o post!', 'error')
    }

    makePagesStore.setLoadingCreatePage(false)

    return notification.update(toastId, 'Post criado com sucesso!', 'success')
}

const hasPageBlog = computed(() => dashboardStore.pages.some(page => page.listPages == 1))
</script>

<template>
    <dashboard-layout id="DashboardHome">
        <media-manager-modal />

        <make-pages-modal />

        <div class="max-w-full md:max-w-xl lg:max-w-5xl xl:max-w-7xl mx-auto">
            <dashbaord-nav-pages />

            <div class="mt-3 space-y-5">
                <div v-if="activePage.pageType == 'search'">Pesquisa</div>

                <div v-else-if="activePage.pageType == 'dashboard'">
                    <dashboard-reports />
                </div>

                <template v-if="!loadingPosts">
                    <template
                        v-if="posts.length > 0"
                    >
                        <div 
                            v-if="activePage.listPages == 1"
                            class="w-full flex justify-end"
                        >
                            <select
                                v-model="dashboardStore.sortType"
                                class="px-3 py-2 bg-transparent border border-gray-300 rounded-md appearance-none"
                                id="inputGroupSelect01"
                            >
                                <option value="CHRONODESC">
                                    Mais Novas
                                </option>
                                <option value="CHRONOASC">
                                    Mais Antigas
                                </option>
                                <option value="ALPHABASC">
                                    Ordem Alfabetica A-Z
                                </option>
                                <option value="ALPHABDESC">
                                    Ordem Alfabetica Z-A
                                </option>
                                <option value="CUSTOM">
                                    Personalizada(arraste)
                                </option>
                            </select>
                        </div>

                        <div
                            v-if="dashboardStore.sortType != 'CUSTOM'"
                            class="grid grid-cols-3 gap-5"
                        >
                            <template
                                v-for="(post ,index) in dashboardStore.posts"
                                :key="post.postId"
                            >
                                <publish-rocket 
                                    v-if="post.postId == 'rocket'"
                                />
                                
                                <card-post
                                    v-else
                                    :index="index"
                                    :post-id="post.postId"
                                    :title="post.title"
                                    :body="post.summary"
                                    :tumbnail="post.mainImage"
                                    :date="post.showDate && post.display_date"
                                    :is-scheduled="post.scheduled == 1"
                                    :is-draft="!!post.sketch"
                                    :is-highlight="post.home_slider"
                                    :route="post.route"
                                    :is-movable="false"
                                    :extra="post.mainImageExtra"
                                />
                            </template>
                        </div>

                        <VueDraggable
                            v-else
                            ref="el"
                            v-model="dashboardStore.posts"
                            :animation="150"
                            ghostClass="ghost"
                            class="grid grid-cols-3 gap-5"
                            @update="() => dashboardStore.updateOrder()"
                        >
                            <card-post
                                v-for="(item, index) in dashboardStore.posts"
                                :key="item.postId"
                                :index="index"
                                :post-id="item.postId"
                                :title="item.title"
                                :body="item.summary"
                                :tumbnail="item.mainImage"
                                :date="item.showDate && item.display_date"
                                :is-scheduled="item.scheduled == 1"
                                :is-draft="!!item.sketch"
                                :is-highlight="item.home_slider"
                                :route="item.route"
                                :is-movable="true"
                                :extra="item.mainImageExtra"
                            />
                        </VueDraggable>
                    </template>

                    <div v-else class="flex flex-col justify-center items-center w-full h-full">
                        <img src="@/assets/images/unicorn.png" alt="unicorn" />

                        <h4 class="mt-3 text-2xl font-bold">Sem posts novos</h4>

                        <p class="mt-2 text-center">
                            Novos conteúdos serão exibidos aqui
                            <br />
                            quando importados de suas redes
                        </p>
                    </div>

                    <button 
                        v-if="activePage.listPages == 1 || (posts.length == 0 && hasPageBlog)"
                        @click="() => handleAddPost()"
                        class="fixed bottom-6 left-1/2 -translate-x-1/2 px-6 py-3 bg-[var(--main-color)] text-white rounded-full"
                    >
                        <span
                            v-if="activePage.listPages == 1"
                        >
                            Adicionar post
                        </span>

                        <span
                            v-else
                        >
                            Ou crie um post
                        </span>
                    </button>
                </template>

                <template v-else>
                    <div class="grid grid-cols-3 gap-5">
                        <div v-for="index in 9" :key="index" class="rounded-lg overflow-hidden cursor-wait">
                            <div class="w-full h-44 bg-gray-200 animate-pulse"></div>
                            <div class="w-full h-32 bg-gray-100 animate-pulse"></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </dashboard-layout>
</template>

<style></style>
