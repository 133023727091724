<template>
    <svg width="88" height="56" viewBox="0 0 88 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1141_3674)">
        <rect x="5.52783" y="5.82947" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.52783" y="20.3177" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.52783" y="34.8058" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="25.147" y="5.82947" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="25.147" y="20.3177" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="25.147" y="34.8058" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="44.7661" y="5.82947" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="44.7661" y="20.3177" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="44.7661" y="34.8058" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="64.3862" y="5.82947" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="64.3862" y="20.3177" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="64.3862" y="34.8058" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="5.52783" y="49.2939" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="25.147" y="49.2939" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="44.7661" y="49.2939" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        <rect x="64.3862" y="49.2939" width="17.8084" height="12.0735" rx="1.20735" fill="#E0E0E0"/>
        </g>
        <rect x="0.6" y="0.6" width="86.8" height="54.8" rx="2.4" stroke="#DCDCDC" stroke-width="0.8"/>
        <defs>
        <clipPath id="clip0_1141_3674">
        <rect x="1" y="1" width="86" height="54" rx="2" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>