<script lang="ts" setup>
import { computed } from 'vue'

import MediaFilter from '@/components/atoms/MediaFilter.vue'

import { useMediaManagerStore } from '@/stores/modal/media-manager'

const mediaManagerStore = useMediaManagerStore()

const filtersMedias = computed(() => mediaManagerStore.filtersMedias)
const activeFilterMedias = computed(() => mediaManagerStore.activeFilterMedias)
</script>

<template>
    <div class="px-3 pb-3 space-y-3 h-auto w-full flex flex-col overflow-x-scroll bg-white z-10">
        <div class="h-8 w-full flex flex-row space-x-4">
            <media-filter
                v-for="(filter, index) in filtersMedias"
                :key="index"
                @click="mediaManagerStore.setActiveFilterMedias(filter)"
                :is-active="activeFilterMedias.id === filter.id"
                :name="filter.name"
                :icon="filter.icon"
            />
        </div>

        <component :is="activeFilterMedias.options"></component>
    </div>
</template>
