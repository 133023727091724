<script lang="ts" setup>
import { computed, ref } from 'vue'

import BaseModal from '@/components/molecules/BaseModal.vue'
import BaseInputField from '@/components/atoms/BaseInputField.vue'
import BaseImage from '@/components/atoms/BaseImage.vue'

import modal from '@/services/modal'

import { useEditPageStore } from '@/stores/modal/edit-pages'
import { useMediaManagerStore } from '@/stores/modal/media-manager'
import clsx from 'clsx'

const editPageStore = useEditPageStore()
const mediaManagerStore = useMediaManagerStore()

const isOpenModal = computed(() => editPageStore.isOpenModal)
const pageForEdit = computed(() => editPageStore.pageForEdit)
const isHome = computed(() => editPageStore.pageForEdit.isHome)
const loadingDeletePage = computed(() => editPageStore.loadingDeletePage)
const loadingUpdatePage = computed(() => editPageStore.loadingUpdatePage)

const confirmModalOpen = ref(false);

const onUpdatePage = () => {
    editPageStore.handleUpdatePage()
}

const onClonePage = async () => {
    confirmModalOpen.value = true

    const title = 'Confirmar clonagem'
    const message = 'Deseja realmente clonar esta página?'
    const type = 'info'
    const confirm = 'Sim, clonar'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        confirmModalOpen.value = false

        return
    }

    editPageStore.handleClonePage()

    confirmModalOpen.value = false
}

const onDeletePage = async () => {
    confirmModalOpen.value = true

    const title = 'Confirmar exclusão'
    const message = 'Deseja realmente remover esta página?'
    const type = 'danger'
    const confirm = 'Sim, remover'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        confirmModalOpen.value = false

        return
    }

    editPageStore.handleDeletePage()

    confirmModalOpen.value = false
}

const onAddMainImage = () => {
    mediaManagerStore.setIsOpenModal(true)
    mediaManagerStore.setMaxSelectedMedias(1)
    mediaManagerStore.setAction(($event: any) => handleChangeMainImage($event))
}

const handleChangeMainImage = (image: any) => {
    pageForEdit.value.mainImage.url = image.content
}

const onAddOgImage = () => {
    mediaManagerStore.setIsOpenModal(true)
    mediaManagerStore.setMaxSelectedMedias(1)
    mediaManagerStore.setAction(($event: any) => handleChangeOgImage($event))
}

const handleChangeOgImage = (image: any) => {
    pageForEdit.value.ogImage = image.content
}

const onRemoveMainImage = () => {
    pageForEdit.value.mainImage.url = null
}

const onRemoveOgImage = () => {
    pageForEdit.value.ogImage = null
}
</script>

<template>
    <base-modal
        :open-watch="isOpenModal"
        @modal-closed="() => editPageStore.reset()"
        title="Editar página"
        header="between"
        size="full"
        container-size="lg"
        :closed-disabled="confirmModalOpen || mediaManagerStore.isOpenModal || loadingDeletePage || loadingUpdatePage"
    >
        <template #header>
            <div v-if="!isHome" class="space-x-3">
                <button
                    @click="() => onDeletePage()"
                    v-tooltip="{
                        text: 'Excluir página',
                        position: 'bottom',
                        background: 'red'
                    }"
                    class="p-2 bg-[#F9F9F9] rounded-full outline-none"
                >
                    <span class="sr-only">Excluir página</span>

                    <svg
                        class="w-4 h-4 stroke-[#060606] opacity-30"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                    </svg>
                </button>

                <button
                    @click="() => onClonePage()"
                    v-tooltip="{
                        text: 'Clonar página',
                        position: 'bottom'
                    }"
                    class="p-2 bg-[#F9F9F9] rounded-full outline-none"
                >
                    <span class="sr-only">Clonar página</span>

                    <svg
                        class="w-4 h-4 stroke-[#060606] opacity-30"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                        />
                    </svg>
                </button>
            </div>
        </template>

        <template #body>
            <div class="space-y-3">
                <div class="flex flex-col space-y-1 md:space-y-0 md:flex-row md:space-x-3">
                    <label for="show-home" class="relative inline-flex items-center cursor-pointer">
                        <input
                            v-model="pageForEdit.visible"
                            type="checkbox"
                            name="show-home"
                            id="show-home"
                            class="sr-only peer"
                        />

                        <div
                            class="w-11 h-6 bg-gray-200 outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--main-color)]"
                        ></div>

                        <span class="ml-3 text-sm font-medium text-gray-900">
                            Exibir na página inicial
                        </span>
                    </label>

                    <label for="show-menu" class="relative inline-flex items-center cursor-pointer">
                        <input
                            v-model="pageForEdit.menu_visible"
                            type="checkbox"
                            name="show-menu"
                            id="show-menu"
                            class="sr-only peer"
                        />

                        <div
                            class="w-11 h-6 bg-gray-200 outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[var(--main-color)]"
                        ></div>

                        <span class="ml-3 text-sm font-medium text-gray-900">Exibir no menu</span>
                    </label>
                </div>

                <base-input-field id="name" label="Nome">
                    <input type="text" id="name" name="name" v-model="pageForEdit.name" />
                </base-input-field>

                <div class="flex">
                    <base-input-field id="tumbnail" label="Capa da página">
                        <button
                            v-if="!pageForEdit.mainImage.url"
                            @click="onAddMainImage"
                            class="flex justify-center items-center w-32 h-32 border-2 border-[var(--main-color)] border-dashed rounded-lg"
                        >
                            <svg
                                class="w-10 h-10 text-[var(--main-color)]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 88 88"
                                fill="currentColor"
                            >
                                <g clip-path="url(#clip0_267_2010)">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M44 22C35.2161 22 27.6271 27.1478 24.0992 34.6073C23.5299 35.811 22.3527 36.6115 21.024 36.6986C13.3807 37.1987 7.33333 43.5615 7.33333 51.3333C7.33333 59.4334 13.8998 66 22 66H25.6667C27.6917 66 29.3333 67.6416 29.3333 69.6667C29.3333 71.6918 27.6917 73.3333 25.6667 73.3333H22C9.84973 73.3333 0 63.4836 0 51.3333C0 40.3993 7.97441 31.3307 18.4267 29.6224C23.4524 20.7015 33.0183 14.6667 44 14.6667C57.746 14.6667 69.2787 24.1185 72.4621 36.8786C81.2607 38.2246 88 45.8253 88 55C88 65.1251 79.7918 73.3333 69.6667 73.3333H62.3333C60.3082 73.3333 58.6667 71.6918 58.6667 69.6667C58.6667 67.6416 60.3082 66 62.3333 66H69.6667C75.742 66 80.6667 61.0753 80.6667 55C80.6667 48.9247 75.742 44 69.6667 44C69.601 44 69.5358 44.0007 69.4709 44.0018C67.6221 44.0337 66.0389 42.6848 65.7774 40.8544C64.2536 30.1954 55.0807 22 44 22Z"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M41.407 34.0739C42.8388 32.642 45.1605 32.642 46.5924 34.0739L57.5924 45.074C59.0242 46.5058 59.0242 48.8275 57.5924 50.2594C56.1605 51.6912 53.8388 51.6912 52.407 50.2594L47.6663 45.5187V66C47.6663 68.0251 46.0248 69.6667 43.9997 69.6667C41.9746 69.6667 40.333 68.0251 40.333 66V45.5187L35.5924 50.2594C34.1605 51.6912 31.8389 51.6912 30.4069 50.2594C28.975 48.8275 28.975 46.5058 30.4069 45.074L41.407 34.0739Z"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_267_2010">
                                        <rect width="88" height="88" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>

                        <div v-else class="relative w-32 h-32">
                            <button
                                @click="onRemoveMainImage"
                                class="absolute top-2 right-2 p-2 rounded-full outline-none bg-[var(--main-color)]"
                            >
                                <span class="sr-only">Remover</span>

                                <svg
                                    class="w-4 h-4 stroke-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            </button>

                            <base-image
                                class="w-full h-full object-cover rounded-lg border-2 border-[var(--main-color)]"
                                :src="pageForEdit.mainImage.url"
                            />
                        </div>
                    </base-input-field>

                    <base-input-field id="whatzapp" label="Prévia Whats (og:image)">
                        <button
                            v-if="!pageForEdit.ogImage"
                            @click="onAddOgImage"
                            class="flex justify-center items-center w-32 h-32 border-2 border-[var(--main-color)] border-dashed rounded-lg"
                        >
                            <svg
                                class="w-10 h-10 text-[var(--main-color)]"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 88 88"
                                fill="currentColor"
                            >
                                <g clip-path="url(#clip0_267_2010)">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M44 22C35.2161 22 27.6271 27.1478 24.0992 34.6073C23.5299 35.811 22.3527 36.6115 21.024 36.6986C13.3807 37.1987 7.33333 43.5615 7.33333 51.3333C7.33333 59.4334 13.8998 66 22 66H25.6667C27.6917 66 29.3333 67.6416 29.3333 69.6667C29.3333 71.6918 27.6917 73.3333 25.6667 73.3333H22C9.84973 73.3333 0 63.4836 0 51.3333C0 40.3993 7.97441 31.3307 18.4267 29.6224C23.4524 20.7015 33.0183 14.6667 44 14.6667C57.746 14.6667 69.2787 24.1185 72.4621 36.8786C81.2607 38.2246 88 45.8253 88 55C88 65.1251 79.7918 73.3333 69.6667 73.3333H62.3333C60.3082 73.3333 58.6667 71.6918 58.6667 69.6667C58.6667 67.6416 60.3082 66 62.3333 66H69.6667C75.742 66 80.6667 61.0753 80.6667 55C80.6667 48.9247 75.742 44 69.6667 44C69.601 44 69.5358 44.0007 69.4709 44.0018C67.6221 44.0337 66.0389 42.6848 65.7774 40.8544C64.2536 30.1954 55.0807 22 44 22Z"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M41.407 34.0739C42.8388 32.642 45.1605 32.642 46.5924 34.0739L57.5924 45.074C59.0242 46.5058 59.0242 48.8275 57.5924 50.2594C56.1605 51.6912 53.8388 51.6912 52.407 50.2594L47.6663 45.5187V66C47.6663 68.0251 46.0248 69.6667 43.9997 69.6667C41.9746 69.6667 40.333 68.0251 40.333 66V45.5187L35.5924 50.2594C34.1605 51.6912 31.8389 51.6912 30.4069 50.2594C28.975 48.8275 28.975 46.5058 30.4069 45.074L41.407 34.0739Z"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_267_2010">
                                        <rect width="88" height="88" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>

                        <div v-else class="relative w-32 h-32">
                            <button
                                @click="onRemoveOgImage"
                                class="absolute top-2 right-2 p-2 rounded-full outline-none bg-[var(--main-color)]"
                            >
                                <span class="sr-only">Remover</span>

                                <svg
                                    class="w-4 h-4 stroke-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                </svg>
                            </button>

                            <base-image
                                class="w-full h-full object-cover rounded-lg border-2 border-[var(--main-color)]"
                                :src="pageForEdit.ogImage"
                            />
                        </div>
                    </base-input-field>
                </div>

                <template v-if="isHome">
                    <base-input-field id="pages" label="Número máximo de posts exibidos na Home">
                        <input
                            class="h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent"
                            type="number"
                            id="pages"
                            name="pages"
                            v-model="pageForEdit.pages"
                        />
                    </base-input-field>

                    <base-input-field id="seoText" label="Texto do H1">
                        <input
                            class="h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent"
                            type="text"
                            id="seoText"
                            name="seoText"
                            v-model="pageForEdit.seoText"
                        />
                    </base-input-field>
                </template>

                <base-input-field id="mainImageClass" label="Crop da foto de capa" type="select">
                    <select
                        v-model="pageForEdit.mainImage.class"
                        id="mainImageClass"
                        name="mainImageClass"
                    >
                        <option value="center-center">Centralizado</option>
                        <option value="top-right">Topo a direita</option>
                        <option value="top-center">Topo ao centro</option>
                        <option value="top-left">Topo a esquerda</option>
                        <option value="center-right">Centralizado a direita</option>
                        <option value="center-left">Centralizado a esquerda</option>
                        <option value="bottom-right">Inferior a direita</option>
                        <option value="bottom-center">Inferior ao centro</option>
                        <option value="bottom-left">Inferior a esquerda</option>
                    </select>
                </base-input-field>

                <base-input-field
                    id="customHeader"
                    label="Código dentro da tag &lt;head&gt;."
                    label-highlight="*Avançado"
                >
                    <textarea
                        v-model="pageForEdit.customHeader"
                        name="customHeader"
                        id="customHeader"
                        cols="30"
                        rows="3"
                    ></textarea>
                </base-input-field>

                <base-input-field
                    id="customFooter"
                    label="Código dentro da tag &lt;body&gt;."
                    label-highlight="*Avançado"
                >
                    <textarea
                        v-model="pageForEdit.customFooter"
                        name="customFooter"
                        id="customFooter"
                        cols="30"
                        rows="3"
                    ></textarea>
                </base-input-field>

                <button
                    :class="
                        clsx(
                            'fixed bottom-6 left-1/2 -translate-x-1/2 py-3 px-10 rounded-full bg-[var(--main-color)] focus:outline-none cursor-pointer text-white font-semibold',
                            loadingDeletePage && 'disabled:bg-gray-500 disabled:cursor-not-allowed',
                            loadingUpdatePage && 'disabled:cursor-wait'
                        )
                    "
                    @click="onUpdatePage()"
                    :disabled="loadingDeletePage || loadingUpdatePage"
                >
                    <span v-if="loadingUpdatePage">Atualizando...</span>

                    <span v-else>Salvar</span>
                </button>
            </div>
        </template>
    </base-modal>
</template>
