<script setup lang="ts">
import { ref, computed } from 'vue'

import { onClickOutside } from '@vueuse/core'

import clsx from 'clsx'

import modal from '@/services/modal'

import IconSettingSection from '@/components/atoms/icons/IconSettingSection.vue'

import IconActionConfig from '@/components/atoms/icons/IconActionConfig.vue'
import IconActionToDown from '@/components/atoms/icons/IconActionToDown.vue'
import IconActionToUp from '@/components/atoms/icons/IconActionToUp.vue'
import IconActionDelete from '@/components/atoms/icons/IconActionDelete.vue'
import IconClose from '@/components/atoms/icons/IconClose.vue'

import { useEditorPageStore } from '@/stores/editor/page'
import { useEditorSidebarStore } from '@/stores/editor/sidebar'

const editorPageStore = useEditorPageStore()
const editorSidebarStore = useEditorSidebarStore()

const props = defineProps({
    sectionBackgroundColor: {
        type: String,
        required: true
    },
    section: {
        type: Object,
        required: true
    },
    sectionIndex: {
        type: Number,
        required: true
    },
    isDarkMode: {
        type: Boolean,
        required: true
    },
    hasConfig: {
        type: Boolean,
        required: true
    },
    hasDelete: {
        type: Boolean,
        required: false,
        default: true
    }
})

const sectionIndex = computed(() => props.sectionIndex)
const showSectionSettings = ref(false)
const sectionSettings = ref(null)

onClickOutside(sectionSettings, () => {
    showSectionSettings.value = false
})

const onChangePositionSection = (direction: string) => {
    switch (direction) {
        case 'up':
            editorPageStore.upSection(sectionIndex.value)
            break

        case 'down':
            editorPageStore.downSection(sectionIndex.value)
            break
    }
}

const onDeleteSection = async () => {
    const title = 'Excluir seção'
    const message = 'Deseja realmente excluir a seção da página?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    editorPageStore.deleteSection(props.section)
}

const onSettingsSection = () => {
    editorPageStore.setSectionForEdit({
        ...props.section,
        sectionIndex: sectionIndex.value
    })

    editorSidebarStore.setConfigsReference('section')

    editorSidebarStore.setIsOpenModal(true)
}

const isFirstSection = (sectionIndex: number) => {
    let indexCompare = 0;

    if (editorPageStore.activePage.content[0].component.includes('home')) {
        indexCompare = 1;
    }

    return sectionIndex == indexCompare
}

const isLastSection = (sectionIndex: number) => {
    let indexCompare = 1;

    return sectionIndex == editorPageStore.activePage.content.length - indexCompare
}
</script>

<template>
    <button
        v-if="!showSectionSettings"
        @click="() => (showSectionSettings = true)"
        :class="clsx('section-settings-button', isDarkMode ? 'dark' : 'light')"
    >
        <span class="sr-only">Section Settings</span>

        <icon-setting-section
            class="w-8 h-8"
        />
    </button>

    <div
        v-else
        ref="sectionSettings"
        :class="clsx('sections-actions', isDarkMode ? 'dark' : 'light')"
        :style="{
            ...({
                backgroundColor: sectionBackgroundColor
            } as any)
        }"
    >
        <button
            v-if="hasConfig"
            class="action-settings"
            @click="onSettingsSection"
        >
            <span class="sr-only">Section Settings</span>

            <icon-action-config
                class="w-[22px] h-[22px]"
            />
        </button>

        <button
            class="action-change-position-down"
            @click="onChangePositionSection('down')"
            :disabled="isLastSection(sectionIndex)"
        >
            <span class="sr-only">Section To Down</span>

            <icon-action-to-down
                class="w-[18px] h-[18px]"
            />
        </button>

        <button
            class="action-change-position-up"
            @click="onChangePositionSection('up')"
            :disabled="isFirstSection(sectionIndex)"
        >
            <span class="sr-only">Section To Up</span>

            <icon-action-to-up
                class="w-[18px] h-[18px]"
            />
        </button>
        
        <button
            class="action-delete"
            @click="onDeleteSection"
            v-if="hasDelete"
        >
            <span class="sr-only">Section Delete</span>

            <icon-action-delete
                class="w-[18px] h-[18px]"
            />
        </button>

        <button class="action-close" @click="() => (showSectionSettings = false)">
            <span class="sr-only">Close Settings</span>

            <icon-close
                class="w-[18px] h-[18px]"
            />
        </button>
    </div>
</template>

<style scoped>
.section-settings-button {
    @apply absolute -top-14 right-6;
}

.section-settings-button.light svg {
    @apply fill-[#060606] opacity-30;
}

.section-settings-button.dark svg {
    @apply fill-[#FAFAFA] opacity-30;
}

.sections-actions {
    @apply absolute -top-14 right-2 space-x-3 px-6 py-2 rounded-full flex items-center brightness-[.9];
}

.sections-actions.light .action-settings svg,
.sections-actions.light .action-change-position-down svg,
.sections-actions.light .action-change-position-up svg,
.sections-actions.light .action-delete svg {
    @apply stroke-[#060606];
}

.sections-actions.light .action-change-position-down:disabled svg,
.sections-actions.light .action-change-position-up:disabled svg {
    @apply stroke-[#060606] opacity-30;
}

.sections-actions.dark .action-settings svg,
.sections-actions.dark .action-change-position-down svg,
.sections-actions.dark .action-change-position-up svg,
.sections-actions.dark .action-delete svg {
    @apply stroke-[#FAFAFA];
}

.sections-actions.dark .action-change-position-down:disabled svg,
.sections-actions.dark .action-change-position-up:disabled svg {
    @apply stroke-[#FAFAFA] opacity-30;
}

.sections-actions.light .action-close svg {
    @apply stroke-[#060606] opacity-30;
}

.sections-actions.dark .action-close svg {
    @apply stroke-[#FAFAFA] opacity-30;
}
</style>
