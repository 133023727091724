<script setup lang="ts">
import { computed } from 'vue'

import clsx from 'clsx'
import BaseImage from '@/components/atoms/BaseImage.vue'

import modal from '@/services/modal';

import { useSiteStore } from '@/stores/site'
import { useEditorPageStore } from '@/stores/editor/page'

const editorPageStore = useEditorPageStore()
const siteStore = useSiteStore()

const props = defineProps<{
    index?: number
    postId: number
    title?: string
    body?: string
    tumbnail?: string
    date?: string
    isScheduled?: boolean
    isDraft?: boolean
    isHighlight?: boolean
    route?: string
    isMovable?: boolean,
    extra?: any
}>()

const formattedDate = computed(() => {
    if (!props.date) return ''

    const months = [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro'
    ]

    const dateObj = new Date(props.date + 'T00:00:00')
    const day = dateObj.getDate()
    const month = months[dateObj.getMonth()]
    const year = dateObj.getFullYear()

    return `${day} de ${month} de ${year}`
})

const onClonePost = async (postId: number) => {
    const title = 'Confirmar clonagem'
    const message = 'Deseja realmente clonar este post?'
    const type = 'info'
    const confirm = 'Sim, clonar'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    editorPageStore.handleClonePost(postId)
}

const onDeletePost = async (postId: number) => {
    const title = 'Confirmar Exclusão'
    const message = 'Deseja realmente excluir este post?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    editorPageStore.handleDeletePost(postId)
}

const onHighlightPost = async (postId: number) => {
    let title = 'Destacar post'
    let message = 'Deseja realmente destacar este post na sua home?'
    const type = 'main'
    let confirm = 'Sim, destacar'
    const cancel = 'Não, voltar'

    if (props.isHighlight) {
        title = 'Remover detaque do post'
        message = 'Deseja realmente remover este post da sua home?'
        confirm = 'Sim, remover'
    }

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    const options = {
        notification: {
            loading: props.isHighlight ? 'Removendo destaque...' : 'Destacando...',
            success: props.isHighlight ? 'Destaque removido com sucesso!' : 'Post destacado com sucesso!',
            error: props.isHighlight ? 'Não foi possível remover o destaque do post!' : 'Não foi possível destacar o post!'
        }
    }

    editorPageStore.handleHighlightPost(postId, options)
}

const onOpenPost = () => {
    const url = `//${siteStore.url}${props.route}`

    window.open(url, '_blank')
}

const backgroundFocalPoint = computed(() => {
    return (
        props.extra?.background_image_focal_point ?? {
            x: 50,
            y: 50
        }
    )
})
</script>

<template>
    <router-link
        :to="{ name: 'post.edit', params: { id: postId } }"
        :class="clsx(
            'w-full relative drop-shadow-none overflow-hidden rounded-xl flex flex-col',
            isDraft ? 'bg-gray-100' : 'bg-white',
            isMovable && 'cursor-move'
        )"
    >
        <div :class="
            clsx(
                'w-full relative h-44',
            )">
            <button 
                v-if="!isDraft"
                @click.prevent="() => onHighlightPost(postId)"
                class="absolute top-3 left-3 py-2 px-3 z-10"
            >
                <span class="sr-only">
                    Destaque
                </span>

                <svg
                    v-if="isHighlight"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 fill-white stroke-white"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                </svg>

                <svg
                    v-else
                    class="w-6 h-6 stroke-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                    />
                </svg>
            </button>

            <button
                v-if="!isDraft"
                @click.prevent="() => onOpenPost()"
                class="absolute top-3 right-3 py-2 px-3 z-10"
            >
                <span class="sr-only">
                    Link
                </span>

                <svg
                    class="w-6 h-6 stroke-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                </svg>
            </button>

            <span
                v-if="isDraft"
                class="absolute bottom-3 left-3 py-1 px-3 z-10 text-xs font-light bg-gray-100 rounded-full"
            >
                Rascunho
            </span>

            <base-image
                :src="tumbnail"
                :class="
                    clsx(
                        'absolute inset-0 w-full h-full object-cover z-0',
                        isDraft && 'bg-gray-200 opacity-60'
                    )
                "
                :style="{
                    objectPosition: `${backgroundFocalPoint.x}% ${backgroundFocalPoint.y}%`
                }"
            />
        </div>

        <div 
            :class="clsx(
                'p-3 flex flex-col flex-grow',
            )"
        >
            <p v-if="!isScheduled" class="text-xs font-light text-gray-500">
                {{ formattedDate }}
            </p>

            <p v-else class="text-xs font-light text-gray-500">
                <span class="font-bold">Agendado para:</span> {{ formattedDate }}
            </p>

            <div class="flex-1">
                <h3 class="text-xl font-bold mt-2">
                    {{ title }}
                </h3>

                <p class="text-sm font-light mt-1 break-all">
                    {{ body }}
                </p>
            </div>

            <div class="mt-3 flex flex-row justify-between">
                <div class="space-x-2">
                    <button
                        @click.prevent="() => onDeletePost(postId)"
                        class="p-2 z-10"
                        type="button"
                    >
                        <span class="sr-only">
                            Delete Post
                        </span>

                        <svg
                            class="w-6 h-6 stroke-gray-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                        </svg>
                    </button>

                    <button
                        @click.prevent="() => onClonePost(postId)"
                        class="p-2"
                        type="button"
                    >
                        <span class="sr-only">
                            Clone Post
                        </span>

                        <svg
                            class="w-6 h-6 stroke-gray-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </router-link>
</template>

<style></style>
