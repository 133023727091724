<template>
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_267_2010)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M44 22C35.2161 22 27.6271 27.1478 24.0992 34.6073C23.5299 35.811 22.3527 36.6115 21.024 36.6986C13.3807 37.1987 7.33333 43.5615 7.33333 51.3333C7.33333 59.4334 13.8998 66 22 66H25.6667C27.6917 66 29.3333 67.6416 29.3333 69.6667C29.3333 71.6918 27.6917 73.3333 25.6667 73.3333H22C9.84973 73.3333 0 63.4836 0 51.3333C0 40.3993 7.97441 31.3307 18.4267 29.6224C23.4524 20.7015 33.0183 14.6667 44 14.6667C57.746 14.6667 69.2787 24.1185 72.4621 36.8786C81.2607 38.2246 88 45.8253 88 55C88 65.1251 79.7918 73.3333 69.6667 73.3333H62.3333C60.3082 73.3333 58.6667 71.6918 58.6667 69.6667C58.6667 67.6416 60.3082 66 62.3333 66H69.6667C75.742 66 80.6667 61.0753 80.6667 55C80.6667 48.9247 75.742 44 69.6667 44C69.601 44 69.5358 44.0007 69.4709 44.0018C67.6221 44.0337 66.0389 42.6848 65.7774 40.8544C64.2536 30.1954 55.0807 22 44 22Z" fill="var(--main-color)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.407 34.0739C42.8388 32.642 45.1605 32.642 46.5924 34.0739L57.5924 45.074C59.0242 46.5058 59.0242 48.8275 57.5924 50.2594C56.1605 51.6912 53.8388 51.6912 52.407 50.2594L47.6663 45.5187V66C47.6663 68.0251 46.0248 69.6667 43.9997 69.6667C41.9746 69.6667 40.333 68.0251 40.333 66V45.5187L35.5924 50.2594C34.1605 51.6912 31.8389 51.6912 30.4069 50.2594C28.975 48.8275 28.975 46.5058 30.4069 45.074L41.407 34.0739Z" fill="var(--main-color)"/>
        </g>
        <defs>
        <clipPath id="clip0_267_2010">
        <rect width="88" height="88" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>