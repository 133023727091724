<script setup lang="ts">
import { computed } from 'vue'

import modal from '@/services/modal'

import { useMediaManagerStore } from '@/stores/modal/media-manager'

const mediaManagerStore = useMediaManagerStore()

const selectedMediasLenght = computed(() => mediaManagerStore.selectedMedias.length)

const onSelectMedias = () => {
    if (selectedMediasLenght.value > 0) {
        return mediaManagerStore.handleUnselectAllMedias()
    }

    return mediaManagerStore.handleSelectAllMedias()
}

const onDeleteSelectedMedias = async () => {
    mediaManagerStore.closeDisabled = true
    
    const title = 'Confirmar exclusão'
    const message = `
        <p>Você está excluindo: ${selectedMediasLenght.value} ${
            selectedMediasLenght.value > 1 ? 'mídias' : 'mídia'
        }</p>
        <p class="font-semibold mb-3">Está ação não poderá ser desfeita</p>
    `
    const type = 'danger'
    const confirm = 'Sim, remover'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        mediaManagerStore.closeDisabled = false

        return
    }

    mediaManagerStore.closeDisabled = false

    mediaManagerStore.deleteMedias(mediaManagerStore.selectedMedias)
}
</script>

<template>
    <label class="flex items-center cursor-pointer" for="select-medias">
        <input
            @change="onSelectMedias"
            type="checkbox"
            name="select-medias"
            id="select-medias"
            class="cursor-pointer appearance-none relative h-4 w-4 bg-[#F9F9F9] checked:bg-[var(--main-color)] rounded-sm checked:after:h-2.5 checked:after:w-1.5 checked:after:border-b-2 checked:after:border-r-2 checked:after:border-white checked:after:absolute checked:after:top-1.5 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:rotate-45"
            :checked="selectedMediasLenght > 0"
        />

        <span v-if="selectedMediasLenght == 0" class="ml-2 text-[#060606] opacity-50">
            Nenhuma mídia selecionada
        </span>

        <span v-else-if="selectedMediasLenght == 1" class="ml-2 text-[#060606] opacity-50">
            1 mídia selecionada
        </span>

        <span v-else class="ml-2 text-[#060606] opacity-50">
            {{ selectedMediasLenght }} mídias selecionadas
        </span>
    </label>

    <button
        v-if="selectedMediasLenght > 0"
        class="ml-4 flex items-center space-x-2 text-[#060606] opacity-50"
        @click="onDeleteSelectedMedias"
    >
        <svg
            class="w-5 h-5 stroke-[#060606] opacity-30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
        </svg>

        <span>Excluir</span>
    </button>
</template>

<style scoped></style>
