<script setup lang="ts">
import { onBeforeMount, onMounted, reactive, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import clsx from 'clsx'

import { useArrayIncludes, useActiveElement } from '@vueuse/core'

import EditorLayout from '@/components/layouts/EditorLayout.vue'
import EditorSection from '@/components/organisms/EditorSection.vue'
import ConfirmationModal from '@/components/organisms/ConfirmationModal.vue'
import PresetModal from '@/components/organisms/PresetModal.vue'
import MediaManagerModal from '@/components/organisms/MediaManagerModal.vue'
import EditorSidebar from '@/components/organisms/EditorSidebar.vue'
import TextAiModal from '@/components/organisms/TextAiModal.vue'
import TextLinkModal from '@/components/organisms/TextLinkModal.vue'

import { useEditorPageStore } from '@/stores/editor/page'
import { useDashboardStore } from '@/stores/dashboard'
import { useAuthStore } from '@/stores/auth'
import { useModalPresetStore } from '@/stores/modal/presets'
import { useEditorCommentStore } from '@/stores/editor/comment'
import { useEditorSidebarStore } from '@/stores/editor/sidebar'

const editorPageStore = useEditorPageStore()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const presetsStore = useModalPresetStore()
const commentStore = useEditorCommentStore()
const editorSidebarStore = useEditorSidebarStore()

const router = useRouter()

const page = ref<any>({ value: null})

const loadingGetPage = computed(() => editorPageStore.loadingGetPage)
const loadingPorcentGetPage = computed(() => editorPageStore.loadingPorcentGetPage)
const deletedSections = computed(() => editorPageStore.deletedSections)
const editorDisabled = computed(() => editorPageStore.editorDisabled)

onBeforeMount(async () => {
    const pageId = router.currentRoute.value.params.id

    await editorPageStore.getPage(Number(pageId))

    dashboardStore.handleClickPage(page)

    page.value = reactive(editorPageStore.activePage)

    await presetsStore.fetchPresets()

    await commentStore.fetchComments()
})

onMounted(() => {
    authStore.checkAccessType()
})

// const handleCreateLastFixedTextElement = () => {
//     const lastSection = page.value.content[page.value.content.length - 1]
//     console.log('lastSection',lastSection);
//     if (!lastSection.component.includes('maincontent')) {
//         // cria seção maincontent com o campo de texto fixo
//         const newSection = {
//             temporaryId: Date.now(),
//             ...defaultStore.newSection,
//             frameContent: [
//                 {
//                     temporaryId: Date.now(),
//                     ...defaultStore.newElementText,
//                     new: true
//                 }
//             ],
//         }

//         editorPageStore.createSection(newSection)

//         return
//     }

//     if (lastSection.frameContent[lastSection.frameContent.length - 1].new) {
//         return
//     }

//     lastSection.frameContent.push({
//         temporaryId: Date.now(),
//         ...defaultStore.newElementText,
//         extra: {
//             margin: {
//                 ...defaultStore.margin
//             },
//             margin_mobile: {
//                 ...defaultStore.margin_mobile,
//             },
//             margin_mobile_lock: defaultStore.margin_mobile_lock,
//             padding: {
//                 ...defaultStore.padding
//             },
//             padding_mobile: {
//                 ...defaultStore.padding_mobile,
//             },
//             padding_mobile_lock: defaultStore.padding_mobile_lock,
//         },
//         new: true
//     })
// }

// watch(page, () => {
//     handleCreateLastFixedTextElement()
// }, { deep: true })

const activeElement = useActiveElement()

watch(activeElement, (newActiveElement, oldActiveElement) => {
    if (editorSidebarStore.isOpen) return

    if (editorPageStore.isBody(newActiveElement)) {
        editorPageStore.clearFrameActive();
        
        return
    }

    if (editorPageStore.ignoreActiveElement(newActiveElement)) return

    const idNewActiveElement = editorPageStore.getElement(newActiveElement)
    const idOldActiveElement = editorPageStore.getElement(oldActiveElement)
    
    if (idNewActiveElement?.id === idOldActiveElement?.id) return

    idNewActiveElement && editorPageStore.onActiveElement(idNewActiveElement);
    idOldActiveElement && editorPageStore.onDeactiveElement(idOldActiveElement);
}, { deep: true })
</script>

<template>
    <editor-layout id="page-editor">
        <media-manager-modal />

        <preset-modal />

        <confirmation-modal />

        <editor-sidebar />

        <text-ai-modal />

        <text-link-modal />

        <div class="relative h-auto">
            <div
                :class="clsx(
                    loadingGetPage ? 'h-2' : 'h-0',
                    ' top-0 bg-[var(--main-color)] transition-all duration-300'
                )"
                :style="{ width: `${loadingPorcentGetPage}%` }"
            ></div>

            <!-- TODO: Adicionar bunner no erp para ativar alertas -->
            <!-- <div
                :class="
                    clsx(
                        'transition-all duration-1000 delay-300 w-full flex justify-center items-center pb-2 left-0 right-0 bg-[var(--main-color)]',
                        loadingPorcentGetPage == 100 ? 'h-12 opacity-100' : 'h-0 opacity-0'
                    )
                "
            >
                <div
                    :class="
                        clsx(
                            'flex items-center text-white space-x-4 duration-1000 delay-1000',
                            loadingPorcentGetPage == 100 ? 'opacity-100' : 'opacity-0'
                        )
                    "
                >
                    <p class="flex text-lg font-bold space-x-3">
                        <svg
                            class="w-6 h-6 fill-[#E0D0FF] animate-pulse"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path
                                d="M14.8234 2.4L16.6537 7.34621L21.5999 9.17647L16.6537 11.0067L14.8234 15.9529L12.9932 11.0067L8.04696 9.17647L12.9932 7.34621L14.8234 2.4Z"
                            />
                            <path
                                d="M6.35284 13.6941L7.95167 16.0482L10.3058 17.6471L7.95167 19.2459L6.35284 21.6L4.75402 19.2459L2.3999 17.6471L4.75402 16.0482L6.35284 13.6941Z"
                            />
                        </svg>

                        <span>Novidade na área!</span>
                    </p>

                    <span class="font-light text-base">Experimente nossa ferramenta com IA.</span>
                </div>
            </div> -->
        </div>

        <template v-if="!loadingGetPage">
            <editor-section
                v-for="(section, sectionIndex) in page.content"
                :key="`${section.frameId}`"
                :section="section"
                :section-index="sectionIndex"
                :is-deleted="useArrayIncludes(deletedSections, section).value"
                :has-delete="page.content.length > 1"
                :is-editor-disabled="editorDisabled"
                :has-add-new-section="true"
            />
        </template>
    </editor-layout>
</template>

<style></style>
