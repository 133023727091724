<script setup lang="ts">
import { ref, onMounted } from 'vue'

import BaseImage from '@/components/atoms/BaseImage.vue'

import { useMediaManagerStore } from '@/stores/modal/media-manager'
import { useSiteStore } from '@/stores/site'

const mediaManagerStore = useMediaManagerStore()
const siteStore = useSiteStore()

const logoDesktop = ref('')
const logoDesktopDark = ref('')

const logoMobile = ref('')
const logoMobileDark = ref('')

const favicon = ref('')

const handleChangeLogoDesktop = async (image: any) => {
    const result = await siteStore.handleUpdateLogo(image.content, 'logo')

    if (result) {
        logoDesktop.value = image.content
    }
}

const handleChangeLogoMobile = async (image: any) => {
    const result = await siteStore.handleUpdateLogo(image.content, 'logo_mobile')

    if (result) {
        logoMobile.value = image.content
    }
}

const handleChangeLogoDesktopDark = async (image: any) => {
    const result = await siteStore.handleUpdateLogo(image.content, 'logo_dark')

    if (result) {
        logoDesktopDark.value = image.content
    }
}

const handleChangeLogoMobileDark = async (image: any) => {
    const result = await siteStore.handleUpdateLogo(image.content, 'logo_dark_mobile')

    if (result) {
        logoMobileDark.value = image.content
    }
}

const handleChangeFavicon = async (image: any) => {
    const result = await siteStore.handleUpdateLogo(image.content, 'favicon')

    if (result) {
        favicon.value = image.content
    }
}

const onChangeLogo = (type: string) => {
    mediaManagerStore.setIsOpenModal(true)
    mediaManagerStore.setMaxSelectedMedias(1)

    switch (type) {
        case 'desktop':
            mediaManagerStore.setAction(($event: any) => handleChangeLogoDesktop($event))
            break

        case 'mobile':
            mediaManagerStore.setAction(($event: any) => handleChangeLogoMobile($event))
            break

        case 'desktop-dark':
            mediaManagerStore.setAction(($event: any) => handleChangeLogoDesktopDark($event))
            break

        case 'mobile-dark':
            mediaManagerStore.setAction(($event: any) => handleChangeLogoMobileDark($event))
            break

        case 'favicon':
            mediaManagerStore.setAction(($event: any) => handleChangeFavicon($event))
            break

        default:
            break
    }
}

onMounted(() => {
    logoDesktop.value = siteStore.logo
    logoDesktopDark.value = siteStore.logoDark

    logoMobile.value = siteStore.logoMobile
    logoMobileDark.value = siteStore.logoDarkMobile

    favicon.value = siteStore.favicon
})
</script>

<template>
    <div class="px-6 space-y-3">
        <h2 class="text-2xl font-bold">Logo</h2>

        <p class="text-sm">
            Envie o logo do seu site para layouts claros ou escuros e se desejar, em versões menores
            para celular e ícone.
        </p>

        <div class="grid grid-cols-2">
            <div class="bg-white px-3 py-4 space-y-2">
                <div class="relative flex flex-col justify-center items-center space-y-1">
                    <label class="text-xs text-black" for="">Computador</label>

                    <div class="flex text-center justify-center items-center">
                        <div class="h-24 w-24 relative">
                            <base-image class="w-full h-full object-scale-down" :src="logoDesktop" />

                            <button
                                @click="onChangeLogo('desktop')"
                                class="absolute -bottom-2 -right-2 py-3 px-3 rounded-full bg-gray-100"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="relative flex flex-col justify-center items-center space-y-1">
                    <label class="text-xs text-black" for="">Celular</label>

                    <div class="flex text-center justify-center items-center">
                        <div class="h-24 w-24 relative">
                            <base-image class="w-full h-full object-scale-down" :src="logoMobile" />

                            <button
                                @click="onChangeLogo('mobile')"
                                class="absolute -bottom-2 -right-2 py-3 px-3 rounded-full bg-gray-100"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-black px-3 py-4 space-y-2">
                <div class="relative flex flex-col justify-center items-center space-y-1">
                    <label class="text-xs text-white" for="">Computador</label>

                    <div class="flex text-center justify-center items-center">
                        <div class="h-24 w-24 relative">
                            <base-image class="w-full h-full object-scale-down" :src="logoDesktopDark" />

                            <button
                                @click="onChangeLogo('desktop-dark')"
                                class="absolute -bottom-2 -right-2 py-3 px-3 rounded-full bg-gray-100"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="relative flex flex-col justify-center items-center space-y-1">
                    <label class="text-xs text-white" for="">Celular</label>

                    <div class="flex text-center justify-center items-center">
                        <div class="h-24 w-24 relative">
                            <base-image class="w-full h-full object-scale-down" :src="logoMobileDark" />

                            <button
                                @click="onChangeLogo('mobile-dark')"
                                class="absolute -bottom-2 -right-2 py-3 px-3 rounded-full bg-gray-100"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white px-3 py-4 space-y-2">
                <div class="relative flex flex-col justify-center items-center space-y-1">
                    <label class="text-xs text-black" for="">Favicon</label>

                    <div class="flex text-center justify-center items-center">
                        <div class="h-24 w-24 relative">
                            <base-image class="w-full h-full object-scale-down" :src="favicon" />

                            <button
                                @click="onChangeLogo('favicon')"
                                class="absolute -bottom-2 -right-2 py-3 px-3 rounded-full bg-gray-100"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-4 h-4"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <p class="font-semibold text-base">Ideal .png tamanhos:</p>

            <p class="font-light text-sm">Desktop: 260px do lado maior</p>

            <p class="font-light text-sm">Celular: 200px do lado maior</p>

            <p class="font-light text-sm">Ícone: 64 x 64 pixels</p>
        </div>
    </div>
</template>

<style></style>
