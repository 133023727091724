<script lang="ts" setup>
import { computed, watch } from 'vue'

import { useImage } from '@vueuse/core'

const props = defineProps<{
    src?: string
    alt?: string
    externalImage?: boolean
    className?: string
}>()

const source = computed(() => getSource())

const getSource = () => {
    if (!props.src) return '/images/default-image.png'

    if (props.externalImage) return props.src

    const fitIn = '0x2400'

    return `${import.meta.env.VITE_CDN_URL}/${fitIn}/${props.src}`
}

const { isLoading } = useImage({ src: source.value })

const emit = defineEmits(['loaded'])

watch(isLoading, (value) => {
    if(!value) {
        emit('loaded')
    }
});
</script>

<template>
    <img v-if="isLoading" src="@/assets/images/Uli-loading.gif" :class="className" />
    
    <img v-else :src="source" :alt="alt" :class="className" />
</template>
