<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import clsx from 'clsx'

import DomainStatus from '@/components/atoms/DomainStatus.vue'
import BaseModal from '@/components/molecules/BaseModal.vue'
import BaseInputField from '@/components/atoms/BaseInputField.vue'

import api from '@/services/api'

import { useSiteStore } from '@/stores/site'
import notification, { type ToastId } from '@/services/notification'
import modal from '@/services/modal'

const siteStore = useSiteStore()

const isOpen = ref<boolean>(false)
const inEditDomain = ref<boolean>(false)
const loading = ref<boolean>(false)
const domainEdit = ref<string>('')

const onEditActiveDomain = () => {
    inEditDomain.value = true
}

const onDesactiveEditActiveDomain = (domain: string, domainId: number) => {
    inEditDomain.value = false

    if (domainEdit.value == '' || domainEdit.value == domain) {
        domainEdit.value = ''

        return
    }

    handleSaveDomain(domainEdit.value, domainId)
}

const onCanceled = () => {
    isOpen.value = false
}

const handleSaveDomain = async (domain: string, domainId: number) => {
    try {
        const response = await api.post(
            `site/${siteStore.id}/domain/${domainId}/update`,
            JSON.stringify({ domain: domain })
        )

        const { success } = await response.json()

        if (!success) {
            throw new Error('Error updating domain')
        }

        notification.simple('Domínio atualizado com sucesso', 'success');

        await fetchDomains()
    } catch (error) {
        console.error(error)

        notification.simple('Erro ao atualizar domínio', 'error');
    }

    domainEdit.value = ''
}

const onMainDomain = async (domainId: number) => {
    const title = 'Confirmar Ação'
    const message = 'Deseja realmente tornar esse domínio o principal?'
    const type = 'main'
    const confirm = 'Sim, tornar principal'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    await handleMainDomain(domainId)
}

const handleMainDomain = async (domainId: number) => {
    try {
        const response = await api.post(
            `site/${siteStore.id}/domain/${domainId}/main`,
            JSON.stringify({domain: domainId })
        )

        const { success } = await response.json()

        if (!success) {
            throw new Error('Error setting main domain')
        }

        notification.simple('Domínio definido como principal com sucesso!', 'success');

        await fetchDomains()
    } catch (error) {
        console.error(error)

        notification.simple('Erro ao definir domínio principal', 'error');
    }
}

const onAddDomain = async () => {
    const toastId: ToastId = notification.loading('Adicionando domínio...');

    isOpen.value = false

    try {
        const data = {
            url: newDomain.value,
            main: 0
        }

        const response = await api.post(
            `site/${siteStore.id}/domain/create`,
            JSON.stringify(data)
        )

        const { status, message } = await response.json()

        if (status != '1') {
            throw new Error('Error adding domain')
        }

        notification.update(toastId, message, 'success');
    } catch (error) {
        console.error(error)

        notification.update(toastId, 'Erro ao adicionar domínio', 'error');
    }

    newDomain.value = ''
    loading.value = false

    await fetchDomains()
}

const onOpen = () => {
    isOpen.value = true
}

const newDomain = ref<string>('')
const domains = ref<any[]>([])
const loadingDomains = ref<boolean>(false)

const fetchDomains = async () => {
    loadingDomains.value = true

    try {
        const response = await api.get(`site/${siteStore.id}/domain`)

        const { status, data } = await response.json()

        if (status != '1') {
            throw new Error('Error fetching domains')
        }

        domains.value = data
    } catch (error) {
        console.error(error)
    }

    loadingDomains.value = false
}

const filterAdctiveDomains = () => {
    return domains.value.filter((domain: any) => domain.main_domain == 1);
}

const filterInactiveDomains = () => {
    return domains.value.filter((domain: any) => domain.main_domain == 0);
}

const onDeleteDomain = async (id: number) => {
    const title = 'Confirmar Exclusão'
    const message = 'Deseja realmente excluir este domínio?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    handleDeleteDomain(id)
}

const handleDeleteDomain = async (id: number) => {
    const toastId: ToastId = notification.loading('Excluindo domínio...');

    try {
        const response = await api.post(`site/${siteStore.id}/domain/${id}/delete`, null)

        const { status } = await response.json()

        if (status != '1') {
            throw new Error('Error deleting domain')
        }

        notification.update(toastId, 'Domínio excluido com sucesos!', 'success');

        await fetchDomains()
    } catch (error) {
        console.error(error)

        notification.update(toastId, 'Erro ao excluir domínio', 'error');
    }
}

onBeforeMount(async () => {
    await fetchDomains()
})

const onChangeDomain = (domain: string) => {
    domainEdit.value = domain
}
</script>

<template>
    <base-modal
        :open-watch="isOpen"
        @modal-closed="() => onCanceled()"
        title="Adicionar Domínio"
        size="custom"
        height="h-[21rem]"
        width="w-[32rem]"
        rounded="2xl"
    >
        <template #body>
            <div class="h-full flex flex-col justify-between">
                <div class="flex-1">
                    <base-input-field
                        label="Domínio"
                        id="domain"
                    >
                        <input
                            type="text"
                            id="domain"
                            name="domain"
                            v-model="newDomain"
                            placeholder="seudominio.com"
                        />
                    </base-input-field>

                    <p class="mt-2 text-[10px]">
                        <b>Ainda não registrou?</b><br>

                        Compre seu domínio .com.br em <a href="https://www.registro.br"><b>www.registro.br</b></a><br>

                        <b>Já registrou?</b><br>

                        Acesse sua conta onde registrou (registro.br, Godaddy, etc) e adicione em DNS nosso IP: 18.229.165.166<br>

                        Dúvidas? <a href="https://uli.zendesk.com/hc/pt-br/articles/360033941314-Como-apontar-meu-dom%C3%ADnio-para-um-site-Uli"><b>Confira esse Tutorial</b></a>
                    </p>
                </div>

                <div class="flex justify-end mt-4 space-x-2">
                    <button
                        @click="() => onCanceled()"
                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                    >
                        Cancelar
                    </button>

                    <button
                        :disabled="loading"
                        @click="() => onAddDomain()"
                        :class="
                            clsx(
                                loading && 'disabled:opacity-50 disabled:cursor-wait disabled:animate-pulse',
                                'bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none'
                            )
                        "
                    >
                        Adicionar
                    </button>
                </div>
            </div>
        </template>
    </base-modal>

    <div v-if="!loadingDomains" class="px-6 space-y-3">
        <h2 class="text-2xl font-bold">Domínios</h2>

        <div v-if="domains" class="w-full flex flex-col items-center space-y-6">
            <div class="w-full space-y-2">
                <label class="text-[10px] font-semibold text-gray-400" for="">
                    Endereço temporário
                </label>

                <div
                    v-for="domain in filterAdctiveDomains()"
                    :key="domain.id"
                    class="drop-shadow-md hover:drop-shadow-lg cursor-pointer bg-white rounded-md p-3"
                >
                    <div class="flex justify-between">
                        <input
                            :disabled="!inEditDomain"
                            :value="domain.domain"
                            @change="(e: any) => onChangeDomain(e.target.value)"
                            class="appearance-none bg-transparent border-none outline-none w-ful disabled:bg-transparent disabled:cursor-not-allowed"
                        />

                        <div class="space-x-3">
                            <button
                                v-if="domain.main_domain == 1"
                            >
                                <svg
                                    class="w-5 h-5 fill-yellow-400 stroke-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                    />
                                </svg>
                            </button>

                            <button
                                v-if="domain.main_domain != 1 && domain.status == 'online' && domain.automatic != 1"
                            >
                                <svg
                                    class="w-5 h-5 stroke-yellow-400 fill-none"
                                    v-show="domain.main_domain != 1 && domain.status == 'online' && domain.automatic != 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>

                            <button
                                @click.prevent="() => onEditActiveDomain()"
                                class="text-gray-400"
                                v-show="domain.automatic == 1"
                                v-if="!inEditDomain"
                            >
                                <svg
                                    class="w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                                    />
                                </svg>
                            </button>

                            <button
                                class="text-gray-400"
                                @click.prevent="() => onDesactiveEditActiveDomain(domain.domain, domain.id)"
                                v-else
                            >
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <path fill="currentColor" d="M5.008 2H2.282c-.181 0-.245.002-.275.007c-.005.03-.007.094-.007.275v11.436c0 .181.002.245.007.275c.03.005.094.007.275.007h11.436c.181 0 .245-.002.275-.007c.005-.03.007-.094.007-.275V4.62c0-.13-.001-.18-.004-.204a2.654 2.654 0 0 0-.141-.147L11.73 2.145a2.654 2.654 0 0 0-.147-.141A2.654 2.654 0 0 0 11.38 2h-.388c.005.08.008.172.008.282v2.436c0 .446-.046.607-.134.77a.909.909 0 0 1-.378.378c-.163.088-.324.134-.77.134H6.282c-.446 0-.607-.046-.77-.134a.909.909 0 0 1-.378-.378C5.046 5.325 5 5.164 5 4.718V2.282c0-.11.003-.202.008-.282M2.282 1h9.098c.259 0 .348.01.447.032a.87.87 0 0 1 .273.113c.086.054.156.11.338.293l2.124 2.124c.182.182.239.252.293.338a.87.87 0 0 1 .113.273c.023.1.032.188.032.447v9.098c0 .446-.046.607-.134.77a.909.909 0 0 1-.378.378c-.163.088-.324.134-.77.134H2.282c-.446 0-.607-.046-.77-.134a.909.909 0 0 1-.378-.378c-.088-.163-.134-.324-.134-.77V2.282c0-.446.046-.607.134-.77a.909.909 0 0 1 .378-.378c.163-.088.324-.134.77-.134M6 2.282v2.436c0 .181.002.245.007.275c.03.005.094.007.275.007h3.436c.181 0 .245-.002.275-.007c.005-.03.007-.094.007-.275V2.282c0-.181-.002-.245-.007-.275A2.248 2.248 0 0 0 9.718 2H6.282c-.181 0-.245.002-.275.007c-.005.03-.007.094-.007.275M8 12a2 2 0 1 1 0-4a2 2 0 0 1 0 4m0-1a1 1 0 1 0 0-2a1 1 0 0 0 0 2"/>
                                </svg>  
                            </button>
                        </div>
                    </div>

                    <!--
                    <domain-status
                        :domain-id="domain.id"
                    />
                    -->
                </div>
            </div>

            <div class="w-full space-y-2">
                <label class="text-[10px] font-semibold text-gray-400" for="">
                    Endereço secundários
                </label>

                <div
                    v-for="domain in filterInactiveDomains()"
                    :key="domain.id"
                    class="drop-shadow-md hover:drop-shadow-lg cursor-pointer bg-white rounded-md p-3"
                >
                    <div class="flex justify-between">
                        <span>{{ domain.domain }}</span>

                        <div class="space-x-3">
                            <button
                                v-if="domain.main_domain == 1"
                            >
                                <svg
                                    class="w-5 h-5 fill-yellow-400 stroke-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                    />
                                </svg>
                            </button>

                            <button
                                @click="() => onMainDomain(domain.id)"
                            >
                                <svg
                                    class="w-5 h-5 stroke-yellow-400 fill-none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>

                            <button
                                v-if="domain.automatic != 1"
                                @click="() => onDeleteDomain(domain.id)"
                            >
                                <span class="sr-only">
                                    Delete Domain
                                </span>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <!--
                    <domain-status
                        :domain-id="domain.id"
                    />
                    -->
                </div>
            </div>

            <button
                class="w-1/2 px-5 py-3 bg-[var(--main-color)] text-xs text-white rounded-full font-bold"
                @click="() => onOpen()"
            >
                Adicionar domínio
            </button>

            <div class="w-full text-xs font-semibold text-gray-400">
                Para adicionar endereço .com.br .com ou outros, siga as instruções abaixo:
            </div>
        </div>
    </div>

    <div v-else class="px-6 py-3">
        <div class="w-full flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 animate-spin">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
        </div>
    </div>

    <div class="mt-3 px-6 border-t-2 border-b-[1px]">
        <Disclosure v-slot="{ open }">
            <DisclosureButton class="cursor-pointer w-full py-3 flex justify-between">
                <span class="text-base font-bold">Configurar domínio no Registro.br</span>

                <svg
                    :class="clsx('w-6 h-6', open ? 'rotate-180' : 'rotate-0')"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                </svg>
            </DisclosureButton>

            <Transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="transform opacity-0 h-0"
                enter-to-class="transform opacity-100 h-full"
                leave-active-class="transition duration-200 ease-out"
                leave-from-class="transform opacity-100 h-full"
                leave-to-class="transform opacity-0 h-0"
            >
                <DisclosurePanel class="py-3 text-[10px] text-gray-500">
                    <p>
                        Se você ainda não registrou um domínio, registre pelo site
						<a
							href="http://www.registro.br"
							target="_blank"
							rel="noopener noreferrer"
						>
							<strong>www.registro.br</strong>
						</a>
                    </p>
                    <br>
                    <p>Se já tem domínio registrado, siga os passos abaixo:</p>
                    <ol class="list-decimal pl-6 space-y-3 mt-3 mb-4">
                        <li>Acesse sua conta em 							
                            <a
								href="http://www.registro.br"
								target="_blank"
								rel="noopener noreferrer"
							>
								<strong>www.registro.br</strong>
							</a>
                        </li>
                        <li>Clique no seu domínio</li>
                        <li>Em DNS clique em
                            <strong>» EDITAR ZONA (Configurar Endereçamento)</strong> e <strong>> Modo Avançado</strong><br>
                            <i>Aguarde cerca de 5 minutos e acesse novamente para aparecer opções.</i>
                        </li>
                        <li>Clique em
                            <strong>NOVA ENTRADA</strong>
                            e adicione as informações abaixo:
                            <ul class="list-disc pl-5 space-y-1 mt-3 mb-4">
                                <li>Primeiro Campo deixe vazio</li>
                                <li>Selecione Tipo <strong>A</strong></li>
                                <li>Endereço IP: <strong>18.229.165.166</strong></li>
                                <li>Clique <strong>Adicionar</strong></li>
                            </ul>
                        </li>
                        <li>Novamente clique em <strong>NOVA ENTRADA</strong> e adicione as informações abaixo:
                            <ul class="list-disc pl-5 space-y-1 mt-3 mb-4">
                                <li>Primeiro campo deixe: <strong>www</strong></li>
                                <li>Selecione tipo <strong>CNAME</strong></li>
                                <li>Nome do servidor: <strong>seudominio.com.br</strong></li>
                                <li>Clique <strong>Adicionar</strong></li>
                            </ul>
                        </li>
                    </ol>

                    <img
                        src="@/assets/images/dominio-zona-dns-registro-br.png"
                        alt="domain-registro-br"
                    />

                    <br />

                    <p>*seudominio significa o domínio que você registrou.</p>
                    <p>
                        Clique em
                        <b>SALVAR</b>
                    </p>

                    <br />

                    <p class="text-center">
                        Depois, adicione seu domínio clicando no botão abaixo:
                    </p>

                    <div class="mt-2 mb-3 w-full flex justify-center">
                        <button
                            class="w-1/2 px-5 py-3 bg-[var(--main-color)] text-xs text-white rounded-full font-bold"
                            @click="() => onOpen()"
                        >
                            Adicionar domínio
                        </button>
                    </div>

                    <p>
                        Se não conseguir seguindo os passos acima, tente direto no servidor que
                        registrou o domínio (dicas abaixo), entre em contato com a gente: (17)
                        3223-5208 pelo email: contato@ulisite.com ou veja esse
                        <b>Tutorial</b>
                    </p>
                </DisclosurePanel>
            </Transition>
        </Disclosure>
    </div>

    <div class="px-6 border-t-[1px] border-b-2">
        <Disclosure v-slot="{ open }">
            <DisclosureButton class="cursor-pointer w-full py-3 flex justify-between">
                <span class="text-base font-bold">Configurar domínio no GoDaddy</span>

                <svg
                    :class="clsx('w-6 h-6', open ? 'rotate-180' : 'rotate-0')"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                </svg>
            </DisclosureButton>

            <Transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="transform opacity-0 h-0"
                enter-to-class="transform opacity-100 h-full"
                leave-active-class="transition duration-200 ease-out"
                leave-from-class="transform opacity-100 h-full"
                leave-to-class="transform opacity-0 h-0"
            >
                <DisclosurePanel class="py-3 text-[10px] text-gray-500">
                    <p>
                        Clique no seu domínio > Gerenciar > Gerenciar DNS > Adicionar > insira as
                        informações abaixo:
                    </p>

                    <br />

                    <p>Selecione Tipo “A”</p>

                    <p>
                        <b>Host:</b>
                        @
                    </p>

                    <p>
                        <b>Aponta para:</b>
                        18.229.165.166
                    </p>

                    <p>
                        <b>TTL:</b>
                        1 hora
                    </p>

                    <p>Adicione Nova entrada > Tipo "CNAME"</p>

                    <p>
                        <b>Valor:</b>
                        www
                    </p>

                    <p>
                        <b>Caminho:</b>
                        seudominio.com.br
                    </p>

                    <p>*substitua "seudominio.com.br" pelo seu endereço personalizado</p>

                    <p>Depois, adicione seu domínio clicando no botão abaixo:</p>

                    <br />

                    <p class="text-center">
                        Depois, adicione seu domínio clicando no botão abaixo:
                    </p>

                    <div class="mt-2 mb-3 w-full flex justify-center">
                        <button
                            class="w-1/2 px-5 py-3 bg-[var(--main-color)] text-xs text-white rounded-full font-bold"
                            @click="() => onOpen()"
                        >
                            Adicionar domínio
                        </button>
                    </div>
                </DisclosurePanel>
            </Transition>
        </Disclosure>
    </div>
</template>

<style></style>
