<script setup lang="ts">
import clsx from 'clsx';

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: [String, Number]
    },
    lock: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['change'])

const onChangeFontWeight = (event: any) => {
    emit('change', {
        target: 'font-weight',
        value: event.target.value
    })
}

const onChangeLock = () => {
    emit('change', {
        target: 'style_font_weight_lock',
        value: !props.lock
    })
}

const options = [
    {
        id: 1,
        value: '100',
        label: 'thin'
    },
    {
        id: 2,
        value: '200',
        label: 'extra-light'
    },
    {
        id: 3,
        value: '300',
        label: 'light'
    },
    {
        id: 4,
        value: '400',
        label: 'normal'
    },
    {
        id: 5,
        value: '500',
        label: 'medium'
    },
    {
        id: 6,
        value: '600',
        label: 'semi-bold'
    },
    {
        id: 7,
        value: '700',
        label: 'bold'
    },
    {
        id: 8,
        value: '800',
        label: 'extra-bold'
    },
    {
        id: 9,
        value: '900',
        label: 'black'
    }
]
</script>

<template>
    <div class="space-y-2">
        <div class="w-full flex items-center justify-between">
            <span class="text-sm font-semibold text-[#060606] opacity-50">
                {{ label }}
            </span>

            <button
                @click="() => onChangeLock()"
            >
                <svg v-if="lock" :class="clsx('w-[14px] h-[14px] stroke-[#060606]')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none" stroke="currentColor">
                    <path d="M3.85005 5.13336V4.60002C3.85005 2.82733 5.25505 1.40002 7.00005 1.40002C8.74505 1.40002 10.15 2.82733 10.15 4.60002V5.13336M3.85005 5.13336C3.27255 5.13336 2.80005 5.61336 2.80005 6.20002V11.5334C2.80005 12.12 3.27255 12.6 3.85005 12.6H10.15C10.7275 12.6 11.2 12.12 11.2 11.5334V6.20002C11.2 5.61336 10.7275 5.13336 10.15 5.13336M3.85005 5.13336H10.15" stroke-width="1.5" stroke-linecap="round"/>
                </svg>

                <svg v-else class="w-[14px] h-[14px] stroke-[#060606]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
                    <g opacity="0.1">
                        <path d="M3.71855 4.5999C3.71855 2.8272 5.12355 1.3999 6.86855 1.3999C7.95555 1.3999 8.91062 1.95374 9.47633 2.7999M3.8498 5.13324C3.2723 5.13324 2.7998 5.61324 2.7998 6.1999V11.5332C2.7998 12.1199 3.2723 12.5999 3.8498 12.5999H10.1498C10.7273 12.5999 11.1998 12.1199 11.1998 11.5332V6.1999C11.1998 5.61324 10.7273 5.13324 10.1498 5.13324H3.8498Z" stroke-width="1.5" stroke-linecap="round"/>
                    </g>
                </svg>
            </button>
        </div>
        

        <div class="w-full flex items-center justify-between space-x-4">
            <div class="w-full">
                <select
                    @change="onChangeFontWeight"
                    class="w-full text-sm bg-[#fcfcfc] outline-none px-4 py-3 rounded border-[#F5F5F5] border-2 appearance-none"
                    id="font-weight"
                    :disabled="lock"
                >
                    <option
                        v-for="option in options"
                        :key="option.id"
                        :value="option.value"
                        :selected="option.value == value"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

