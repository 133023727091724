<script lang="ts" setup>
import { computed } from 'vue'

import { useMediaManagerStore } from '@/stores/modal/media-manager'

const mediaManagerStore = useMediaManagerStore()

const activeFilterMedias = computed(() => mediaManagerStore.activeFilterMedias)
</script>

<template>
    <component :is="activeFilterMedias.body"></component>
</template>
