import { ref, watch, markRaw, shallowRef } from 'vue'

import { defineStore } from 'pinia'
import api from '@/services/api'
import { useSiteStore } from '../site'
import notification, { type ToastId } from '@/services/notification'

import IconFilterImages from '@/components/atoms/icons/IconFilterImages.vue'
import IconFilterVideos from '@/components/atoms/icons/IconFilterVideos.vue'

import MediaImageOptions from '@/components/molecules/MediaImageOptions.vue'
import MediaVideoOptions from '@/components/molecules/MediaVideoOptions.vue'

import MediaImageBody from '@/components/molecules/MediaImageBody.vue'
import MediaVideoBody from '@/components/molecules/MediaVideoBody.vue'

/**
 * @description Media Manager Store
 */
export const useMediaManagerStore = defineStore('media-manager', () => {
    const siteStore = useSiteStore()

    // states
    const closeDisabled = ref(false)
    const finishedUpload = ref(false)

    const fileIDs = ref<any[]>([])
    
    const notificationAlertId = ref<string | number | null>(null)
    const isOpenModal = ref(false)

    const loading = ref(false)
    const mediasLoading = ref(false)

    const isOverDropZone = ref(false)

    const medias = ref<any[]>([])
    const mediasForLoading = ref<any[]>([
        {
            id: 0
        },
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        },
        {
            id: 6
        },
        {
            id: 7
        },
        {
            id: 8
        },
        {
            id: 9
        },
        {
            id: 10
        },
        {
            id: 11
        },
        {
            id: 12
        },
        {
            id: 13
        },
        {
            id: 14
        },
        {
            id: 15
        },
        {
            id: 16
        },
        {
            id: 17
        },
        {
            id: 18
        },
        {
            id: 19
        },
        {
            id: 20
        }
    ])

    const filesWithError = ref<any[]>([])
    const mediasUploaded = ref<any[]>([])

    const fileProgressShow = ref(false);
    const filesUploaded = ref(0);
    const filesTotal = ref(0);
    const filesTotalProgress = ref(0);
    const filesUploadedWithError = ref(0);

    const filtersMedias = ref<any[]>([
        {
            id: 1,
            name: 'Imagens',
            icon: markRaw(IconFilterImages),
            options: shallowRef(MediaImageOptions),
            body: shallowRef(MediaImageBody),
            value: 'image'
        },
        {
            id: 2,
            name: 'Vídeos',
            icon: markRaw(IconFilterVideos),
            options: shallowRef(MediaVideoOptions),
            body: shallowRef(MediaVideoBody),
            value: 'video'
        }
    ])

    const orderMedias = ref<any[]>([
        {
            id: 1,
            name: 'Mais recentes',
            sort: 'created_at',
            order: 'desc',
        },
        {
            id: 2,
            name: 'Mais antigos',
            sort: 'created_at',
            order: 'asc',
        },
        {
            id: 3,
            name: 'Nome A-Z',
            sort: 'name',
            order: 'asc'
        },
        {
            id: 4,
            name: 'Nome Z-A',
            sort: 'name',
            order: 'desc'
        }
    ])

    const activeFilterMedias = ref<any>(filtersMedias.value[0])
    const activeOrderMedias = ref(orderMedias.value[0]);
    const activeSearchMedias = ref('')

    const selectedMedias = ref<any[]>([])

    const maxSelectedMedias = ref(100)

    const nextUrl = ref<string | null>(null)

    const action = ref<Function | null>()
    // fim states

    // actions
    const setIsOpenModal = (value: boolean) => {
        isOpenModal.value = value
    }

    const setMedias = (value: any[]) => {
        medias.value = value
    }

    const addMediasUploaded = (value: any) => {
        mediasUploaded.value = [...mediasUploaded.value, value]
    }

    const addMedias = (values: any, position: string = 'after') => {
        if (position == 'after') {
            medias.value = [...medias.value, ...values]
            return
        }

        if (position == 'before') {
            medias.value = [...values, ...medias.value]
            return
        }
    }

    const setLoading = (value: boolean) => {
        loading.value = value
    }

    const setMediasLoading = (value: boolean) => {
        mediasLoading.value = value
    }

    const setNextUrl = (value: string) => {
        nextUrl.value = value
    }

    const setNotificationAlertId = (value: string | number | null) => {
        notificationAlertId.value = value
    }

    const mountUrlMedias = (filetype: string, sort?: string, order?: string, search?: string) => {
        const url = `site/${siteStore.id}/file/list?filetype=${filetype}&sort=${sort}&order=${order}&search=${search}`

        return url
    }

    const setSearchMedias = (value: string) => {
        activeSearchMedias.value = value

        fetchMedias(
            activeFilterMedias.value.value,
            activeOrderMedias.value.sort,
            activeOrderMedias.value.order,
            activeSearchMedias.value
        )
    }

    const fetchMedias = async (filetype: string, sort?: string, order?: string, search?: string) => {
        setMediasLoading(true)

        const url = mountUrlMedias(filetype, sort, order, search)

        const response = await api.get(url)

        const { status, content, next } = await response.json()

        if (status != '1') {
            throw new Error('Error on fetch medias')
        }

        setMedias(content)
        setNextUrl(next)

        setMediasLoading(false)
    }

    const fetchNextMedias = async () => {
        setLoading(true)
        console.log('fetchNextMedias')

        if (!nextUrl.value) {
            setLoading(false)
            return
        }

        // TODO: precisa remover o / no inicio da url next
        const response = await api.get(nextUrl.value.substring(1))

        const { status, content, next } = await response.json()

        if (status != '1') {
            throw new Error('Error on fetch medias')
        }

        addMedias(content)

        setNextUrl(next)

        setLoading(false)
    }

    const selectMedia = (media: any) => {
        const isDeselect = isMediaSelected(media)

        if (isDeselect) {
            return (selectedMedias.value = selectedMedias.value.filter(item => item != media))
        }

        const inLimit = verifyLimitSelectedMedias()

        if (inLimit) {
            return
        }

        selectedMedias.value.push(media)
    }

    const isMediaSelected = (media: any) => {
        return selectedMedias.value.find(item => item.id == media.id || item.content == media.content) ? true : false
    }

    const setMaxSelectedMedias = (value: number) => {
        maxSelectedMedias.value = value
    }

    const setAction = (value: Function | null) => {
        action.value = value
    }

    const setActiveFilterMedias = (value: any) => {
        activeFilterMedias.value = value

        fetchMedias(
            activeFilterMedias.value.value,
            activeOrderMedias.value.sort,
            activeOrderMedias.value.order,
            activeSearchMedias.value
        )
    }

    const verifyLimitSelectedMedias = () => {
        if (selectedMedias.value.length >= maxSelectedMedias.value) {
            if (notificationAlertId.value) {
                return true
            }

            const notificationId = notification.simple(
                'Você atingiu o limite máximo de mídias selecionadas',
                'warning'
            )

            setNotificationAlertId(notificationId)

            setTimeout(() => {
                setNotificationAlertId(null)
            }, 5000)

            return true
        }

        return false
    }

    const handleUnselectAllMedias = () => {
        selectedMedias.value = []
    }

    const handleSelectAllMedias = () => {
        if (maxSelectedMedias.value < medias.value.length) {
            selectedMedias.value = medias.value.slice(0, maxSelectedMedias.value)

            return
        }

        selectedMedias.value = medias.value
    }

    const reset = () => {
        isOpenModal.value = false
        loading.value = false
        medias.value = []
        selectedMedias.value = []
        maxSelectedMedias.value = 100
        nextUrl.value = null
        action.value = () => {}
        activeFilterMedias.value = filtersMedias.value[0]
        activeOrderMedias.value = orderMedias.value[0]
        activeSearchMedias.value = ''
    }

    const setOrderMedias = (value: any) => {
        const order = orderMedias.value.find(item => item.id == value)

        activeOrderMedias.value = order

        fetchMedias(
            activeFilterMedias.value.value,
            activeOrderMedias.value.sort,
            activeOrderMedias.value.order,
            activeSearchMedias.value
        )
    }

    const handleAddNewVideo = async (videoUrl: any) => {
        const toastId: ToastId = notification.loading('Adicionando vídeo...');

        try {
            const url = `site/${siteStore.id}/file/create`;

            const data = { uri: videoUrl };

            const response = await api.post(url, JSON.stringify(data));

            const { status, message, video } = await response.json();

            if (status != '1') {
                throw new Error(message);
            }

            const newMedia = {
                content: video.path,
                datetime: video.created_at,
                embed: video.filename,
                extension: video.extension,
                height: video.height,
                id: video.id,
                name: video.name,
                source: "Banco de videos",
                type: video.filetype,
                width: video.width
            }

            notification.update(toastId, 'Vídeo adicionado com sucesso', 'success');

            medias.value = [newMedia, ...medias.value]

            selectedMedias.value.push(newMedia)
        } catch (error) {
            notification.update(toastId, 'Erro ao adicionar vídeo', 'error');
            
            console.error(error);
        }
        
    }

    const deleteMedias = async (mediasForDelete: any) => {
        const id: ToastId = notification.loading('Excluindo mídias...');

        try {
            const url = `site/${siteStore.id}/file/delete`;

            const response = await api.post(
                url,
                JSON.stringify({ files: mediasForDelete })
            );

            const { success, message } = await response.json();

            if (!success) {
                throw new Error(message);
            }

            medias.value = medias.value.filter((item: any) => !mediasForDelete.includes(item))
            selectedMedias.value = selectedMedias.value.filter((item: any) => !mediasForDelete.includes(item))

            // busca as mídias novamente
            await fetchMedias(
                activeFilterMedias.value.value,
                activeOrderMedias.value.sort,
                activeOrderMedias.value.order,
                activeSearchMedias.value
            )

            notification.update(id, 'Sucesso ao excluir mídias', 'success');
        } catch (error) {
            notification.update(id, 'Erro ao excluir mídias', 'error');

            console.error(error);
        }
    }
    // fim actions

    watch(isOpenModal, value => {
        if (value) {
            fetchMedias(
                activeFilterMedias.value.value,
                activeOrderMedias.value.sort,
                activeOrderMedias.value.order,
                activeSearchMedias.value
            )
        } else {
            reset()
        }
    })

    return {
        isOpenModal,
        loading,
        mediasLoading,
        medias,
        mediasForLoading,
        nextUrl,
        selectedMedias,
        maxSelectedMedias,
        action,
        isOverDropZone,
        filtersMedias,
        activeFilterMedias,
        orderMedias,
        activeOrderMedias,
        activeSearchMedias,
        closeDisabled,
        finishedUpload,
        fileIDs,
        fileProgressShow,
        filesUploaded,
        filesTotal,
        filesTotalProgress,
        mediasUploaded,
        filesUploadedWithError,
        filesWithError,
        setIsOpenModal,
        fetchMedias,
        fetchNextMedias,
        selectMedia,
        isMediaSelected,
        setMaxSelectedMedias,
        setAction,
        setActiveFilterMedias,
        handleUnselectAllMedias,
        handleSelectAllMedias,
        reset,
        setOrderMedias,
        setSearchMedias,
        handleAddNewVideo,
        addMedias,
        addMediasUploaded,
        deleteMedias
    }
})
