import '@/assets/main.css'

import { createApp, ref } from 'vue'
import { createPinia } from 'pinia'

import VueGoogleMaps from "@fawmi/vue-google-maps";

import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'

import { vMaska } from 'maska'

import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router'

import { TooltipPlugin } from '@/plugins/tooltip'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(TooltipPlugin)
app.use(Vue3Toasity, {
    autoClose: 3000
} as ToastContainerOptions)
app.use(Vue3ColorPicker)

app.use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      libraries: "places"
    },
})

app.directive('maska', vMaska)

Sentry.init({
  app,
  dsn: "https://037f84ce02285469e210320855af00af@o646378.ingest.us.sentry.io/4507101928030208",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "painel2.epics.vc",
    "painel2.ulisite.com",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app')
