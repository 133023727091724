<script setup lang="ts">
import { computed } from 'vue'

import { useSidebarDashboardStore } from '@/stores/dashboard/sidebar'
import { useApplicationStore } from '@/stores/application'
import DashboardSidebarDivider from '@/components/molecules/DashboardSidebarDivider.vue'
import DashboardSidebarItem from '@/components/molecules/DashboardSidebarItem.vue'
import router from '@/router'

const sidebarDashboardStore = useSidebarDashboardStore()
const applicationStore = useApplicationStore()

function closeSidebar() {
    sidebarDashboardStore.toggleMenu()
}

const sidebarItems = computed(() => {
    return sidebarDashboardStore.items.filter(item => {
        if (item.name === 'Início' && router.currentRoute.value.name === 'dashboard') {
            return false
        }

        if (
            (applicationStore.className == 'epics' && item.name == 'Afiliados')
            || (applicationStore.className == 'epics' && item.name == 'Minhas assinaturas')
        ) {
            return false
        }
        
        return true
    })
})
</script>

<template>
    <aside
        id="dashboard-sidebar"
        v-show="sidebarDashboardStore.getIsOpenMenu"
        class="w-full md:w-[375px] h-screen z-50 fixed top-0 left-0 bottom-0 bg-white shadow-lg overflow-y-auto overflow-x-hidden"
    >
        <template v-if="!sidebarDashboardStore.getMenuOption">
            <div class="w-full flex items-center px-6 py-4">
                <button id="button-close-dashboard-sidebar" @click="closeSidebar">
                    <span class="sr-only">Fechar</span>

                    <svg
                        class="w-5 h-5 text-[var(--main-color)] hover:brightness-100"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>

            <template v-for="(item, key) in sidebarItems" :key="key">
                <dashboard-sidebar-divider />

                <dashboard-sidebar-item
                    :on-click="item.onClick"
                    :name="item.name"
                    :icon="item.icon"
                    :has-arrow="item.hasArrow"
                    :is-link="item.isLink"
                />
            </template>
        </template>

        <template v-else>
            <div class="w-full flex items-center px-6 py-4">
                <button
                    id="button-back-component-sidebar"
                    @click="sidebarDashboardStore.setMenuOption(null)"
                >
                    <span class="sr-only">Voltar</span>

                    <svg
                        class="w-5 h-5 text-[var(--main-color)] hover:brightness-100"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M15 19l-7-7 7-7"></path>
                    </svg>
                </button>
            </div>

            <component :is="sidebarDashboardStore.activeComponentMenuOption" />
        </template>
    </aside>

    <div
        id="dashboard-sidebar-backdrop"
        v-show="sidebarDashboardStore.getIsOpenMenu"
        @click="closeSidebar"
        class="z-40 fixed inset-0 bg-black opacity-60"
    ></div>
</template>

<style></style>
