import { ref } from 'vue'

import { defineStore } from 'pinia'

import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'

/**
 * @description Auth Store
 */
export const useAuthStore = defineStore('auth', () => {
    const router = useRouter()

    const userStore = useUserStore()

    const accessTypes = ref([
        {
            name: 'default',
            pass: '',
        },
        {
            name: 'admin',
            pass: '3P1C5',
        }
    ])

    const accessType = ref(accessTypes.value[0].name)
    // states
    // fim states

    // actions
    const checkToken = async () => {
        try {
            const payload = JSON.parse(atob(userStore.token.split('.')[1]))

            const currentTimestamp = Math.floor(Date.now() / 1000)

            if (payload.exp < currentTimestamp) {
                throw new Error('Token expired')
            }

            return true
        } catch (error) {
            console.warn(error)

            return false
        }
    }

    const checkAccessType = () => {
        const pass = router.currentRoute.value.query.access

        if (pass) {
            const access = accessTypes.value.find((item) => item.pass === pass)

            if (access) {
                accessType.value = access.name

                return true
            }
        }
    }
    // fim actions

    return {
        // disponibiliza as states
        accessType,
        // disponibiliza as actions
        checkToken,
        checkAccessType
    }
})
