<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingText from '@/components/atoms/icons/IconSettingText.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingFontSize from '@/components/atoms/SettingFontSize.vue';
import SettingFontWeight from '@/components/atoms/SettingFontWeight.vue';
import SettingColorPicker from '@/components/atoms/SettingColorPicker.vue';

import SettingWidth from '@/components/atoms/SettingWidth.vue'
import SettingTextAlign from '@/components/atoms/SettingTextAlign.vue';
import SettingMargin from '@/components/atoms/SettingMargin.vue';
import SettingPadding from '@/components/atoms/SettingPadding.vue';

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'
import { useLayoutStore } from '@/stores/layout'

const editorPageStore = useEditorPageStore()
const layoutStore = useLayoutStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()

}

const onChangeMargin = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin: {
                ...editorPageStore.elementForEdit.extra.margin,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile: {
                ...editorPageStore.elementForEdit.extra.margin_mobile,
                [event.target]: event.value
            }
        }
        
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangePadding = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding: {
                ...editorPageStore.elementForEdit.extra.padding,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile: {
                ...editorPageStore.elementForEdit.extra.padding_mobile,
                [event.target]: event.value
            }
        }
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const settings = ref([
    {
        id: 1,
        name: 'title',
        icon: markRaw(IconSettingText)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])

const onChangeStyle = (event: any) => {
    if (event.target == 'font-size') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra?.style,
                fontSize: event.value
            }
        }
    }

    if (event.target == 'font-weight') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra?.style,
                fontWeight: event.value
            }
        }
    }

    if (event.target == 'text-align') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra?.style,
                textAlign: event.value
            }
        }
    }

    if (event.target == 'style_font_size_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_font_size_lock: event.value
        }
    }

    if (event.target == 'style_font_weight_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_font_weight_lock: event.value
        }
    }

    if (event.target == 'style_text_align_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_text_align_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangeColor = (value: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        style: {
            ...editorPageStore.elementForEdit.extra?.style,
            color: value
        }
    }

    editorPageStore.updateElementForEdit()
}
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #title>
            <settings-layout
                name="Configurações do Título"
                :default-open="true"
            >
                <setting-color-picker
                    label="Cor do Texto"
                    :value="elementForEdit.extra?.style?.color"
                    :default-value="
                        layoutStore.getSectionTextColorDefault(elementForEdit.sectionIndex) ?? ''
                    "
                    @change="onChangeColor"
                />

                <setting-font-size
                    label="Tamanho da Fonte"
                    :value="elementForEdit.extra?.style?.fontSize"
                    :lock="elementForEdit.extra?.style_font_size_lock"
                    @change="onChangeStyle"
                />

                <setting-font-weight
                    label="Espessura da Fonte"
                    :value="elementForEdit.extra?.style?.fontWeight"
                    :lock="elementForEdit.extra?.style_font_weight_lock"
                    @change="onChangeStyle"
                />
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                name="Configurações do Conteúdo"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit.grid"
                    :value-mobile="elementForEdit.gridMobile"
                    :values-permission-desktop="[4, 6, 12]"
                    :values-permission-mobile="[4, 6, 12]"
                    @change="onChangeWidth"
                />

                <setting-text-align
                    label="Alinhamento"
                    :value="elementForEdit.extra?.style?.textAlign"
                    :lock="elementForEdit.extra?.style_text_align_lock"
                    @change="onChangeStyle"
                />
            </settings-layout>

            <settings-layout
                name="Avançado"
            >
                <setting-margin
                    label="Margem Externa"
                    :value="elementForEdit.extra?.margin"
                    :value-mobile="elementForEdit.extra?.margin_mobile"
                    :lock="elementForEdit.extra?.margin_mobile_lock"
                    @change="onChangeMargin"
                />

                <setting-padding
                    label="Margem Interna"
                    :value="elementForEdit.extra?.padding"
                    :value-mobile="elementForEdit.extra?.padding_mobile"
                    :lock="elementForEdit.extra?.padding_mobile_lock"
                    @change="onChangePadding"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
