<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'

import clsx from 'clsx'

import notification, { type ToastId } from '@/services/notification'

import MasonryWall from '@yeger/vue-masonry-wall'
import BaseModal from '@/components/molecules/BaseModal.vue'

import { useMakePagesStore } from '@/stores/modal/make-pages'
import { useDashboardStore } from '@/stores/dashboard'

const dashboardStore = useDashboardStore()
const makePagesStore = useMakePagesStore()

const pageName = ref('')

const filtersPage = computed(() => makePagesStore.filtersPage)
const loadingCombinations = computed(() => makePagesStore.loadingCombinations)
const step = computed(() => makePagesStore.step)
const filterActive = computed(() => makePagesStore.filterActive)
const combinationActive = computed(() => makePagesStore.combinationActive)
const hasFilterSteps = computed(() => !!makePagesStore.filterActive.steps)
const hasFilterDescription = computed(() => !!makePagesStore.filterActive.description)
const filterStep = computed(() => makePagesStore.filterStep)
const isOpenModal = computed(() => makePagesStore.isOpenModal)
const loadingCreatePage = computed(() => makePagesStore.loadingCreatePage)

const combinationsFiltered = computed(() => {
    if (makePagesStore.loadingCombinations) return

    if (filterActive.value.id === 1) {
        return makePagesStore.combinationsDefault
    }

    if (filterActive.value.id === 10) {
        return []
    }

    if (hasFilterSteps.value && filterStep.value >= 1) {
        const filterFn =
            filterActive.value.id === 2
                ? (combination: any) => combination.hasComments || combination.hasSidebar
                : (combination: any) => !combination.hasComments && !combination.hasSidebar

        return makePagesStore.combinations[
            filterActive.value.steps[filterStep.value].component
        ].filter(filterFn)
    }

    return makePagesStore.combinations[makePagesStore.filterActive.component]
})

const disabledButtonCreatePage = computed(() => {
    if (loadingCreatePage.value || hasPageWithName.value) return true

    return !pageName.value || (!combinationActive.value[filterStep.value]?.id && filterActive.value.component != 'link')
})

const disabledButtonNextStep = computed(() => {
    if (hasFilterSteps.value && !combinationActive.value[filterActive.value.steps.length - 1]?.id)
        return true

    if (filterActive.value.id === 10) return false

    return !combinationActive.value[filterStep.value]?.id
})

const handleClickFilter = (filter: any) => {
    makePagesStore.setFilterStep(0)
    makePagesStore.setFilterActive(filter)
    makePagesStore.setCombinationActive(null, filterStep.value)
}

const handleClickCombination = (combination: any) => {
    makePagesStore.setCombinationActive(combination, filterStep.value)

    if (hasFilterSteps.value) {
        makePagesStore.setFilterStep(1)
    }
}

const handlePreviousStep = () => {
    pageName.value = ''
    makePagesStore.previousStep()
}

const handleNextStep = () => {
    makePagesStore.nextStep()
}

const hasPageWithName = computed(() => {
    return dashboardStore.pages.some((page) => page.name === pageName.value)
})

const handleCreatePage = async () => {
    const toastId: ToastId = notification.loading('Criando página...')

    makePagesStore.setLoadingCreatePage(true)

    const typePage = filterActive.value.id == 1 
        ? combinationActive.value[filterStep.value]?.component
        : filterActive.value.component

    const status = await dashboardStore.createPage({
        name: pageName.value,
        target: 0,
        type: typePage,
        options: filterActive.value.options,
        combination: combinationActive.value
    })

    if (!status) {
        return notification.update(toastId, 'Não foi possível criar a página!', 'error')
    }

    handleReset()

    makePagesStore.setLoadingCreatePage(false)

    return notification.update(toastId, 'Página criada com sucesso!', 'success')
}

const handleReset = () => {
    pageName.value = ''
    makePagesStore.reset()
}

onMounted(() => {
    makePagesStore.setFilterActive(filtersPage.value[0])
})
</script>

<template>
    <base-modal
        @modal-closed="handleReset()"
        :open-watch="isOpenModal"
        title="Criador de páginas"
        size="full"
        :closed-disabled="loadingCreatePage"
    >
        <template #trigger="{ open }">
            <slot name="trigger" :open="open"></slot>
        </template>

        <template #header>
            <div class="h-full flex items-center text-sm">
                <p v-if="step === 0">- Escolha um tipo de página</p>
                <p v-else-if="step === 1">
                    - Defina o nome da página do tipo:
                    <span class="font-bold">{{ filterActive.name }}</span>
                </p>
            </div>
        </template>

        <template #body>
            <template v-if="step === 0">
                <div
                    class="sticky -top-4 py-1 h-auto w-full flex flex-col overflow-x-scroll bg-white z-10"
                >
                    <div class="mb-3 space-x-2">
                        <button
                            v-for="(filter, index) in filtersPage"
                            :key="index"
                            :class="
                                clsx(
                                    'py-1 px-2 whitespace-nowrap relative bg-transparent focus:outline-none cursor-pointer',
                                    filterActive.id === filter.id &&
                                        'after:h-1.5 after:w-1.5 after:content-[\'\'] after:bg-[var(--main-color)] after:absolute after:bottom-0 after:left-1/2 after:transform after:-translate-x-1/2 after:rounded-full after:transition-all after:duration-300 after:ease-in-out'
                                )
                            "
                            @click="handleClickFilter(filter)"
                        >
                            <span
                                :class="
                                    clsx(
                                        filterActive.id === filter.id
                                            ? 'text-black'
                                            : 'text-gray-400',
                                        'hover:text-black font-medium w-full break-words'
                                    )
                                "
                            >
                                {{ filter.name }}
                            </span>
                        </button>
                    </div>

                    <div
                        class="px-2"
                        v-if="hasFilterDescription"
                        v-html="filterActive.description"
                    ></div>

                    <div class="px-2 space-x-3" v-if="hasFilterSteps">
                        <button
                            v-for="(stepFilter, index) in filterActive.steps"
                            :key="index"
                            :class="clsx('text-xs', filterStep === index && 'font-bold')"
                            @click="() => makePagesStore.setFilterStep(index)"
                            :disabled="filterStep === 0 && !combinationActive[filterStep]?.id"
                        >
                            {{ stepFilter.message }}
                        </button>
                    </div>
                </div>

                <div class="mt-3 relative flex flex-col space-y-3 overflow-y-scroll">
                    <MasonryWall
                        v-if="!loadingCombinations"
                        :items="combinationsFiltered"
                        :max-columns="3"
                        :gap="20"
                    >
                        <template #default="{ item, index }: any">
                            <button
                                @click="() => handleClickCombination(item)"
                                :index="index"
                                :class="
                                    clsx(
                                        'flex p-3 justify-center items-center border-2 w-full h-auto rounded-lg',
                                        combinationActive[filterStep]?.id === item.id &&
                                            'border-[var(--main-color)]'
                                    )
                                "
                            >
                                <img :src="item.picture" :alt="index" />
                            </button>
                        </template>
                    </MasonryWall>

                    <div v-else>...carregando</div>
                </div>
            </template>

            <template v-else-if="step === 1">
                <div>
                    <label class="text-[10px] font-semibold text-gray-400" for="name">
                        Nome da página
                    </label>

                    <input
                        tabindex="1"
                        id="name"
                        name="name"
                        type="text"
                        class="w-full border-b border-gray-300 p-3 focus:outline-none focus:border-[var(--main-color)]"
                        v-model="pageName"
                        placeholder="Ex: Página de Vendas"
                        :readonly="loadingCreatePage"
                    />

                    <span
                        v-if="hasPageWithName"
                        class="text-xs text-red-400"
                    >
                        Já existe uma página com esse nome
                    </span>
                </div>
            </template>

            <button
                v-if="step === 0"
                class="fixed bottom-6 left-1/2 -translate-x-1/2 py-3 px-6 rounded-full bg-[var(--main-color)] focus:outline-none cursor-pointer text-white font-semibold disabled:bg-gray-500 disabled:cursor-not-allowed"
                @click="handleNextStep"
                :disabled="disabledButtonNextStep"
            >
                Continuar
            </button>

            <div v-else-if="step === 1" class="fixed bottom-6 left-1/2 -translate-x-1/2 space-x-3">
                <button
                    class="py-3 px-6 rounded-full focus:outline-none cursor-pointer text-black underline font-semibold disabled:cursor-not-allowed"
                    @click="handlePreviousStep"
                    :disabled="loadingCreatePage"
                >
                    Escolher outro tipo
                </button>

                <button
                    :class="
                        clsx(
                            'py-3 px-6 rounded-full bg-[var(--main-color)] focus:outline-none cursor-pointer text-white font-semibold',
                            !loadingCreatePage &&
                                'disabled:bg-gray-500 disabled:cursor-not-allowed',
                            loadingCreatePage && 'disabled:cursor-wait'
                        )
                    "
                    @click="handleCreatePage"
                    :disabled="disabledButtonCreatePage"
                >
                    <span v-if="!loadingCreatePage">Criar Página</span>

                    <span class="animate-pulse" v-else>Criando...</span>
                </button>
            </div>
        </template>
    </base-modal>
</template>
