import { useModalConfirmationStore } from '@/stores/modal/confirmation'
import { computed, watch } from 'vue'

interface ModalConfirmOptions {
    type?: 'danger' | 'warning' | 'success' | 'info' | 'main'
    textConfirm?: string
    textCancel?: string
}

const modal = {
    confirmation: async (
        title: string,
        message: string,
        { type = 'danger', textConfirm = 'Confirmar', textCancel = 'Cancelar' }: ModalConfirmOptions
    ): Promise<boolean> => {
        const modalConfirmationStore = useModalConfirmationStore()

        modalConfirmationStore.reset()

        modalConfirmationStore.setTitle(title)
        modalConfirmationStore.setMessage(message)
        modalConfirmationStore.setType(type)
        modalConfirmationStore.setTextConfirm(textConfirm)
        modalConfirmationStore.setTextCancel(textCancel)

        const result = computed(() => modalConfirmationStore.result)

        return new Promise((resolve, reject) => {
            modalConfirmationStore.setIsOpenModal(true)

            watch(
                result,
                value => {
                    if (value) {
                        return resolve(true)
                    }

                    return resolve(false)
                },
                { deep: true }
            )
        })
    }
}

export default modal
