<script setup lang="ts">
import DashboardLayout from '@/components/layouts/DashboardLayout.vue'
import api from '@/services/api';

import { ref, onBeforeMount } from 'vue'

import { useSiteStore } from '@/stores/site'
import BaseInputField from '@/components/atoms/BaseInputField.vue';
import notification, { type ToastId } from '@/services/notification';
import modal from '@/services/modal';
import BaseModal from '@/components/molecules/BaseModal.vue';

const siteStore = useSiteStore()

const files = ref<any[]>([])
const file = ref()
const loadingFiles = ref(false)
const loadingAddFile = ref(false)
const hasSubmitFile = ref(false)
const loadingSubmitFile = ref(false)

const newFile = ref({
    id: null,
    file: null,
    name: '',
    path: ''
})

const fetchFiles = async () => {
    loadingFiles.value = true;

    try {
        const response = await api.get(`site/${siteStore.id}/file`);

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error('Error fetching files.');
        }

        files.value = data;
    } catch (error) {
        console.error(error);
    }

    loadingFiles.value = false;
}

onBeforeMount(async () => {
    await fetchFiles();
})

const handleCopyLink = (link: string) => {
    try {
        navigator.clipboard.writeText(link);

        notification.simple('Link copiado para a área de transferência.', 'success');
    } catch (error) {
        console.error(error);

        notification.simple('Erro ao copiar link para a área de transferência.', 'error');
    }
}

const onDeleteFile = async (id: number) => {
    const title = 'Confirmar exclusão'
    const message = 'Deseja realmente excluir este arquivo?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    await handleDeleteFile(id);
}

const handleDeleteFile = async (id: number) => {
    const toastId: ToastId = notification.loading('Excluindo arquivo...');
    
    try {
        const response = await api.delete(`site/${siteStore.id}/file/${id}/delete`);

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error('Error deleting file.');
        }

        await fetchFiles();

        notification.update(toastId, 'Arquivo excluído com sucesso.', 'success');
    } catch (error) {
        console.error(error);

        notification.update(toastId, 'Erro ao excluir arquivo.', 'error');
    }
}

const checkFile = (file: any) => {
    // Verifica se o arquivo é de uma extensão inválida
    const forbid = ['exe', 'sql', 'bat', 'bash', 'bin'];
    let error = '';
    let str = file.name.toLowerCase().split(".");
    let ext = str[str.length - 1];
    
    if (forbid.includes(ext)) {
        error = 'Arquivo do tipo .' + ext + ' não suportado.';
    } else if(file.size > 30*1000*1000){
        error = 'Não é possível enviar um arquivo maior do que 30MB.'
    }

    return error;
}

const slug = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/\(/g, '');
    str = str.replace(/\)/g, '');

    str = str.replace(/[^a-z0-9 -|.]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

const isOpen = ref(false)

const onOpenModal = () => {
    isOpen.value = true
}

const onCloseModal = () => {
    isOpen.value = false

    hasSubmitFile.value = false
}


const handleNewFile = async () => {
    loadingAddFile.value = true;

    const toastId: ToastId = notification.loading('Adicionando arquivo...');

    try {
        newFile.value.path = slug(newFile.value.path);

        if (!newFile.value.name || !newFile.value.path || !newFile.value.file) {
            throw new Error('Preencha todos os campos.');
        }

        const response = await api.post(
            `site/${siteStore.id}/file/create`,
            JSON.stringify(newFile.value)
        );

        const { status } = await response.json();

        if (status != '1') {
            throw new Error('Erro ao adicionar arquivo.');
        }

        newFile.value = {
            id: null,
            file: null,
            name: '',
            path: ''
        }

        notification.update(toastId, 'Arquivo adicionado com sucesso.', 'success');

        await fetchFiles();
    } catch (error: any) {
        console.error(error);

        notification.update(toastId, error, 'error');
    }

    loadingAddFile.value = false;

    onCloseModal()
}

const onChangeFileName = () => {
    newFile.value.path = slug(newFile.value.name);
}

const onChangeFilePath = () => {
    newFile.value.path = slug(newFile.value.path);
}

const submitFile = async (file: any) => {
    loadingSubmitFile.value = true;

    const error = checkFile(file);

    if (error) {
        notification.simple(error, 'error');

        return;
    }

    const formData = new FormData();
    formData.append('upload', file);

    try {
        const { data } = await api.file(
            `site/${siteStore.id}/file/create`,
            formData
        );

        if (data.status != '1') {
            throw new Error('Error uploading file.');
        }

        if (!newFile.value.name) {
            newFile.value.name = file.name;
            newFile.value.path = slug(file.name);
        } else{
            let str = file.name.toLowerCase().split(".");
            let ext = str[str.length - 1];

            newFile.value.path = slug(newFile.value.path + '.' + ext);
        }

        newFile.value.file = data.data;

        hasSubmitFile.value = true;
    } catch (error) {
        console.error(error);
    }

    loadingSubmitFile.value = false;
}

const onChangeFile = () => {
    const value = file.value.files[0];

    submitFile(value);
}

const normalizeName = (name: string) => {
    if (name.length > 15) {
        return name.substring(0, 20) + '...';
    }

    return name;
}
</script>

<template>
    <dashboard-layout id="DashboardFiles">
        <div class="max-w-full py-6 px-10 space-y-5">
            <div class="w-full flex justify-between">
                <div class="flex items-center space-x-3">
                    <h1 class="text-3xl font-bold">Arquivos disponíveis</h1>
                </div>

                <base-modal
                    :open-watch="isOpen"
                    title="Adicionar novo arquivo"
                    size="custom"
                    height="h-[30rem]"
                    width="w-[36rem]"
                    rounded="2xl"
                >
                    <template #trigger>
                        <button
                            @click="() => onOpenModal()"
                            class="px-4 py-2 bg-[var(--main-color)] font-semibold text-white rounded-full"
                        >
                            + Novo
                        </button>
                    </template>

                    <template #body>
                        <div class="h-full flex flex-col justify-between">
                            <div class="flex-1 space-y-3">
                                <base-input-field
                                    label="Nome do arquivo"
                                    id="name"
                                >
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        @input="() => onChangeFileName()"
                                        v-model="newFile.name"
                                    />
                                </base-input-field>

                                <base-input-field
                                    label="Caminho"
                                    id="path"
                                >
                                    <input
                                        type="text"
                                        id="path"
                                        name="path"
                                        @input="() => onChangeFilePath()"
                                        v-model="newFile.path"
                                    />
                                </base-input-field>

                                <template
                                    v-if="!loadingSubmitFile"
                                >
                                    <base-input-field
                                        v-if="!hasSubmitFile"
                                        label="Arquivo"
                                        id="file"
                                    >
                                        <input
                                            ref="file"
                                            type="file"
                                            id="file"
                                            name="file"
                                            @change="() => onChangeFile()"
                                        />
                                    </base-input-field>

                                    <div v-else>
                                        <p>
                                            <b>Arquivo enviado:</b> {{ newFile.name }}
                                        </p>
                                    </div>
                                </template>

                                <template
                                    v-else
                                >
                                    <p>
                                        Enviando arquivo...
                                    </p>
                                </template>
                            </div>

                            <p>
                                Exemplo: {{ siteStore.url + '/downloads/' + newFile.path }}
                            </p>

                            <div class="flex justify-end mt-4 space-x-2">
                                <button
                                    @click="() => onCloseModal()"
                                    class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                                >
                                    Cancelar
                                </button>

                                <button
                                    @disabled="!newFile.name || !newFile.path || !newFile.file || loadingAddFile"
                                    @click="() => handleNewFile()"
                                    class="bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </template>
                </base-modal>
            </div>

            <div v-if="loadingFiles" class="text-center">
                <p>Carregando...</p>
            </div> 

            <div v-else-if="files.length == 0">
                <p>
                    Você ainda não possui arquivos enviados. Clique no botão "Novo" para
                    disponiblizar um novo arquivo.
                </p>
            </div>

            <div v-else>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    <div
                        v-for="file in files"
                        :key="file.id"
                        class="bg-white rounded-lg shadow-md p-5 flex space-x-5 items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-20 h-20">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                        </svg>

                        <div class="w-full">
                            <div class=" flex justify-between items-center">
                                <h2 class="text-lg font-semibold">
                                    {{ normalizeName(file.name) }}
                                </h2>
                                
                                <div class="space-x-3">
                                    <button
                                        @click="() => handleCopyLink(file.domain + file.route_path)"
                                    >
                                        <span class="sr-only">
                                            Copy Link
                                        </span>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                        </svg>
                                    </button>

                                    <button
                                        class="text-red-500"
                                        @click="() => onDeleteFile(file.id)"
                                    >
                                        <span class="sr-only">
                                            Delete
                                        </span>
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <p class="flex items-center space-x-3">
                                <span>URL: </span>

                                <a 
                                    class="text-xs text-gray-500 underline"
                                    :href="'//' + file.domain + file.route_path"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {{file.domain + file.route_path}}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </dashboard-layout>
</template>

<style></style>
