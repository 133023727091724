import { toast, type UpdateOptions, type ToastOptions } from 'vue3-toastify'

export type ToastId = string | number

const notification = {
    simple: (
        message: string,
        type: 'success' | 'error' | 'info' | 'warning',
        options?: ToastOptions
    ): ToastId => {
        return toast(message, {
            type: type,
            ...options
        })
    },
    loading: (message: string, options?: ToastOptions): ToastId => {
        return toast.loading(message, {
            ...options
        })
    },
    update: (
        id: ToastId,
        message: string,
        type: 'success' | 'error' | 'info' | 'warning',
        options: UpdateOptions = {
            autoClose: true,
            closeButton: true,
            closeOnClick: true,
            hideProgressBar: false,
            isLoading: false
        }
    ) => {
        toast.update(id, {
            render: message,
            type: type,
            ...options
        })
    },
    clear: () => {
        toast.clearAll()
    }
}

export default notification
