<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        fill="none"
        stroke="currentColor"
    >
        <path
            d="M4 7L9.00081 11.58L14 7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>