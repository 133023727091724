<script setup lang="ts">
import { defineAsyncComponent, markRaw, onBeforeMount, computed, ref } from 'vue'

import { isDark } from '@/utils/color'

const props = defineProps<{
    element: any
    elementIndex: number
    sectionIndex: number
    sectionBackgroundColor: string
    sectionHasBackgroundImage?: boolean
    isHome?: boolean
    isAbout?: boolean
    isEditable?: boolean
    isChild?: boolean
    elementParent?: any
    elementParentIndex?: number
    schema?: any
}>()

const marginHorizontal = computed(() => {
    if (!props.element.extra || !props.element.extra.margin) return '0px'

    const marginHorizontal = parseInt(props.element.extra.margin.left ?? 0) + parseInt(props.element.extra.margin.right ?? 0)

    return `${marginHorizontal}px`
})

const gridSizeDesktopPorcent = computed(() => {
    if (isFull.value) return

    if ((props.isHome || !props.isEditable) && !props.isAbout) return `100%`

    const porcent = (props.element.grid / 12) * 100

    return `${porcent}%`
})

const gridSizeMobilePorcent = computed(() => {
    if (props.element.grid === 99) return

    if (props.isHome || !props.isEditable) return `100%`

    const porcent = (props.element.gridMobile / 12) * 100
    
    return `${porcent}%`
})

const isMobile = computed(() => {
    return window.innerWidth < 768
})

const component = ref<any>(null)

const isDarkMode = computed(() => {
    return isDark(props.sectionBackgroundColor)
})

const isFull = computed(() => {
    return props.element.grid === 99
})

const dynamicWidth = computed(() => {
    if (isFull.value) return

    if (!isMobile.value) {
        return `calc(${gridSizeDesktopPorcent.value})`
    }

    return `calc(${gridSizeMobilePorcent.value})`
})

onBeforeMount(async () => {
    component.value = await markRaw(defineAsyncComponent(async () => {
        try {
            if (!props.element.frameType) {
                console.error('Elemento não possui frameType:', props.element)

                throw new Error("Elemento não possui frameType.");
            }

            const typeCapitalized =
                props.element.frameType.charAt(0).toUpperCase() + props.element.frameType.slice(1)

            const module = await import(`@/components/molecules/EditorElement${typeCapitalized}.vue`)

            if (!module.default) {
                console.error(`Componente Editor${typeCapitalized}.vue não foi importado corretamente.`)

            throw new Error("Componente não foi importado corretamente.");
            }

            return module.default
        } catch (error) {
            console.error('Erro durante o carregamento assíncrono do componente:', error)

            return await import(`@/components/molecules/EditorElementDefault.vue`);
        }
    }))
})
</script>

<template>
    <component
        v-if="component"
        :id="element.frameId || element.temporaryId"
        :is="component"
        :style="{ 
            width: dynamicWidth,
        }"
        :element="element"
        :extra="element.extra"
        :element-index="elementIndex"
        :section-index="sectionIndex"
        :is-dark-mode="isDarkMode"
        :is-full="isFull"
        :section-background-color="sectionBackgroundColor"
        :section-has-background-image="sectionHasBackgroundImage"
        :is-child="isChild"
        :element-parent="elementParent"
        :element-parent-index="elementParentIndex"
        :is-editable="isEditable"
        :schema="schema"
    />
</template>

<style scoped></style>
