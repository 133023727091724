<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

import draggable from 'vuedraggable';

import { useSiteStore } from '@/stores/site'
import { useDashboardStore } from '@/stores/dashboard'
import { useSidebarDashboardStore } from '@/stores/dashboard/sidebar'
import { useMakePagesStore } from '@/stores/modal/make-pages'
import { useEditPageStore } from '@/stores/modal/edit-pages'

import MakePagesModal from '@/components/organisms/MakePagesModal.vue'
import EditPagesModal from '@/components/organisms/EditPagesModal.vue'
import clsx from 'clsx'

const siteStore = useSiteStore()
const dashboardStore = useDashboardStore()
const sidebarDashboardStore = useSidebarDashboardStore()
const makePagesStore = useMakePagesStore()
const editPageStore = useEditPageStore()

const loadingPages = computed(() => dashboardStore.loadingPages)
const loadingUpdateScripts = computed(() => siteStore.loadingUpdateScripts)

onBeforeMount(async () => {
    await dashboardStore.fetchPages()

    await siteStore.fetchScripts()
})

const handleClickPage = (page: any) => {
    dashboardStore.handleClickPage(page)

    sidebarDashboardStore.toggleMenu()
}

const handleEditPage = (page: any) => {
    // desvincula a referencia dos valores da pagina
    const pageForEdit = JSON.parse(JSON.stringify(page))

    // passa para a ediçao os valores da pagina sem referencia
    editPageStore.setPageForEdit(pageForEdit)

    // abre o modal de ediçao
    editPageStore.setIsOpenModal(true)
}

const onApplyScript = () => {
    siteStore.handleApplyScript()
}

const home = computed(() => dashboardStore.pages.filter((page) => page.isHome)[0])

const formatUrl = (route: string) => {
    if (route.includes('https://') || route.includes('http://')) return route

    return `//${siteStore.url}${route}`
}

const normalizeNamePage = (name: string | null) => {
    if (name === null) {
        name = 'Página sem nome'
    }
    return normalizeName(name);
}

const normalizeName = (name: string) => {
    if (name.length > 25) return `${name.slice(0, 25)}...`

    return name
}

const onCreatePage = () => {
    sidebarDashboardStore.toggleMenu()

    makePagesStore.setIsOpenModal(true)
}
</script>

<template>
    <make-pages-modal />
    <edit-pages-modal />
    
    <div class="px-6">
        <h2 class="text-2xl font-bold">Páginas</h2>

        <template v-if="!loadingPages">
            <div
                class="mt-2 w-full py-4 px-2 border-b-[1px] flex justify-between items-center"
            >
                <div class="flex items-center space-x-6">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 stroke-gray-400">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                    </svg>
                    
                    <button @click="handleClickPage(home)" class="font-light">
                        {{ home.name }}
                    </button>
                </div>

                <div class="flex items-center space-x-3">
                    <a
                        :href="`//${siteStore.url}${home.route}`"
                        target="_blank"
                        class="text-gray-400"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                            />
                        </svg>
                    </a>

                    <button @click="handleEditPage(home)" class="text-gray-400">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-5 h-5"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                            />
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            <draggable
                :list="dashboardStore.pages"
                item-key="index"
                handle=".handle-drag"
                @end="dashboardStore.updatePositionPages()"
            >
                <template #item="{ element }">
                    <div
                        v-if="!element.isHome"
                        class="mt-2 w-full py-4 px-2 border-b-[1px] flex justify-between items-center"
                    >
                        <div class="flex justify-start items-center space-x-6 w-2/3">
                            <button
                                class="cursor-ns-resize handle-drag"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                    />
                                </svg>
                            </button>

                            <button
                                @click="handleClickPage(element)"
                                class="font-light text-left"
                            >
                                {{ normalizeNamePage(element.name) }}
                            </button>
                        </div>

                        <div class="flex items-center space-x-3">
                            <a
                                :href="formatUrl(element.route)"
                                target="_blank"
                                class="text-gray-400"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                    />
                                </svg>
                            </a>

                            <button @click="handleEditPage(element)" class="text-gray-400">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    class="w-5 h-5"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                    />
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </template>
            </draggable>
        </template>

        <div v-else class="flex justify-center items-center w-full h-full">
            <img class="h-64 w-64" src="@/assets/images/Uli-loading.gif" />
        </div>

        <div class="w-full py-4 px-2 border-b-[1px] flex justify-center items-center">
            <button
                @click="() => onCreatePage()"
                class="flex justify-center items-center space-x-3 text-[var(--main-color)]"
            >
                <span class="text-2xl">+</span>

                <span class="text-base font-bold">Criar página</span>
            </button>
        </div>

        <div class="mt-3 px-2">
            <Disclosure>
                <DisclosureButton class="cursor-pointer py-3 float-right text-gray-500">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </DisclosureButton>

                <Transition
                    enter-active-class="transition duration-200 ease-out"
                    enter-from-class="transform opacity-0 h-0"
                    enter-to-class="transform opacity-100 h-full"
                    leave-active-class="transition duration-200 ease-out"
                    leave-from-class="transform opacity-100 h-full"
                    leave-to-class="transform opacity-0 h-0"
                >
                    <DisclosurePanel class="w-full pt-10 space-y-2 pb-3 flex flex-col">
                        <h3 class="text-lg font-bold">Scripts do site</h3>

                        <div class="w-full space-y-1">
                            <label class="text-xs font-semibold text-gray-400" for="scriptHeader">
                                Código dentro da tag. &lt;head&gt;.
                            </label>

                            <textarea
                                v-model="siteStore.scriptHeader"
                                class="w-full border-b-[1px] focus:border-[var(--main-color)] outline-none"
                                name="scriptHeader"
                                id="scriptHeader"
                                cols="30"
                                rows="3"
                            ></textarea>
                        </div>

                        <div class="w-full space-y-1">
                            <label class="text-xs font-semibold text-gray-400" for="scriptFooter">
                                Código dentro da tag. &lt;body&gt;.
                            </label>

                            <textarea
                                v-model="siteStore.scriptFooter"
                                class="w-full border-b-[1px] focus:border-[var(--main-color)] outline-none"
                                name="scriptFooter"
                                id="scriptFooter"
                                cols="30"
                                rows="3"
                            ></textarea>
                        </div>

                        <button
                            :class="
                            clsx(
                                'px-5 py-3 bg-[var(--main-color)] text-base text-white rounded-full font-bold',
                                loadingUpdateScripts && 'disabled:cursor-wait'
                                )
                            "
                            @click="onApplyScript()"
                            :disabled="loadingUpdateScripts"
                        >
                            <span v-if="loadingUpdateScripts">Atualizando...</span>

                            <span v-else>Aplicar Scripts</span>
                        </button>
                    </DisclosurePanel>
                </Transition>
            </Disclosure>
        </div>
    </div>
</template>

<style></style>
