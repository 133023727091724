<script lang="ts" setup>
import { computed } from 'vue'

import BaseModal from '@/components/molecules/BaseModal.vue'

import { useModalConfirmationStore } from '@/stores/modal/confirmation'
import clsx from 'clsx'

const modalConfirmationStore = useModalConfirmationStore()

const title = computed(() => modalConfirmationStore.title)
const message = computed(() => modalConfirmationStore.message)
const textCancel = computed(() => modalConfirmationStore.textCancel)
const textConfirm = computed(() => modalConfirmationStore.textConfirm)
const isOpenModal = computed(() => modalConfirmationStore.isOpenModal)

const buttonClassHighlight = computed(() => {
    switch (modalConfirmationStore.type) {
        default:
        case 'danger':
            return 'text-white bg-red-500 hover:bg-red-600'

        case 'success':
            return 'text-white bg-green-500 hover:bg-green-600'

        case 'warning':
            return 'text-white bg-yellow-500 hover:bg-yellow-600'

        case 'info':
            return 'text-white bg-blue-500 hover:bg-blue-600'

        case 'main':
            return 'text-white bg-[var(--main-color)] hover:brightness-90'
    }
})

const onClickCancel = () => {
    modalConfirmationStore.handleCancel()
}

const onClickConfirm = () => {
    modalConfirmationStore.handleConfirm()
}
</script>

<template>
    <base-modal
        :open-watch="isOpenModal"
        @modal-closed="() => modalConfirmationStore.handleCancel()"
        :show-button-close-header="false"
        :title="title"
        size="custom"
        height="h-[14rem]"
        width="w-[30rem]"
        rounded="2xl"
    >
        <template #body>
            <div class="h-full flex flex-col justify-between">
                <div class="flex-1" v-html="message"></div>

                <div class="flex justify-end mt-4 space-x-2">
                    <button
                        @click="onClickCancel()"
                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none"
                    >
                        {{ textCancel }}
                    </button>

                    <button
                        @click="onClickConfirm()"
                        :class="
                            clsx(
                                buttonClassHighlight,
                                'px-4 py-2 text-sm font-medium rounded-full outline-none'
                            )
                        "
                    >
                        {{ textConfirm }}
                    </button>
                </div>
            </div>
        </template>
    </base-modal>
</template>
