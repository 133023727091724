import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'

import DashboardHome from '@/views/DashboardHome.vue'
import DashboardAdversiments from '@/views/DashboardAdversiments.vue'
import DashboardSeo from '@/views/DashboardSeo.vue'
import DashboardLinkBuilding from '@/views/DashboardLinkBuilding.vue'
import DashboardRedirects from '@/views/DashboardRedirects.vue'
import DashboardFiles from '@/views/DashboardFiles.vue'
import PageEditor from '@/views/PageEditor.vue'
import PageExternalEditor from '@/views/PageExternalEditor.vue'
import PostEditor from '@/views/PostEditor.vue'
import LoginApplication from '@/views/LoginApplication.vue'
import NotFound from '@/views/NotFound.vue'

import auth from '@/middlewares/auth'

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        children: [
            {
                path: '',
                name: 'dashboard',
                component: DashboardHome
            },
            {
                path: 'seo',
                name: 'dashboard.seo',
                component: DashboardSeo
            },
            {
                path: 'publicidades',
                name: 'dashboard.publicidades',
                component: DashboardAdversiments
            },
            {
                path: 'link-building',
                name: 'dashboard.linkBuilding',
                component: DashboardLinkBuilding
            },
            {
                path: 'redirects',
                name: 'dashboard.redirects',
                component: DashboardRedirects
            },
            {
                path: 'files',
                name: 'dashboard.files',
                component: DashboardFiles
            }
        ],
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/post',
        children: [
            {
                path: ':id/edit',
                name: 'post.edit',
                component: PostEditor
            }
        ],
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/page',
        children: [
            {
                path: 'external/:id/edit',
                name: 'page.external.edit',
                component: PageExternalEditor
            },
            {
                path: ':id/edit',
                name: 'page.edit',
                component: PageEditor
            }
        ],
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/login/application',
        name: 'login.application',
        component: LoginApplication
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not.found',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes
})

function nextFactory(context: any, middleware: any, index: any) {
    const nextMiddleware = middleware[index]

    if (!nextMiddleware) {
        return context.next
    }

    const subsequentMiddleware: any = nextFactory(context, middleware, index + 1)

    return nextMiddleware({ ...context, next: subsequentMiddleware })
}

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }

    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = {
        to,
        from,
        next,
        router
    }

    return middleware[0]({ ...context, next: nextFactory(context, middleware, 1) })
})

export default router
