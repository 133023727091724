import { ref, computed, markRaw } from 'vue'
import { defineStore } from 'pinia'
import router from '@/router'

import DashboardSidebarItemContact from '@/components/molecules/DashboardSidebarItemContact.vue'
import DashboardSidebarItemLogos from '@/components/molecules/DashboardSidebarItemLogos.vue'
import DashboardSidebarItemIntegrations from '@/components/molecules/DashboardSidebarItemIntegrations.vue'
import DashboardSidebarItemOpportunities from '@/components/molecules/DashboardSidebarItemOpportunities.vue'
import DashboardSidebarItemPages from '@/components/molecules/DashboardSidebarItemPages.vue'
import DashboardSidebarItemDomains from '@/components/molecules/DashboardSidebarItemDomains.vue'
import DashboardSidebarItemAffiliates from '@/components/molecules/DashboardSidebarItemAffiliates.vue'
import DashboardSidebarItemSubscriptions from '@/components/molecules/DashboardSidebarItemSubscriptions.vue'
import DashboardSidebarItemHelp from '@/components/molecules/DashboardSidebarItemHelp.vue'

import IconDashboard from '@/components/atoms/icons/IconDashboard.vue'
import IconSeo from '@/components/atoms/icons/IconSeo.vue'
import IconContact from '@/components/atoms/icons/IconContact.vue'
import IconLogo from '@/components/atoms/icons/IconLogo.vue'
import IconIntegrations from '@/components/atoms/icons/IconIntegrations.vue'
import IconOpportunities from '@/components/atoms/icons/IconOpportunities.vue'
import IconLinkBuilding from '@/components/atoms/icons/IconLinkBuilding.vue'
import IconDesign from '@/components/atoms/icons/IconDesign.vue'
import IconPages from '@/components/atoms/icons/IconPages.vue'
import IconButtonsAndSeals from '@/components/atoms/icons/IconButtonsAndSeals.vue'
import IconDomains from '@/components/atoms/icons/IconDomains.vue'
import IconRedirects from '@/components/atoms/icons/IconRedirects.vue'
import IconFiles from '@/components/atoms/icons/IconFiles.vue'
import IconAffiliates from '@/components/atoms/icons/IconAffiliates.vue'
import IconSubscriptions from '@/components/atoms/icons/IconSubscriptions.vue'
import IconHelp from '@/components/atoms/icons/IconHelp.vue'
import IconLogout from '@/components/atoms/icons/IconLogout.vue'

import { useDashboardStore } from '../dashboard'
import { useSiteStore } from '../site'
import { useApplicationStore } from '../application'

import api from '@/services/api'

/**
 * @description Sidebar Dashboard Store
 */
export const useSidebarDashboardStore = defineStore('sidebar-dashboard', () => {
    const dashboardStore = useDashboardStore()
    const siteStore = useSiteStore()
    const applicationStore = useApplicationStore()

    // states
    const isOpenMenu = ref(false)

    const menuOption = ref()

    const items = ref([
        {
            name: 'Início',
            icon: markRaw(IconDashboard),
            hasArrow: true,
            isLink: true,
            onClick: () => {
                // console.log('Dashboard')
                dashboardStore.handleClickPage(dashboardStore.defaultPages[1])

                redirectSidebarItem('dashboard')
            }
        },
        {
            name: 'Dados do site (SEO)',
            icon: markRaw(IconSeo),
            hasArrow: true,
            isLink: true,
            onClick: () => {
                // console.log('Dados do site')

                redirectSidebarItem('dashboard.seo')
            }
        },
        {
            name: 'Dados de contato',
            icon: markRaw(IconContact),
            hasArrow: true,
            onClick: () => {
                // console.log('Dados de contato')

                setMenuOption('contact')
            }
        },
        {
            name: 'Logo',
            icon: markRaw(IconLogo),
            hasArrow: true,
            onClick: () => {
                // console.log('Logo')

                setMenuOption('logos')
            }
        },
        {
            name: 'Integrações',
            icon: markRaw(IconIntegrations),
            hasArrow: true,
            onClick: () => {
                // console.log('Integrações')

                setMenuOption('integrations')
            }
        },
        {
            name: 'Oportunidades',
            icon: markRaw(IconOpportunities),
            hasArrow: true,
            onClick: () => {
                setMenuOption('opportunities')
            }
        },
        {
            name: 'Link Building',
            icon: markRaw(IconLinkBuilding),
            hasArrow: true,
            isLink: true,
            onClick: () => {
                redirectSidebarItem('dashboard.linkBuilding')
            }
        },
        {
            name: 'Design',
            icon: markRaw(IconDesign),
            hasArrow: true,
            isLink: true,
            onClick: async () => {
                try {
                    const response = await api.get('site/' + siteStore.id + '/design/access');

                    const { status, message, url } = await response.json();

                    if ( status != '1') {
                        throw new Error(message);
                    }
                    
                    window.location.replace(url + '&urlPanel=' + window.location.host);
                } catch (error) {
                    console.error(error);
                }
            }
        },
        {
            name: 'Páginas',
            icon: markRaw(IconPages),
            hasArrow: true,
            onClick: () => {
                setMenuOption('pages')
            }
        },
        {
            name: 'Banners e Selos',
            icon: markRaw(IconButtonsAndSeals),
            hasArrow: true,
            isLink: true,
            onClick: () => {
                redirectSidebarItem('dashboard.publicidades')
            }
        },
        {
            name: 'Domínios',
            icon: markRaw(IconDomains),
            hasArrow: true,
            onClick: () => {
                setMenuOption('domains')
            }
        },
        {
            name: 'Redirecionamentos',
            icon: markRaw(IconRedirects),
            hasArrow: true,
            isLink: true,
            onClick: () => {
                redirectSidebarItem('dashboard.redirects')
            }
        },
        {
            name: 'Arquivos',
            icon: markRaw(IconFiles),
            hasArrow: true,
            isLink: true,
            onClick: () => {
                redirectSidebarItem('dashboard.files')
            }
        },
        {
            name: 'Afiliados',
            icon: markRaw(IconAffiliates),
            hasArrow: true,
            onClick: () => {
                setMenuOption('affiliates')
            }
        },
        {
            name: 'Minhas assinaturas',
            icon: markRaw(IconSubscriptions),
            hasArrow: true,
            onClick: () => {
                setMenuOption('subscriptions')
            }
        },
        {
            name: 'Ajuda',
            icon: markRaw(IconHelp),
            hasArrow: true,
            onClick: () => {
                setMenuOption('help')
            }
        },
        {
            name: 'Sair',
            icon: markRaw(IconLogout),
            hasArrow: false,
            isLink: true,
            onClick: async () => {
                try {
                    const response = await api.get('site/' + siteStore.id + '/post/imported/update');

                    const { status, message } = await response.json();

                    if ( status != '1') {
                        throw new Error(message);
                    }

                    const urlLogout = applicationStore.appBaseDomain;

                    localStorage.clear();

                    if (urlLogout) {
                        if (urlLogout.includes('epics.vc')) {
                            window.location.replace("https://epics.com.br/dashboard")
                        }

                        window.location.replace("https://" + urlLogout);
                    } else {
                        router.push("/login");
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
    ])

    const itemsComponents = {
        contact: markRaw(DashboardSidebarItemContact),
        logos: markRaw(DashboardSidebarItemLogos),
        integrations: markRaw(DashboardSidebarItemIntegrations),
        opportunities: markRaw(DashboardSidebarItemOpportunities),
        pages: markRaw(DashboardSidebarItemPages),
        domains: markRaw(DashboardSidebarItemDomains),
        affiliates: markRaw(DashboardSidebarItemAffiliates),
        subscriptions: markRaw(DashboardSidebarItemSubscriptions),
        help: markRaw(DashboardSidebarItemHelp)
    }

    const activeComponentMenuOption = ref()
    // fim states

    // getters
    /**
     * @description Get is open menu (REACTIVE)
     * @returns boolean
     */
    const getIsOpenMenu = computed(() => isOpenMenu.value)

    /**
     * @description Get menu option (REACTIVE)
     * @returns string | null
     */
    const getMenuOption = computed(() => menuOption.value)
    // fim getters

    // actions
    /**
     * @description Method to toggle menu
     * @returns void
     */
    function toggleMenu() {
        isOpenMenu.value = !isOpenMenu.value
        menuOption.value = null
    }

    /**
     * @description Method to redirect to sidebar item
     * @param name string
     * @returns void
     */
    async function redirectSidebarItem(name: string) {
        isOpenMenu.value = false
        menuOption.value = null
        // console.log('redirectSidebarItem', name)
        router.push({ name: name })
    }

    /**
     * @description Method to set menu option and open menu
     * @param option string | null
     * @returns void
     */
    function setMenuOption(option: keyof typeof itemsComponents | null) {
        menuOption.value = option
        isOpenMenu.value = true

        const activeComponent = option ? itemsComponents[option] : null

        activeComponentMenuOption.value = activeComponent
    }
    // fim actions

    return {
        // disponibiliza as states
        items,
        activeComponentMenuOption,
        // disponibiliza os getters
        getIsOpenMenu,
        getMenuOption,
        // disponibiliza as actions
        toggleMenu,
        setMenuOption
    }
})
