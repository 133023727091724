import { useUserStore } from '@/stores/user'
import axios, { type AxiosResponse } from 'axios'

async function makeRequest(
    uri: string,
    method: string,
    body: any | null,
    contentType = 'application/json'
): Promise<Response> {
    const userStore = useUserStore()

    const headers = new Headers()
    headers.append('Access-Control-Allow-Origin', '*')
    headers.append('Accept', '*/*')
    headers.append('Content-Type', contentType)

    if (userStore.token) {
        headers.append('Authorization', `Bearer ${userStore.token}`)
    }

    const options: RequestInit = {
        method,
        headers
    }

    if (method !== 'GET' && method !== 'HEAD' && body) {
        options.body = body
    }

    const response = await fetch(`${import.meta.env.VITE_API_URL}/${uri}`, options)

    return response
}

async function makeRequestFile(
    uri: string,
    method: string,
    body: FormData | null
): Promise<AxiosResponse> {
    const userStore = useUserStore()

    const response = await axios(
        `${import.meta.env.VITE_API_URL}/${uri}`,
        {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userStore.token}`,
                'Content-Type': 'multipart/form-data',
            },
            data: body
        }
    );

    return response
}

const api = {
    get: async (uri: string) => {
        return makeRequest(uri, 'GET', null)
    },
    post: async (uri: string, body: any | null, contentType = 'application/json') => {
        return makeRequest(uri, 'POST', body, contentType)
    },
    put: async (uri: string, body: any | null, contentType = 'application/json') => {
        return makeRequest(uri, 'PUT', body, contentType)
    },
    patch: async (uri: string, body: any | null, contentType = 'application/json') => {
        return makeRequest(uri, 'PATCH', body, contentType)
    },
    delete: async (uri: string) => {
        return makeRequest(uri, 'DELETE', null)
    },
    file: async (uri: string, body: any | null) => {
        return makeRequestFile(uri, 'POST', body)
    }
}

export default api
