
<script setup lang="ts">
import { ref } from 'vue'

import clsx from 'clsx'
import type { Component } from 'vue';

type Setting = {
    id: number
    name: string,
    icon: Component
}

const props = defineProps<{
    settings: Setting[]
}>()

const settingActive = ref(1);

const onChangeSetting = (value: number) => {
    settingActive.value = value;
}
</script>

<template>
    <div 
        v-if="settings.length > 1"
        class="w-full flex p-1 bg-[#E0E0E0] rounded"
    >
        <button 
            v-for="setting in settings"
            :key="setting.id"
            :class="clsx(
                'flex items-center justify-center w-1/2 rounded-[2px] py-2 transition-all duration-500',
                settingActive == setting.id 
                    ? 'bg-[#FAFAFA]'
                    : 'opacity-30',
            )"
            @click="onChangeSetting(setting.id)"
        >
            <component :is="setting.icon" />
        </button>
    </div>

    <template
        v-for="setting in settings" 
        :key="setting.id"
    >
        <slot
            v-if="setting.id == settingActive"
            :name="setting.name"
        ></slot>
    </template>
</template>

<style scoped>

</style>