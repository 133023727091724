import { computed, reactive, ref, toRefs, nextTick } from 'vue'

import { defineStore } from 'pinia'

import api from '@/services/api'

import { useSiteStore } from '@/stores/site'
import { useLayoutStore } from '@/stores/layout'
import { useEditorDefaultStore } from '@/stores/editor/default'
import { useDashboardStore } from '@/stores/dashboard'

import { useRouter } from 'vue-router'
import notification, { type ToastId } from '@/services/notification'
import { rand } from '@vueuse/core'
import moment from 'moment'

/**
 * @description Editor Page Store
 */
export const useEditorPageStore = defineStore('editor-page', () => {
    const router = useRouter()

    // stores
    const siteStore = useSiteStore()
    const layoutStore = useLayoutStore()
    const editorDefaultStore = useEditorDefaultStore()
    const dashboardStore = useDashboardStore()
    // fim stores

    // states
    const state = reactive({
        disabledPublish: ref(false),
        postStep: ref(1),
        activePage: ref<any>(null),
        deletedFrames: ref<any>([]),
        deletedSections: ref<any>([]),
        loadingGetPage: ref(false),
        loadingPorcentGetPage: ref(0),
        framesActives: ref<any>([]),
        sectionsRebuild: ref(false),
        elementsRebuild: ref(false),
        isEditableContent: ref(true),
        sectionForEdit: ref<any>(null),
        elementForEdit: ref<any>(null),
        editorDisabled: ref(false),
        schedule: ref<boolean>(false),
        thumbnailIsAutoSelect: ref<boolean>(false),
    })
    // fim states

    // getters
    const sections = computed(() => state.activePage.value?.content)
    // fim getters

    // actions
    const setSectionsRebuild = (value: boolean) => {
        state.sectionsRebuild = value
    }

    const setElementsRebuild = (value: boolean) => {
        state.elementsRebuild = value
    }

    const setActivePage = (page: any) => {
        state.activePage = page
    }

    const setLoadingGetPage = (value: boolean) => {
        state.loadingGetPage = value
    }

    const setLoadingPorcentGetPage = (value: number) => {
        state.loadingPorcentGetPage = value
    }

    const getPage = async (id: string | number) => {
        setLoadingGetPage(true)
        setLoadingPorcentGetPage(10)

        try {
            const response = await api.get(`site/${siteStore.id}/post/${id}/edit`)

            const { status, data } = await response.json()

            setLoadingPorcentGetPage(40)

            if (status != '1') {
                throw new Error('Error on get page')
            }

            setLoadingPorcentGetPage(70)

            setActivePage({
                ...data,
                id: id
            })

            await layoutStore.getLayout()

            setLoadingPorcentGetPage(100)
        } catch (error) {
            setLoadingPorcentGetPage(0)

            console.warn(error)
        }

        setLoadingGetPage(false)
    }

    const deleteSection = async (section: any) => {
        if (section.new) {
            state.deletedSections.push(section)

            return
        }

        try {
            const url = `site/${siteStore.id}/post/${state.activePage.id}/card/${section.frameDataId}`;
            const response = await api.delete(url);

            const data = await response.json();

            if (data.status != '1') {
                throw new Error('Error on delete section');
            }

            state.deletedSections.push(section)
        } catch (error) {
            console.warn(error)
        }
    }

    const removeSection = async (sectionIndex: number) => {
        // TIMEOUT PARA ANIMAÇÃO
        setTimeout(() => {
            state.activePage.content.splice(sectionIndex, 1)

            setSectionsRebuild(true)
        }, 1000)
    }

    const createSection = (section: any, position?: number) => {
        if (!position) {
            position = state.activePage.content.length
        }

        state.activePage.content.splice(position, 0, section)

        setSectionsRebuild(true)
    }

    const getSectionExtra = (sectionIndex: number) => {
        return state.activePage?.content[sectionIndex]?.extra ?? null
    }

    const upSection = async (sectionIndex: number) => {
        const movedSection = state.activePage?.content.splice(sectionIndex, 1)[0]

        sectionIndex -= 1

        state.activePage?.content.splice(sectionIndex, 0, movedSection)

        // setSectionsRebuild(true)
        await nextTick(() => setSectionsRebuild(true))
    }

    const checkIsBlankPost = () => {
        const titleAndText = state.activePage.content[0].frameContent.filter(
            (item: any) => item.frameType === "title" || item.frameType === "text"
        );

        if (titleAndText.length === state.activePage.content.length) {

            return titleAndText.every(
                (item: any) => item.frameContent == null || item.frameContent.length < 1 || item.frameContent.replace(/<[^>]*>/g, '').trim() == ''
            );
        }

        return false;
    }

    const nextPostStep = () => {
        state.postStep += 1

        state.disabledPublish = true

        // Evita click duplo no botao de publicar
        setTimeout(() => {
            state.disabledPublish = false
        }, 1000)
    }

    const previousStep = () => {
        state.postStep -= 1
    }

    const downSection = async (sectionIndex: number) => {
        const movedSection = state.activePage?.content.splice(sectionIndex, 1)[0]

        sectionIndex += 1

        state.activePage?.content.splice(sectionIndex, 0, movedSection)

        // setSectionsRebuild(true)
        await nextTick(() => setSectionsRebuild(true))
    }

    const publish = async () => {
        const toastId: ToastId = notification.loading('Publicando...')

        state.editorDisabled = true
        state.disabledPublish = true

        try {
            const pageData = {
                deletedFrames: state.deletedFrames,
                ...state.activePage
            }
            
            const response = await api.post(
                `site/${siteStore.id}/post/${state.activePage?.id}/update`,
                JSON.stringify(pageData)
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on publish page')
            }


            notification.update(toastId, 'Publicado com sucesso!', 'success')

            // sei la o pq mas router.push('/dashboard') não funciona, até troca a rota, mas o dom n atualiza
            window.location.href = '/dashboard'
        } catch (error) {
            console.warn(error)

            state.editorDisabled = false
            state.disabledPublish = false

            return notification.update(toastId, 'Não foi possível publicar!', 'error')
        }
    }

    const publishPost = async (options: any) => {
        const toastId: ToastId = notification.loading(options?.notification.loading)

        state.editorDisabled = true
        state.disabledPublish = true

        // verifica se esta voltando e se o post 
        // está em um blog, se ele estiver num blog ou ja estiver publicado
        // ele não pode voltar a ser rascunho
        if (options?.sketch == 1 && (state.activePage.pages?.length > 0 || state.activePage.sketch == 0)) {
            options.sketch = 0
        }

        state.activePage.sketch = options?.sketch

        state.activePage.dates.scheduled = state.schedule ? 1 : 0;

        if (!state.schedule) {
            const today = moment(new Date()).format("YYYY-MM-DD");
            state.activePage.dates.published_at = today;
        }

        try {
            const pageData = {
                deletedFrames: state.deletedFrames,
                ...state.activePage
            }
            
            const response = await api.post(
                `site/${siteStore.id}/post/${state.activePage?.id}/update`,
                JSON.stringify(pageData)
            )

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on publish page')
            }


            notification.update(toastId, options?.notification.success, 'success')

            // sei la o pq mas router.push('/dashboard') não funciona, até troca a rota, mas o dom n atualiza
            window.location.href = '/dashboard'
        } catch (error) {
            console.warn(error)

            state.editorDisabled = false
            state.disabledPublish = false

            return notification.update(toastId, options?.notification.error, 'error')
        }
    }

    const handleDeletePost = async (postId: number) => {
        const toastId: ToastId = notification.loading('Excluindo...')

        try {
            const response = await api.post(`site/${siteStore.id}/post/${postId}/delete`, null)

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on delete post')
            }

            notification.update(toastId, 'Post excluido com sucesso!', 'success')

            dashboardStore.posts = dashboardStore.posts.filter((post: any) => post.postId != postId)
        } catch (error) {
            console.warn(error)

            return notification.update(toastId, 'Não foi possível excluir o post!', 'error')
        }
    }

    const handleClonePost = async (postId: number) => {
        const toastId: ToastId = notification.loading('Clonando...')

        try {
            const response = await api.post(`site/${siteStore.id}/post/${postId}/duplicate`, null)

            const { status, id } = await response.json()

            if (status != '1') {
                throw new Error('Error on clone post')
            }

            notification.update(toastId, 'Post clonado com sucesso!', 'success')

            await router.replace({ name: 'post.edit', params: { id: id } })
        } catch (error) {
            console.warn(error)

            return notification.update(toastId, 'Não foi possível clonar o post!', 'error')
        }
    }

    const handleHighlightPost = async (id: number, options?: any) => {
        const toastId: ToastId = notification.loading(options.notification.loading)

        try {
            const response = await api.post(`site/${siteStore.id}/post/${id}/home`, null)

            const { status } = await response.json()

            if (status != '1') {
                throw new Error('Error on highlight post')
            }

            notification.update(toastId, options.notification.success, 'success')

            dashboardStore.fetchPosts()
        } catch (error) {
            console.warn(error)

            return notification.update(toastId, options.notification.error, 'error')
        }
    }

    const setFrameActive = (element: any) => {
        if (!element) return
        
        const id = element.frameId || element.temporaryId;

        if (isFrameActive(id)) return
        
        state.framesActives.push(id)
    }

    const removeFrameActive = async (elementToRemove: any) => {
        const index = await state.framesActives.findIndex((id: any) => (
            id === elementToRemove.frameId || id === elementToRemove.temporaryId
        ));

        if (index > -1) {
            state.framesActives.splice(index, 1);
        }
    }

    const isFrameActive = (elementId: any) => {
        return state.framesActives.includes(elementId)
    }

    const clearFrameActive = () => {
        state.framesActives = []
    }

    const setSectionForEdit = (section: any) => {
        state.sectionForEdit = section
    }

    const updateSectionExtra = (extra: any) => {
        state.sectionForEdit.extra = extra

        state.activePage.content[state.sectionForEdit.sectionIndex].extra = state.sectionForEdit.extra
    }

    const setBackgrounImageSectionForEdit = (image: any) => {
        state.sectionForEdit.backgroundImage = image

        state.activePage.content[state.sectionForEdit.sectionIndex].backgroundImage = state.sectionForEdit.backgroundImage

        updateSectionExtra({
            ...state.sectionForEdit.extra,
            background_image_opacity: "80"
        })
    }

    const updateElementForEdit = () => {
        updateElementBySchema(state.elementForEdit.schema, state.elementForEdit)
    }

    const reset = () => {
        state.activePage = null

        state.deletedFrames = []
        state.deletedSections = []

        state.loadingGetPage = false
        state.loadingPorcentGetPage = 0

        state.framesActives = []

        state.sectionsRebuild = false

        state.sectionForEdit = null

        state.editorDisabled = false
        state.disabledPublish = false
    }

    const addElementIntoSection = (structure: any,  sectionIndex: number,  position?: number) => {
        const section = state.activePage.content[sectionIndex]

        if (!position) {
            position = section.frameContent.length + 1
        }

        section.frameContent.splice(position, 0, structure)

        state.activePage.content.splice(sectionIndex, 1, section)

        return position
    }

    const addElementIntoElement = (structure: any,  sectionIndex: number,  parentIndex: number,  position?: number) => {
        console.log('addElementIntoElement', structure, sectionIndex, parentIndex, position)

        const section = state.activePage.content[sectionIndex]

        const parent = section.frameContent[parentIndex]

        if (!position) {
            position = parent.frameContent.length
        }

        parent.frameContent.splice(position, 0, structure)

        section.frameContent.splice(parentIndex, 1, parent)

        state.activePage.content.splice(sectionIndex, 1, section)

        return position
    }

    const removeElementIntoSection = (sectionIndex: number, frameIndex: number) => {
        const section = state.activePage.content[sectionIndex];

        const element = section.frameContent[frameIndex]
        
        if (element && !element.new) {
            state.deletedFrames.push(element)
        }

        section.frameContent.splice(frameIndex, 1)

        state.activePage.content.splice(sectionIndex, 1, section)
    }

    const removeElementIntoElement = (sectionIndex: number, frameIndex: number, parentIndex?: number) => {
        if (!parentIndex) {
            console.warn('parentIndex is undefined')

            return
        }

        const section = state.activePage.content[sectionIndex];

        const parent = section.frameContent[parentIndex]

        const element = parent.frameContent[frameIndex]
        
        if (element && !element.new) {
            state.deletedFrames.push(element)
        }

        parent.frameContent.splice(frameIndex, 1)

        section.frameContent.splice(parentIndex, 1, parent)

        state.activePage.content.splice(sectionIndex, 1, section)
    }

    const createLastFixedElementText = (sectionIndex: number) => {
        const newElement = {
            temporaryId: rand(0, 9999999999999),
            ...editorDefaultStore.newElementText,
            extra: {
                margin: {
                    ...editorDefaultStore.margin
                },
                padding: {
                    ...editorDefaultStore.padding
                }
            },
            new: true
        }
        
        addElementIntoSection(
            newElement,
            sectionIndex
        )
    }

    const removeLastFixedTextElement = (sectionIndex: number) => {
        const index = state.activePage.content[sectionIndex].frameContent.length - 1
    
        if (index < 0) return
    
        if (state.activePage.content[sectionIndex].frameContent[index].frameContent != '') return
        
        removeElementIntoSection(sectionIndex, index)
    
        clearFrameActive()
    }

    const hasFixedTextElement = (sectionIndex: number) => {
        const index = state.activePage.content[sectionIndex].frameContent.length - 1
    
        if (index < 0) return false
    
        if (state.activePage.content[sectionIndex].frameContent[index].frameContent == '') return true
    
        return false
    }

    const setIsEditableContent = (value: boolean) => {
        state.isEditableContent = value
    }

    const setElementForEdit = (element: any) => {
        state.elementForEdit = element
    }

    const isBody = (el: any) => {
        return el.classList.contains('body')
    }

    const ignoreActiveElement = (el: any) => {
        if (el.classList.contains('tiptap')) return false
        if (el.classList.contains('input-element-title')) return false
        if (el.classList.contains('input-element-button')) return false
        if (el.classList.contains('input-element-script')) return false
        if (el.classList.contains('button-element')) return false
        if (el.hasAttribute('is-active')) return false

        return true
    }

    const getElement = (el: any) => {
        let element = el;

        if (
            element.classList.contains('tiptap')
            || element.classList.contains('input-element-button')
            || element.classList.contains('input-element-script')
        ) {

            element = element.parentElement?.parentElement
        }

        if (
            element.classList.contains('input-element-title')
            || element.classList.contains('element-button')
        ) {
            element = element.parentElement
        }

        if (element.hasAttribute('is-active')) {
            return {
                id: element.id,
                schema: JSON.parse(element.getAttribute('schema'))
            }
        }

        return null
    } 

    const mountSchemaLevels = (schema: any) => {
        const levels: any[] = [];

        schema.forEach((item: any) => {
            if (item.level === 0) {
                // Nível superior, diretamente em state.activePage.content
                levels[item.level] = {
                    level: item.level,
                    index: item.index,
                    type: item.element,
                    element: state.activePage.content[item.index] ?? null
                };
            } else {
                // Níveis inferiores, elementos aninhados
                const parent = levels[item.level - 1].element;

                if (!parent || !parent.frameContent) {
                    console.error("Parent element not found or invalid for", item);
                    return; // Early return para evitar erro
                }

                levels[item.level] = {
                    level: item.level,
                    index: item.index,
                    type: item.element,
                    element: parent.frameContent[item.index] ?? null
                };
            }
        });

        return levels;
    }

    const onActiveElement = (element: any) => {
        const levels = mountSchemaLevels(element.schema);

        if (levels.length > 0) {
            const lastIndex = levels.length - 1;
            const lastLevel = levels[lastIndex];
    
            setFrameActive(lastLevel.element);
        }
    }

    const onDeactiveElement = (element: any) => {
        const levels = mountSchemaLevels(element.schema);

        if (levels.length > 0) {
            const lastIndex = levels.length - 1;
            const lastLevel = levels[lastIndex];
    
            removeFrameActive(lastLevel.element);
        }
    }

    const cloneElementBySchema = async (schema: any) => {
        const levels = mountSchemaLevels(schema);

        if (levels.length > 0) {
            const lastIndex = levels.length - 1;
            const lastLevel = levels[lastIndex];
    
            // Clona o elemento
            const cloneElement = mountCloneElement(lastLevel.element);
    
            // Adiciona o novo elemento no pai do ultimo nível, uma posiçao a mais do elemento a ser clonado
            const parentLevel = levels[lastIndex - 1];

            if (!parentLevel || !parentLevel.element || !parentLevel.element.frameContent) {
                console.error("Parent element not found or invalid for clone.");
                return;
            }

            parentLevel.element.frameContent.splice(lastLevel.index + 1, 0, cloneElement);
    
            // Caminho reverso para atualizar todos os níveis até a raiz
            updateElementCascate(lastIndex, levels);
        }
    }

    const mountCloneElement = (elementForClone: any) => {
        const cloneElement = JSON.parse(JSON.stringify(elementForClone));
        cloneElement.frameId = null;
        cloneElement.frameDataId = null;
        cloneElement.temporaryId = rand(0, 9999999999999);

        if (['row', 'container'].includes(cloneElement.frameType)) {
            cloneElement.frameContent = cloneElement.frameContent.map((element: any) => {
                return mountCloneElement(element);
            });
        }

        return cloneElement;
    }

    const removeElementBySchema = async (schema: any) => {
        const levels = mountSchemaLevels(schema);

        // Verifica se temos níveis suficientes para realizar a remoção
        if (levels.length > 0) {
            const lastIndex = levels.length - 1;
            const lastLevel = levels[lastIndex];
    
            // Elemento a ser removido
            const elementToRemove = lastLevel.element;

            if (!elementToRemove) {
                // Se o elemento a ser removido não existe ou é marcado como novo, não prossegue
                console.error("Element to remove not found or is new.");
                return;
            }
    
            // Adiciona o elemento removido ao array de frames deletados
            state.deletedFrames.push(elementToRemove);
    
            // Se está no nível 0, remove diretamente do content
            if (lastIndex === 0) {
                state.activePage.content.splice(lastLevel.index, 1);
            } else {
                // Remove o elemento do seu container imediato
                const parentLevel = levels[lastIndex - 1];
                parentLevel.element.frameContent.splice(lastLevel.index, 1);
            }
        }
    };

    const addElementBySchema = async (schema: any, structure: any, position = 0) => {
        const levels = mountSchemaLevels(schema);

        if (levels.length === 1) {
            const index = levels[0].index + position;

            state.activePage.content.splice(index, 0, structure);

            return;
        } 

        if (levels.length > 0) {
            const lastIndex = levels.length - 1;
            const parentLevel = levels[lastIndex - 1];

            const index = parentLevel.index + position;
    
            parentLevel.element.frameContent.splice(index, 0, structure);
        }
    }

    const updateElementBySchema = async (schema: any, newElement: any) => {
        const levels = mountSchemaLevels(schema);
    
        // A lógica de atualização começa aqui, agora que os níveis já estão montados
        if (levels.length > 0) {
            const lastIndex = levels.length - 1;
            const lastLevel = levels[lastIndex];
    
            // Substituir o elemento no nível mais profundo
            lastLevel.element = newElement;
    
            // Caminho reverso para atualizar todos os níveis até a raiz
            updateElementCascate(lastIndex, levels);
        }
    };

    const updateElementCascate = (lastIndex: number, levels: any) => {
        for (let i = lastIndex; i >= 0; i--) {
            const currentLevel = levels[i];
            const parentLevel = levels[i - 1];

            // Se não houver parente, estamos no nível superior ou seja é uma seção
            if (parentLevel) {
                // Atualizar o parente imediato com o elemento (ou com a atualização feita)
                // Aqui, usamos o índice e substituímos o elemento atualizado
                parentLevel.element.frameContent.splice(currentLevel.index, 1, currentLevel.element);
            } else {
                // No nível superior, atualizar diretamente no state
                state.activePage.content.splice(currentLevel.index, 1, currentLevel.element);
            }
        }
    }

    const handlerAddNewSection = async (schema: any) => {
        const response = await api.post(
            `site/${siteStore.id}/post/${state.activePage.id}/card`,
            JSON.stringify({
                index: schema[0].index + 1
            })
        );

        const data = await response.json();

        if (data.status != '1') {
            throw new Error('Error on add new section');
        }

        const newSection = {
            ...editorDefaultStore.newSection,
            frameId: data.id.frame,
            frameContent: [
                {
                    ...editorDefaultStore.newElementTitle,
                    frameContent: '',
                    temporaryId: rand(0, 9999999999999),
                    extra: {
                        ...editorDefaultStore.newElementTitle.extra,
                        style: {
                            ...editorDefaultStore.newElementTitle.extra.style,
                            fontSize: '32px',
                            fontWeight: '700',
                            textAlign: 'left',
                            color: ''
                        },
                        style_font_size_lock: false,
                        style_font_weight_lock: false,
                        style_text_align_lock: false
                    },
                    new: true
                }
            ],
        }

        addElementBySchema(schema, newSection, 1);
    }

    const checkThumbnail = () => {
        if (state.activePage.thumbnail.mainImage && !state.thumbnailIsAutoSelect) {
            return;
        }
    
        let found = false;
    
        state.activePage.content.forEach((section: any) => {
            if (found) return;
    
            section.frameContent.forEach((element: any) => {
                if (found) return;
    
                if (
                    element.frameType == 'image'
                    || element.frameType == 'video'
                    || element.frameType == 'gallery'
                ) {
                    switch (element.frameType) {
                        case 'image':
                            state.activePage.thumbnail.mainImage = element.frameContent;
                            state.thumbnailIsAutoSelect = true;

                            found = true;
                            break;
                        case 'video':
                            state.activePage.thumbnail.mainImage = element.thumbnail;
                            state.thumbnailIsAutoSelect = true;

                            found = true;
                            break;
                        case 'gallery':
                            state.activePage.thumbnail.mainImage = element.frameContent.items[0].image.url;
                            state.thumbnailIsAutoSelect = true;

                            found = true;
                            break; 
                    }
                }
            });
        });
    };
    
    // fim actions

    return {
        // disponibiliza as states
        ...toRefs(state),
        // disponibiliza as getters
        sections,
        // disponibiliza as actions
        getPage,
        publish,
        publishPost,
        deleteSection,
        createSection,
        upSection,
        downSection,
        setFrameActive,
        removeFrameActive,
        isFrameActive,
        clearFrameActive,
        removeSection,
        setSectionsRebuild,
        setSectionForEdit,
        updateSectionExtra,
        setBackgrounImageSectionForEdit,
        addElementIntoSection,
        addElementIntoElement,
        removeElementIntoSection,
        removeElementBySchema,
        removeElementIntoElement,
        createLastFixedElementText,
        removeLastFixedTextElement,
        hasFixedTextElement,
        setElementsRebuild,
        setIsEditableContent,
        setElementForEdit,
        addElementBySchema,
        updateElementBySchema,
        updateElementForEdit,
        cloneElementBySchema,
        checkIsBlankPost,
        nextPostStep,
        previousStep,
        handleDeletePost,
        handleClonePost,
        handleHighlightPost,
        getSectionExtra,
        isBody,
        ignoreActiveElement,
        getElement,
        onActiveElement,
        onDeactiveElement,
        handlerAddNewSection,
        checkThumbnail,
        reset
    }
})
