<script setup lang="ts">
import { ref, watch } from 'vue';

import Uppy, { type UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3'

import IconCloud from '@/components/atoms/icons/IconCloud.vue';

import api from '@/services/api';

import { useSiteStore } from '@/stores/site';
import { useMediaManagerStore } from '@/stores/modal/media-manager';
import notification from '@/services/notification';

import { useDropZone } from '@vueuse/core'

const props = defineProps<{
    modalRef?: HTMLDivElement | null
}>()

const siteStore = useSiteStore();
const mediaManagerStore = useMediaManagerStore();

const fileInput = ref();
const filesError = ref<any>([]);

const uppy = new Uppy({
    debug: true,
    id: 'uppyInstance',
    autoProceed: true,
    restrictions: {
        allowedFileTypes: [
            '.jpg', '.jpeg', '.png', '.webp', '.gif'
        ],
    },
});

uppy.use(AwsS3, {
    limit: 5, // limite de uploads simultaneos
    timeout: 1 * 60 * 3000, // 1 * 60 * 1000 = 1 minutos
    allowedMetaFields: ['name'],
    id: 'aws-s3',
    async getUploadParameters(file: UppyFile): Promise<any> {
        try {
            const data = file.data;

            const url = URL.createObjectURL(data);

            const image = new Image();

            image.src = url;

            await image.decode();

            const { width, height } = image;

            const { success, message } = await getPresignedS3Url(file, width, height);

            if (success) {
                const image = {
                    id: message.file.id,
                    name: message.file.name,
                    width: message.file.width,
                    height: message.file.height,
                    content: message.file.path,
                    datetime: message.file.updated_at,
                    extension: message.file.extension,
                    source: "Banco de imagens",
                    type: message.file.filetype
                };

                mediaManagerStore.addMediasUploaded(image);

                return {
                    url: message.url,
                    method: 'put',
                    fields: [],
                    image: image,
                    headers: {
                        'Content-type': message.file.filetype,
                    }
                };
            }
        } catch (error) {
            console.error('getUploadParameters error:', error);
        }

        return null;
    }
});

const getPresignedS3Url = async (file: any, width: any, height: any) => {
    try {
        if (!siteStore.id) {
            throw new Error('Site ID is required');
        }

        var urlS3 = `site/${siteStore.id}/file/upload/link`;

        if (!file) {
            throw new Error('File is required');
        }

        urlS3 += `?`;
        urlS3 += `filename=${file.name}`;
        urlS3 += `&type=${file.meta.type}`;
        urlS3 += `&extension=${file.extension}`;
        urlS3 += `&contenttype=${file.meta.type}`;
        urlS3 += `&size=${file.progress.bytesTotal}`;
        urlS3 += `&width=${width}`;
        urlS3 += `&height=${height}`;

        const response = await api.get(urlS3);

        const data = await response.json();

        return data;
    } catch (error) {
        console.log(error);
    }
}

uppy.on('upload', (data) => {
    mediaManagerStore.finishedUpload = false;

    if (mediaManagerStore.filesUploadedWithError == 0) {
        mediaManagerStore.mediasUploaded = [];
    }
    
    mediaManagerStore.filesWithError = [];
    mediaManagerStore.filesUploaded = 0;
    mediaManagerStore.filesTotal = 0;
    mediaManagerStore.filesTotalProgress = 0;
    mediaManagerStore.filesUploadedWithError = 0;

    mediaManagerStore.fileProgressShow = true;

    mediaManagerStore.filesTotal = data.fileIDs.length;
    mediaManagerStore.fileIDs = data.fileIDs;
});

uppy.on('progress', (progress) => {
    mediaManagerStore.filesTotalProgress = progress;
});

uppy.on('upload-error', (file, error) => {
    console.log('error:', error);
    console.log('file-error:', file);

    mediaManagerStore.filesWithError = [
        ...mediaManagerStore.filesWithError,
        file
    ];

    mediaManagerStore.filesUploadedWithError = mediaManagerStore.filesUploadedWithError + 1;
});

uppy.on('upload-success', (file: any, response: any) => {
    mediaManagerStore.filesUploaded = mediaManagerStore.filesUploaded + 1;

    console.log(uppy.getFiles());
});

uppy.on('file-added', (file: any) => {
    mediaManagerStore.filesUploaded = 0;
    mediaManagerStore.filesTotal = 0;
    mediaManagerStore.filesTotalProgress = 0;
});

uppy.on('complete', () => {
    mediaManagerStore.finishedUpload = true;

    if (mediaManagerStore.filesUploadedWithError == 0) {
        mediaManagerStore.fileProgressShow = false;
        mediaManagerStore.filesTotal = 0;
        mediaManagerStore.filesTotalProgress = 0;
        mediaManagerStore.fileIDs = [];
    } 

    // Verifica se é possível adicionar mais mídias selecionadas
    const remainingSlots = mediaManagerStore.maxSelectedMedias - mediaManagerStore.mediasUploaded.length;
    const canAddMoreMedias = remainingSlots > 0;

    // Adiciona mídias adicionais se houver espaço disponível
    if (canAddMoreMedias) {
        const selectedMediasToAdd = mediaManagerStore.selectedMedias.slice(0, remainingSlots);
        
        mediaManagerStore.selectedMedias = [...mediaManagerStore.mediasUploaded, ...selectedMediasToAdd];
    }

    // Adiciona as mídias carregadas, se necessário
    if (mediaManagerStore.selectedMedias.length === 0) {
        mediaManagerStore.selectedMedias = mediaManagerStore.mediasUploaded.slice(0, mediaManagerStore.maxSelectedMedias);
    }

    // Adiciona as mídias carregadas
    mediaManagerStore.addMedias(
        mediaManagerStore.mediasUploaded,
        'before'
    )
});

uppy.on('restriction-failed', (file: any, error: Error) => {
    if (error.message.includes('.jpg, .jpeg, .png, .webp, .gif')) {
        notification.simple('Os unicos tipos permitidos de arquivos são: .jpg, .jpeg, .png, .webp, .gif', 'error');   
    }
});

const handleFileChange = (event: any) => {
    const files = event.target.files;
    
    uppy.addFiles(files);
};

const onDrop = (files: any) => {
    uppy.addFiles(files);
};

const handleRetry = async () => {
    if (uppy.getFiles().length > 0) {
        await uppy.retryAll();

        return
    }

    await new Promise((resolve: any) => {
        mediaManagerStore.filesWithError.forEach((file: any) => {
            const fileError = mediaManagerStore.filesWithError.find((errorFile: any) => errorFile.id == file.id);
            
            filesError.value.push(fileError);
        });

        resolve();
    });

    uppy.addFiles(filesError.value);
};

const { isOverDropZone } = useDropZone(props.modalRef, {
    onDrop,
})

watch(isOverDropZone, (value: any) => {
    mediaManagerStore.isOverDropZone = value;
})
</script>

<template>
    <div 
        class="relative"
    >
        <button 
            @click="fileInput.click()"
            class="flex flex-col items-center justify-center w-full h-full border-dashed border-2 border-[var(--main-color)] rounded-md space-y-2"
        >
            <input
                class="hidden"
                type="file"
                ref="fileInput"
                @change="handleFileChange"
                multiple
            >

            <IconCloud />

            <span 
                class="text-[var(--main-color)] text-xl font-bold"
            >
                Enviar Arquivo
            </span>

            <p class="text-[#060606] text-xs text-center">
                Adicione uma imagem
                <br>
                <b>clicando</b> ou <b>arraste</b> para
                <br>
                dentro do contorno.
            </p>
        </button>

        <template
            v-if="mediaManagerStore.fileProgressShow"
        >
            <div class="absolute inset-0 bg-[var(--main-color)] rounded-lg z-[99999]">
                <div class="w-full h-full flex flex-col items-center justify-center">
                    <div class="flex items-end space-x-1">
                        <h2 class="text-white text-6xl">
                            {{ mediaManagerStore.filesUploaded }}
                        </h2>

                        <span class="text-[#fcfcfc] opacity-30">
                            / {{ mediaManagerStore.filesTotal }}
                        </span>
                    </div>

                    <span v-if="!mediaManagerStore.finishedUpload" class="text-sm text-white animate-pulse">
                        Enviando...
                    </span>

                    <div class="flex flex-col text-sm" v-if="mediaManagerStore.filesUploadedWithError > 0">
                        <span class="mt-3 text-[#fcfcfc]">
                            <b>{{ mediaManagerStore.filesUploadedWithError }} <span v-if="mediaManagerStore.filesUploadedWithError > 1">imagens</span><span v-else>image</span></b> com <b>erro</b> ao enviar
                        </span>

                        <button class="text-[#fcfcfc] font-bold mt-3" @click="handleRetry">
                            Tentar novamente
                        </button>

                        <span class="text-center text-[#fcfcfc] font-light">
                            ou
                        </span>

                        <button class="text-[#fcfcfc] font-bold" @click="mediaManagerStore.fileProgressShow = false">
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>

            <div 
                v-if="!mediaManagerStore.finishedUpload"
                class="absolute z-[999999] bottom-0 left-0 right-0 h-4 bg-[#F5F5F5] animate-pulse"
                :style="{ width : `${mediaManagerStore.filesTotalProgress}%` }"
            ></div>
        </template>
    </div>
</template>

<style scoped>

</style>