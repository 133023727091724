<template>
    <svg
        class="w-6 h-6 fill-[#060606]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        fill="currentColor"
    >
        <path
            d="M7.4 7C7.4 6.50294 6.99706 6.1 6.5 6.1C6.00294 6.1 5.6 6.50294 5.6 7H7.4ZM5.6 17C5.6 17.4971 6.00294 17.9 6.5 17.9C6.99706 17.9 7.4 17.4971 7.4 17H5.6ZM4 1.9H14V0.1H4V1.9ZM16.1 4V14H17.9V4H16.1ZM14 16.1H4V17.9H14V16.1ZM1.9 14V4H0.1V14H1.9ZM4 16.1C2.8402 16.1 1.9 15.1598 1.9 14H0.1C0.1 16.1539 1.84609 17.9 4 17.9V16.1ZM16.1 14C16.1 15.1598 15.1598 16.1 14 16.1V17.9C16.1539 17.9 17.9 16.1539 17.9 14H16.1ZM14 1.9C15.1598 1.9 16.1 2.8402 16.1 4H17.9C17.9 1.84609 16.1539 0.1 14 0.1V1.9ZM4 0.1C1.84609 0.1 0.1 1.84609 0.1 4H1.9C1.9 2.8402 2.8402 1.9 4 1.9V0.1ZM5.6 7V17H7.4V7H5.6ZM1.5 7.4H16.5V5.6H1.5V7.4Z"
        />
    </svg>
</template>