import { ref } from 'vue'

import { defineStore } from 'pinia'

/**
 * @description Modal Confirmation Store
 */
export const useModalConfirmationStore = defineStore('modal-confirmation', () => {
    // states
    const isOpenModal = ref(false)

    const title = ref('')

    const message = ref('')

    const type = ref('')

    const textConfirm = ref('')

    const textCancel = ref('')

    const result = ref()
    // fim states

    // actions
    const setIsOpenModal = (value: boolean) => {
        isOpenModal.value = value
    }

    const setTitle = (value: string) => {
        title.value = value
    }

    const setMessage = (value: string) => {
        message.value = value
    }

    const setType = (value: string) => {
        type.value = value
    }

    const setTextConfirm = (value: string) => {
        textConfirm.value = value
    }

    const setTextCancel = (value: string) => {
        textCancel.value = value
    }

    const setResult = (value?: boolean) => {
        result.value = value
    }

    const handleConfirm = () => {
        setResult(true)

        setIsOpenModal(false)
    }

    const handleCancel = () => {
        setResult(false)

        setIsOpenModal(false)
    }

    const reset = () => {
        setIsOpenModal(false)

        setTitle('')

        setMessage('')

        setTextConfirm('')

        setTextCancel('')

        setResult(undefined)
    }
    // fim actions

    return {
        isOpenModal,
        title,
        message,
        type,
        textConfirm,
        textCancel,
        result,
        setIsOpenModal,
        setTitle,
        setMessage,
        setType,
        setTextConfirm,
        setTextCancel,
        handleConfirm,
        handleCancel,
        reset
    }
})
