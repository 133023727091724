<script setup lang="ts">
import { useMediaManagerStore } from '@/stores/modal/media-manager'

const mediaManagerStore = useMediaManagerStore()
</script>

<template>
    <div class="flex items-center space-x-1">
        <select
            name="filter-medias"
            id="filter-medias"
            class="appearance-none bg-transparent border-none outline-none font-semibold text-base text-[#060606]"
            @change="($event: any) => mediaManagerStore.setOrderMedias($event.target.value)"
        >
            <option 
                v-for="(item, index) in mediaManagerStore.orderMedias"
                :key="index"
                :value="item.id"
            >
                {{ item.name }}
            </option>
        </select>

        <svg
            class="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M5 8L10.0008 12.58L15 8"
                stroke="#060606"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </div>
</template>

<style scoped></style>
