<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    value: {
        type: String
    },
    valueOpenIn: {
        type: [String, Number]
    }
})

const emit = defineEmits(['change'])

const onChangeLink = (event: any) => {
    emit('change', {
        target: 'link',
        value: event.target.value
    })
}

const onChangeOpenIn = (event: any) => {
    emit('change', {
        target: 'open-in',
        value: event.target.value
    })
}

const options = [
    {
        id: 1,
        value: {
            string: '',
            number: 0
        },
        label: 'Selecione uma opção'
    },
    {
        id: 2,
        value: {
            string: '_self',
            number: 1
        },
        label: 'Na mesma janela'
    },
    {
        id: 3,
        value: {
            string: '_blank',
            number: 2
        },
        label: 'Em uma nova Janela'
    },
    {
        id: 4,
        value: {
            string: '_parent',
            number: 3
        },
        label: 'Em uma seção'
    }
]

const getOptionValue = (option: any, type: string) => {
    return option.value[type];
}
</script>

<template>
    <div class="space-y-3">
        <div class="space-y-2">
            <span class="text-sm font-semibold text-[#060606] opacity-50">
                {{ label }}
            </span>

            <div class="w-full">
                <input
                    @input="onChangeLink"
                    class="w-full text-sm bg-[#fcfcfc] outline-none px-4 py-3 rounded border-[#F5F5F5] border-2"
                    id="link"
                    type="text"
                    :value="value"
                    placeholder="https://exemplo.com.br"
                >
            </div>
        </div>

        <div class="space-y-2">
            <span class="text-sm font-semibold text-[#060606] opacity-50">
                Abrir em
            </span>

            <div class="w-full">
                <select
                    @change="onChangeOpenIn"
                    class="w-full text-sm bg-[#fcfcfc] outline-none px-4 py-3 rounded border-[#F5F5F5] border-2 appearance-none"
                    id="open-in"
                >
                    <option
                        v-for="option in options"
                        :key="option.id"
                        :value="getOptionValue(option, props.type)"
                        :selected="getOptionValue(option, props.type) == valueOpenIn"
                    >
                        {{ option.label }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
