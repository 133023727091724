<script setup lang="ts">
import api from '@/services/api';
import { ref, onBeforeMount, computed } from 'vue';

import router from '@/router';

import { useSiteStore } from '@/stores/site';
import { useSidebarDashboardStore } from '@/stores/dashboard/sidebar';
import { useApplicationStore } from '@/stores/application';

const siteStore = useSiteStore();
const sidebarDashboardStore = useSidebarDashboardStore();
const applicationStore = useApplicationStore();

const loading = ref(false);

const filterBillsBy = ref('all');
const totalHitoricFilterActive = ref(0);
const clientProductsNames = ref<any>([]);
const clientHistoric = ref<any>([]);
const clientProducts = ref<any>([]);

const getProductsAndHistoric = async () => {
    loading.value = true;

    try {
        const response = await api.get(`site/${siteStore.id}/bill`);

        const { contracts, bill } = await response.json();

        clientProducts.value = contracts;

        if (clientProducts.value.length === 0) {
            sidebarDashboardStore.toggleMenu();

            const token = siteStore.contract.token;

            // envia para o checkout do painel antigo
            window.location.href = applicationStore.wizardUrl + "/checkout?token=" + token;
        }

        clientProductsNames.value = [...new Set(contracts.map((prod: any) => prod.name))];
        clientHistoric.value = bill;
    } catch (error) {
        console.error(error);
    }

    loading.value = false;
};

onBeforeMount(async () => {
    console.log('DashboardSidebarItemSubscriptions');

    await getProductsAndHistoric();
});

const clientHistoricCounter = computed(() => {
    if (filterBillsBy.value === "all") {
        return clientHistoric.value;
    } else {
        return clientHistoric.value.filter((bill:any) => bill.product == filterBillsBy.value);
    }
})

const toCurrency = (value: string) => {
    const parsedValue = parseFloat(value);

    if (typeof parsedValue !== "number") {
        return parsedValue;
    }

    var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2
    });

    return formatter.format(parsedValue);
}

const toPortuguese = (value: string) => {
    if (value == "paid") {
        return "Pago";
    } else if (value == "waiting payment") {
        return "Pendente";
    } else if (value == "canceled") {
        return "Cancelado";
    } else {
        return "Recusado";
    }
}

const messageLink = (text: string) => {
    if (text == "paid") {
        return "Nota fiscal";
    } else {
        return "Pagar agora";
    }
}
</script>

<template>
    <div class="px-6 space-y-3">
        <h2 class="text-2xl font-bold">Minhas Assinaturas</h2>

        <div class="w-full">
            <p>Seus Produtos</p>

            <template 
                v-if="clientProducts.length > 0"
            >
                <div
                    class="w-full p-3 flex flex-col space-y-3 shadow-md rounded-md"
                    v-for="(item, index) in clientProducts"
                    :key="index"
                >
                    <div class="w-full flex justify-between font-semibold">
                        <p>{{ item.name }}</p>

                        <p>{{ toCurrency(item.priceValue) }}</p>
                    </div>

                    <div class="w-full flex-col">
                        <div class="text-sm text-[var(--main-color)]">
                            <a
                                :href="item.url"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {{item.url}}
                            </a>
                        </div>
                    </div>
                </div>
            </template>

            <p v-else class="text-center">Buscando seus produtos...</p>
        </div>

        <hr class="my-3">

        <div>
            <div class="w-full flex pb-4 items-center justify-between">
                <label>Faturas</label>

                <select id="" class="appearance-none" v-model="filterBillsBy">
                    <option value="all" selected>Todos</option>
                    <option
                        :value="item"
                        v-for="item in clientProductsNames"
                        :key="item.id"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>
            
            <div
                class="p-3 mb-4 shadow-md rounded-md"
                v-for="bill in clientHistoricCounter"
                :key="bill.id"
            >
                <div class="w-full flex flex-col">
                    <div class="w-full flex justify-between">
                        <p>
                            Nº da fatura: <strong>{{ bill.id }}</strong>
                        </p>

                        <span
                            class="pill"
                            :class="[
                                bill.status == 'paid' ? 'success' : '',
                                bill.status == 'canceled' || bill.status == 'refused'
                                ? 'error'
                                : ''
                            ]"
                        >
                            {{ toPortuguese(bill.status) }}
                        </span>
                    </div>

                    <div class="mt-2 w-full font-bold">
                        <p>{{ bill.product }}</p>
                    </div>

                    <div class="mt-1 w-full flex justify-between">
                        <p class="font-semibold">Vencimento:</p>

                        <span>{{ bill.expiry }}</span>
                    </div>

                    <div class="mt-1 w-full flex justify-between">
                        <p>Ref. Mês/Ano</p>

                        <p>{{ bill.referer }}</p>
                    </div>

                    <div class="mt-2 w-full text-right">
                        <p>R$ {{ bill.value }}</p>
                    </div>

                    <div
                        class="w-full" 
                        v-if="bill.link"
                    >
                        <a :href="bill.link">{{ messageLink(bill.status) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.pill {
  background-color: #f2f2f2;
  color: #858585;
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 25px;
  min-width: 55px;
  display: inline-block;
  text-align: center;
}
.pill.success {
  background-color: #cff5d4;
}
.pill.error {
  background-color: #ff4a51;
  color: #fff;
}
</style>
