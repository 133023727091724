<script setup lang="ts">
import { onBeforeMount, computed } from 'vue'
import { RouterView } from 'vue-router'

import { useSiteStore } from '@/stores/site'
import { useApplicationStore } from '@/stores/application'
import { useUserStore } from '@/stores/user'

const siteStore = useSiteStore()
const applicationStore = useApplicationStore()
const userStore = useUserStore()

const siteDefined = computed(() => siteStore.defined)
const applicationDefined = computed(() => applicationStore.defined)
const userDefined = computed(() => userStore.defined)

onBeforeMount(() => {
    const siteData = localStorage.getItem('siteData')
    const applicationData = localStorage.getItem('applicationData')
    const userData = localStorage.getItem('userData')

    if (!siteData && !applicationData && !userData) {
        return
    }

    if (!siteDefined.value && siteData) {
        siteStore.setSite(JSON.parse(siteData))
    }

    if (!applicationDefined.value && applicationData) {
        applicationStore.setApplication(JSON.parse(applicationData))
    }

    if (!userDefined.value && userData) {
        userStore.setUser(JSON.parse(userData))
    }

    document.title = applicationStore.appName + " - Painel";

    const body = document.body;
    
    if (applicationStore.className == "uli") {
        body.classList.add(applicationStore.className);
    } else {
        body.classList.add("others");
    }
})
</script>

<template>
    <main>
        <router-view></router-view>
    </main>
</template>

<style scoped></style>
