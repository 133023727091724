import { ref } from 'vue'
import { defineStore } from 'pinia'

/**
 * @description Application Store
 */
export const useApplicationStore = defineStore('application', () => {
    // states
    const defined = ref<any>(false)
    const contactEmail = ref<any>()
    const contactPhone = ref<any>()
    const financial = ref<any>()
    const themeColor = ref<any>()
    const backgroundColor = ref<any>()
    const ogImage = ref<any>()
    const ogDescription = ref<any>()
    const appleIcon = ref<any>()
    const showAffiliate = ref<any>()
    const className = ref<any>()
    const appName = ref<any>()
    const appKey = ref<any>()
    const appBaseDomain = ref<any>()
    const appDefaultIdd = ref<any>()
    const panelUrl = ref<any>()
    const wizardUrl = ref<any>()
    const appLogo = ref<any>()
    // fim states

    // actions
    const setApplication = (application: any) => {
        contactEmail.value = application.contact_email
        contactPhone.value = application.contact_phone
        financial.value = application.financial
        themeColor.value = application.main_color
        backgroundColor.value = application.background_color
        ogImage.value = application.og_image
        ogDescription.value = application.og_description
        appleIcon.value = application.apple_icon
        showAffiliate.value = application.show_affiliate
        appKey.value = application.app_key
        className.value = application.class
        appName.value = application.app_name
        appBaseDomain.value = application.app_base_domain
        appDefaultIdd.value = application.default_idd
        panelUrl.value = application.panelUrl
        wizardUrl.value = application.wizardUrl
        appLogo.value = application.app_logo

        tintPanel()

        defined.value = true
    }

    const tintPanel = () => {
        document.documentElement.style.setProperty('--main-color', themeColor.value)
        document.documentElement.style.setProperty('--bg-color', backgroundColor.value)
    }
    // fim actions

    return {
        // disponibiliza as states
        defined,
        contactEmail,
        contactPhone,
        financial,
        themeColor,
        backgroundColor,
        ogImage,
        ogDescription,
        className,
        appleIcon,
        showAffiliate,
        appName,
        appKey,
        appBaseDomain,
        appDefaultIdd,
        panelUrl,
        wizardUrl,
        appLogo,
        // disponibiliza as actions
        setApplication,
        tintPanel
    }
})
