<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingImage from '@/components/atoms/icons/IconSettingImage.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingImageSelector from '@/components/atoms/SettingImageSelector.vue';

import SettingWidth from '@/components/atoms/SettingWidth.vue'

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'

const editorPageStore = useEditorPageStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value
    }

    editorPageStore.updateElementForEdit()
}

const settings = ref([
    {
        id: 1,
        name: 'image',
        icon: markRaw(IconSettingImage)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])

const onChangeAfterImage = (event: any) => {
    editorPageStore.elementForEdit = {
        ...editorPageStore.elementForEdit.frameContent,
        afterPicture: event.value.content
    }

    editorPageStore.updateElementForEdit()
}

const onChangeBeforeImage = (event: any) => {
    editorPageStore.elementForEdit = {
        ...editorPageStore.elementForEdit.frameContent,
        beforePicture: event.value.content
    }

    editorPageStore.updateElementForEdit()
}
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #image>
            <settings-layout
                name="Configurações da Imagem"
                :default-open="true"
            >
                <setting-image-selector
                    label="Imagem Esquerda"
                    :value="elementForEdit.frameContent.afterPicture"
                    @change="onChangeAfterImage"
                />

                <setting-image-selector
                    label="Imagem Direita"
                    :value="elementForEdit.frameContent.beforePicture"
                    @change="onChangeBeforeImage"
                />
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                name="Configurações do Conteúdo"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit.grid"
                    :value-mobile="elementForEdit.gridMobile"
                    :values-permission-desktop="[4, 6, 12]"
                    :values-permission-mobile="[4, 6, 12]"
                    @change="onChangeWidth"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
