<script setup lang="ts">
import { ref, reactive, computed, watch } from 'vue'

import { useEditorPageStore } from '@/stores/editor/page'
import { useDashboardStore } from '@/stores/dashboard'
import { useApplicationStore } from '@/stores/application'

import modal from '@/services/modal';
import clsx from 'clsx';

const editorPageStore = useEditorPageStore()
const dashboardStore = useDashboardStore()
const applicationStore = useApplicationStore()

const loadingGetPage = computed(() => editorPageStore.loadingGetPage)

const page = ref<any>({ value: null})

watch(loadingGetPage, () => {
    page.value = reactive(editorPageStore.activePage)
})

const onClickPublish = () => {
    editorPageStore.publish()
}

const goDashboard = async () => {
    const title = 'Voltar para o dashboard?'
    const message = 'Está ação poderá resultar na perda de dados não salvos.'
    const type = 'danger'
    const confirm = 'Sim, voltar'
    const cancel = 'Não, continuar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        return
    }

    const dashboard = dashboardStore.defaultPages[1]

    dashboardStore.handleClickPage(dashboard)
}
</script>

<template>
    <div id="page-editor-menu" class="page-editor-menu">
        <div class="fragment-action-back">
            <button
                class="button-back"
                @click="goDashboard"
            >
                <span class="sr-only">Back Dashboard</span>

                <svg
                    class="w-4 h-4"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.63552 3.20005L1.59961 6.27704M1.59961 6.27704L4.63552 9.35404M1.59961 6.27704L11.1381 6.27704C12.9394 6.27704 14.3996 7.73727 14.3996 9.53855V9.53855C14.3996 11.3398 12.9394 12.8 11.1381 12.8L7.99961 12.8"
                        stroke="#060606"
                        stroke-opacity="0.3"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>

        <div class="fragment-title-page">
            <span v-if="!loadingGetPage" class="title-page">
                {{ page.title }}
            </span>

            <span v-else class="title-page-loading">Carregando...</span>
        </div>

        <div class="fragment-actions">
            <button 
                @click="onClickPublish"
                :class="clsx(
                    'button-publish',
                    applicationStore.className == 'uli' && 'bg-gradient-to-br from-violet-500 via-purple-600 to-indigo-700',
                    applicationStore.className != 'uli' && 'bg-gradient-to-bl from-lime-500 via-emerald-500 to-green-600'
                )"
                :disabled="loadingGetPage || editorPageStore.disabledPublish"
            >
                Salvar
            </button>
        </div>
    </div>
</template>

<style scoped>
.page-editor-menu {
    @apply z-50 fixed top-0 left-0 right-0 h-[78px] w-full mx-auto px-20 flex items-center justify-between backdrop-blur-sm;
}

.page-editor-menu .fragment-action-back {
    @apply w-[180px] text-center flex items-center justify-start;
}

.page-editor-menu .fragment-action-back .button-back {
    @apply p-2 bg-[#f2f2f2] rounded-full outline-none disabled:cursor-not-allowed;
}

.page-editor-menu .fragment-title-page {
    @apply text-center flex flex-1 items-center justify-center;
}

.page-editor-menu .fragment-title-page .title-page,
.page-editor-menu .fragment-title-page .title-page-loading {
    @apply text-base text-[#060606] opacity-50 font-bold;
}

.page-editor-menu .fragment-title-page .title-page-loading {
    @apply animate-pulse;
}

.page-editor-menu .fragment-actions {
    @apply w-[180px] flex items-center  justify-end space-x-3;
}

.page-editor-menu .fragment-actions .button-preview {
    @apply ml-auto;
}

.page-editor-menu .fragment-actions .button-publish {
    @apply px-8 py-2 rounded-full font-semibold text-white text-base disabled:bg-none disabled:bg-gray-500 disabled:cursor-not-allowed; 
}
</style>
