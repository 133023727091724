<script setup lang="ts">
import { onBeforeMount, onMounted, reactive, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

import moment from 'moment'

import clsx from 'clsx'

import { useActiveElement, useArrayIncludes } from '@vueuse/core'

import Datepicker from 'vue3-datepicker'

import EditorLayout from '@/components/layouts/EditorLayout.vue'
import SettingsLayout from '@/components/layouts/SettingsLayout.vue'

import SettingAutocompleteLocation from '@/components/atoms/SettingAutocompleteLocation.vue'

import EditorSection from '@/components/organisms/EditorSection.vue'
import ConfirmationModal from '@/components/organisms/ConfirmationModal.vue'
import PresetModal from '@/components/organisms/PresetModal.vue'
import MediaManagerModal from '@/components/organisms/MediaManagerModal.vue'
import EditorSidebar from '@/components/organisms/EditorSidebar.vue'
import TextAiModal from '@/components/organisms/TextAiModal.vue'
import TextLinkModal from '@/components/organisms/TextLinkModal.vue'


import { useEditorPageStore } from '@/stores/editor/page'
import { useDashboardStore } from '@/stores/dashboard'
import { useAuthStore } from '@/stores/auth'
import { useModalPresetStore } from '@/stores/modal/presets'
import { useEditorCommentStore } from '@/stores/editor/comment'
import { useEditorDefaultStore } from '@/stores/editor/default'
import { useSiteStore } from '@/stores/site'
import { useEditorSidebarStore } from '@/stores/editor/sidebar'

import BaseModal from '@/components/molecules/BaseModal.vue'
import BaseInputField from '@/components/atoms/BaseInputField.vue'
import SettingImageSelector from '@/components/atoms/SettingImageSelector.vue'
import api from '@/services/api'

const editorPageStore = useEditorPageStore()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const presetsStore = useModalPresetStore()
const commentStore = useEditorCommentStore()
const defaultStore = useEditorDefaultStore()
const siteStore = useSiteStore()
const editorSidebarStore = useEditorSidebarStore()

const router = useRouter()

const post = ref<any>({ value: null })

const loadingGetPage = computed(() => editorPageStore.loadingGetPage)
const loadingPorcentGetPage = computed(() => editorPageStore.loadingPorcentGetPage)
const deletedSections = computed(() => editorPageStore.deletedSections)
const editorDisabled = computed(() => editorPageStore.editorDisabled)

onBeforeMount(async () => {
    const postId = router.currentRoute.value.params.id

    await editorPageStore.getPage(Number(postId))

    await dashboardStore.handleClickPage(post)

    post.value = reactive(editorPageStore.activePage)

    await presetsStore.fetchPresets()

    await commentStore.fetchComments()
})

onMounted(() => {
    if (dashboardStore.pages.length === 0) {
        dashboardStore.fetchPages()
    }

    authStore.checkAccessType()

})

// const handleCreateLastFixedTextElement = () => {
//     const lastSection = page.value.content[page.value.content.length - 1]
//     console.log('lastSection',lastSection);
//     if (!lastSection.component.includes('maincontent')) {
//         // cria seção maincontent com o campo de texto fixo
//         const newSection = {
//             temporaryId: Date.now(),
//             ...defaultStore.newSection,
//             frameContent: [
//                 {
//                     temporaryId: Date.now(),
//                     ...defaultStore.newElementText,
//                     new: true
//                 }
//             ],
//         }

//         editorPageStore.createSection(newSection)

//         return
//     }

//     if (lastSection.frameContent[lastSection.frameContent.length - 1].new) {
//         return
//     }

//     lastSection.frameContent.push({
//         temporaryId: Date.now(),
//         ...defaultStore.newElementText,
//         extra: {
//             margin: {
//                 ...defaultStore.margin
//             },
//             margin_mobile: {
//                 ...defaultStore.margin_mobile,
//             },
//             margin_mobile_lock: defaultStore.margin_mobile_lock,
//             padding: {
//                 ...defaultStore.padding
//             },
//             padding_mobile: {
//                 ...defaultStore.padding_mobile,
//             },
//             padding_mobile_lock: defaultStore.padding_mobile_lock,
//         },
//         new: true
//     })
// }

// watch(page, () => {
//     handleCreateLastFixedTextElement()
// }, { deep: true })

const blogs = computed(() => dashboardStore.pages?.filter((page: any) => page.listPages == 1))

const checkActiveBlog = (id: number) => {
    if (editorPageStore.activePage.pages.length == 0) {
        return false
    }

    const blogParent = editorPageStore.activePage.pages.find((page: any) => page.id == id)

    return blogParent !== undefined
}

const handleRemoveBlogParent = (blogParent: any) => {
    editorPageStore.activePage.pages = editorPageStore.activePage.pages?.filter((page: any) => page.id !== blogParent.id)
}

const handleAddBlogParent = (blogParent: any) => {
    editorPageStore.activePage.pages.push({
        id: blogParent.id,
        title: blogParent.name,
    })
}

const changeBlogParent = (id: number | null) => {
    if (id === null) {
        editorPageStore.activePage.pages = []
        return
    }

    const blogParentExists = editorPageStore.activePage.pages?.find((page: any) => page.id == id)

    if (blogParentExists !== undefined) {
        return handleRemoveBlogParent(blogParentExists)
    }

    const blogParent = blogs.value.find((blog: any) => blog.id == id);

    if (blogParent === undefined) {
        return
    }

    handleAddBlogParent(blogParent)
}

const checkActiveCategory = (title: string) => {
    if (editorPageStore.activePage.categories.length == 0) {
        return false
    }

    const category = editorPageStore.activePage.categories.find((category: any) => category.title == title)

    return category !== undefined
}

const handleRemoveCategory = (categorySelected: any) => {
    editorPageStore.activePage.categories = editorPageStore.activePage.categories.filter((category: any) => category.title !== categorySelected.title)
}

const handleAddCategory = (category: any) => {
    editorPageStore.activePage.categories.push({
        id: category.id,
        title: category.title,
        route_path: category.route_path,
    })
}

const toggleCategory = (title: string) => {
    const categorySelected = siteStore.categories.find((category: any) => category.title == title)

    if (categorySelected === undefined) {
        return
    }

    const categoryExists = editorPageStore.activePage.categories.find((category: any) => category.title == title)

    if (categoryExists !== undefined) {
        return handleRemoveCategory(categoryExists)
    }

    handleAddCategory(categorySelected)
}

const categoryTitle = ref('')

const onAddCategory = async () => {
    if (categoryTitle.value === '') {
        return
    }

    const newCategory = {
        id: null,
        title: categoryTitle.value,
    }

    const categoryExists = siteStore.categories.find((category: any) => category.title == newCategory.title)

    if (categoryExists !== undefined) {
        handleAddCategory(categoryExists)

        return
    }

    await siteStore.categories.push(newCategory)

    await siteStore.refreshSiteData();

    editorPageStore.activePage.categories.push(newCategory)

    categoryTitle.value = ''
}

const onChangeThumbnail = (event: any) => {
    if (event.value === null) {
        editorPageStore.thumbnailIsAutoSelect = false;

        editorPageStore.activePage.thumbnail = {
            width: 0,
            height: 0,
            mainImage: '',
            mainImageClass: '',
            mainImageExtra: {
                background_image_focal_point: {
                    x: 50,
                    y: 50
                }
            }
        }

        return
    }

    if (event.target == 'focal-point') {
        onChangeFocalPointThumbnail(event)

        return
    }

    editorPageStore.thumbnailIsAutoSelect = false;

    editorPageStore.activePage.thumbnail = {
        width: event.value.width,
        height: event.value.height,
        mainImage: event.value.content,
        mainImageClass: 'center-center',
        mainImageExtra: {
            background_image_focal_point: {
                x: 50,
                y: 50
            }
        }
    }
}

const onChangeFocalPointThumbnail = (event: any) => {
    editorPageStore.activePage.thumbnail = {
        ...editorPageStore.activePage.thumbnail,
        mainImageExtra: {
            ...editorPageStore.activePage.thumbnail.mainImageExtra,
            background_image_focal_point: event.value
        }
    }
}

const toogleAtHome = () => {
    if (editorPageStore.activePage?.isAtHome) {
        editorPageStore.activePage.isAtHome = 0
        return
    }

    editorPageStore.activePage.isAtHome = 1
}

const setPlace = (place: any) => {
    console.log('place', place);
    editorPageStore.activePage.location = {
        address: place.formatted_address,
        address_components: place.address_components,
        geolocation: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
        }
    }
}

const onChangeShowDate = (value: number) => {
    editorPageStore.activePage.dates.showDate = value
}

const validateSchedulePost = async () => {
    try {
        const today = moment(new Date()).format("YYYY-MM-DD");
        const toPublish = moment(new Date(editorPageStore.activePage.dates?.display_date)).format(
            "YYYY-MM-DD"
        );

        const formData = {
            first: toPublish,
            seccond: today,
        };

        const response = await api.post('date/compare', JSON.stringify(formData));

        const { status, data } = await response.json();

        if (status != '1') {
            throw new Error("Erro ao validar data de publicação");
        }

        if (data) {
            editorPageStore.schedule = true

            return
        }

        editorPageStore.schedule = false
    } catch (error) {
        console.log('error', error);
    }
}

const showDate = computed(() => editorPageStore.activePage.dates?.showDate === 1)

const selectedDate: any = ref(null)

const focalPointThumbnail = computed(() => {
    return editorPageStore.activePage.thumbnail?.mainImageExtra?.background_image_focal_point ?? {
        x: 50,
        y: 50
    }
})

const postStep = computed(() => editorPageStore.postStep)

watch(postStep, () => {
    if (postStep.value == 2 && !selectedDate.value) {
        if (!editorPageStore.activePage.dates?.display_date) {
            selectedDate.value = new Date();

            return
        };

        const split = editorPageStore.activePage.dates?.display_date.split(' ');

        selectedDate.value = new Date(split[0] + "T00:00:00");
    }
})

watch(selectedDate, async () => {
    const day = String(selectedDate.value.getDate());
    const month = selectedDate.value.getMonth() + 1 < 10 ? `0${selectedDate.value.getMonth() + 1}` : String(selectedDate.value.getMonth() + 1);
    const year = selectedDate.value.getFullYear();

    editorPageStore.activePage.dates.display_date = `${year}-${month}-${day} 00:00:00`;

    await validateSchedulePost()
})

const activeElement = useActiveElement()

watch(activeElement, (newActiveElement, oldActiveElement) => {
    if (editorSidebarStore.isOpen) return

    if (editorPageStore.isBody(newActiveElement)) {
        editorPageStore.clearFrameActive();
        
        return
    }

    if (editorPageStore.ignoreActiveElement(newActiveElement)) return

    const idNewActiveElement = editorPageStore.getElement(newActiveElement)
    const idOldActiveElement = editorPageStore.getElement(oldActiveElement)

    if (idNewActiveElement?.id === idOldActiveElement?.id) return

    idNewActiveElement && editorPageStore.onActiveElement(idNewActiveElement);
    idOldActiveElement && editorPageStore.onDeactiveElement(idOldActiveElement);
}, { deep: true })

watch(() => editorPageStore.postStep, () => {
    if (editorPageStore.postStep == 2) {
        editorPageStore.checkThumbnail()
    }
}, { deep: true })
</script>

<template>
    <editor-layout id="post-editor">
        <media-manager-modal />

        <preset-modal />

        <confirmation-modal />

        <editor-sidebar />

        <text-ai-modal />

        <text-link-modal />

        <template v-if="editorPageStore.postStep == 1">
            <div class="relative h-auto">
                <div
                    :class="clsx(
                        loadingGetPage ? 'h-2' : 'h-0',
                        ' top-0 bg-[var(--main-color)] transition-all duration-300'
                    )"
                    :style="{ width: `${loadingPorcentGetPage}%` }"
                ></div>

                <!-- TODO: Adicionar bunner no erp para ativar alertas -->
                <!-- <div
                    :class="
                        clsx(
                            'transition-all duration-1000 delay-300 w-full flex justify-center items-center pb-2 left-0 right-0 bg-[var(--main-color)]',
                            loadingPorcentGetPage == 100 ? 'h-12 opacity-100' : 'h-0 opacity-0'
                        )
                    "
                >
                    <div
                        :class="
                            clsx(
                                'flex items-center text-white space-x-4 duration-1000 delay-1000',
                                loadingPorcentGetPage == 100 ? 'opacity-100' : 'opacity-0'
                            )
                        "
                    >
                        <p class="flex text-lg font-bold space-x-3">
                            <svg
                                class="w-6 h-6 fill-[#E0D0FF] animate-pulse"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                            >
                                <path
                                    d="M14.8234 2.4L16.6537 7.34621L21.5999 9.17647L16.6537 11.0067L14.8234 15.9529L12.9932 11.0067L8.04696 9.17647L12.9932 7.34621L14.8234 2.4Z"
                                />
                                <path
                                    d="M6.35284 13.6941L7.95167 16.0482L10.3058 17.6471L7.95167 19.2459L6.35284 21.6L4.75402 19.2459L2.3999 17.6471L4.75402 16.0482L6.35284 13.6941Z"
                                />
                            </svg>

                            <span>Novidade na área!</span>
                        </p>

                        <span class="font-light text-base">Experimente nossa ferramenta com IA.</span>
                    </div>
                </div> -->
            </div>

            <template v-if="!loadingGetPage">
                <editor-section
                    v-for="(section, sectionIndex) in post.content"
                    :key="`${section.frameId}`"
                    :section="section" :section-index="sectionIndex"
                    :is-deleted="useArrayIncludes(deletedSections, section).value" :has-delete="post.content.length > 1"
                    :is-editor-disabled="editorDisabled"
                />
            </template>
        </template>

        <template v-else-if="editorPageStore.postStep == 2">
            <div class="container flex-col py-20 space-y-12">
                <div class="flex flex-row items-center space-x-3">
                    <h2 class="font-bold text-2xl">
                        {{ editorPageStore.activePage?.title }}
                    </h2>

                    <button class="w-6 h-6" @click="() => toogleAtHome()">
                        <svg v-if="editorPageStore.activePage?.isAtHome === 0"
                            class="w-6 h-6 stroke-[var(--main-color)] fill-none" viewBox="0 0 24 24" fill="currentColor"
                            stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.4946 2.71381C11.7012 2.29527 12.298 2.29527 12.5046 2.71381L15.1786 8.13194C15.2606 8.29814 15.4192 8.41334 15.6026 8.43999L21.5818 9.30883C22.0437 9.37595 22.2282 9.94357 21.8939 10.2694L17.5673 14.4868C17.4346 14.6162 17.374 14.8026 17.4053 14.9852L18.4267 20.9403C18.5056 21.4004 18.0228 21.7512 17.6096 21.534L12.2616 18.7224C12.0976 18.6361 11.9016 18.6361 11.7375 18.7224L6.38953 21.534C5.9764 21.7512 5.49355 21.4004 5.57245 20.9403L6.59383 14.9852C6.62516 14.8026 6.5646 14.6162 6.43187 14.4868L2.10525 10.2694C1.77102 9.94357 1.95545 9.37595 2.41734 9.30883L8.3966 8.43999C8.58001 8.41334 8.73857 8.29814 8.8206 8.13194L11.4946 2.71381Z"
                                stroke-width="2" stroke-linejoin="round" />
                        </svg>

                        <svg v-else class="w-6 h-6 stroke-[var(--main-color)] fill-[var(--main-color)]" viewBox="0 0 24 24"
                            fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.4946 2.71381C11.7012 2.29527 12.298 2.29527 12.5046 2.71381L15.1786 8.13194C15.2606 8.29814 15.4192 8.41334 15.6026 8.43999L21.5818 9.30883C22.0437 9.37595 22.2282 9.94357 21.8939 10.2694L17.5673 14.4868C17.4346 14.6162 17.374 14.8026 17.4053 14.9852L18.4267 20.9403C18.5056 21.4004 18.0228 21.7512 17.6096 21.534L12.2616 18.7224C12.0976 18.6361 11.9016 18.6361 11.7375 18.7224L6.38953 21.534C5.9764 21.7512 5.49355 21.4004 5.57245 20.9403L6.59383 14.9852C6.62516 14.8026 6.5646 14.6162 6.43187 14.4868L2.10525 10.2694C1.77102 9.94357 1.95545 9.37595 2.41734 9.30883L8.3966 8.43999C8.58001 8.41334 8.73857 8.29814 8.8206 8.13194L11.4946 2.71381Z"
                                stroke-width="2" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>

                <div class="w-full flex space-x-20">
                    <div class="w-1/3 h-full flex flex-col justify-start">
                        <setting-image-selector
                            label="Capa"
                            :value="editorPageStore.activePage?.thumbnail.mainImage"
                            :focal-point="focalPointThumbnail"
                            with-remove
                            with-focal-point
                            @change="onChangeThumbnail"
                        />

                        <span
                            class="mt-3 text-center text-xs font-regular text-[#060606] opacity-50"
                            v-if="editorPageStore.thumbnailIsAutoSelect"
                        >
                            *Seleção automatica da capa, você pode alterar se preferir.
                        </span>
                    </div>

                    <div class="w-2/3 h-full flex flex-col justify-start space-y-10">
                        <div class="flex flex-col space-y-4">
                            <div class="space-y-2">
                                <span class="text-sm font-semibold text-[#060606] opacity-50" for="">
                                    Data de publicação
                                </span>

                                <div class="w-full flex space-x-6">
                                    <div class="w-auto flex items-center space-x-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.75 17.2202V17.1428M12.25 17.2202V17.1428M12.25 13.0286V12.9512M16.25 13.0286V12.9512M4.75 8.91425H18.75M6.55953 3V4.54304M16.75 3V4.54285M16.75 4.54285H6.75C5.09315 4.54285 3.75 5.92436 3.75 7.62855V17.9143C3.75 19.6185 5.09315 21 6.75 21H16.75C18.4069 21 19.75 19.6185 19.75 17.9143L19.75 7.62855C19.75 5.92436 18.4069 4.54285 16.75 4.54285Z"
                                                stroke="var(--main-color)" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>

                                        <Datepicker
                                            :input-format="'dd/MM/yyyy'"
                                            :placeholder="'DD/MM/AAAA'"
                                            :upper-limit="new Date(2025, 1, 1)"
                                            v-model="selectedDate"
                                            :class="clsx('w-full text-[#060606] font-semibold text-2xl bg-transparent border-none outline-none')"
                                        />
                                    </div>

                                    <label class="w-full flex items-center space-x-2" for="show-date-publish">
                                        <input @change="(event: any) => onChangeShowDate(event.target.checked ? 1 : 0)"
                                            :checked="showDate" type="checkbox" name="show-date-publish"
                                            id="show-date-publish">

                                        <span class="text-[#060606] text-sm font-semibold text-">
                                            Exibir data de publicação
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="space-y-4">
                            <label class="text-sm font-semibold text-[#060606] opacity-50" for="">Postar conteúdo em</label>

                            <div class="w-full flex space-x-4">
                                <button :class="clsx(
                                    'py-3 px-6 text-base font-semibold text-[#060606] bg-transparent rounded-full border-2',
                                    editorPageStore.activePage?.pages.length == 0 ? 'border-[var(--main-color)]' : 'border-[#060606] border-opacity-10',
                                )" @click="() => changeBlogParent(null)">
                                    Nenhum
                                </button>

                                <button v-for="blog in blogs" :key="blog.id" :class="clsx(
                                    'py-3 px-6 text-base font-semibold text-[#060606] bg-transparent rounded-full border-2',
                                    checkActiveBlog(blog.id) ? 'border-[var(--main-color)]' : 'border-[#060606] border-opacity-10',
                                )" @click="() => changeBlogParent(blog.id)">
                                    {{ blog.name }}
                                </button>
                            </div>
                        </div>

                        <div class="space-y-4">
                            <span class="text-sm font-semibold text-[#060606] opacity-50">
                                Categorias
                            </span>

                            <div class="w-full flex flex-wrap gap-3">
                                <base-modal title="Adicionar Categoria" size="custom" height="h-[14rem]" width="w-[30rem]"
                                    rounded="2xl">
                                    <template #trigger="{ open }">
                                        <button @click="open" class="w-auto h-auto">
                                            <span class="sr-only">
                                                Add Categorie
                                            </span>

                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.9">
                                                    <path
                                                        d="M25.625 19.9991H20M20 19.9991H14.375M20 19.9991V25.6241M20 19.9991L20 14.3741M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z"
                                                        stroke="var(--main-color)" stroke-width="2.5" stroke-linecap="round" />
                                                </g>
                                            </svg>
                                        </button>
                                    </template>

                                    <template #body="{ close }">
                                        <div class="h-full flex flex-col justify-between">
                                            <div class="flex-1">
                                                <base-input-field label="Nome" id="nome">
                                                    <input type="text" id="nome" name="nome" v-model="categoryTitle" />
                                                </base-input-field>
                                            </div>

                                            <div class="flex justify-end mt-4 space-x-2">
                                                <div>
                                                    <button @click="close"
                                                        class="px-4 py-2 text-sm text-[#D9D9D9] bg-transparent rounded-full hover:text-gray-500 outline-none">
                                                        Cancelar
                                                    </button>

                                                    <button @click="async () => { await onAddCategory(); close() }" :class="clsx(
                                                        'bg-[var(--main-color)] text-white px-4 py-2 text-sm font-medium rounded-full outline-none'
                                                    )
                                                        ">
                                                        Adicionar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </base-modal>

                                <button v-for="(category, index) in siteStore.categories"
                                    @click="() => toggleCategory(category.title)" :key="index" :class="clsx(
                                        'py-1 px-3 text-xs font-semibold text-[#060606] bg-transparent rounded-full border-2',
                                        checkActiveCategory(category.title) ? 'border-[var(--main-color)]' : 'border-[#060606] border-opacity-10',
                                    )">
                                    {{ category.title }}
                                </button>
                            </div>
                        </div>

                        <hr class="border-2 border-dashed">

                        <settings-layout name="Configurações Avançadas">
                            <setting-autocomplete-location
                                label="Localização"
                                id="location"
                                name="location"
                                :value="editorPageStore.activePage?.location.address"
                                placeholder="Pesquise uma localização"
                                @change="(event) => setPlace(event.value)"
                            />

                            <div class="flex flex-col space-y-4">
                                <label class="text-sm font-semibold text-[#060606] opacity-50" for="">
                                    Descrição
                                </label>

                                <div class="w-full flex space-x-4">
                                    <textarea v-model="editorPageStore.activePage.summary"
                                        class="w-full text-sm bg-[#fcfcfc] outline-none px-4 py-3 rounded border-[#F5F5F5] border-2"
                                        name="" id="" cols="30" rows="10"></textarea>
                                </div>
                            </div>
                        </settings-layout>
                    </div>
                </div>
            </div>
        </template>
    </editor-layout>
</template>

<style scoped>
.container {
    @apply mx-auto h-full flex items-start justify-start max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px];
}
</style>
