import { ref, watch } from 'vue'

import { defineStore } from 'pinia'
import api from '@/services/api'

/**
 * @description Editor Presets Store
 */
export const useModalPresetStore = defineStore('modal-preset', () => {
    // statess
    const isOpenModal = ref(false)

    const loading = ref(false)
    const presetsLoading = ref(false)

    const activeFilter = ref('all')
    const presetSelected = ref<any>(null)

    const presets = ref<any[]>([])
    const presetsFiltered = ref<any[]>([])
    const presetsForLoading = ref<any[]>([
        {
            id: 0
        },
        {
            id: 1
        },
        {
            id: 2
        },
        {
            id: 3
        },
        {
            id: 4
        },
        {
            id: 5
        },
        {
            id: 6
        },
        {
            id: 7
        },
        {
            id: 8
        },
        {
            id: 9
        },
        {
            id: 10
        },
        {
            id: 11
        },
        {
            id: 12
        },
        {
            id: 13
        },
        {
            id: 14
        },
        {
            id: 15
        },
        {
            id: 16
        },
        {
            id: 17
        },
        {
            id: 18
        },
        {
            id: 19
        },
        {
            id: 20
        }
    ])

    const filters = ref<any[]>([
        {
            id: 1,
            name: 'Todos',
            value: 'all'
        },
        {
            id: 2,
            name: 'Texto',
            value: 'text'
        },
        {
            id: 3,
            name: 'Imagem',
            value: 'image'
        },
        {
            id: 4,
            name: 'Ícones',
            value: 'icon'
        },
        {
            id: 5,
            name: 'Depoimentos',
            value: 'testimonial'
        },
        {
            id: 6,
            name: 'Botão',
            value: 'button'
        },
        {
            id: 7,
            name: 'Blog',
            value: 'blog'
        },
        {
            id: 8,
            name: 'Formulário',
            value: 'form'
        },
        {
            id: 9,
            name: 'FAQ',
            value: 'faq'
        },
        {
            id: 10,
            name: 'Avançado',
            value: 'advanced'
        }
    ])

    const action = ref<Function | null>(null)
    // fim states

    // actions
    const setAction = (value: Function | null) => {
        action.value = value
    }

    const fetchPresets = async () => {
        loading.value = true

        try {
            const response = await api.get('frame/organism/combination?filters=active:=:1')

            const { status, message, data } = await response.json()

            if (!status) {
                throw new Error(message)
            }

            setPresets(data)
        } catch (error) {
            console.warn(error)
        }
        
        loading.value = false
    }

    const filterPresets = async (filterType: string) => {
        if (activeFilter.value === 'all') {
            return presetsFiltered.value = presets.value
        }

        return presetsFiltered.value = presets.value.filter(
            preset => preset.type.toLowerCase().includes(filterType.toLowerCase())
        )
    }

    const setIsOpenModal = (value: boolean) => {
        isOpenModal.value = value
    }

    const setPresets = (value: any[]) => {
        presets.value = value
    }

    const setActiveFilter = (value: string) => {
        activeFilter.value = value
    }

    const handleCancel = () => {
        setIsOpenModal(false)

        presetSelected.value = null
    }

    const handleConfirm = () => {
        setIsOpenModal(false)

        if (action.value) {
            action.value(presetSelected.value)
        }

        presetSelected.value = null
    }

    const setPresetSelected = (value: any) => {
        presetSelected.value = value
    }

    const reset = () => {
        isOpenModal.value = false
        loading.value = false
        presets.value = []
    }

    watch(isOpenModal, async () => {
        if (!isOpenModal.value) {
            loading.value = false
            presetSelected.value = null
            activeFilter.value = 'all'
        }
    })

    watch(presets, async () => {
       await filterPresets('all')
    }, {
        deep: true
    })

    watch(activeFilter, async () => {
        await filterPresets(activeFilter.value)
    })
    // fim actions

    return {
        isOpenModal,
        loading,
        action,
        presetsLoading,
        presetSelected,
        presetsFiltered,
        activeFilter,
        presets,
        filters,
        presetsForLoading,
        setAction,
        fetchPresets,
        setPresetSelected,
        filterPresets,
        setActiveFilter,
        setIsOpenModal,
        handleCancel,
        handleConfirm,
        reset
    }
})
