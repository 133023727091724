<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
        required: true
    },
    idd: {
        type: String
    },
    phone: {
        type: String,
        required: false
    },
})

const emit = defineEmits(['change'])

const onChangeIdd = (event: any) => {
    emit('change', {
        target: 'idd',
        value: event.target.value
    })
}

const onChangePhone = (event: any) => {
    emit('change', {
        target: 'phone',
        value: event.target.value
    })
}

</script>

<template>
    <div class="space-y-3">
        <div class="space-y-2">
            <span class="text-sm font-semibold text-[#060606] opacity-50">
                {{ label }}
            </span>

            <div class="flex w-full space-x-3">
                <input
                    @input="onChangeIdd"
                    class="w-1/3 p-3 text-sm outline-none"
                    type="text"
                    :value="idd"
                >

                <input
                    @input="onChangePhone"
                    class="w-2/3 p-3 text-sm outline-none"
                    type="text"
                    :value="phone"
                >
            </div>
        </div>
    </div>
</template>

<style scoped></style>
