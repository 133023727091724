<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'

import countries from '@/data/countries.json'
import whatsOptions from '@/data/whatsOptions.json'

import BaseInputField from '@/components/atoms/BaseInputField.vue'

import { useSiteStore } from '@/stores/site'
import { useApplicationStore } from '@/stores/application'
import { useSidebarDashboardStore } from '@/stores/dashboard/sidebar'

const siteStore = useSiteStore()
const applicationStore = useApplicationStore()
const sidebarDashboardStore = useSidebarDashboardStore()

const dataForEdit = ref({
    address: '',
    location: {
        lat: 0,
        lng: 0,
    },
    userEmail: '',
    mainEmail: '',
    contactPhone: '',
    whatsappButton: '',
    whatsappMessage: '',
    whatsappIndex: 0,
    idd: applicationStore.appDefaultIdd,
})

onBeforeMount(() => {
    dataForEdit.value = JSON.parse(
        JSON.stringify({
            address: siteStore.address,
            userEmail: siteStore.contactEmail,
            location: siteStore.location,
            mainEmail: siteStore.mainEmail,
            contactPhone: siteStore.contactPhone,
            whatsappButton: siteStore.whatsappTitle,
            whatsappMessage: siteStore.whatsappMessage,
            whatsappIndex: siteStore.whatsappIndex,
            idd: siteStore.phoneCountry ?? applicationStore.appDefaultIdd,
        })
    )
})

const onChangeIdd = (event: any) => {
    dataForEdit.value.idd = event.target.value
}

const onSaveChanges = async () => {
    await siteStore.handleUpdateContact(dataForEdit.value)

    sidebarDashboardStore.setMenuOption(null);
}

const onChangeWhatsOption = (option: any) => {
    dataForEdit.value.whatsappIndex = option.value
    dataForEdit.value.whatsappButton = option.text
    dataForEdit.value.whatsappMessage = option.message
}

const onChangeAddress = (value: any) => {
    if (value.type == 'input') {
        dataForEdit.value.address = value.target.value

        return
    }

    dataForEdit.value.address = value.formatted_address

    dataForEdit.value.location = {
        lat: value.geometry?.location?.lat(),
        lng: value.geometry?.location?.lng(),
    }
}
</script>

<template>
    <button
        @click="onSaveChanges"
        class="font-bold text-[var(--main-color)] absolute right-6 top-3.5"
    >
        OK
    </button>

    <div class="px-6 space-y-3">
        <h2 class="text-2xl font-bold">Perfil</h2>

        <base-input-field label="Email de contato" id="userEmail">
            <input type="email" name="userEmail" id="userEmail" v-model="dataForEdit.userEmail" />
        </base-input-field>

        <base-input-field label="Email de orçamento" id="mainEmail">
            <input type="email" name="mainEmail" id="mainEmail" v-model="dataForEdit.mainEmail" />
        </base-input-field>

        <base-input-field label="Qual seu endereço?" id="address">
            <g-map-autocomplete
                :value="dataForEdit.address"
                :placeholder="'Digite seu endereço'"
                @input="onChangeAddress"
                @place_changed="(event: any) => onChangeAddress(event)"
                class="h-10 border-b-[1px] focus:border-[var(--main-color)] outline-none bg-transparent"
            />
        </base-input-field>

        <div class="w-full">
            <label class="text-base font-semibold text-gray-400" for="">Telefone de contato</label>

            <div class="flex space-x-3">
                <div class="flex flex-col w-1/3">
                    <base-input-field label="DDI" id="idd" type="select">
                        <select @change="onChangeIdd($event)" name="idd" id="idd">
                            <option
                                v-for="(country, index) in countries"
                                :key="index"
                                :value="country.id"
                                :selected="country.id === dataForEdit.idd"
                            >
                                {{ country.emoji + ' (+' + country.phonecode + ')' }}
                            </option>
                        </select>
                    </base-input-field>
                </div>

                <div class="flex flex-col w-2/3">
                    <base-input-field label="Telefone (DDD + Número)" id="contactPhone">
                        <input
                            v-if="dataForEdit.idd == '30'"
                            type="tel"
                            name="contactPhone"
                            id="contactPhone"
                            v-model="dataForEdit.contactPhone"
                            v-maska
                            data-maska="(##) #####-####"
                        />

                        <input
                            v-else
                            type="tel"
                            name="contactPhone"
                            id="contactPhone"
                            v-model="dataForEdit.contactPhone"
                            v-maska
                            data-maska="############"
                        />
                    </base-input-field>
                </div>
            </div>
        </div>

        <div v-if="applicationStore.className != 'epics'" class="w-full flex flex-col space-y-3">
            <base-input-field label="Tipo de botão">
                <label
                    v-for="(button, index) in whatsOptions"
                    :key="index"
                    class="cursor-pointer flex space-x-2 justify-start items-center"
                    :for="'type-' + index"
                >
                    <input
                        type="radio"
                        name="type-button"
                        :id="'type-' + index"
                        :checked="button.value == dataForEdit.whatsappIndex"
                        @change="onChangeWhatsOption(button)"
                    />

                    <span class="text-xs">
                        {{ button.label }}
                    </span>
                </label>
            </base-input-field>

            <template v-if="dataForEdit.whatsappIndex == 6">
                <base-input-field label="Texto do Botão" id="whatsappButton">
                    <input
                        type="text"
                        name="whatsappButton"
                        id="whatsappButton"
                        v-model="dataForEdit.whatsappButton"
                    />
                </base-input-field>

                <base-input-field label="Texto da Mensagem" id="whatsappMessage">
                    <input
                        type="text"
                        name="whatsappMessage"
                        id="whatsappMessage"
                        v-model="dataForEdit.whatsappMessage"
                    />
                </base-input-field>
            </template>
        </div>
    </div>
</template>

<style></style>
