<script setup lang="ts">
import { ref, computed, markRaw } from 'vue'

import IconSettingText from '@/components/atoms/icons/IconSettingText.vue';
import IconSettingLayout from '@/components/atoms/icons/IconSettingLayout.vue';

import SettingTypeButton from '@/components/atoms/SettingTypeButton.vue';
import SettingLink from '@/components/atoms/SettingLink.vue';
import SettingWhatsapp from '@/components/atoms/SettingWhatsapp.vue';
import SettingFontSize from '@/components/atoms/SettingFontSize.vue';
import SettingFontWeight from '@/components/atoms/SettingFontWeight.vue';
import SettingColorPicker from '@/components/atoms/SettingColorPicker.vue';
import SettingBorderRadius from '@/components/atoms/SettingBorderRadius.vue';

import SettingWidth from '@/components/atoms/SettingWidth.vue'
import SettingTextAlign from '@/components/atoms/SettingTextAlign.vue';
import SettingMargin from '@/components/atoms/SettingMargin.vue';
import SettingPadding from '@/components/atoms/SettingPadding.vue';

import EditorSidebarLayout from '@/components/layouts/EditorSidebarLayout.vue';
import SettingsLayout from '@/components/layouts/SettingsLayout.vue';

import { useEditorPageStore } from '@/stores/editor/page'
import { useLayoutStore } from '@/stores/layout'

const editorPageStore = useEditorPageStore()
const layoutStore = useLayoutStore()

const elementForEdit = computed(() => editorPageStore.elementForEdit)

const onChangeWidth = (event: any) => {
    if (event.target == 'desktop') {
        editorPageStore.elementForEdit.grid = event.value

        editorPageStore.updateElementForEdit()

        return
    }

    if (event.target == 'mobile') {
        editorPageStore.elementForEdit.gridMobile = event.value

        editorPageStore.updateElementForEdit()
        
        return
    }
}

const onChangeMargin = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin: {
                ...editorPageStore.elementForEdit.extra.margin,
                [event.target]: event.value
            }
        }

        editorPageStore.updateElementForEdit()

        return
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile: {
                ...editorPageStore.elementForEdit.extra.margin_mobile,
                [event.target]: event.value
            }
        }

        editorPageStore.updateElementForEdit()
        
        return
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            margin_mobile_lock: event.value
        }

        editorPageStore.updateElementForEdit()
        
        return
    }
}

const onChangePadding = (event: any) => {
    if (event.config == 'desktop') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding: {
                ...editorPageStore.elementForEdit.extra.padding,
                [event.target]: event.value
            }
        }

        editorPageStore.updateElementForEdit()

        return
    }

    if (event.config == 'mobile') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile: {
                ...editorPageStore.elementForEdit.extra.padding_mobile,
                [event.target]: event.value
            }
        }
        
        editorPageStore.updateElementForEdit()

        return
    }

    if (event.config == 'lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            padding_mobile_lock: event.value
        }

        editorPageStore.updateElementForEdit()

        return
    }
}

const settings = ref([
    {
        id: 1,
        name: 'title',
        icon: markRaw(IconSettingText)
    },
    {
        id: 2,
        name: 'layout',
        icon: markRaw(IconSettingLayout)
    },
])

const onChangeStyle = (event: any) => {
    if (event.target == 'font-size') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                fontSize: event.value
            }
        }
    }

    if (event.target == 'font-weight') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                fontWeight: event.value
            }
        }
    }

    if (event.target == 'text-align') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                textAlign: event.value
            }
        }
    }

    if (event.target == 'border-radius') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style: {
                ...editorPageStore.elementForEdit.extra.style,
                borderRadius: event.value
            }
        }
    }

    if (event.target == 'style_font_size_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_font_size_lock: event.value
        }
    }

    if (event.target == 'style_font_weight_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_font_weight_lock: event.value
        }
    }

    if (event.target == 'style_text_align_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_text_align_lock: event.value
        }
    }

    if (event.target == 'style_border_radius_lock') {
        editorPageStore.elementForEdit.extra = {
            ...editorPageStore.elementForEdit.extra,
            style_border_radius_lock: event.value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangeColor = (value: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        style: {
            ...editorPageStore.elementForEdit.extra.style,
            color: value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangeBackgorundColor = (value: any) => {
    editorPageStore.elementForEdit.extra = {
        ...editorPageStore.elementForEdit.extra,
        style: {
            ...editorPageStore.elementForEdit.extra.style,
            backgroundColor: value
        }
    }

    editorPageStore.updateElementForEdit()
}

const onChangeLink = (event: any) => {
    if (event.target == 'link') {
        editorPageStore.elementForEdit.frameContent.ref = event.value
        
        editorPageStore.updateElementForEdit()

        return
    }

    if (event.target == 'open-in') {
        editorPageStore.elementForEdit.frameContent.settings = {
            ...settings,
            target: event.value
        }

        editorPageStore.updateElementForEdit()

        return
    }
}

const onChangeTypeButton = (event: any) => {
    editorPageStore.elementForEdit.frameContent.settings = {
        ...editorPageStore.elementForEdit.frameContent.settings,
        type: event.value
    }

    editorPageStore.updateElementForEdit()
}

const onChangeWhatsapp = (event: any) => {
    if (event.target == 'phone') {
        editorPageStore.elementForEdit.frameContent.dataPhone = {
            ...editorPageStore.elementForEdit.frameContent.dataPhone,
            phone: event.value
        }

        editorPageStore.updateElementForEdit()

        return
    }

    if (event.target == 'idd') {
        editorPageStore.elementForEdit.frameContent.dataPhone = {
            ...editorPageStore.elementForEdit.frameContent.dataPhone,
            phone: event.value
        }

        editorPageStore.updateElementForEdit()

        return
    }
}
</script>

<template>
    <editor-sidebar-layout
        :settings="settings"
    >
        <template #title>
            <settings-layout
                name="Configurações do Título"
                :default-open="true"
            >
                <setting-type-button
                    label="Tipo de Botão"
                    :value="elementForEdit.frameContent.settings.type"
                    @change="onChangeTypeButton"
                />

                <setting-link
                    v-if="elementForEdit.frameContent.settings.type == 'url'"
                    label="Link"
                    type="string"
                    :value="elementForEdit.frameContent.ref"
                    :value-open-in="elementForEdit.frameContent.settings.target"
                    @change="onChangeLink"
                />

                <setting-whatsapp
                    v-if="elementForEdit.frameContent.settings.type == 'whatsapp'"
                    label="Telefone"
                    :idd="elementForEdit.frameContent.dataPhone.idd"
                    :phone="elementForEdit.frameContent.dataPhone.phone"
                    @change="onChangeWhatsapp"
                />

                <setting-color-picker
                    label="Cor do Texto"
                    :value="elementForEdit.extra.style?.color"
                    :default-value="
                        layoutStore.getButtonColor(elementForEdit.sectionIndex) ?? ''
                    "
                    @change="onChangeColor"
                />

                <setting-border-radius
                    label="Borda Arredondada"
                    :value="elementForEdit.extra.style?.borderRadius"
                    :lock="elementForEdit.extra?.style_border_radius_lock"
                    @change="onChangeStyle"
                />

                <setting-color-picker
                    label="Cor do Botão"
                    :value="elementForEdit.extra.style?.backgroundColor"
                    :default-value="
                        layoutStore.getButtonBackgroundColor(elementForEdit.sectionIndex) ?? ''
                    "
                    @change="onChangeBackgorundColor"
                />

                <setting-font-size
                    label="Tamanho da Fonte"
                    :value="elementForEdit.extra.style?.fontSize"
                    :lock="elementForEdit.extra?.style_font_size_lock"
                    @change="onChangeStyle"
                />

                <setting-font-weight
                    label="Espessura da Fonte"
                    :value="elementForEdit.extra.style?.fontWeight"
                    :lock="elementForEdit.extra?.style_font_weight_lock"
                    @change="onChangeStyle"
                />
            </settings-layout>
        </template>

        <template #layout>
            <settings-layout
                name="Configurações do Conteúdo"
                :default-open="true"
            >
                <setting-width
                    label="Largura"
                    :value="elementForEdit.grid"
                    :value-mobile="elementForEdit.gridMobile"
                    :values-permission-desktop="[4, 6, 12]"
                    :values-permission-mobile="[4, 6, 12]"
                    @change="onChangeWidth"
                />

                <setting-text-align
                    label="Alinhamento"
                    :value="elementForEdit.extra.style?.textAlign"
                    :lock="elementForEdit.extra?.style_text_align_lock"
                    @change="onChangeStyle"
                />
            </settings-layout>

            <settings-layout
                name="Avançado"
            >
                <setting-margin
                    label="Margem Externa"
                    :value="elementForEdit.extra?.margin"
                    :value-mobile="elementForEdit.extra?.margin_mobile"
                    :lock="elementForEdit.extra?.margin_mobile_lock"
                    @change="onChangeMargin"
                />

                <setting-padding
                    label="Margem Interna"
                    :value="elementForEdit.extra?.padding"
                    :value-mobile="elementForEdit.extra?.padding_mobile"
                    :lock="elementForEdit.extra?.padding_mobile_lock"
                    @change="onChangePadding"
                />
            </settings-layout>
        </template>
    </editor-sidebar-layout>
</template>

<style scoped></style>
