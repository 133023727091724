import { useApplicationStore } from '@/stores/application'
import { useAuthStore } from '@/stores/auth'

export default async function auth({ next }: any) {
    const applicationStore = useApplicationStore()
    const authStore = useAuthStore()

    const result = await authStore.checkToken()

    if (!result) {
        console.log('Not logged in')

        return (document.location.href = '//' + applicationStore.appBaseDomain)
    }

    return next()
}
