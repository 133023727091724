import { defineStore } from 'pinia'

import { reactive, toRefs } from 'vue'

import { useEditorPageStore } from '../editor/page'

/**
 * @description AI Store
 */
export const useAiStore = defineStore('ai', () => {
    const editorPageStore = useEditorPageStore()

    // states
    const state = reactive({
        isOpenModal: false,
        model: '',
        action: null as Function | null,
        content: '',
        countReturnStream: 0,
        inGenerateText: false,
    })
    // fim states

    // actions
    const setModel = (model: string) => {
        state.model = model
    }

    const setIsOpenModal = (isOpenModal: boolean) => {
        state.isOpenModal = isOpenModal
    }

    const setAction = (value: Function | null) => {
        state.action = value
    }

    const validateStructure = (text: string) => {
        state.countReturnStream++

        if (state.countReturnStream == 1) {
            state.content += '<p>' + text

            return
        }

        state.content += text

        return
    }
    
    const generateText = (prompt: string, element: any, schema: any) => {
        state.inGenerateText = true
        
        const evtSource = new EventSource(import.meta.env.VITE_AI_API_URL + 'api/textComplete?prompt=' + prompt);

        if (element.frameContent !== '') {
            state.content = element.frameContent
        }

        evtSource.onopen = () => {
            console.log("Connection to server opened.");
        };

        evtSource.onmessage = (e: any) => {
            validateStructure(e.data)

            editorPageStore.updateElementBySchema(schema, {
                ...element,
                frameContent: state.content,
            });
        };

        evtSource.onerror = () => {
            evtSource.close();

            state.content = ''

            state.inGenerateText = false
        };
    }

    const reset = () => {
        state.isOpenModal = false
        state.model = ''
        state.content = ''
        state.countReturnStream = 0
        state.action = () => {}
    }
    // fim actions

    return {
        ...toRefs(state),
        setModel,
        setIsOpenModal,
        setAction,
        generateText,
        reset
    }
})
