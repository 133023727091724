<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import CardChartReport from '@/components/molecules/CardChartReport.vue'
import CardReport from '@/components/molecules/CardReport.vue'
import api from '@/services/api'

import { useSiteStore } from '@/stores/site'

const siteStore = useSiteStore()

const siteId = computed(() => siteStore.id)

const render = ref<boolean>(true)
const days = ref<number>(30)
const blockSelect = ref<boolean>(true)

const opportunitiesReportTitle = ref<any>('')
const opportunitiesReportValue = ref<any>('')
const opportunitiesReportConversionRate = ref<any>('')
const opportunitiesReportList = ref<any>('')

const accessReportTitle = ref<any>('')
const accessReportValue = ref<any>('')

const mainColor = ref<string>('')

const chart = ref<any>(null)
const chartDate = ref<string>('')
const chartData = ref<any>({
    labels: '',
    datasets: []
})
const chartOptions = ref<any>({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            beginAtZero: true
        }
    },
    elements: {
        point: {
            radius: 0
        }
    },
    layout: {
        padding: {
            top: 20,
            bottom: 20,
            left: 30,
            right: 30
        }
    },
    interaction: {
        intersect: false,
        mode: 'index'
    }
})

const getReportData = async () => {
    blockSelect.value = true

    try {
        const url = `site/${siteId.value}/report?d=${days.value}`

        const response = await api.get(url)

        const { status, message, data } = await response.json()

        if (status == 0) {
            throw new Error(message)
        }

        if (data) {
            mainColor.value = data.mainColor

            chart.value = data.charts[0]

            data.reportCards.find((item: any) => {
                if (item.reportTitle == 'Oportunidades') {
                    opportunitiesReportTitle.value = item.reportTitle
                    opportunitiesReportValue.value = item.reportValue
                    opportunitiesReportConversionRate.value = item.conversionRate
                    opportunitiesReportList.value = item.reportList

                    return
                }

                if (item.reportTitle == 'Acessos') {
                    accessReportTitle.value = item.reportTitle
                    accessReportValue.value = item.reportValue

                    return
                }
            })

            mountDate()

            render.value = true
        }
    } catch (error) {
        console.warn('getReportData', error)
        render.value = false
    }

    blockSelect.value = false
}

const mountDate = () => {
    const dataAtual = new Date()
    const dia = dataAtual.getDate()
    const mes = dataAtual.getMonth()
    const ano = dataAtual.getFullYear()
    const dateReport = new Date(dataAtual.setDate(dataAtual.getDate() - days.value))
    const diaReport = dateReport.getDate()
    const mesReport = dateReport.getMonth()
    const anoReport = dateReport.getFullYear()
    chartDate.value =
        diaReport +
        ' ' +
        getMesPT(mesReport) +
        ' ' +
        anoReport +
        ' - ' +
        dia +
        ' ' +
        getMesPT(mes) +
        ' ' +
        ano
}

const getMesPT = (mes: number) => {
    var ano = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

    return ano[mes]
}

watch(days, () => {
    if (blockSelect.value) {
        return
    }

    getReportData()
})

watch(chart, () => {
    chartData.value.labels = chart.value?.xaxis

    chartData.value.datasets[0] = {
        label: chart.value?.series?.name,
        backgroundColor: mainColor.value,
        data: chart.value?.series?.data,
        borderColor: mainColor.value,
        borderWidth: 4,
        pointBorderColor: mainColor.value,
        pointBorderWidth: 2,
        pointHoverBackgroundColor: mainColor.value,
        pointHoverBorderColor: '#ffffff',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 6,
        tension: 0.3
    }
})

onMounted(() => {
    getReportData()
})
</script>

<template>
    <div v-if="render" class="space-y-2">
        <div class="w-full flex justify-between">
            <span class="text-xs text-gray-400">
                {{ chartDate }}
            </span>

            <select
                v-model="days"
                class="text-xs focus:outline-none border-b-[1px] border-transparent focus:border-[var(--main-color)] appearance-none"
                :disabled="blockSelect"
            >
                <option value="365">Último ano</option>
                <option value="90">Últimos 90 dias</option>
                <option value="30">Últimos 30 dias</option>
                <option value="7">Últimos 7 dias</option>
            </select>
        </div>

        <div class="w-full grid grid-cols-2 md:grid-cols-4 gap-5">
            <template v-if="!blockSelect">
                <card-chart-report
                    id="card-chart-report-access"
                    :data="chartData"
                    :options="chartOptions"
                />

                <card-report
                    id="card-report-opportunities"
                    :title="opportunitiesReportTitle"
                    :value="opportunitiesReportValue"
                    :conversion-rate="opportunitiesReportConversionRate"
                    :list="opportunitiesReportList"
                />

                <card-report
                    id="card-report-access"
                    :title="accessReportTitle"
                    :value="accessReportValue"
                />
            </template>

            <template v-else>
                <div
                    class="cursor-wait col-span-2 w-full h-48 animate-pulse bg-gray-100 relative rounded-xl"
                ></div>

                <div
                    class="cursor-wait w-full h-48 animate-pulse bg-gray-100 relative rounded-xl"
                ></div>

                <div
                    class="cursor-wait w-full h-48 animate-pulse bg-gray-100 relative rounded-xl"
                ></div>
            </template>
        </div>
    </div>
</template>

<style scoped></style>
