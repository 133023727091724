<script setup lang="ts">
import { ref, computed, watch } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'

import MasonryWall from '@yeger/vue-masonry-wall'
import BaseImage from '@/components/atoms/BaseImage.vue'
import ImageUploader from '@/components/atoms/ImageUploader.vue'

import { useMediaManagerStore } from '@/stores/modal/media-manager'
import modal from '@/services/modal'
import notification from '@/services/notification'

const mediaManagerStore = useMediaManagerStore()

const mediasContainer = ref()

const loading = computed(() => mediaManagerStore.loading)

const medias = computed(() => mediaManagerStore.medias)
const selectedMedias = computed(() => mediaManagerStore.selectedMedias)

const nextUrl = computed(() => mediaManagerStore.nextUrl)

const mediasLoading = computed(() => mediaManagerStore.mediasLoading)
const mediasForLoading = computed(() => mediaManagerStore.mediasForLoading)

const infiniteScroll = ref(null)
const infiniteScrollIsVisible = ref(false)

const handleSelectMedia = (media: any) => {
    mediaManagerStore.selectMedia(media)
}

const isMediaSelected = (media: any) => {
    return mediaManagerStore.isMediaSelected(media)
}

const onLoadMore = async () => {
    if (loading.value) return

    const currentScrollPosition = mediasContainer.value.scrollTop

    await mediaManagerStore.fetchNextMedias()

    mediasContainer.value.scrollTop = currentScrollPosition
}

const { stop } = useIntersectionObserver(
    infiniteScroll,
    ([{ isIntersecting }], observerElement) => {
        infiniteScrollIsVisible.value = isIntersecting
    }
)

const defineTypeClass = (media: any) => {
    if (media.type === 'image') {
        return parseInt(media.width) < parseInt(media.height)
            ? `image-vertical`
            : `image-horizontal`
    }
}

const handleAction = () => {
    if (mediaManagerStore.action) {
        if (mediaManagerStore.maxSelectedMedias == 1) {
            mediaManagerStore.action(selectedMedias.value[0])
        } else {
            mediaManagerStore.action(selectedMedias.value)
        }
    }

    mediaManagerStore.reset()
}

watch(infiniteScrollIsVisible, isVisible => {
    if (isVisible) {
        setTimeout(() => {
            onLoadMore()
        }, 300)
    }
})

const handleDeleteMedia = async (media: any) => {
    mediaManagerStore.closeDisabled = true

    const title = 'Confirmar Exclusão'
    const message = 'Deseja realmente excluir esta mídia?'
    const type = 'danger'
    const confirm = 'Sim, excluir'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        mediaManagerStore.closeDisabled = false

        return
    }

    mediaManagerStore.deleteMedias([media])
    
    mediaManagerStore.closeDisabled = false
}

const handleCopyLink = (media: any) => {
    try {
        navigator.clipboard.writeText(import.meta.env.VITE_CDN_URL + '/' + media.content)
        
        notification.simple('Link copiado com sucesso!', 'success')
    } catch (error) {
        notification.simple('Erro ao copiar link!', 'error')

        console.error(error)
    }
}

const onClickMidia = (event: any, media: any) => {
    if (
        event.target.id === 'delete-midia' ||
        event.target.id === 'icon-delete-midia' ||
        event.target.id === 'copy-link-midia' ||
        event.target.id === 'icon-copy-link-midia' ||
        event.srcElement.localName == 'path'
    ) {
        return
    }

    handleSelectMedia(media)
}
</script>

<template>
    <div 
        ref="mediasContainer"
        v-if="!mediasLoading"
        class="w-full h-full pb-[12vh] overflow-y-scroll scroll-hidden relative"
    >
        <div 
            v-if="mediaManagerStore.isOverDropZone"
            class="fixed inset-0 z-50 bg-gray-100 border-dashed border-8 opacity-30 border-[var(--main-color)]"
        ></div>
        
        <template
            v-if="medias.length > 0"
        >
            <masonry-wall 
                :items="medias"
                :min-columns="4"
                :gap="20"
                :scroll-container="mediasContainer"
                class="p-3"
            >
                <template #default="{ item, index }">
                    <image-uploader
                        :modalRef="mediasContainer"
                        class="image-horizontal mb-5"
                        v-if="index == 0"
                    />

                    <div
                        :key="index"
                        :class="[
                            'bg-white overflow-hidden rounded-lg relative cursor-pointer hover:scale-[1.02] border-4',
                            isMediaSelected(item) ? 'border-[var(--main-color)]' : 'border-transparent',
                            defineTypeClass(item)
                        ]"
                        @click="(event:any) => onClickMidia(event, item)"
                    >
                        <div class="actions-media absolute right-3 bottom-3 space-x-2">
                            <button @click="() => handleDeleteMedia(item)" class="p-[6px] bg-[#FCFCFC] rounded-full z-10" id="delete-midia">
                                <span class="sr-only">Delete Mídia</span>

                                <svg id="icon-delete-midia" class="stroke-[var(--main-color)]" width="16" height="17" viewBox="0 0 16 17" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 3.47059H14.25M6.59375 11.7059V6.76471M9.65625 11.7059V6.76471M11.1875 15H5.0625C4.21681 15 3.53125 14.2626 3.53125 13.3529V4.29412C3.53125 3.83929 3.87403 3.47059 4.29687 3.47059H11.9531C12.376 3.47059 12.7188 3.83929 12.7188 4.29412V13.3529C12.7188 14.2626 12.0332 15 11.1875 15ZM6.59375 3.47059H9.65625C10.0791 3.47059 10.4219 3.10188 10.4219 2.64706V1.82353C10.4219 1.36871 10.0791 1 9.65625 1H6.59375C6.17091 1 5.82812 1.36871 5.82812 1.82353V2.64706C5.82812 3.10188 6.17091 3.47059 6.59375 3.47059Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>

                            <button @click="() => handleCopyLink(item)" class="p-[6px] bg-[#FCFCFC] rounded-full z-10" id="copy-link-midia">
                                <span class="sr-only">Copy Link Mídia</span>

                                <svg id="icon-copy-link-midia" class="w-4 h-4 stroke-[var(--main-color)]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"/>
                                </svg>
                            </button>
                        </div>

                        <span v-if="item.name" class="name-media max-w-[50%] absolute py-[3px] px-2 left-3 bottom-3 bg-[#FCFCFC] text-[#060606] rounded-full text-xs truncate">{{ item.name }}</span>

                        <base-image :src="item.content" class="w-full h-full object-cover" />
                    </div>
                </template>
            </masonry-wall>

            <div ref="infiniteScroll" class="mt-2 w-full flex justify-center">
                <p v-if="nextUrl == '' || nextUrl == null">Você não possui mais imagens</p>

                <p v-else-if="loading">Carregando imagens...</p>

                <p v-else>Carregar mais imagens</p>
            </div>
        </template>

        <div 
            v-else
            class="flex flex-col justify-center items-center w-full h-full"
        >
            <img src="@/assets/images/unicorn.png" alt="unicorn" />

            <h4 class="mt-3 text-2xl font-bold">Sem imagens</h4>

            <p class="mt-2 text-center">
                Faça o upload de suas imagens para exibí-las aqui
            </p>

            <div class="w-1/2 h-full mt-6">
                <image-uploader
                    :modalRef="mediasContainer"
                    class="image-horizontal"
                />
            </div>
        </div>

        <button
            class="fixed bottom-6 left-1/2 -translate-x-1/2 py-3 px-10 rounded-full bg-[var(--main-color)] focus:outline-none cursor-pointer text-white font-semibold disabled:opacity-40 disabled:cursor-not-allowed"
            @click="handleAction()"
            :disabled="selectedMedias.length == 0"
        >
            Adicionar
        </button>
    </div>

    <div v-else class="w-full h-full pb-[18vh] overflow-y-scroll scroll-hidden">
        <masonry-wall :items="mediasForLoading" :min-columns="4" :gap="20" class="p-3">
            <template #default="{ item, index }">
                <div :key="item.id" :class="[
                    index / 2 == 0 ? 'image-horizontal' : 'image-vertical',
                    'animate-pulse bg-gray-100 rounded-lg cursor-wait'
                ]"></div>
            </template>
        </masonry-wall>
    </div>
</template>

<style scoped>
.image-vertical {
    @apply h-[20rem] md:h-[28rem] lg:h-[36rem];
}

.image-horizontal {
    @apply h-[11rem] md:h-[15rem] lg:h-[19rem];
}

.actions-media,
.name-media {
    @apply hidden;
}

.image-vertical:hover .actions-media,
.image-horizontal:hover .actions-media,
.image-vertical:hover .name-media,
.image-horizontal:hover .name-media {
    @apply block;
}
</style>
