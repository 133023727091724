<script setup lang="ts">
import { useMediaManagerStore } from '@/stores/modal/media-manager'

const mediaManagerStore = useMediaManagerStore()
</script>

<template>
    <div class="flex items-center space-x-4 mr-8">
        <input
            @input="($event: any) => mediaManagerStore.setSearchMedias($event.target.value)"
            type="text"
            name="search-medias"
            id="search-medias"
            v-model="mediaManagerStore.activeSearchMedias"
            placeholder="Pesquisar por Nome, data, ..."
            class="w-72 h-8 border-b-[1px] border-[#060606] placeholder:text-[#060606] focus:border-[var(--main-color)] outline-none bg-transparent"
        />

        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                d="M14.1057 14.2L17 17M16.0667 9.53333C16.0667 13.1416 13.1416 16.0667 9.53333 16.0667C5.92507 16.0667 3 13.1416 3 9.53333C3 5.92507 5.92507 3 9.53333 3C13.1416 3 16.0667 5.92507 16.0667 9.53333Z"
                stroke="#060606"
                stroke-width="1.5"
                stroke-linecap="round"
            />
        </svg>
    </div>
</template>

<style scoped></style>
