<script setup lang="ts">
import clsx from 'clsx'
import IconRocket from '../atoms/icons/IconRocket.vue'

import { useSiteStore } from '@/stores/site'
import { useApplicationStore } from '@/stores/application';

const siteStore = useSiteStore()
const applicationStore = useApplicationStore()

const goToCheckout = () => {
    window.location.href = `//${applicationStore.wizardUrl}/checkout?token=${siteStore.contract.token}`
}
</script>

<template>
    <div
        :class="
            clsx(
                'h-64 w-full rounded-lg relative',
                applicationStore.className == 'uli' && 'bg-gradient-to-br from-violet-500 via-purple-600 to-indigo-700',
                applicationStore.className != 'uli' && 'bg-gradient-to-bl from-lime-500 via-emerald-500 to-green-600'
            )
        "
    >
        <div class="absolute top-1/2 -translate-y-1/2 left-6 flex flex-col w-1/2 space-y-3">
            <div class="flex flex-col -space-y-1">
                <h2 class="text-xl text-white font-semibold">Publique seu site</h2>

                <span class="text-xs text-white font-light">no seu endereço www profissional</span>
            </div>

            <button
                @click.prevent="goToCheckout"
                class="w-full h-12 bg-white text-[var(--main-color)] font-bold rounded-full z-10"
            >
                Ative agora
            </button>
        </div>

        <IconRocket class="absolute right-4 bottom-0 w-48 md:w-40 z-0" />
    </div>
</template>
