<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import clsx from 'clsx'

import { useDraggable } from '@vueuse/core'

import modal from '@/services/modal'

import BaseImage from '@/components/atoms/BaseImage.vue'

import { useMediaManagerStore } from '@/stores/modal/media-manager'
import { useEditorSidebarStore } from '@/stores/editor/sidebar'

const mediaManagerStore = useMediaManagerStore()
const editorSidebarStore = useEditorSidebarStore()

const props = defineProps({
    label: {
        type: String,
        required: true
    },
    value: {
        type: String
    },
    withFocalPoint: {
        type: Boolean,
        default: false
    },
    withRemove: {
        type: Boolean,
        default: false
    },
    withChange: {
        type: Boolean,
        default: true
    },
    focalPoint: {
        type: Object,
        default: () => {
            return {
                x: 50,
                y: 50
            }
        }
    },
    border: {
        type: Boolean,
        default: true
    },
    classImage: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['change'])

const hasValue = computed(() => {
    return props.value ? true : false
})

const onClickImageSelector = () => {
    editorSidebarStore.setDisabledClosed(true)

    mediaManagerStore.setMaxSelectedMedias(1)

    mediaManagerStore.setIsOpenModal(true)

    mediaManagerStore.setAction(($event: any) => handleChangeImage($event))
}

const handleChangeImage = (image: any) => {
    mediaManagerStore.setIsOpenModal(false)

    mediaManagerStore.setAction(null)

    emit('change', {
        target: 'image',
        value: image
    })

    editorSidebarStore.setDisabledClosed(false)

    if (props.withFocalPoint) {
        emit('change', {
            target: 'focal-point',
            value: {
                x: 50,
                y: 50
            }
        })
    }
}

const containerImage = ref<any>()
const point = ref<HTMLElement | null>(null)

const showRemove = ref(true)
const showChange = ref(true)

const onClickRemoveImage = async () => {
    editorSidebarStore.setDisabledClosed(true)

    const title = 'Remover Imagem'
    const message = 'Deseja realmente remover a imagem?'
    const type = 'danger'
    const confirm = 'Sim, remover'
    const cancel = 'Não, voltar'

    const result = await modal.confirmation(title, message, {
        type: type,
        textConfirm: confirm,
        textCancel: cancel
    })

    if (!result) {
        editorSidebarStore.setDisabledClosed(false)
        return
    }

    emit('change', {
        target: 'image',
        value: null
    })

    editorSidebarStore.setDisabledClosed(false)
}

const computedStyle = computed(() => {
    if (!props.withFocalPoint) return

    if (!containerImage.value || !point.value) return

    return {
        left: `calc(${x.value}px + 20px)`,
        top: `calc(${y.value}px + 20px)`,
        transform: `translate(-50%, -50%)`
    }
})

const loadingFocalPoint = ref(true)

const { x, y } = useDraggable(point, {
    containerElement: containerImage,
    onStart: () => {
        showRemove.value = false
        showChange.value = false 
    },
    onMove: (position) => {
        if (!props.withFocalPoint) return;

        const container = containerImage.value;
        const point = position;

        if (!container || !point) return;

        const xPercentage = (Math.round(point.x) / (container.offsetWidth - 40)) * 100;
        const yPercentage = (Math.round(point.y) / (container.offsetHeight - 40)) * 100;

        emit('change', {
            target: 'focal-point',
            value: {
                x: Math.round(xPercentage),
                y: Math.round(yPercentage)
            }
        });
    },
    onEnd: () => {
        showRemove.value = true
        showChange.value = true
    }
})

const calcFocalPoint = () => {
    loadingFocalPoint.value = true
    
    setTimeout(() => {
        if (!props.withFocalPoint) return

        const pointX = (containerImage.value.offsetWidth - 40) * (props.focalPoint.x / 100);
        const pointY = (containerImage.value.offsetHeight - 40) * (props.focalPoint.y / 100);

        x.value = pointX;
        y.value = pointY;

        loadingFocalPoint.value = false

    }, 500)
}

const content = computed(() => {
    return props.value ? props.value : ''
})

watch(content, () => {
    calcFocalPoint()
})

const isExternalImage = computed(() => {
    const defaultUrls = [
        'https://uli-pictures.s3-sa-east-1.amazonaws.com/panel/before-after-mockup-1.jpg',
        'https://uli-pictures.s3-sa-east-1.amazonaws.com/panel/before-after-mockup-2.jpg',
    ]

    return props.value ? defaultUrls.includes(props.value) : false
})
</script>

<template>
    <div class="space-y-2">
        <span class="text-sm font-semibold text-[#060606] opacity-50">
            {{ label }}
        </span>

        <div class="w-full flex justify-center">
            <button
                v-if="!hasValue"
                @click="onClickImageSelector"
                class="flex justify-center items-center w-full bg-[var(--main-color)] py-[18px] px-6 space-x-4 rounded-full"
            >
                <span class="text-white">Selecionar Imagem</span>

                <svg
                    class="w-6 h-6 fill-[#FAFAFA]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.9857 2.25C19.3999 2.25 19.7357 2.58579 19.7357 3L19.7357 5.26429L22 5.26429C22.4142 5.26429 22.75 5.60008 22.75 6.01429C22.75 6.42851 22.4142 6.76429 22 6.76429L19.7357 6.76429L19.7357 9.02859C19.7357 9.4428 19.3999 9.77859 18.9857 9.77859C18.5715 9.77859 18.2357 9.4428 18.2357 9.02859L18.2357 6.76429L15.9714 6.76429C15.5572 6.76429 15.2214 6.42851 15.2214 6.01429C15.2214 5.60008 15.5572 5.26429 15.9714 5.26429L18.2357 5.26429L18.2357 3C18.2357 2.58579 18.5715 2.25 18.9857 2.25ZM2.25 7.08001C2.25 4.90006 4.0172 3.13287 6.19714 3.13287H13.1243C13.5385 3.13287 13.8743 3.46865 13.8743 3.88287C13.8743 4.29708 13.5385 4.63287 13.1243 4.63287H6.19714C4.84562 4.63287 3.75 5.72849 3.75 7.08001V17.7371C3.75 18.9938 4.69721 20.0292 5.91661 20.1684L14.7417 11.8624C15.0369 11.5846 15.4994 11.5916 15.786 11.8782L19.3014 15.3936V12.4086C19.3014 11.9944 19.6372 11.6586 20.0514 11.6586C20.4656 11.6586 20.8014 11.9944 20.8014 12.4086V17.7371C20.8014 19.9171 19.0342 21.6843 16.8543 21.6843H6.19714C4.0172 21.6843 2.25 19.9171 2.25 17.7371V7.08001ZM19.3014 17.3895C19.1868 17.3549 19.0788 17.2923 18.9882 17.2018L15.2399 13.4534L8.08833 20.1843H16.8543C18.2058 20.1843 19.3014 19.0887 19.3014 17.7371V17.3895ZM7.79571 7.83001C7.32706 7.83001 6.94714 8.20993 6.94714 8.67858C6.94714 9.14723 7.32706 9.52715 7.79571 9.52715C8.26437 9.52715 8.64428 9.14723 8.64428 8.67858C8.64428 8.20993 8.26437 7.83001 7.79571 7.83001ZM5.44714 8.67858C5.44714 7.3815 6.49863 6.33001 7.79571 6.33001C9.09279 6.33001 10.1443 7.3815 10.1443 8.67858C10.1443 9.97566 9.09279 11.0271 7.79571 11.0271C6.49863 11.0271 5.44714 9.97566 5.44714 8.67858Z"
                    />
                </svg>
            </button>

            <div
                :class="clsx(
                    'relative rounded-lg overflow-auto',
                    border && 'border-dashed border-[var(--main-color)] border-2',
                    classImage
                )"
                v-else
            >
                <button 
                    v-show="showRemove"
                    v-if="withRemove"
                    @click="onClickRemoveImage"
                    class="absolute left-2 top-2 z-20"
                >
                    <span class="sr-only">Remove Image</span>

                    <svg
                        class="w-6 h-6 stroke-[#FAFAFA]"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                    >
                        <path d="M18 6L6 18M18 18L6 6" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </button>

                <button
                    v-show="showChange"
                    v-if="withChange"
                    @click="onClickImageSelector"
                    class="absolute right-2 bottom-2 z-20"
                >
                    <span class="sr-only">Change Image</span>

                    <svg
                        class="w-6 h-6 stroke-[#FAFAFA]"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                    >
                        <path
                            d="M16.25 18.5149C18.4918 17.2068 20 14.7617 20 11.9612C20 8.786 18.0611 6.06763 15.3125 4.94525M15.3125 16.217V20H19.0625M8.75 5.48505C6.50824 6.79324 5 9.23833 5 12.0388C5 15.214 6.93891 17.9324 9.6875 19.0547M9.6875 7.78296L9.6875 4L5.9375 4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>

                <div
                    ref="containerImage"
                    class="relative overflow-hidden"
                >
                    <div
                        v-if="props.withFocalPoint && !loadingFocalPoint"
                        :style="computedStyle"
                        @mouseover="() => {showRemove = false; showChange = false}"
                        @mouseleave="() => {showRemove = true; showChange = true}"
                        ref="point"
                        class="cursor-grab absolute h-10 w-10 z-20 border-[3px] border-[#FAFAFA] rounded-lg"
                    ></div>
                    
                    <div
                        class="absolute inset-0 z-10 bg-black bg-opacity-20"
                    ></div>

                    <base-image
                        :src="value"
                        :alt="label"
                        @loaded="() => calcFocalPoint()"
                        :external-image="isExternalImage"
                        class="w-full object-cover"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>
