<script setup lang="ts">
const props = defineProps({
    label: {
        type: String,
        required: true
    },
    placeholder: {
        type: String,
        required: true
    },
    value: {
        type: String
    },
})

const emit = defineEmits(['change'])

const onChangeText = (event: any) => {
    emit('change', {
        value: event.target.value
    })
}
</script>

<template>
    <div class="space-y-3">
        <div class="space-y-2">
            <span class="text-sm font-semibold text-[#060606] opacity-50">
                {{ label }}
            </span>

            <div class="w-full">
                <input
                    @input="onChangeText"
                    class="w-full text-sm bg-[#fcfcfc] outline-none px-4 py-3 rounded border-[#F5F5F5] border-2"
                    type="text"
                    :value="value"
                    :placeholder="placeholder"
                >
            </div>
        </div>
    </div>
</template>

<style scoped></style>
